import * as echarts from 'echarts';
import { WidgetTemplateId, WidgetType } from '../enums/widget.enum';

export type LabelFormatterFn = echarts.LabelFormatterCallback<echarts.DefaultLabelFormatterCallbackParams>;
export type CssSize = number | string;

export type WidgetSettings = {
  hasLegend: boolean;
  noHeader: boolean;
  cardHeight: CssSize;
  useRawDataOnly: boolean;
};

export type WidgetSettingsByTemplateId = { [k in WidgetTemplateId]: WidgetSettings };

const defaultTemplateSettings = {
  hasLegend: true,
  noHeader: false,
  cardHeight: 300 as CssSize,
  useRawDataOnly: false,
} satisfies WidgetSettings;

const defaultDisplayThresholdPercentage = 12;

const defaultFormatLabelFn =
  (total: number, minPercent: number): LabelFormatterFn =>
  (data) => {
    // Calculate percentage of current stackbar.
    const percentage = typeof data.value === 'number' ? (data.value / total) * 100 : 0;

    return percentage >= minPercent ? data?.value?.toString() ?? '' : '';
  };

export const DashboardConstants = {
  standardDashboardCompanyId: 0,
  standardDashboardTitleForm: 'CommonResource',
  maxGridWidth: 12,
  minGridWidth: 4,
  gridGapInPx: 20,
};

export const WidgetConstants = {
  rendererOptions: {
    renderer: 'svg',
  } satisfies NonNullable<Parameters<typeof echarts.init>[2]>,
  widgetFixedHeightInPx: 244 as CssSize, // Increased 20px to lessen the empty space.
  widgetMinWidthInPx: 410 as CssSize,

  componentType: {
    markPoint: 'markPoint',
    series: 'series',
  },

  defaultTemplateSettings,
  widgetSettingsByTemplateId: {
    [WidgetTemplateId.IssuesByStatus]: {
      ...defaultTemplateSettings,
    },
    [WidgetTemplateId.NumberOfIssues]: {
      ...defaultTemplateSettings,
      hasLegend: false,
      cardHeight: 132,
    },
    [WidgetTemplateId.IssuesByCustomers]: {
      ...defaultTemplateSettings,
    },
    [WidgetTemplateId.IssuesByCCAndDueDate]: {
      ...defaultTemplateSettings,
    },
    [WidgetTemplateId.Image]: {
      ...defaultTemplateSettings,
      hasLegend: false,
      useRawDataOnly: true,
    },
    [WidgetTemplateId.IssuesByCCAndStatus]: {
      ...defaultTemplateSettings,
    },
    [WidgetTemplateId.EquipmentFromView]: {
      ...defaultTemplateSettings,
    },
    [WidgetTemplateId.JobFromView]: {
      ...defaultTemplateSettings,
    },
  } satisfies WidgetSettingsByTemplateId,

  resizeOptions: {
    animation: {
      duration: 500,
      easing: 'quadraticOut',
    },
  } satisfies echarts.ResizeOpts,

  general: {
    compact: {
      minimumBarWidth: 25,
      minimumColumnWidth: 35,
      gap: 10,
      minChartHeight: 250 as CssSize,
      minChartWidth: '100%' as CssSize,
      barGridOptions: {
        left: '5px', // 10px for Verdana on iOS
        right: '4%',
        bottom: '10px',
        top: '10px',
        containLabel: true,
      } satisfies echarts.GridComponentOption,
      columnGridOptions: {
        left: '15px', // 20px for Verdana on iOS
        right: '20px',
        bottom: '10px',
        top: '15px',
        containLabel: true,
      } satisfies echarts.GridComponentOption,
    },
    expand: {
      minimumBarWidth: 50,
      minimumColumnWidth: 50,
      gap: 20,
      minChartHeight: '100%' as CssSize,
      minChartWidth: '100%' as CssSize,
      barGridOptions: {
        left: '5px', // 20px for Verdana on iOS
        right: '4%',
        bottom: '10px',
        top: '10px',
        containLabel: true,
      } satisfies echarts.GridComponentOption,
      columnGridOptions: {
        left: '20px',
        right: '20px',
        bottom: '5px',
        top: '10px',
        containLabel: true,
      } satisfies echarts.GridComponentOption,
    },
  },

  isExpand: {
    true: 'expand',
    false: 'compact',
  },

  donut: {
    seriesLabelFormatterGenFn: defaultFormatLabelFn,
    compact: {
      labelDisplayThresholdPercentage: 5,
      circleRadius: ['40%', '100%'],
      circlePosition: ['25%', '50%'],
      chartHeight: '100%' as CssSize,
      legend: {
        show: true,
        orient: 'vertical',
        textStyle: {
          fontFamily: `'Segoe UI', Verdana, Helvetica, Sans-Serif`,
          overflow: 'truncate',
        },
        tooltip: {
          show: true,
          textStyle: {
            fontSize: '12px',
          },
          padding: 3,
        },
        left: '50%',
        type: 'scroll',
        selectedMode: false,
        animation: false,
        pageIcons: {
          vertical: [
            'image://data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWNoZXZyb24tdXAiPjxwYXRoIGQ9Im0xOCAxNS02LTYtNiA2Ii8+PC9zdmc+',
            'image://data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWNoZXZyb24tZG93biI+PHBhdGggZD0ibTYgOSA2IDYgNi02Ii8+PC9zdmc+',
          ],
        },

        // use data to display all legend items
      } as echarts.LegendComponentOption,
      legendWidthInPercentage: 45,
    },
    expand: {
      labelDisplayThresholdPercentage: 5,
      circleRadius: ['35%', '99%'],
      circlePosition: ['50%', '50%'],
      chartHeight: '100%' as CssSize,
      legend: {} as echarts.LegendComponentOption,
    },
    labelColor: 'white',
  },

  stackbar: {
    seriesLabelFormatterGenFn: defaultFormatLabelFn,

    seriesLabelOptions: {
      show: true,
      color: undefined, // Let ECharts decides the color.
    } satisfies echarts.BarSeriesOption['label'],

    labelDisplayThresholdPercentage: defaultDisplayThresholdPercentage,

    compact: {
      axisLabelOptions: {
        overflow: 'truncate',
        width: '50',
        fontFamily: 'inherit',
        hideOverlap: false,
      } satisfies echarts.YAXisComponentOption['axisLabel'],
      emphasis: {
        disabled: true,
        focus: 'none',
      } satisfies echarts.BarSeriesOption['emphasis'],
    },
    expand: {
      axisLabelOptions: {
        overflow: 'breakAll',
        width: '100',
        hideOverlap: false,
        fontFamily: 'inherit',
      } satisfies echarts.YAXisComponentOption['axisLabel'],
      emphasis: {
        disabled: true,
        focus: 'none',
      } satisfies echarts.BarSeriesOption['emphasis'],
    },
  },

  bar: {
    seriesLabelFormatterGenFn: defaultFormatLabelFn,

    seriesLabelOptions: {
      show: true,
      color: undefined, // Let ECharts decides the color.
    } satisfies echarts.BarSeriesOption['label'],

    labelDisplayThresholdPercentage: defaultDisplayThresholdPercentage,

    compact: {
      axisLabelOptions: {
        overflow: 'truncate',
        width: '50',
        fontFamily: 'inherit',
        hideOverlap: false,
      } satisfies echarts.YAXisComponentOption['axisLabel'],
      emphasis: {
        disabled: true,
        focus: 'none',
      } satisfies echarts.BarSeriesOption['emphasis'],
      legend: {
        show: true,
        orient: 'horizontal',
        textStyle: {
          fontFamily: `'Segoe UI', Verdana, Helvetica, Sans-Serif`,
          overflow: 'truncate',
          fontSize: '12px',
        },
        padding: 3,
        top: '0px',
        left: '50%',
        type: 'scroll',
        selectedMode: false,
      } satisfies echarts.LegendComponentOption,
    },
    expand: {
      axisLabelOptions: {
        overflow: 'breakAll',
        width: '100',
        hideOverlap: false,
        fontFamily: 'inherit',
      } satisfies echarts.YAXisComponentOption['axisLabel'],
      emphasis: {
        disabled: true,
        focus: 'none',
      } satisfies echarts.BarSeriesOption['emphasis'],
    },
  },

  column: {
    seriesLabelFormatterGenFn: defaultFormatLabelFn,

    seriesLabelOptions: {
      show: true,
      color: undefined, // Let ECharts decides the color.
      position: 'inside',
      rotate: 90,
    } satisfies echarts.BarSeriesOption['label'],

    labelDisplayThresholdPercentage: defaultDisplayThresholdPercentage,

    compact: {
      axisLabelOptions: {
        overflow: 'truncate',
        width: '75',
        fontFamily: 'inherit',
        hideOverlap: false,
        rotate: 0,
        interval: 0,
      } satisfies echarts.YAXisComponentOption['axisLabel'],
      emphasis: {
        disabled: true,
        focus: 'none',
      } satisfies echarts.BarSeriesOption['emphasis'],
      legend: {
        show: true,
        orient: 'horizontal',
        textStyle: {
          fontFamily: `'Segoe UI', Verdana, Helvetica, Sans-Serif`,
          overflow: 'truncate',
          fontSize: '12px',
        },
        padding: 3,
        top: '0px',
        left: '50%',
        type: 'scroll',
        selectedMode: false,
      } satisfies echarts.LegendComponentOption,
    },
    expand: {
      axisLabelOptions: {
        overflow: 'truncate',
        width: 100,
        hideOverlap: false,
        fontFamily: 'inherit',
        rotate: 0,
        interval: 0,
      } satisfies echarts.YAXisComponentOption['axisLabel'],
      emphasis: {
        disabled: true,
        focus: 'none',
      } satisfies echarts.BarSeriesOption['emphasis'],
    },
  },

  defaultLegendColor: '#555',
  defaultLegendText: '#missingLegend', // Dummy text, this text should not be displayed in UI.

  widgetTypeFromString: {
    donut: WidgetType.Donut,
    number: WidgetType.Number,
    image: WidgetType.Image,
    bar: WidgetType.Bar,
    stackbar: WidgetType.StackBar,
    column: WidgetType.Column,
    dynamic: WidgetType.Dynamic,
  } as Record<string, WidgetType>,
} as const;
