import { CommandButton, Icon, Stack } from '@fluentui/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTracking } from '../../../../common/hooks/useTracking';
import { Skeleton } from '../../../../common/ui/Skeleton/Skeleton';
import { Modules } from '../../../../constants/modules.constants';
import { TrackingEvent } from '../../../../constants/tracking-event.constant';
import { CaptionForm } from '../../../../enums/language.enum';
import { Equipment } from '../../../../models/equipmentDetail/equipment.model';
import { equipmentActions } from '../../../../states/equipment/equipmentSlice';
import { appDispatch } from '../../../../states/store';
import { EquipmentDetailContext } from '../EquipmentDetail';
import './index.scss';

interface IRelatedRowProps {
  isOpenFromRelatedEquipmentDetail?: boolean;
}

const styleButton = {
  flexContainer: {
    width: '100%',
  },
};

export const RelatedRows = (props: IRelatedRowProps) => {
  const [translate] = useTranslation();
  const { equipment, isLoading } = useContext(EquipmentDetailContext);
  const [model, setModel] = useState<Equipment>();
  const { trackEvent } = useTracking({ module: Modules.Equipment });

  useEffect(() => {
    if (equipment) {
      setModel(equipment);
    }
  }, [equipment]);

  const onOpenDocuments = () => {
    appDispatch(equipmentActions.setShowDocumentManagementPanel(true));
    trackEvent(TrackingEvent.Equipment.EquipmentDetail.Detail.RelatedSection.Documents);
  };

  const onOpenPictures = () => {
    appDispatch(equipmentActions.setShowPictureManagement({ isOpen: true }));
    trackEvent(TrackingEvent.Equipment.EquipmentDetail.Detail.RelatedSection.Pictures);
  };

  return (
    <>
      {isLoading ? (
        <Stack tokens={{ childrenGap: 1 }} style={{ marginTop: 10 }}>
          <Skeleton numberElement={1} styles={{ shimmerWrapper: { height: '40px' } }} />
          <Skeleton numberElement={1} styles={{ shimmerWrapper: { height: '40px' } }} />
        </Stack>
      ) : (
        <div className="related-rows">
          <CommandButton styles={styleButton} onClick={onOpenDocuments}>
            <div className="related-button">
              <span>
                {translate('Menu.lblDocuments')} ({model?.documentCount})
              </span>
              <Icon iconName="ChevronRight" />
            </div>
          </CommandButton>
          <CommandButton styles={styleButton} onClick={onOpenPictures}>
            <div className="related-button">
              <span>
                {translate(`${CaptionForm.CaptionResource}.Pictures`)} ({model?.pictureCount})
              </span>
              <Icon iconName="ChevronRight" />
            </div>
          </CommandButton>
        </div>
      )}
    </>
  );
};
