import { IModalStyles, Icon } from '@fluentui/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CommonModal from '../../common/ui/Modal/CommonModal';
import { SearchBox } from '../../common/ui/SearchBox/SearchBox';
import Title from '../../common/ui/Title/Title';
import { CompanyExpressItem } from '../../models/company-express-item.model';
import { CompanyExpressContext } from '../../pages/Layout/MainLayout';
import { getCurrentAuthenticationService } from '../../services/authentication.service';
import { CompanyService } from '../../services/company.service';
import { showMessageBox } from '../../services/modal.service';
import { ModalProps } from '../../types/modal.type';
import { cn } from '../../utils/css.utils';
import { getAuthenticatedProfile, getLoggedAlias } from '../../utils/localStorage.utils';

type Props = ModalProps;

const modalStyle: IModalStyles = {
  root: undefined,
  main: {
    width: '810px',
    height: 'auto',
    maxHeight: '600px',
  },
  scrollableContent: undefined,
  layer: undefined,
  keyboardMoveIconContainer: undefined,
  keyboardMoveIcon: undefined,
};

const SwitchOrg = ({ isOpen, onDismiss }: Props) => {
  const [translate] = useTranslation();
  const { info, setInfo } = useContext(CompanyExpressContext);
  const { companyExpressSetup, expressId } = info;
  const [expressList, setExpressList] = useState<CompanyExpressItem[]>([]);
  const [searchText, setSearchText] = useState<string | undefined>('');
  const [selectedExpressCompanyId, setSelectedExpressCompanyId] = useState<number>();
  const authenticationService = getCurrentAuthenticationService();

  const navigate = useNavigate();

  const getExpressList = async () => {
    if (info) {
      const res = await CompanyService.getListCompanyExpress(companyExpressSetup.companyId, info.contact.email, searchText);
      setExpressList(res);
    }
  };

  useEffect(() => {
    getExpressList();
    return () => {
      setSearchText('');
    };
  }, []);

  const handleSearching = async () => {
    await getExpressList();
  };

  const handleRowClick = (selectedExpressId: number) => {
    setSelectedExpressCompanyId(selectedExpressId);
  };

  const handleSwitchOrganization = async () => {
    if (selectedExpressCompanyId && onDismiss) {
      if (expressId !== selectedExpressCompanyId) {
        var result = await authenticationService?.switchingExpressCompany(selectedExpressCompanyId);
        if (!result.isSuccess) {
          showMessageBox({
            header: <Title text={translate('CommonResource.CannotPerformAction')} />,
            children: translate('CommonResource.CannotSwitchOrg'),
          });
          return;
        }
        setInfo(getAuthenticatedProfile());
        navigateToHomePage();
      }

      if (onDismiss) onDismiss();
    }
  };

  const getDescription = (expressName: string) => {
    return expressName ? `${companyExpressSetup?.companyName} - ${expressName}` : companyExpressSetup?.companyName;
  };

  const navigateToHomePage = () => {
    navigate(`/${getLoggedAlias()}`);
    window.location.reload();
  };

  return (
    <CommonModal
      isOpen={isOpen}
      header={<span className="text-base">{translate('CommonResource.SwitchOrg')}</span>}
      children={
        <>
          <div className="flex flex-col gap-2 py-3">
            <div className="flex items-center justify-between gap-4 px-2 py-4 bg-[#F1E5F1]">
              <Icon iconName="Info" className="text-3xl text-[#8D398E]"></Icon>
              <p className="italic line leading-4 text-[13px]">{translate('CaptionResource.BannerSwitchOrgInfoExp')}</p>
            </div>
            <div>
              <SearchBox
                disabled={false}
                classNames="md:w-1/2"
                searchTextValue={searchText}
                onChange={(_, value) => setSearchText(value)}
                onClear={() => setSearchText('')}
                onSearch={handleSearching}
                placeholder="CommonResource.SearchByCompanyNamePlaceholder"
                style={{ fontSize: 12 }}
              ></SearchBox>
            </div>
            <table className="font-semibold">
              <thead>
                <tr>
                  <th className="border-neutral-400 border p-1 h-8 text-xs">{translate('CaptionResource.Caption0')}</th>
                </tr>
              </thead>
              <tbody className="block w-full h-[300px] max-h-[300px] overflow-auto">
                {expressList.map((item) => (
                  <tr
                    key={item.expressId}
                    className={cn('table table-fixed w-full h-8 cursor-pointer', {
                      'bg-grey30': item.expressId === selectedExpressCompanyId,
                    })}
                    onClick={() => handleRowClick(item.expressId)}
                  >
                    <td className="border-l border-r border-b border-grey30 p-1 w-full text-xs">{getDescription(item.expressName)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <span className="p-1 bg-grey30 leading-7 h-9 text-xs">
              {translate('CaptionResource.Total')}: {expressList.length} {translate('CommonResource.lblItem')}
            </span>
          </div>
        </>
      }
      footer={
        <>
          <button className="oex-btn border border-gray-400 bg-white" onClick={onDismiss}>
            {translate('CaptionResource.Caption53')}
          </button>
          <button className="oex-btn oex-btn-primary uppercase shadow-md" onClick={handleSwitchOrganization}>
            {translate('CaptionResource.CAPTION_OK')}
          </button>
        </>
      }
      onDismiss={onDismiss}
      styles={modalStyle}
      footerClass="py-2"
    />
  );
};

export default SwitchOrg;
