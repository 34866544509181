import { cn } from '../../../utils/css.utils';
import { Icon } from '../Icon/Icon';

type Props = {
  icon: string;
  text: string;
  className?: string;
  iconClassName?: string;
  disabled?: boolean;
  onClick: () => void;
};

const ButtonIcon = ({ icon, text, className: classNameProp, iconClassName, disabled, onClick }: Props) => {
  const className = classNameProp ?? '';

  return (
    <button className={cn('oex-btn-icon', className)} onClick={onClick} disabled={disabled || false}>
      <Icon iconName={icon} className={cn('text-base', iconClassName || '')}></Icon>
      <span>{text}</span>
    </button>
  );
};

export default ButtonIcon;
