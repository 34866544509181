import { IPersonaSharedProps, Persona, PersonaSize } from '@fluentui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useTracking } from '../../common/hooks/useTracking';
import ApplicationSetting from '../../constants/application.constants';
import { DefaultPersona } from '../../constants/common.constants';
import { Modules } from '../../constants/modules.constants';
import { TrackingEvent } from '../../constants/tracking-event.constant';
import { CompanyExpressContext } from '../../pages/Layout/MainLayout';
import { getCurrentAuthenticationService } from '../../services/authentication.service';
import { ModalProps } from '../../types/modal.type';
import { getImageUrlBase64 } from '../../utils/img.utils';

type Props = ModalProps;

const UserCard = ({ isOpen }: Props) => {
  const { trackEvent } = useTracking({ module: Modules.Header });
  const { info } = useContext(CompanyExpressContext);
  const { contact } = info;
  const authenticationService = getCurrentAuthenticationService();

  const personaData: IPersonaSharedProps = {
    ...DefaultPersona,
    imageUrl: getImageUrlBase64(contact.pictureBase64String),
    size: PersonaSize.size72,
  };

  const [translate] = useTranslation();

  const onSignOut = () => {
    authenticationService?.logout();
    trackEvent(TrackingEvent.Header.SignOutAction);
  };
  const onClickMyAccount = () => {
    trackEvent(TrackingEvent.Header.MyOnixAccountAction);
  };

  return (
    <>
      {isOpen && (
        <div className="px-6 pt-1 pb-8 text-black bg-white shadow-depth16 rounded-sm min-w-[320px] min-h-[157px]">
          <div className="flex justify-end gap-4 truncate">
            <span className="cursor-pointer" onClick={onSignOut}>
              {translate('CommonResource.SignOut')}
            </span>
          </div>
          <div className="flex gap-4 mt-5">
            <Persona {...personaData}></Persona>
            <div className="truncate flex flex-col justify-center">
              <h5 className="font-bold">
                {contact.firstName} {contact.lastName}
              </h5>
              <span className="text-mini">{contact.email}</span>
              <a onClick={onClickMyAccount} href={ApplicationSetting.myAccountUrl} className="underline" target="blank">
                {translate('CommonResource.MyOnixAccount')}
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserCard;
