/* eslint-disable react-hooks/rules-of-hooks */
import { DirectionalHint, IColumn, IDetailsColumnProps, Link, TooltipHost } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { onRenderCell, onRenderHeader } from '../../../common/hooks/useTable';
import { OnixTooltip } from '../../../common/ui/OnixTooltip/OnixTooltip';
import { LinkStyles } from '../../../constants/style';
import { TableColumnConstant } from '../../../constants/table-column.constant';
import { EquipmentColumns } from '../../../enums/equipment-columns.enum';
import { EquipmentHelper } from '../../../helpers/equipment-helper';
import { StatusHelper } from '../../../helpers/status-helper';
import { EquipmentListItem } from '../../../models/equipment.model';
import { equipmentActions } from '../../../states/equipment/equipmentSlice';
import { appDispatch, store } from '../../../states/store';
import { cn } from '../../../utils/css.utils';
import { formatDate } from '../../../utils/dateTime';

const iconStyle = 'icon icon16 text-[16px]';

const onRenderEquipmentIdentifier = (equipment: EquipmentListItem): JSX.Element | undefined => {
  return (
    <div>
      <OnixTooltip content={equipment.equipmentIdentifier}>
        <Link
          style={LinkStyles}
          underline
          onClick={() => {
            handleClickLinkButton(equipment);
          }}
        >
          {equipment.equipmentIdentifier}
        </Link>
      </OnixTooltip>
    </div>
  );
};

const handleClickLinkButton = (equipment: EquipmentListItem) => {
  const selectedEquipments = store.getState().equipment.selectedEquipments;
  const isSelectingMultiple = selectedEquipments.length > 1;
  const timeoutOpenDetail = isSelectingMultiple ? 200 : 0;
  if (isSelectingMultiple) {
    appDispatch(equipmentActions.setSelectingSingleLineOnly(true));
  }
  appDispatch(equipmentActions.setSelectedEquipmentId(equipment.equipmentId));
  setTimeout(() => {
    appDispatch(equipmentActions.setShowHidePanel(true));
  }, timeoutOpenDetail);
};

const onRenderIssueDueStatus = (equipment: EquipmentListItem): JSX.Element | undefined => {
  const iconClass = EquipmentHelper.getIssueDueDateIcon(equipment.issueDueStatus);
  const content = iconClass ? <div className={cn(iconClass, iconStyle)}></div> : <></>;
  const tooltip = iconClass ? StatusHelper.getDueDateStatusText(equipment.issueDueStatus) : '';
  return onRenderCell(equipment, undefined, content, <>{tooltip}</>);
};

const onRenderHeaderIssueDueStatus = (props?: IDetailsColumnProps | undefined): JSX.Element | null => {
  return onRenderHeader(props, () => <div className={cn(iconStyle, 'oi-exclamation-circle')}></div>);
};

const onRenderIssueStatus = (equipment: EquipmentListItem): JSX.Element | undefined => {
  const iconClass = EquipmentHelper.getEquipmentStatusIcon(equipment.statusValue);
  const content = iconClass ? <div className={cn(iconClass, iconStyle)}></div> : <></>;
  const tooltip = iconClass ? StatusHelper.getStatusText(equipment.statusValue) : '';
  return onRenderCell(equipment, undefined, content, <>{tooltip}</>);
};

const onRenderHeaderIssueStatus = (props?: IDetailsColumnProps | undefined): JSX.Element | null => {
  return onRenderHeader(props, () => <div className={cn(iconStyle, 'oi-issue')}></div>);
};

const onRenderMandatoryDueStatus = (equipment: EquipmentListItem): JSX.Element | undefined => {
  const iconClass = EquipmentHelper.getNextControlDueIcon(equipment.mandatoryDueStatus);
  const content = iconClass ? <div className={cn(iconClass, iconStyle)}></div> : <></>;
  const tooltip = iconClass ? StatusHelper.getDueDateStatusText(equipment.mandatoryDueStatus) : '';
  return onRenderCell(equipment, undefined, content, <>{tooltip}</>);
};

const onRenderHeaderMandatoryDueStatus = (props?: IDetailsColumnProps | undefined): JSX.Element | null => {
  return onRenderHeader(props, () => <div className={cn(iconStyle, 'oi-custom-due-icon legal')}></div>);
};

const onRenderPreventiveDueStatus = (equipment: EquipmentListItem): JSX.Element | undefined => {
  const iconClass = EquipmentHelper.getNextPreventiveDueIcon(equipment.preventiveDueStatus);
  const content = iconClass ? <div className={cn(iconClass, iconStyle)}></div> : <></>;
  const tooltip = iconClass ? StatusHelper.getDueDateStatusText(equipment.preventiveDueStatus) : '';
  return onRenderCell(equipment, undefined, content, <>{tooltip}</>);
};

const onRenderHeaderPreventiveDueStatus = (props?: IDetailsColumnProps | undefined): JSX.Element | null => {
  return onRenderHeader(props, () => <div className={cn(iconStyle, 'oi-custom-due-icon preventive')}></div>);
};

const onRenderOperatorDueStatus = (equipment: EquipmentListItem): JSX.Element | undefined => {
  const iconClass = EquipmentHelper.getNextOperatorDueIcon(equipment.operatorDueStatus);
  const content = iconClass ? <div className={cn(iconClass, iconStyle)}></div> : <></>;
  const tooltip = iconClass ? StatusHelper.getDueDateStatusText(equipment.operatorDueStatus) : '';
  return onRenderCell(equipment, undefined, content, <>{tooltip}</>);
};

const onRenderHeaderOperatorDueStatus = (props?: IDetailsColumnProps | undefined): JSX.Element | null => {
  return onRenderHeader(props, () => <div className={cn(iconStyle, 'oi-custom-due-icon operator')}></div>);
};

const onRenderProductNo = (equipment: EquipmentListItem): JSX.Element | undefined => {
  return (
    <div>
      <OnixTooltip content={equipment.articleNo}>
        <Link
          style={LinkStyles}
          underline
          onClick={() => {
            handleClickLinkButton(equipment);
          }}
        >
          {equipment.articleNo}
        </Link>
      </OnixTooltip>
    </div>
  );
};

const onRenderDateField = (equipment?: any, column?: IColumn | undefined, isSplitContent?: boolean) => {
  if (column) {
    const dateValue = equipment[column.fieldName ?? ''];
    const displayValue = dateValue ? formatDate(dateValue) : '';
    return onRenderContentTooltip(displayValue);
  }
  return undefined;
};

const onRenderLatestMandatoryDueStatus = (equipment: EquipmentListItem): JSX.Element | undefined => {
  const jobStatus = EquipmentHelper.getJobStatusText(equipment.latestMandatoryJobStatus);
  return onRenderContentTooltip(jobStatus);
};

const onRenderWLLSWL = (equipment: EquipmentListItem): JSX.Element | undefined => {
  const WLLSwl = EquipmentHelper.formatWLLSwl(equipment, true);
  return onRenderContentTooltip(WLLSwl);
};

const onRenderTextField = (equipment?: any, column?: IColumn | undefined): JSX.Element | undefined => {
  const contentId = useId(column?.fieldName ?? '');
  const tooltipId = useId('tooltipId');

  const calloutProps = {
    gapSpace: 1,
    target: `#${contentId}`,
    isBreak: false,
    directionalHint: DirectionalHint.bottomLeftEdge,
  };

  if (column) {
    const textValue = equipment[column.fieldName ?? ''];
    return (
      <TooltipHost id={tooltipId} content={textValue} calloutProps={calloutProps}>
        <div id={contentId} aria-describedby={tooltipId} className="text-three-dots">
          {textValue}
        </div>
      </TooltipHost>
    );
  }
  return undefined;
};

const onRenderContentTooltip = (content: string): JSX.Element | undefined => {
  if (content && content !== '') {
    return (
      <div>
        <OnixTooltip content={content}>{content}</OnixTooltip>
      </div>
    );
  }
  return undefined;
};

export const EquipmentTableColumns = [
  {
    id: EquipmentColumns.ID,
    propertyKey: 'equipmentIdentifier',
    defaultWidth: TableColumnConstant.LargeColumnDimension,
    onRender: onRenderEquipmentIdentifier,
  },
  {
    id: EquipmentColumns.IssueDueStatus,
    propertyKey: 'issueDueStatus',
    isIconOnly: true,
    onRender: onRenderIssueDueStatus,
    onRenderHeader: onRenderHeaderIssueDueStatus,
  },
  {
    id: EquipmentColumns.IssueStatus,
    propertyKey: 'equipmentIsueStatus',
    isIconOnly: true,
    onRender: onRenderIssueStatus,
    onRenderHeader: onRenderHeaderIssueStatus,
  },
  {
    id: EquipmentColumns.MandatoryDueStatus,
    propertyKey: 'mandatoryDueStatus',
    isIconOnly: true,
    onRender: onRenderMandatoryDueStatus,
    onRenderHeader: onRenderHeaderMandatoryDueStatus,
  },
  {
    id: EquipmentColumns.PreventiveDueStatus,
    propertyKey: 'preventiveDueStatus',
    isIconOnly: true,
    onRender: onRenderPreventiveDueStatus,
    onRenderHeader: onRenderHeaderPreventiveDueStatus,
  },
  {
    id: EquipmentColumns.OperatorDueStatus,
    propertyKey: 'operatorDueStatus',
    isIconOnly: true,
    onRender: onRenderOperatorDueStatus,
    onRenderHeader: onRenderHeaderOperatorDueStatus,
  },
  {
    id: EquipmentColumns.ProductNo,
    propertyKey: 'articleNo',
    defaultWidth: TableColumnConstant.StandardColumnDimension,
    onRender: onRenderProductNo,
  },
  {
    id: EquipmentColumns.Type,
    propertyKey: 'type',
    defaultWidth: TableColumnConstant.LargeColumnDimension,
    onRender: onRenderTextField,
  },
  {
    id: EquipmentColumns.Model,
    propertyKey: 'typeInfo',
    defaultWidth: TableColumnConstant.LargeColumnDimension,
    onRender: onRenderTextField,
  },
  {
    id: EquipmentColumns.ControlCategory,
    propertyKey: 'controlCategoryDescription',
    defaultWidth: TableColumnConstant.StandardColumnDimension,
    onRender: onRenderTextField,
  },
  {
    id: EquipmentColumns.WLLSWL,
    propertyKey: 'wllswl',
    defaultWidth: TableColumnConstant.StandardColumnDimension,
    onRender: onRenderWLLSWL,
  },
  {
    id: EquipmentColumns.CertificateNo,
    propertyKey: 'certificate',
    defaultWidth: TableColumnConstant.StandardColumnDimension,
    onRender: onRenderTextField,
  },
  {
    id: EquipmentColumns.Location,
    propertyKey: 'locationDescription',
    defaultWidth: TableColumnConstant.StandardColumnDimension,
    onRender: onRenderTextField,
  },
  {
    id: EquipmentColumns.DueMandatory,
    propertyKey: 'nextControlDue',
    defaultWidth: TableColumnConstant.StandardColumnDimension,
    onRender: onRenderDateField,
  },
  {
    id: EquipmentColumns.LatestMandatoryJobDate,
    propertyKey: 'latestMandatoryJobDate',
    defaultWidth: TableColumnConstant.LargeColumnDimension,
    onRender: onRenderDateField,
  },
  {
    id: EquipmentColumns.LatestMandatoryJobStatus,
    propertyKey: 'latestMandatoryJobStatus',
    defaultWidth: TableColumnConstant.LargeColumnDimension,
    onRender: onRenderLatestMandatoryDueStatus,
  },
];
