import { EquipmentColumnsExcel } from '../../enums/equipment-columns.enum';

class EquipmentField {
  field: number;
  key: string;
  fieldName: string;
  captionKey: string;
  allowGrouping: boolean;

  constructor(field: number, fieldName: string, captionKey: string, allowGrouping: boolean) {
    this.field = field;
    this.key = EquipmentColumnsExcel[field];
    this.fieldName = fieldName;
    this.captionKey = captionKey;
    this.allowGrouping = allowGrouping;
  }
}

const EQUIPMENT_FIELD_COLUMNS: EquipmentField[] = [
  new EquipmentField(EquipmentColumnsExcel.IssueStatus, 'IssueStatus', 'Equipment.JobStatus', true),
  new EquipmentField(EquipmentColumnsExcel.IssueDueStatus, 'IssueDueStatus', 'Equipment.IssueDueStatus', true),
  new EquipmentField(EquipmentColumnsExcel.MandatoryDueStatus, 'MandatoryDueStatus', 'Equipment.LegalJobDueStatus', true),
  new EquipmentField(EquipmentColumnsExcel.PreventiveDueStatus, 'PreventiveDueStatus', 'Equipment.PreventiveJobDueStatus', true),
  new EquipmentField(EquipmentColumnsExcel.OperatorDueStatus, 'OperatorDueStatus', 'Equipment.OperatorJobDueStatus', true),
  new EquipmentField(EquipmentColumnsExcel.ControlCategory, 'ControlCategoryDescription', 'Equipment.ControlCategory', true),
  new EquipmentField(EquipmentColumnsExcel.Type, 'Type', 'Equipment.Type', true),
  new EquipmentField(EquipmentColumnsExcel.Model, 'TypeInfo', 'Equipment.Model', true),
  new EquipmentField(EquipmentColumnsExcel.WLLSWL, 'WLLSWL', 'Article6.Main.WllSwl', true),
  new EquipmentField(EquipmentColumnsExcel.DueMandatory, 'NextControlDue', 'Equipment.LegalJobDueDate', true),
  new EquipmentField(EquipmentColumnsExcel.Location, 'LocationDescription', 'Equipment.Location', true),
  new EquipmentField(EquipmentColumnsExcel.ProductNo, 'ArticleNo', 'Article6.Main.ArticleNo', true),
  new EquipmentField(EquipmentColumnsExcel.CertificateNo, 'Certificate', 'Equipment.CertificateNo', true),
  new EquipmentField(EquipmentColumnsExcel.LatestMandatoryJobDate, 'LatestMandatoryJobDate', 'Equipment.LatestMandatoryJobDate', false),
  new EquipmentField(
    EquipmentColumnsExcel.LatestMandatoryJobStatus,
    'LatestMandatoryJobStatus',
    'Equipment.LatestMandatoryJobStatus',
    false
  ),
  new EquipmentField(EquipmentColumnsExcel.EquipmentIdentifier, 'EquipmentIdentifier', 'CommonResource.ID', false),
];

const getEquipmentFieldColumnByKey = (key: string): EquipmentField | undefined => {
  return EQUIPMENT_FIELD_COLUMNS.find((x) => x.key === key);
};

export { EQUIPMENT_FIELD_COLUMNS, getEquipmentFieldColumnByKey };
