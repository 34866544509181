export enum EquipmentDocumentsColumns {
  ContentType = 1,
  Description = 2,
  DocumentType = 3,
  CreatedDate = 4,
}

export const EquipmentDocumentsDisplayFieldKeyCaptions: Record<number, string> = {
  // JobMainInfo
  [EquipmentDocumentsColumns.ContentType]: 'Document.Documentfiletype',
  [EquipmentDocumentsColumns.Description]: 'CaptionResource.lblDescription',
  [EquipmentDocumentsColumns.DocumentType]: 'Settings.DocumentType',
  [EquipmentDocumentsColumns.CreatedDate]: 'Equipment.CreatedDate',
};
