export enum StatusValues {
  None = 0,
  NC = 1,
  RC = 2,
  MO = 3,
  C = 4,
  OK = 5,
  U = 6,
  M = 7,
  NA = 8,
  I = 9,
}

export enum StatusValueIconShape {
  Circle = 'circle',
  Square = 'square',
}
  