import { TextField as FluentTextField, ICalloutProps, IconButton } from '@fluentui/react';
import { CSSProperties, ForwardedRef, KeyboardEventHandler, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyBoardCodes } from '../../../constants/common.constants';

export interface ISearchBoxRef {
  clear: () => void;
}

export type Props = {
  placeholder?: string;
  classNames?: string;
  calloutProps?: ICalloutProps;
  disabled?: boolean;
  style?: CSSProperties;
  onChange?: (event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
  onSearch?: (newValue?: string) => void;
  onClear?: () => void;
  onEscape?: () => void;
  searchTextValue?: string;
};

export const SearchBox = forwardRef((props: Props, ref: ForwardedRef<ISearchBoxRef>) => {
  const [translate] = useTranslation();
  const [searchText, setSearchText] = useState<string | undefined>('');

  useImperativeHandle(ref, () => ({
    clear() {
      setSearchText('');
    },
  }));

  useEffect(() => {
    if (props.searchTextValue) {
      setSearchText(props.searchTextValue);
    }
  }, [props.searchTextValue]);

  const onChange = (event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setSearchText(newValue);
    props.onChange && props.onChange(undefined, newValue);
  };

  const onClear = () => {
    setSearchText('');
    props.onClear && props.onClear();
  };

  const onSearch = () => {
    props.onSearch && props.onSearch(searchText);
  };

  const onKeyDownEvent: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (ev) => {
    switch (ev.key) {
      case KeyBoardCodes.ENTER:
        onSearch();
        break;
      case KeyBoardCodes.ESCAPE:
        onClear();
        break;
    }
  };

  return (
    <div className={`oex-search-box flex items-center justify-between ${props.classNames ? ' ' + props.classNames : ''}`}>
      <FluentTextField
        value={searchText}
        placeholder={props?.placeholder ? translate(props.placeholder) : undefined}
        disabled={props.disabled}
        style={{
          ...props.style,
        }}
        styles={{
          root: {
            width: '100%',
          },
        }}
        onChange={onChange}
        onKeyDown={onKeyDownEvent}
        onRenderSuffix={() => (
          <div className="flex items-center justify-end w-[30px]">
            {searchText && !props.disabled && (
              <IconButton iconProps={{ iconName: 'Cancel' }} onClick={onClear} className="hover:bg-transparent text-black"></IconButton>
            )}
            <IconButton iconProps={{ iconName: 'Search' }} onClick={onSearch} className="hover:bg-transparent text-[#8D398E] "></IconButton>
          </div>
        )}
      />
    </div>
  );
});
