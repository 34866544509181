import { IOnixTableRequest } from '@onix/common/src/components/OnixTable/IOnixTable';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { OwnerFilterData } from '../../common/ui/FilterControls/NewOwnership/NewOwnership';
import { EquipmentListItem } from '../../models/equipment.model';
import { FieldFilter } from '../../models/filter-controls/search-field.model';
import { RootState } from '../store';

type State = {
  hasEquipmentLicense: boolean;
  selectedEquipments: EquipmentListItem[];
  numOfSelectedEquipments: number;
  selectedEquipmentId: number;
  isShowPanelDetail: boolean;
  isShowPictureManagementPanel: boolean;
  isShowDocumentManagementPanel: boolean;

  totalFilters: number;
  totalItems: number;
  refinedBy: FieldFilter[] | undefined;
  searchId: string | undefined;
  dataFetch: any[] | undefined;
  isSelectingSingleLineOnly: boolean;
  searchFilterDefault?: IOnixTableRequest;

  showPictureManagement: any | undefined;
};

const INTIAL_STATE: State = {
  hasEquipmentLicense: false,
  selectedEquipments: [],
  numOfSelectedEquipments: 0,
  selectedEquipmentId: 0,
  isShowPanelDetail: false,
  isShowPictureManagementPanel: false,
  isShowDocumentManagementPanel: false,

  totalFilters: 0,
  totalItems: 0,
  refinedBy: [],
  searchId: undefined,
  dataFetch: undefined,
  isSelectingSingleLineOnly: false,
  searchFilterDefault: undefined,
  showPictureManagement: undefined,
};

export const equipmentSlice = createSlice({
  name: 'equipment',
  initialState: INTIAL_STATE,
  reducers: {
    setEquipmentLicense: (state, action: PayloadAction<boolean>) => {
      state.hasEquipmentLicense = action.payload;
    },
    setShowPictureManagement: (state, { payload }) => {
      state.showPictureManagement = payload;
    },
    setShowPictureManagementPanel: (state, action: PayloadAction<boolean>) => {
      state.isShowPictureManagementPanel = action.payload;
    },
    setShowHidePanel: (state, action: PayloadAction<boolean>) => {
      state.isShowPanelDetail = action.payload;
    },
    setSelectedEquipmentId: (state, action: PayloadAction<number>) => {
      state.selectedEquipmentId = action.payload;
    },
    updateSectedEquipments: (state, action: PayloadAction<EquipmentListItem[]>) => {
      state.selectedEquipments = action.payload;
      state.numOfSelectedEquipments = action.payload.length;
      if (action.payload !== null && action.payload.length === 1) {
        state.selectedEquipmentId = action.payload[0].equipmentId;
      }
    },
    updateSearchId(state) {
      state.searchId = uuid();
      let newFilterLength = 0;
      if (state.refinedBy) {
        state.refinedBy?.forEach((fieldFilter) => {
          if (
            fieldFilter.fieldName !== OwnerFilterData.OwnerShipProperty &&
            fieldFilter.fieldName !== OwnerFilterData.OwnersProperty &&
            fieldFilter.fieldValue
          ) {
            newFilterLength++;
          }
        });
        state.totalFilters = newFilterLength;
      }
    },
    setNoSearch(state) {
      state.searchId = undefined;
    },
    updateEquipmenttFilter(state, { payload }) {
      state.refinedBy = payload;
    },
    setTotalItems: (state, { payload }: PayloadAction<number>) => {
      state.totalItems = payload;
    },
    setDataFetch(state, { payload }) {
      const data = [...(state.dataFetch ?? []), ...(payload as any)];
      state.dataFetch = data;
    },
    resetData(state) {
      return {
        ...INTIAL_STATE,
        hasEquipmentLicense: state.hasEquipmentLicense,
      };
    },
    setRefinedBy(state, { payload }) {
      state.refinedBy = payload;
    },
    setShowDocumentManagementPanel: (state, action: PayloadAction<boolean>) => {
      state.isShowDocumentManagementPanel = action.payload;
    },
    setSelectingSingleLineOnly: (state, action: PayloadAction<boolean>) => {
      state.isSelectingSingleLineOnly = action.payload;
    },
    setFilterDefault(state, { payload }: PayloadAction<IOnixTableRequest>) {
      state.searchFilterDefault = payload;
    },
  },
});

export const equipmentActions = equipmentSlice.actions;
export const equipmentReducer = equipmentSlice.reducer;

const selectSelf = (state: RootState) => state.equipment;

export const equipmentSelector = createSelector(selectSelf, (state) => state);
export const numOfSelectedEquipmentsSelector = createSelector(selectSelf, (state) => state.numOfSelectedEquipments);
export const allEquipmentsSelector = createSelector(selectSelf, (state) => state.selectedEquipments);
