import { Label } from '@fluentui/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldFilter, FilterControlRef, ISearchFieldProps } from '../../../../models/filter-controls/search-field.model';
import TextField from '../../TextField/TextField';

const TextBox = (props: ISearchFieldProps) => {
  const [translate] = useTranslation();
  const [text, setText] = useState<string>(props.field.value ?? '');

  const onTextChanged = (newValue?: string) => {
    setText(newValue ?? '');
    props.field.value = newValue?.trim();

    if (typeof props.onDataChanged === 'function') {
      setTimeout(props.onDataChanged, 100);
    }
  };

  const onClearData = useCallback(() => {
    setText('');
    props.field.value = '';

    if (typeof props.onDataChanged === 'function') {
      setTimeout(props.onDataChanged, 100);
    }
  }, [props.field, props.onDataChanged]);

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (typeof props.onInvokeApplyFilters === 'function') {
        props.onInvokeApplyFilters();
      }
    }
  };

  const getFilter = useCallback((): FieldFilter | undefined => {
    const value = (props.field.value ?? '').trim();
    if (value !== '') {
      const filter = new FieldFilter();
      filter.fieldName = props.field.key;
      filter.type = props.field.type;
      filter.fieldValue = value;
      return filter;
    }
    return undefined;
  }, [props.field.key, props.field.type, props.field.value]);

  useEffect(() => {
    const controlRef = new FilterControlRef();
    controlRef.onClearData = onClearData;
    controlRef.getFilter = getFilter;
    props.field.controlRef = controlRef;
  }, []);

  return (
    <>
      <Label>
        {translate(props.field.label)}
        {props.labelAdditionalInfo ?? <> {props.labelAdditionalInfo}</>}
      </Label>

      <TextField
        placeholder={props.field.placeholder ? translate(props.field.placeholder) : undefined}
        value={text}
        onChange={(_, newValue) => onTextChanged(newValue)}
        onKeyDown={onKeyDown}
        onBlur={(t) => {
          const tt = t.currentTarget.value ?? '';
          setText(tt.trim());
        }}
        disabled={props.field.disabled}
        className="input-control"
      />
    </>
  );
};

export default TextBox;
