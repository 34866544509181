import { DayOfWeek, IDatePickerStrings } from '@fluentui/react';
import i18n from 'i18next';
const dateTimeFormat = {
  dateFormat: 'dd.MM.yyyy',
  momentDateFormat: 'DD.MM.YYYY',
  momentDateTimeFormat: 'DD.MM.YYYY HH:mm',
  momentDateDashFormat: 'DD-MM-YYYY',
  momentDateDatabaseFormat: 'YYYY-MM-DD',
  stringDateFormat: 'dd.mm.yyyy',
  dateTimeFormat: 'dd.MM.yyyy HH:mm',
  dateDatabaseFormat: 'yyyy-MM-dd',
  dateDashFormat: 'dd-MM-yyyy',
  dateTimeDashFormat: 'yyyy-MM-dd HH:mm:s',
  printDateTimeFormat: 'dd/MM/yyyy, hh:mm A',
  firstDayOfWeek: DayOfWeek.Monday,
};

const getDatePickerStrings = () => {
  const datePicker: IDatePickerStrings = {
    months: i18n.t('CommonResource.datePickerMonths')?.replaceAll(' ', '')?.split(','),
    shortMonths: i18n.t('CommonResource.datePickerShortMonths')?.replaceAll(' ', '')?.split(','),
    days: i18n.t('CommonResource.datePickerDays')?.replaceAll(' ', '')?.split(','),
    shortDays: i18n.t('CommonResource.datePickerShortDays')?.replaceAll(' ', '')?.split(','),
    goToToday: i18n.t('CommonResource.goToToday'),
  };

  return datePicker;
};

export { dateTimeFormat, getDatePickerStrings };
