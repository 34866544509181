import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { ThemeProvider } from '@fluentui/react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './assets/css/global.css';
import './assets/css/tailwind-global.css';
import './assets/scss/_global.scss';
import './assets/scss/index.scss';
import { defaultTheme } from './assets/styles/theme';
import { ApiConstants } from './constants/api.constants';
import { reactPlugin } from './constants/application-insight.constant';
import { store } from './states/store';
import languageConfiguration from './utils/language.utils';

languageConfiguration(ApiConstants.common.loadTranslation());
initializeIcons();
const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider style={{ height: '100%' }} theme={defaultTheme}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <App />
        </AppInsightsContext.Provider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);
