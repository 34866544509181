import i18n from 'i18next';
import { DueDateStatuses } from '../enums/due-date-statuses.enum';
import { StatusValueIconShape, StatusValues } from '../enums/status-value.enum';

export class StatusHelper {
  public static getStatusText(value: number, prefix: boolean = true) {
    const statuses: Record<number, string> = {
      [StatusValues.None]: `${prefix ? '? - ' : ''}${i18n.t('StaticValues.CaptionStatusValueDescription_?')}`,
      [StatusValues.NC]: `${prefix ? 'NC - ' : ''}${i18n.t('StaticValues.CaptionStatusValueDescription_NC')}`,
      [StatusValues.RC]: `${prefix ? 'RC - ' : ''}${i18n.t('StaticValues.CaptionStatusValueDescription_RC')}`,
      [StatusValues.MO]: `${prefix ? 'MO - ' : ''}${i18n.t('StaticValues.CaptionStatusValueDescription_MO')}`,
      [StatusValues.C]: `${prefix ? 'C - ' : ''}${i18n.t('StaticValues.CaptionStatusValueDescription_C')}`,
      [StatusValues.OK]: `${prefix ? 'OK - ' : ''}${i18n.t('StaticValues.CaptionStatusValueDescription_Ok')}`,
      [StatusValues.U]: `${prefix ? 'U - ' : ''}${i18n.t('StaticValues.CaptionStatusValueDescription_U')}`,
      [StatusValues.M]: `${prefix ? 'M - ' : ''}${i18n.t('StaticValues.CaptionStatusValueDescription_M')}`,
      [StatusValues.NA]: `${prefix ? 'NA - ' : ''}${i18n.t('StaticValues.CaptionStatusValueDescription_NA')}`,
      [StatusValues.I]: `${prefix ? 'I - ' : ''}${i18n.t('StaticValues.CaptionStatusValueDescription_I')}`,
    };

    return statuses[value] !== undefined && statuses[value] !== null ? statuses[value] : '';
  }
  //this method for full description of status
  public static getStatusDescription(value: number) {
    const statuses: Record<number, string> = {
      [StatusValues.None]: `${i18n.t('Settings.StatusNoneDescription')}`,
      [StatusValues.NC]: `${i18n.t('Settings.StatusNCDescription')}`,
      [StatusValues.RC]: `${i18n.t('Settings.StatusRCDescription')}`,
      [StatusValues.MO]: `${i18n.t('Settings.StatusMODescription')}`,
      [StatusValues.C]: `${i18n.t('Settings.StatusCDescription')}`,
      [StatusValues.OK]: `${i18n.t('Settings.StatusOKDescription')}`,
      //u is show none
      [StatusValues.U]: `${i18n.t('Settings.StatusNoneDescription')}`,
      [StatusValues.M]: `${i18n.t('Settings.StatusMDescription')}`,
      [StatusValues.NA]: `${i18n.t('Settings.StatusNADescription')}`,
      [StatusValues.I]: `${i18n.t('Settings.StatusIDescription')}`,
    };

    return statuses[value] !== undefined && statuses[value] !== null ? statuses[value] : '';
  }

  public static getStatussIconClassName(value: number, type: StatusValueIconShape = StatusValueIconShape.Square): string {
    const iconStatuses: Record<number, string> = {
      [StatusValues.None]: `oi-status-${type}-question status-icon status-0`,
      [StatusValues.NC]: `oi-status-${type}-nc status-icon status-1`,
      [StatusValues.RC]: `oi-status-${type}-rc status-icon status-2`,
      [StatusValues.MO]: `oi-status-${type}-mo status-icon status-3`,
      [StatusValues.C]: `oi-status-${type}-c status-icon status-4`,
      [StatusValues.OK]: `oi-status-${type}-ok status-icon status-5`,
      [StatusValues.U]: `oi-status-${type}-u status-icon status-6`,
      [StatusValues.I]: `oi-status-${type}-i status-icon status-9`,
      [StatusValues.NA]: `oi-status-${type}-na status-icon status-8`,
    };

    return iconStatuses[value] !== undefined && iconStatuses[value] !== null ? iconStatuses[value] : '';
  }

  public static getDueDateStatusText(status: number) {
    switch (status) {
      case DueDateStatuses.Undefined:
        return i18n.t(`Equipment.NotAvailable`);
      case DueDateStatuses.Alert: //yeallow
        return i18n.t(`Equipment.CloseToDueDate`);
      case DueDateStatuses.Bad: //red
        return i18n.t(`Equipment.Overdue`);
      case DueDateStatuses.Good:
        return i18n.t(`Equipment.Good`);
      case DueDateStatuses.None:
        return i18n.t(`Settings.NoDueDate`);
      default:
        return '';
    }
  }

  public static sortStatusValues(listStatuses: number[]): number[] {
    const expectedSortingStatusValues = [
      StatusValues.None,
      StatusValues.U,
      StatusValues.OK,
      StatusValues.C,
      StatusValues.MO,
      StatusValues.RC,
      StatusValues.NC,
      StatusValues.NA,
      StatusValues.M,
      StatusValues.I,
    ]; // the expected sorting of status.
    const sortedItems = listStatuses.sort((a, b) => {
      const statusValueA = expectedSortingStatusValues.indexOf(a);
      const statusValueB = expectedSortingStatusValues.indexOf(b);
      return statusValueA - statusValueB;
    });
    return sortedItems;
  }
}
