const downloadFileByUrl = async (url: string, fileName: string) => {
  if (url === undefined || url === null || url === '') return;

  let downloadEle = document.createElement('a');
  downloadEle.href = url;
  downloadEle.download = url;
  document.body.appendChild(downloadEle);
  downloadEle.click();
  document.body.removeChild(downloadEle);
};

const downloadFileByUrlIncludeFileName = (url: string) => {
  if (url === undefined || url === null || url === '') return;

  let downloadEle = document.createElement('a');
  downloadEle.href = url;
  document.body.appendChild(downloadEle);
  downloadEle.click();
  document.body.removeChild(downloadEle);
};

const downloadFileByBlob = (blobData: Blob, contentType: string, fileName: string) => {
  // Create a Blob object from the response data
  const blob = new Blob([blobData], { type: contentType });

  // Create a download URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName; // Set the filename extracted from the response header
  link.click();

  // Clean up the URL object to release resources
  window.URL.revokeObjectURL(url);
};

export const FileHelper = {
  downloadFileByUrl,
  downloadFileByUrlIncludeFileName,
  downloadFileByBlob,
};
