import { EquipmentTagType } from '../enums/equipment-tag.enum';

export const EquipmentTagTypeToElement: {
  [k in EquipmentTagType]: {
    icon: string;
  } & (
    | {
        text?: undefined;
        label: string;
      }
    | {
        text: string;
        label?: undefined;
      }
  );
} = {
  [EquipmentTagType.RFID]: {
    text: 'RFID RAIN',
    icon: 'oi-rfid',
  },
  [EquipmentTagType.NFC]: {
    text: 'NFC',
    icon: 'oi-nfc',
  },
  [EquipmentTagType.QR]: {
    text: 'QR',
    icon: 'oi-qr-code',
  },
  [EquipmentTagType.Other]: {
    label: 'CommonResource.Others',
    icon: 'oi-others-tag',
  },
};

export const EquipmentTagTypeOrder = [EquipmentTagType.RFID, EquipmentTagType.NFC, EquipmentTagType.QR, EquipmentTagType.Other];
