import {
  ContextualMenuItemType,
  DirectionalHint,
  IColumn,
  IContextualMenuItem,
  IContextualMenuProps,
  IDetailsColumnProps,
  IGroup,
  Sticky,
} from '@fluentui/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sort } from '../../constants/sort.constants';
import { SortedColumn } from '../../models/sorted-column.model';
import { OnixTooltip } from '../ui/OnixTooltip/OnixTooltip';

export interface IUseTable {
  sortedColumn?: SortedColumn;
  groupColumnName?: string;
  groupColumnKeys?: string[];
  onDefaultFromViewClick?: () => void;
  additionalContextualMenuItems?: IContextualMenuItem[];
}

export const useTable = (props?: IUseTable) => {
  const [translate] = useTranslation();

  const [contextualMenuProps, setContextualMenuProps] = useState<IContextualMenuProps | undefined>(undefined);
  const [sortedColumn, setSortedColumn] = useState<SortedColumn>(props?.sortedColumn ?? { columnName: '', isDescending: false });
  const [groupColumnName, setGroupColumnName] = useState<string | undefined>(props?.groupColumnName ?? undefined);
  const [groups, setGroups] = useState<IGroup[] | undefined>(undefined);

  const headerColumnClick = useCallback(
    (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
      const items: IContextualMenuItem[] = [
        {
          key: sort.ASC,
          canCheck: true,
          checked: column.isSorted && !column.isSortedDescending,
          text: translate('CommonResource.lblSortAZ'),
          onClick: () => {
            const sortedColumn = { columnName: column.key, isDescending: false };
            setSortedColumn(sortedColumn);
          },
        },
        {
          key: sort.DESC,
          canCheck: true,
          checked: column.isSorted && column.isSortedDescending,
          text: translate('CommonResource.lblSortZA'),
          onClick: () => {
            const sortedColumn = { columnName: column.key, isDescending: true };
            setSortedColumn(sortedColumn);
          },
        },
        {
          key: 'divide_1',
          itemType: ContextualMenuItemType.Divider,
        },
      ];
      if (props?.groupColumnKeys && props?.groupColumnKeys.includes(column.key)) {
        items.push({
          key: 'group',
          canCheck: true,
          checked: column.isGrouped,
          text: `${translate('Equipment.GroupBy')} ${column.name}`,
          onClick: () => {
            if (!column.isGrouped) {
              setGroupColumnName(column.key);
              return;
            }
            setGroupColumnName(undefined);
          },
        });
        items.push({
          key: 'divide_2',
          itemType: ContextualMenuItemType.Divider,
        });
      }
      if (props?.onDefaultFromViewClick) {
        items.push({
          key: 'resetFromView',
          canCheck: true,
          checked: false,
          text: translate('Equipment.DefaultFromView'),
          onClick: () => {
            if (props.onDefaultFromViewClick) {
              props.onDefaultFromViewClick();
              return;
            }
            setSortedColumn({ columnName: '', isDescending: false });
            setGroupColumnName(undefined);
          },
        });
      }
      if (props?.additionalContextualMenuItems) {
        props?.additionalContextualMenuItems.forEach((item) => {
          items.push(item);
        });
      }

      const contextualMenuProps = {
        items: items,
        target: ev?.currentTarget as HTMLElement,
        directionalHint: DirectionalHint.bottomLeftEdge,
        gapSpace: 1,
        onDismiss: () => setContextualMenuProps(undefined),
      } as IContextualMenuProps;
      setContextualMenuProps(contextualMenuProps);
    },
    [props?.groupColumnKeys, props?.onDefaultFromViewClick]
  );

  const onRenderHeader = useCallback(
    (columnProps?: IDetailsColumnProps, defaultRender?: (props?: any) => JSX.Element | null): JSX.Element | null => {
      return <OnixTooltip content={columnProps?.column?.name}>{defaultRender ? (defaultRender(columnProps) as any) : <></>}</OnixTooltip>;
    },
    []
  );

  const onRenderCell = useCallback((item: any, column?: IColumn, element?: JSX.Element, tooltipContent?: JSX.Element): JSX.Element => {
    let content = item[column?.fieldName ?? ''];
    if (element) {
      content = <span style={{ display: 'flex', flexDirection: 'column' }}>{element}</span>;
    }
    return <OnixTooltip content={<div data-tooltip>{tooltipContent ?? content}</div>}>{content}</OnixTooltip>;
  }, []);

  const renderDetailsHeader = useCallback((headerProps?: any, defaultRender?: (props?: any) => JSX.Element | null): JSX.Element | null => {
    if (defaultRender) {
      return <Sticky>{defaultRender(headerProps)}</Sticky>;
    }
    return null;
  }, []);

  return {
    sortedColumn,
    setGroupColumnName,
    groupColumnName,
    groups,
    contextualMenuProps,
    setGroups,
    renderDetailsHeader,
    setSortedColumn,
    headerColumnClick,
    onRenderHeader,
    onRenderCell,
  };
};

export const onRenderCell = (item: any, column?: IColumn, element?: JSX.Element, tooltipContent?: JSX.Element): JSX.Element => {
  let content = item[column?.fieldName ?? ''];
  if (element) {
    content = <span>{element}</span>;
  }
  return <OnixTooltip content={<div data-tooltip>{tooltipContent ?? content}</div>}>{content}</OnixTooltip>;
};

export const onRenderHeader = (
  columnProps?: IDetailsColumnProps,
  defaultRender?: (props?: any) => JSX.Element | null
): JSX.Element | null => {
  return <OnixTooltip content={columnProps?.column?.name}>{defaultRender ? (defaultRender(columnProps) as any) : <></>}</OnixTooltip>;
};
