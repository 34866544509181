import { useTranslation } from 'react-i18next';
import { hideModal } from '../../../services/modal.service';
import { MessageBoxProps } from '../../../types/modal.type';
import { cn } from '../../../utils/css.utils';
import PrimaryButton from '../Button/PrimaryButton';
import CommonModal, { CommonModalProps } from './CommonModal';

type Props = CommonModalProps & MessageBoxProps;

const MessageBoxModal = ({ id, isOpen, header, children, primaryButton, primaryButtonClassName, styles: stylesProp }: Props) => {
  const [translate] = useTranslation();

  const closeModal = () => {
    hideModal(id || '');
  };

  return (
    <CommonModal
      isOpen={isOpen}
      header={header}
      children={<div className={cn('py-2 max-w-[90%]')}>{children}</div>}
      footer={
        <PrimaryButton
          text={primaryButton || translate('CaptionResource.CAPTION_OK')}
          onClick={closeModal}
          className={cn('min-w-[50px] uppercase', primaryButtonClassName || '')}
        />
      }
      footerGreyOut={false}
      onDismiss={closeModal}
      onEscape={closeModal}
      styles={stylesProp}
    />
  );
};

export default MessageBoxModal;
