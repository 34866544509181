import jwtDecode from 'jwt-decode';
import { UserManager } from 'oidc-client';
import ApplicationSetting from '../constants/application.constants';
import { SettingKeyConstants } from '../constants/setting-keys.constants';
import { AuthenTypes } from '../enums/authen.enum';
import { appActions } from '../states/app/appSlice';
import { appDispatch } from '../states/store';
import {
  getLocalStorageItem,
  getLoggedAlias,
  getLoggedCompanyId,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../utils/localStorage.utils';
import { getSessionStorageItem } from '../utils/sessionStorage.utils';
import { IAuthenBase, getToken, reloadUserProfile, setToken } from './authentication.service';

/**
 * Service class for handling authentication using OpenID Connect (OIDC).
 */
export class AuthenticationOidcService implements IAuthenBase {
  userManager = new UserManager(ApplicationSetting.oidcSetting);

  constructor() {
    // Subscribe to events raised when the user's sign-in status at the OP has changed
    this.userManager.events.addUserSignedOut(async () => {
      await this.logout({ isCancelCallLogout: true });
    });

    // Subscribe to events raised prior to the access token expiring
    this.userManager.events.addAccessTokenExpiring(async () => {
      const currentToken = getToken();
      if (currentToken) {
        const { companyId } = jwtDecode(currentToken) as { companyId: number };
        await this.refresh({ targetExpressCompanyId: companyId });
      }
    });
  }

  /**
   * Initiates the OIDC login process.
   * @param {Object} params - Additional parameters for the login process.
   */
  public login = (params: any) => {
    this.userManager.signinRedirect({
      extraQueryParams: {
        friendlyAlias: params?.inputAlias,
      },
    });
  };

  /**
   * Initiates the OIDC logout process.
   * @param {Object} params - Additional parameters for the logout process.
   */
  public logout = async (params: any) => {
    try {
      appDispatch(appActions.showLoading());
      var authenticatedUser = await this.userManager.getUser();
      if (authenticatedUser != null) {
        await this.userManager
          .signoutRedirect({
            extraQueryParams: {
              culture: getLocalStorageItem(SettingKeyConstants.Language) || 'en',
              friendlyAlias: getSessionStorageItem(SettingKeyConstants.InputAlias) || getLoggedAlias(),
              companyId: getLoggedCompanyId(),
              isCancelCallLogout: params?.isCancelCallLogout ?? false,
            },
          })
          .then(async () => {
            await this.userManager.clearStaleState();
            await this.userManager.removeUser();
            this.clearLocalStorage();
          });
      }
    } catch (error) {
    } finally {
      appDispatch(appActions.hideLoading());
    }
  };

  /**
   * Switches the current user's associated company within the OIDC authentication context.
   * @param {number} targetCompanyId - The ID of the target company to switch to.
   */
  public switchingExpressCompany = async (targetCompanyId: number) => {
    return await this.refresh({ targetExpressCompanyId: targetCompanyId });
  };

  /**
   * Initiates the refresh token process to obtain a new access token using a refresh token.
   * @param {number} targetCompanyId - The ID of the target company to switch to (optional).
   * @returns {Object} An object indicating the success of the refresh token process.
   */
  public refresh = async (params: any) => {
    try {
      appDispatch(appActions.showLoading());
      const tokenResult = await this.userManager.signinSilent({
        request_express_company_id: params?.targetExpressCompanyId ?? '',
        companyexpert: params?.targetCompanyId ?? '',
        is_workflow_express: true,
      });
      if (tokenResult) {
        setToken(tokenResult.access_token, SettingKeyConstants.AccessToken);
        setToken(tokenResult.refresh_token || '', SettingKeyConstants.RefreshToken);
        await reloadUserProfile();
        return { isSuccess: true };
      }
    } catch (error) {
      return { isSuccess: false };
    } finally {
      appDispatch(appActions.hideLoading());
    }
  };

  /**
   * Completes the OIDC authentication process after a redirection.
   * This method is called after the user is redirected back from the OIDC provider.
   * @returns {Object} An object indicating the success of the authentication process.
   */
  public completeAuthentication = async () => {
    const user = await this.userManager.signinRedirectCallback();
    if (user) {
      const companyexpert = getSessionStorageItem(SettingKeyConstants.CompanyId);
      const tokenResult = await this.userManager.signinSilent({
        companyexpert: companyexpert,
        is_workflow_express: true,
      });
      if (tokenResult) {
        setToken(tokenResult.access_token, SettingKeyConstants.AccessToken, true);
        setToken(tokenResult.refresh_token || '', SettingKeyConstants.RefreshToken, true);
        setLocalStorageItem(SettingKeyConstants.AuthenTypes, AuthenTypes.OIDC.toString());
        await reloadUserProfile();
        return { isSuccess: true };
      }
    }

    return { isSuccess: false };
  };

  /**
   * Clears specific items from the local storage.
   */
  public clearLocalStorage = () => {
    const keys = [
      SettingKeyConstants.AccessToken,
      SettingKeyConstants.RefreshToken,
      SettingKeyConstants.AuthenticatedUserInfo,
      SettingKeyConstants.CompanyId,
    ];
    keys.forEach((key) => removeLocalStorageItem(key));
  };
}
