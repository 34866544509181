import { Icon as FluentIcon, IIconProps, IStyle } from '@fluentui/react';
import { css } from '../../../utils/css.utils';

type IconProps = IIconProps & {
  className?: string;
  size?: number | string;
};

export const Icon = ({ className, size, ...props }: IconProps) => {
  const styles: IStyle = {
    fontSize: size,
  };

  return <FluentIcon {...props} className={css('oex-icon', className || '')} styles={{ root: styles }} />;
};
