import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PreviewDocument } from '../../../common/ui/PreviewDocument/PreviewDocument';
import { documentStateSelector } from '../../../states/document/documentSlice';

export const DocumentPreview = () => {
  const { selectedDocument, searchId, dataFetch: data } = useSelector(documentStateSelector);
  const [translate] = useTranslation();

  return (
    <>
      {selectedDocument ? (
        <PreviewDocument
          fileSize={selectedDocument.fileSize}
          contentType={selectedDocument?.contentType ?? ''}
          documentId={selectedDocument?.documentId ?? 0}
          description={selectedDocument.description}
        ></PreviewDocument>
      ) : searchId && data?.length ? (
        <div className="flex items-center justify-center h-full w-full">{translate('CommonResource.EmptyMessageDataArea')}</div>
      ) : (
        <div></div>
      )}
    </>
  );
};
