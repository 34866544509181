import { useTranslation } from 'react-i18next';
import Toggle from '../../common/ui/Toggle/Toggle';
import { AlertSetting, KeysOfAlertSetting } from '../../models/alert.model';
import { AlignType } from '../../types/common.type';
import { cn } from '../../utils/css.utils';

type Props = {
  setting: AlertSetting;
  titleAlign?: AlignType;
  handleToggle?: (field: KeysOfAlertSetting, checked: boolean | undefined) => void;
};

const EditAlerts = ({ setting: settingProp, titleAlign = 'left', handleToggle }: Props) => {
  const [translate] = useTranslation();

  const orderReceivedText = translate('CommonResource.OrderReceivedFromSupplier');
  const inspectionText = translate('CommonResource.InspectionCompleted');

  return (
    <div>
      <p
        className={cn('font-medium my-2', {
          'text-center': titleAlign === 'center',
        })}
      >
        {translate('CommonResource.GetWeeklyAlertOn')}
      </p>
      <Toggle
        defaultChecked
        onText={orderReceivedText}
        offText={orderReceivedText}
        checked={settingProp.isOrderRecevied}
        onChange={(_, checked) => {
          handleToggle && handleToggle('isOrderRecevied', checked);
        }}
      />
      <Toggle
        defaultChecked
        onText={inspectionText}
        offText={inspectionText}
        checked={settingProp.isInspection}
        onChange={(_, checked) => {
          handleToggle && handleToggle('isInspection', checked);
        }}
      />
    </div>
  );
};

export default EditAlerts;
