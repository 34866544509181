import jwtDecode from 'jwt-decode';
import querystring from 'querystring';
import ApplicationSetting from '../constants/application.constants';
import { SettingKeyConstants } from '../constants/setting-keys.constants';
import { AuthenTypes } from '../enums/authen.enum';
import { getAuthenTypes, getLocalStorageItem, getLoggedExpressId, removeLocalStorageItem } from '../utils/localStorage.utils';
import { IAuthenBase, getRefreshToken, reloadUserProfile, setToken } from './authentication.service';
import { postAsync } from './base.service';

/**
 * Service class for handling authentication using UID delegation.
 */
export class AuthenticationUidService implements IAuthenBase {
  constructor() {
    setInterval(this.checkAndRefreshAccessTokenIntervalJob, 10000);
  }

  /**
   * Initiates the UID-based login process to obtain an access token and refresh token.
   * @param {Object} param - Parameters required for the UID-based login.
   * @returns {Object} An object indicating the success of the login process.
   */
  public login = async (param: any) => {
    try {
      const formDataString = querystring.stringify({
        grant_type: 'uid_delegation',
        client_id: ApplicationSetting.oidcSetting.client_id,
        scope: 'email offline_access onixexpress',
        uid: param?.uid,
        udpexpert: param?.udpexpert,
      });

      const tokenResult = await postAsync(`${ApplicationSetting.oidcSetting.authority}/connect/token`, formDataString, true, {
        'Content-Type': 'application/x-www-form-urlencoded',
      });

      if (tokenResult) {
        setToken(tokenResult.access_token, SettingKeyConstants.AccessToken);
        setToken(tokenResult.refresh_token, SettingKeyConstants.RefreshToken);
        await reloadUserProfile();
        return { isSuccess: true };
      }
    } catch (error) {
      return { isSuccess: false };
    }
    return { isSuccess: false };
  };

  /**
   * Initiates the logout process for the authenticated user.
   * This method revokes the access token and clears related local storage items.
   * @param {Object} params - Additional parameters for the logout process.
   */
  public logout = async (params: any) => {
    try {
      const formDataString = querystring.stringify({
        client_id: ApplicationSetting.oidcSetting.client_id,
        token: getRefreshToken(),
        token_type_hint: 'refresh_token',
      });

      await postAsync(`${ApplicationSetting.oidcSetting.authority}/connect/revocation`, formDataString, true, {
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      this.clearLocalStorage();
      window.location.href = `/${getLocalStorageItem(SettingKeyConstants.InputAlias)}`;
    } catch (error) {}
  };

  /**
   * Initiates the refresh token process to obtain a new access token using a refresh token.
   * @param {number} targetCompanyId - The ID of the target company to switch to (optional).
   * @returns {Object} An object indicating the success of the refresh token process.
   */
  public refresh = async (params: any) => {
    try {
      const formDataString = querystring.stringify({
        grant_type: 'refresh_token',
        client_id: ApplicationSetting.oidcSetting.client_id,
        scope: 'email offline_access onixexpress',
        refresh_token: getRefreshToken(),
        request_express_company_id: params?.targetExpressCompanyId ?? getLoggedExpressId(),
        companyexpert: params?.targetCompanyId ?? '',
        is_workflow_express: true,
      });

      const tokenResult = await postAsync(`${ApplicationSetting.oidcSetting.authority}/connect/token`, formDataString, true, {
        'Content-Type': 'application/x-www-form-urlencoded',
      });

      if (tokenResult) {
        setToken(tokenResult.access_token, SettingKeyConstants.AccessToken);
        setToken(tokenResult.refresh_token, SettingKeyConstants.RefreshToken);
        await reloadUserProfile();
        return { isSuccess: true };
      }
    } catch (error) {
      return { isSuccess: false };
    }
    return { isSuccess: false };
  };

  /**
   * Switches the current user's associated company by initiating a refresh token process.
   * @param {number} targetCompanyId - The ID of the target company to switch to.
   * @returns {Object} An object indicating the success of the company switching process.
   */
  public switchingExpressCompany = async (targetCompanyId: number) => {
    return await this.refresh({ targetExpressCompanyId: targetCompanyId });
  };

  /**
   * Clears specific items from the local storage that are related to the authentication process.
   * This method clears access tokens, refresh tokens, and other relevant data.
   */
  private clearLocalStorage = () => {
    const keys = [
      SettingKeyConstants.AccessToken,
      SettingKeyConstants.RefreshToken,
      SettingKeyConstants.AuthenticatedUserInfo,
      SettingKeyConstants.AuthenTypes,
      SettingKeyConstants.InputUdpExpert,
      SettingKeyConstants.InputUid,
    ];
    keys.forEach((key) => removeLocalStorageItem(key));
  };

  /**
   * Refreshes the access token if it's about to expire.
   */
  private checkAndRefreshAccessTokenIntervalJob = async () => {
    const authenType = getAuthenTypes();
    if (authenType === AuthenTypes.UID) {
      const accessToken = getLocalStorageItem(SettingKeyConstants.AccessToken);
      if (!accessToken) {
        return;
      }

      const { exp } = jwtDecode(accessToken) as { exp: number };
      const currentTime = Date.now();
      const refreshTime = exp * 1000 - 60 * 1000;
      if (currentTime >= refreshTime) {
        await this.refresh({});
      }
    }
  };
}
