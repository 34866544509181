import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { ConfirmModalProps, DiscardModalProps, MessageBoxProps, ModalProps } from '../../types/modal.type';
import { RootState } from '../store';

type ModalState = ModalProps &
  (
    | {
        type: 'discard';
        props: DiscardModalProps;
      }
    | {
        type: 'box';
        props: MessageBoxProps;
      }
    | {
        type: 'confirm';
        props: ConfirmModalProps;
      }
  );

type State = {
  modals: ModalState[];
};

const INITIAL_STATE: State = {
  modals: [],
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState: INITIAL_STATE,
  reducers: {
    showDiscard: (state, action: PayloadAction<DiscardModalProps>) => {
      const payload = action.payload;
      state.modals.unshift({
        id: uuid(),
        isOpen: true,
        type: 'discard',
        props: payload,
      });
    },
    showBox: (state, action: PayloadAction<MessageBoxProps>) => {
      const payload = action.payload;
      state.modals.unshift({
        id: uuid(),
        isOpen: true,
        type: 'box',
        props: payload,
      });
    },
    showConfirm: (state, action: PayloadAction<ConfirmModalProps>) => {
      const payload = action.payload;
      state.modals.unshift({
        id: uuid(),
        isOpen: true,
        type: 'confirm',
        props: payload,
      });
    },
    hideModal: (state, action: PayloadAction<string>) => {
      state.modals = state.modals.filter((x) => x.id !== action.payload);
    },
  },
});

export const modalActions = modalSlice.actions;
export const modalReducer = modalSlice.reducer;

const selectSelf = (state: RootState) => state.modal;

export const modalSelector = createSelector(selectSelf, (state) => state);
