import { CheckboxVisibility, ColumnActionsMode, DetailsRow, IColumn, Selection, SelectionMode } from '@fluentui/react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toUpperFirstLetter } from '../../../../common/helper/string-helper';
import { onRenderCell } from '../../../../common/hooks/useTable';
import { IOnixTableRequest } from '../../../../common/ui/OnixTable/IOnixTable';
import { OnixTable } from '../../../../common/ui/OnixTable/OnixTable';
import { TableColumnConstant } from '../../../../constants/table-column.constant';
import { EquipmentDocumentsColumns, EquipmentDocumentsDisplayFieldKeyCaptions } from '../../../../enums/equipment-document-columns.enum';
import CommonHelper from '../../../../helpers/common-helper';
import { DocumentDto, GetDocumentDto } from '../../../../models/document.model';
import { showCommonDialog } from '../../../../services/dialog.service';
import { documentService } from '../../../../services/document.service';
import { equipmentDocumentActions, equipmentDocumentSelector } from '../../../../states/equipment/equipmentDocumentSlice';
import { equipmentSelector } from '../../../../states/equipment/equipmentSlice';
import { appDispatch } from '../../../../states/store';
import { getLanguageId } from '../../../../utils/localStorage.utils';
import { EquipmentDocumentsTableColumns } from '../Columns/Columns';

const ColumnWidth = TableColumnConstant.StandardColumnDimension;
const pageSize = 50;

export type EquipmentDocumentsTableImperative = {
  clearSelectedDocuments: () => void;
};

const EquipmentDocumentsTableContent = forwardRef((props, ref?: React.Ref<EquipmentDocumentsTableImperative>) => {
  const [translate] = useTranslation();
  const predicateToGetKey = (document: any) => document.documentId;
  const [selection] = useState(
    new Selection({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => {
        const documents = selection.getSelection() as DocumentDto[];
        appDispatch(equipmentDocumentActions.updateSelectedDocuments(documents));
      },
      getKey: (item: any) => predicateToGetKey(item),
    })
  );

  const { searchId, searchText, documentTypeIds } = useSelector(equipmentDocumentSelector);
  const { selectedEquipments } = useSelector(equipmentSelector);

  useImperativeHandle(
    ref,
    () =>
      ({
        clearSelectedDocuments() {
          selection.setAllSelected(false);
        },
      } as EquipmentDocumentsTableImperative),
    []
  );

  const dependencyArrayToGetItems = useMemo(() => {
    return [searchId];
  }, [searchId]);

  useEffect(() => {
    appDispatch(equipmentDocumentActions.updateSearchId());
    return () => {
      appDispatch(equipmentDocumentActions.resetData());
    };
  }, []);

  const columns = useMemo(() => {
    const createIconColumn = (
      key: string,
      propertyKey: string,
      name: string,
      onRender: (item?: any) => JSX.Element | undefined = onRenderCell,
      onRenderHeader?: () => JSX.Element | null
    ): IColumn => {
      return {
        key,
        name,
        isIconOnly: true,
        fieldName: propertyKey,
        minWidth: 20,
        maxWidth: 40,
        isResizable: false,
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onRender: (item) => onRender(item),
        onRenderHeader,
      };
    };

    const createColumn = (
      key: string,
      propertyKey: string,
      name: string,
      maxWidth: number = ColumnWidth,
      onRender: (item?: any, column?: IColumn) => JSX.Element | null = onRenderCell,
      columnProps?: IColumn
    ): IColumn => {
      return {
        key,
        name,
        fieldName: propertyKey,
        minWidth: 40,
        maxWidth: maxWidth,
        isResizable: true,
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onRender: (item, _, column) => onRender(item, column),
        ...columnProps,
      };
    };

    const columns: IColumn[] = [];

    EquipmentDocumentsTableColumns.map((column) => {
      const fieldKeyCaption = EquipmentDocumentsDisplayFieldKeyCaptions[column.id];
      if (column.isIconOnly) {
        columns.push(
          createIconColumn(
            EquipmentDocumentsColumns[column.id],
            column.propertyKey,
            translate(fieldKeyCaption),
            column.onRender,
            column.onRenderHeader as any
          )
        );
      } else {
        columns.push(
          createColumn(
            EquipmentDocumentsColumns[column.id],
            column.propertyKey,
            translate(fieldKeyCaption),
            column.defaultWidth ?? undefined,
            column.onRender as any
          )
        );
      }
    });
    return columns;
  }, [translate]);

  const createPayload = (request: IOnixTableRequest, additionalParams: Record<string, any> = {}): GetDocumentDto => {
    return {
      columnName: toUpperFirstLetter(request.sortedColumn.columnName),
      isDescending: request.sortedColumn.isDescending,
      languageId: getLanguageId(),
      pageNumber: request.pageNumber,
      pageSize: pageSize,
      equipmentIds: selectedEquipments.map((x) => x.equipmentId).join(','),
      searchText: searchText,
      documentTypeIds: documentTypeIds.join(','),
      ...additionalParams,
    } as GetDocumentDto;
  };

  const getDocuments = async (request: IOnixTableRequest) => {
    const payload = createPayload(request, {
      pageSize: pageSize,
      pageNumber: request.pageNumber,
    });

    const equipmentPagination = await documentService.getListDocument(payload);
    return equipmentPagination;
  };
  const getGroupDocuments = async (request: IOnixTableRequest) => {
    const payload = createPayload(request, {
      groupPageNumber: request.groupPageNumber,
      groupColumnName: toUpperFirstLetter(request.groupColumnName),
      isFetchInsideGroup: request.isFetchInsideGroup,
      groupValue: request.groupValue,
      loadedRowNumber: request.loadedRowNumber,
    });

    const result = await documentService.getListDocumentGroup(payload);
    if (request.pageNumber === 0) {
      appDispatch(equipmentDocumentActions.setTotalItems(result.totalItems));
    }
    return result;
  };

  const onGetItems = async (request: IOnixTableRequest) => {
    try {
      if (!searchId) {
        return;
      }
      return request.groupColumnName ? await getGroupDocuments(request) : await getDocuments(request);
    } catch (error) {
      const message = CommonHelper.getErrorMessage(error);
      if (message) {
        showCommonDialog(translate('CaptionResource.CAPTION_ERROR'), translate(message));
      }
    }
  };

  const groupColumnKeys = useMemo(() => {
    return [
      EquipmentDocumentsColumns[EquipmentDocumentsColumns.ContentType],
      EquipmentDocumentsColumns[EquipmentDocumentsColumns.DocumentType],
    ];
  }, []);

  const sortableColumn = useMemo(() => {
    return { columnName: EquipmentDocumentsColumns[EquipmentDocumentsColumns.CreatedDate], isDescending: true };
  }, []);

  const onRenderRow = useCallback((rowProps?: any): JSX.Element | null => {
    return <DetailsRow {...rowProps} onDoubleClick={() => handleRowClick()} />;
  }, []);

  const handleRowClick = () => {
    appDispatch(equipmentDocumentActions.setShowPreviewDocument(true));
  };

  return (
    <OnixTable
      compact
      checkboxVisibility={CheckboxVisibility.always}
      selection={selection}
      dependencyArrayToGetItems={dependencyArrayToGetItems}
      predicateToGetKey={predicateToGetKey}
      columns={columns}
      onGetItems={onGetItems}
      onRenderRow={onRenderRow}
      groupColumnKeys={groupColumnKeys}
      sortableColumn={sortableColumn}
      isGroupedListV2={true}
    />
  );
});

export default EquipmentDocumentsTableContent;
