/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line no-shadow
export enum DateRanges {
  Today = 1,
  NextWeek = 2,
  NextMonth = 3,
  NextQuarter = 4,
  LastWeek = 5,
  LastMonth = 6,
  LastQuarter = 7,
  ThisWeek = 8,
  ThisMonth = 9,
  ThisQuarter = 10,
  ThisYear = 11,
  StartFromToday = 12, //After today.
  EndToday = 13, // Before today (All due)
  OverdueXDays = 14, //e.g: Over due in the next 30 days.
  MissingDueDate = 15, //No due date.

  UntilEndOfThisWeek = 16,
  UntilEndOfThisMonth = 17,
  UntilEndOfThisQuarter = 18,
  UntilEndOfThisYear = 19,

  CloseToDue = 20, //from: Today; to: Today+30days
  Good = 21, //from: Today+31days
  Custom = 22,
  NoDate = 23
}
