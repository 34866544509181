import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTracking } from '../../common/hooks/useTracking';
import ButtonIcon from '../../common/ui/Button/ButtonIcon';
import Shimmer from '../../common/ui/Shimmer/Shimmer';
import InviteColleagues from '../../components/InviteColleagues/InviteColleagues';
import { Modules } from '../../constants/modules.constants';
import { TrackingEvent } from '../../constants/tracking-event.constant';
import { HomeViewInfo } from '../../models/home-view.model';
import { CommonService } from '../../services/common.service';
import { isStringNotNullOrUndefined } from '../../utils/common.utils';
import { getCompanyLogo } from '../../utils/img.utils';
import { CompanyExpressContext } from '../Layout/MainLayout';

const Home = () => {
  const { trackEvent } = useTracking({ module: Modules.HomePage });
  const { info } = useContext(CompanyExpressContext);
  const { companyExpressSetup, expressName } = info;
  const [openInviteColleagues, setOpenInviteColleagues] = useState(false);
  const [translate] = useTranslation();
  const logoModel = {
    imageInfoId: companyExpressSetup.logoImageInfoId,
    imageContent: companyExpressSetup.logoImageContent,
    companyLogo: companyExpressSetup.companyLogo,
  };
  const showLogo = logoModel.imageInfoId > 0 || isStringNotNullOrUndefined(logoModel.companyLogo?.toString());
  const [viewInfo, setViewInfo] = useState<HomeViewInfo>({} as HomeViewInfo);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadViewInfo = async () => {
      setLoading(true);
      try {
        const loadResult = await CommonService.getHomeViewInfo();
        if (loadResult) {
          setViewInfo(loadResult);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    loadViewInfo();
  }, []);

  const homeBannerKey = 'ExpressHomePageBanner';
  const homeBanner = translate(`CommonResource.${homeBannerKey}`);

  return (
    <div className="flex flex-col gap-4 p-6 pb-4 h-full lg:gap-10">
      <div className="flex flex-col gap-6">
        <h4 className="text-[20px]">{translate('CommonResource.TitleDocumentDistributionPortalOf')}</h4>
        <div className="flex flex-col gap-4 text-sm lg:h-[235px] lg:flex-row">
          <div className="flex flex-col justify-between bg-white shadow-depth16 rounded-sm lg:flex-row lg:w-2/3">
            {showLogo && (
              <div className="bg-primarybg flex justify-center items-center flex-1 min-h-[138px] rounded-sm p-6 lg:py-0">
                <img src={getCompanyLogo(logoModel)} alt="" className="max-h-[85%] object-contain" />
              </div>
            )}
            <div className="flex flex-col justify-center leading-5 flex-1 text-mini p-4">
              {loading ? (
                <Shimmer lines={7} />
              ) : (
                <>
                  <h5 className="font-medium uppercase mb-3 text-sm">{companyExpressSetup.companyName}</h5>
                  <span>
                    {viewInfo?.businessRoles
                      ?.sort((x) => x.businessRoleOrder)
                      ?.map((x) => x.captionLanguageDescription)
                      ?.join(', ')}
                  </span>
                  <p>{viewInfo.address1}</p>
                </>
              )}
            </div>
          </div>
          <div className="shadow-depth16 rounded-sm p-6 lg:w-1/3 bg-white">
            {loading ? (
              <Shimmer lines={7} />
            ) : (
              <div className="flex flex-col justify-center items-center gap-2 text-center h-full w-full">
                <h5 className="uppercase font-medium">{expressName}</h5>
                {viewInfo?.expressManagingContact !== 0 && (
                  <div className="text-mini">
                    <span className="font-medium">{translate('CaptionResource.ManagedBy')}:</span>
                    <p>{viewInfo.expressManagingContactName}</p>
                    <p>{viewInfo.expressManagingContactEmail}</p>
                  </div>
                )}
                <ButtonIcon
                  icon="PeopleAdd"
                  text={translate('Home.InviteColleagues')}
                  className="mt-3 shadow-depth8"
                  onClick={() => {
                    setOpenInviteColleagues(true);
                    trackEvent(TrackingEvent.HomePage.InviteColleaguesAction);
                  }}
                ></ButtonIcon>
              </div>
            )}
            {openInviteColleagues && (
              <InviteColleagues isOpen={openInviteColleagues} onDismiss={() => setOpenInviteColleagues(false)}></InviteColleagues>
            )}
          </div>
        </div>
      </div>
      {isStringNotNullOrUndefined(homeBanner) && homeBanner !== `#${homeBannerKey}` && (
        <div className="h-[150px] md:h-[270px] lg:h-[390px] w-full rounded-xl overflow-hidden">
          <iframe
            title="marketting-home-page"
            src={homeBanner}
            id="marketingContent2"
            scrolling="no"
            width="100%"
            height="100%"
            sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default Home;
