import { useEffect, useState } from 'react';
import { Equipment } from '../../models/equipmentDetail/equipment.model';

export const useEquipmentId = (equipment: Equipment) => {
  const [equipmentId, setEquipmentId] = useState('');

  useEffect(() => {
    let equipmentId = '';
    if (!equipment) {
      setEquipmentId(equipmentId);
    }

    if (equipment?.ownerEquipmentId) {
      equipmentId = equipment.ownerEquipmentId;
    } else if (equipment?.serialNumber) {
      equipmentId = equipment.serialNumber;
    } else if (equipment?.batchNo) {
      equipmentId = equipment.batchNo;
    } else if (equipment?.articleNo) {
      equipmentId = equipment.articleNo;
    }
    setEquipmentId(equipmentId);
  }, [equipment]);

  return equipmentId;
};
