import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface IDashboardState {
  hasDashboardLicense: boolean;
}

const initialState: IDashboardState = {
  hasDashboardLicense: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    setDashboardLicense: (state, action: PayloadAction<boolean>) => {
      state.hasDashboardLicense = action.payload;
    },
  },
});

export const dashboardReducer = dashboardSlice.reducer;
const selectSelf = (state: RootState) => state.dashboard;

export const dashboardActions = dashboardSlice.actions;

// Selectors
export const dashboardSelector = createSelector(selectSelf, (state) => state);
