import { CheckboxVisibility, FontIcon, Selection, SelectionMode } from '@fluentui/react';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon } from '../../../common/ui/Icon/Icon';
import { OnixSplitTable } from '../../../common/ui/OnixSplitTable/OnixSplitTable';
import { IOnixTableRequest } from '../../../common/ui/OnixTable/IOnixTable';
import { OnixTooltip } from '../../../common/ui/OnixTooltip/OnixTooltip';
import { colors } from '../../../constants/common.constants';
import { dateTimeFormat } from '../../../constants/dateTime.constant';
import { DocumentHelper } from '../../../helpers/document.helper';
import { DocumentDto, GetDocumentDto } from '../../../models/document.model';
import { SortedColumn } from '../../../models/sorted-column.model';
import { documentService } from '../../../services/document.service';
import { documentActions, documentStateSelector } from '../../../states/document/documentSlice';
import { appDispatch } from '../../../states/store';
import { cn } from '../../../utils/css.utils';
import { getLanguageId } from '../../../utils/localStorage.utils';
interface IDocumentTableContent {}
const pageSize = 50;
const SortDocumentField = {
  DocumentDate: 'DocumentDate',
};

const DocumentTableContent = (props: IDocumentTableContent) => {
  const [translate] = useTranslation();
  const [selection] = useState(
    new Selection({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => {
        const documents = selection.getSelection() as DocumentDto[];
        if (documents.length) {
          const documentSelected = documents[0];
          const document = {
            ...documentSelected,
            Description: documentSelected.description,
            ContentType: documentSelected.contentType,
            DocumentId: documentSelected.documentId,
            // FileSize: documentSelected?.fileSize ?? 0,
          };
          appDispatch(documentActions.selectDocument(document));
        } else {
          appDispatch(documentActions.selectDocument(undefined));
        }
      },
    })
  );
  const { searchId, filterData, dataFetch: data } = useSelector(documentStateSelector);
  const [sortedColumn, setSortedColumn] = useState<SortedColumn>({ columnName: SortDocumentField.DocumentDate, isDescending: true });

  useEffect(() => {
    return () => {
      appDispatch(documentActions.resetData());
    };
  }, []);

  const getDocuments = async (request: IOnixTableRequest) => {
    if (!searchId) {
      return;
    }
    const payload = {
      columnName: sortedColumn.columnName,
      isDescending: sortedColumn.isDescending,
      languageId: getLanguageId(),
      pageNumber: request.pageNumber,
      pageSize: pageSize,
      ...filterData,
    } as GetDocumentDto;
    const documentPagination = await documentService.getListDocument(payload);

    appDispatch(documentActions.setDataFetch(documentPagination.items));
    appDispatch(documentActions.setTotalItems(documentPagination.totalItems));
    return documentPagination;
  };

  const handleSortClick = () => {
    if (searchId) {
      setSortedColumn({ ...sortedColumn, isDescending: !sortedColumn.isDescending });
      appDispatch(documentActions.updateSearchId());
    }
  };

  const onRenderHeaderContent = () => {
    return (
      <div onClick={handleSortClick} className="flex pl-2 gap-1 hover:cursor-pointer">
        <div>{translate('CommonResource.SortByDateLbl')}</div>
        <Icon iconName={sortedColumn.isDescending ? 'Down' : 'Up'} />
      </div>
    );
  };

  const onRenderRowContent = useCallback(
    (item?: DocumentDto) => {
      if (!item) {
        return <></>;
      }

      const content = (
        <>
          <ToolTipText text={item.description} className="text-[14px]"></ToolTipText>
          <ToolTipText text={item.documentType ? translate(`CaptionResource.DocumentType.Content${item.documentType}`) : ''}></ToolTipText>
          <ToolTipText text={item.documentJobForm}></ToolTipText>
          <ToolTipText text={item.equipmentID} className={'text-grey130'}></ToolTipText>
          <ToolTipText
            text={
              !item.equipmentType && !item.equipmentModel ? (
                ''
              ) : (
                <>
                  {item.equipmentType || ''}
                  {!item.equipmentType || !item.equipmentModel ? '' : <> &#9679; </>}
                  {item.equipmentModel || ''}
                </>
              )
            }
            className={'text-grey130'}
          ></ToolTipText>
          <ToolTipText
            text={format(new Date(item.documentDate), dateTimeFormat.dateFormat) ?? '-'}
            className={'text-grey130'}
          ></ToolTipText>
        </>
      );

      return (
        <div className="flex justify-between p-[10px]">
          <div className="left overflow-hidden text-ellipsis">{content}</div>
          <div className="right">
            <OnixTooltip content={DocumentHelper.getDocumentContentTypeName(item)}>
              <FontIcon
                aria-label="Compass"
                className={
                  item.documentSource === 0 || DocumentHelper.getDocumentContentTypeName(item) === 'image'
                    ? ''
                    : item.documentSource === 1
                    ? 'purple'
                    : 'blue'
                }
                iconName={DocumentHelper.getDocumentIconName(item.contentType)}
                style={{ fontSize: 16, color: colors.grey160 }}
              />
            </OnixTooltip>
          </div>
        </div>
      );
    },
    [translate]
  );

  const dependencyArrayToGetItems = useMemo(() => {
    return [searchId];
  }, [searchId]);

  const renderEmpty = useCallback(
    () => (
      <div
        className="text-sm self-start mt-[270px] text-center"
        dangerouslySetInnerHTML={{
          __html: searchId && data !== undefined ? translate('CommonResource.EmptyMessageHits') : translate('Document.searchDocument'),
        }}
      ></div>
    ),
    [data, searchId, translate]
  );

  return (
    <>
      <OnixSplitTable
        compact
        checkboxVisibility={CheckboxVisibility.hidden}
        predicateToGetKey={(document: DocumentDto) => document.documentId}
        selection={selection}
        onGetItems={getDocuments}
        onRenderHeaderContent={onRenderHeaderContent}
        onRenderRowContent={onRenderRowContent}
        dependencyArrayToGetItems={dependencyArrayToGetItems}
        onRenderEmpty={renderEmpty}
      />
    </>
  );
};

export default DocumentTableContent;

const ToolTipText = ({ text, className }: { text?: JSX.Element | string; className?: string }) => (
  <>
    {text ? (
      <OnixTooltip content={text}>
        <div className={cn('text-grey190 truncate line-clamp-1 whitespace-pre-wrap', className)}>{text || '-'}</div>
      </OnixTooltip>
    ) : (
      <div>-</div>
    )}
  </>
);
