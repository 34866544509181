import { Icon, css } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '../../../../common/ui/Skeleton/Skeleton';
import { EquipmentActiveStatusConstants } from '../../../../constants/equipment-active-status.constants';
import { QuantityUsageConstants } from '../../../../constants/quantity-usage.constants';
import { RentalStatuses } from '../../../../enums/rental-status.enum';
import { EquipmentHelper } from '../../../../helpers/equipment-helper';
import { Equipment } from '../../../../models/equipmentDetail/equipment.model';
import './index.scss';

interface Props {
  equipment: Equipment | undefined;
  isLoading: boolean;
}

export const EquipmentHeader = (props: Props) => {
  const [translate] = useTranslation();
  const { equipment, isLoading } = props;

  const isTagged = (equipment?.tagTypes?.length ?? 0) > 0;

  return (
    <div className={css('equipment-header')}>
      {isLoading ? (
        <div className="loading">
          <Skeleton width="100%" />
        </div>
      ) : (
        <>
          <div className="left">
            {isTagged && (
              <>
                <i className="oi-tagged-equipment" />
                <span>{translate('Equipment.Tagged')}</span>
              </>
            )}
          </div>
          <div className="right">
            {equipment?.rentalStatus !== RentalStatuses.None && (
              <span>
                <Icon iconName={EquipmentHelper.getRentalStatusIcon(equipment?.rentalStatus)} />
                {EquipmentHelper.getRentalStatusText(equipment?.rentalStatus)}
              </span>
            )}
            <span
              className={EquipmentHelper.getQuantityUsageIcon(equipment?.quantityUsage as number)}
              style={{ marginRight: '-13px' }}
            ></span>
            <span>{equipment?.quantityUsage !== undefined && translate(QuantityUsageConstants[equipment.quantityUsage])}</span>
            <span>{equipment?.activeStatus && translate(EquipmentActiveStatusConstants[equipment.activeStatus])}</span>
          </div>
        </>
      )}
    </div>
  );
};
