/// This is the implementation for css function, which is used in @microsoft/fluentui

import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { colors } from '../constants/common.constants';

/**
 * Dictionary of booleans.
 *
 * @internal
 */
export interface IDictionary {
  [className: string]: boolean;
}

/**
 * Serializable object.
 *
 * @internal
 */
export interface ISerializableObject {
  toString?: () => string;
}

/**
 * css input type.
 *
 * @internal
 */
export type ICssInput = string | ISerializableObject | IDictionary | null | undefined | boolean;

/**
 * Concatination helper, which can merge class names together. Skips over falsey values.
 *
 * @public
 */
export function css(...args: ICssInput[]): string {
  let classes = [];

  for (let arg of args) {
    if (arg) {
      if (typeof arg === 'string') {
        classes.push(arg);
      } else if (arg.hasOwnProperty('toString') && typeof arg.toString === 'function') {
        classes.push(arg.toString());
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        for (let key in arg as any) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((arg as any)[key]) {
            classes.push(key);
          }
        }
      }
    }
  }

  return classes.join(' ');
}

export function cn(...classes: ClassValue[]) {
  return twMerge(clsx(...classes));
}

export function setupAppColor({ primary, primaryText }: { primary: string; primaryText: string }) {
  const rootEl = document.querySelector(':root') as HTMLElement;
  if (rootEl) {
    rootEl.style.setProperty('--primary-color', primary);
    rootEl.style.setProperty('--text-color', primaryText);
    rootEl.style.setProperty('--primary-purple', colors.primarypurple);
  }
}
