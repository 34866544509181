import { TFunction } from 'i18next';
import { DateRanges } from '../../../enums/date-ranges.enum';
import { DueDateClasses } from '../../../enums/due-date-classes.enum';
import { EquipmentActiveStatuses } from '../../../enums/equipment-active-status.enum';
import { StatusValues } from '../../../enums/status-value.enum';
import { StatusHelper } from '../../../helpers/status-helper';

export const getActiveStatusOptions = (translate: TFunction<'translation', undefined>) => {
  const activeStatusOptions = [
    { key: EquipmentActiveStatuses.Active, text: translate('CaptionResource.EquipmentActiveStatus.1') },
    { key: EquipmentActiveStatuses.Inactive, text: translate('CaptionResource.EquipmentActiveStatus.2') },
    { key: EquipmentActiveStatuses.Discarded, text: translate('CaptionResource.EquipmentActiveStatus.3') },
    { key: EquipmentActiveStatuses.Missing, text: translate('CaptionResource.EquipmentActiveStatus.4') },
  ];

  return activeStatusOptions;
};

export const getEquipmentStatusOptions = (translate: TFunction<'translation', undefined>) => {
  const activeStatusOptions = [
    { key: StatusValues.NC, text: `NC - ${translate('StaticValues.CaptionStatusValueDescription_NC')}`, title: 'NC' },
    { key: StatusValues.RC, text: `RC - ${translate('StaticValues.CaptionStatusValueDescription_RC')}`, title: 'RC' },
    { key: StatusValues.MO, text: `MO - ${translate('StaticValues.CaptionStatusValueDescription_MO')}`, title: 'MO' },
    { key: StatusValues.C, text: `C - ${translate('StaticValues.CaptionStatusValueDescription_C')}`, title: 'C' },
    { key: StatusValues.None, text: translate('Equipment.NoIssues'), title: translate('Equipment.NoIssues') },
  ];

  const keys = StatusHelper.sortStatusValues(activeStatusOptions.map((x) => x.key));
  activeStatusOptions.sort((a, b) => {
    return keys.indexOf(a.key) - keys.indexOf(b.key);
  });
  return activeStatusOptions;
};

export const getCommonDateOptions = () => {
  const commonDateOptions = [
    { key: DateRanges.EndToday, text: 'Equipment.dpRangeUntilToday' },
    { key: DateRanges.Today, text: 'RibbonMenu.Today' },
    { key: DateRanges.ThisWeek, text: 'Equipment.dpRangeThisWeek' },
    { key: DateRanges.ThisMonth, text: 'Equipment.dpRangeThisMonth' },
    { key: DateRanges.ThisQuarter, text: 'Equipment.dpRangeThisQuarter' },
    { key: DateRanges.ThisYear, text: 'Equipment.dpRangeThisYear' },
    { key: DateRanges.LastWeek, text: 'Equipment.dpRangeLastWeek' },
    { key: DateRanges.LastMonth, text: 'Equipment.dpRangeLastMonth' },
    { key: DateRanges.LastQuarter, text: 'Equipment.dpRangeLastQuarter' },
    { key: DateRanges.Custom, text: 'Equipment.dpRangeCustom' },
    { key: DateRanges.NoDate, text: 'Equipment.dpRangeNoDate' },
  ];

  return commonDateOptions;
};

export const getDueDateOptions = (translate: TFunction<'translation', undefined>) => {
  const dueDateOptions = [
    { key: DateRanges.EndToday, text: translate('Equipment.dpRangeAllDue'), type: 'period' },
    { key: DateRanges.OverdueXDays, text: translate('Equipment.dpRangeOverdueIn30Days'), type: 'period' },
    { key: DateRanges.StartFromToday, text: translate('Equipment.dpRangeFromToday'), type: 'period' },
    { key: DateRanges.ThisWeek, text: translate('Equipment.dpRangeThisWeek'), type: 'period' },
    { key: DateRanges.ThisMonth, text: translate('Equipment.dpRangeThisMonth'), type: 'period' },
    { key: DateRanges.ThisQuarter, text: translate('Equipment.dpRangeThisQuarter'), type: 'period' },
    { key: DateRanges.ThisYear, text: translate('Equipment.dpRangeThisYear'), type: 'period' },
    { key: DateRanges.NextWeek, text: translate('Equipment.dpRangeNextWeek'), type: 'period' },
    { key: DateRanges.NextMonth, text: translate('Equipment.dpRangeNextMonth'), type: 'period' },
    { key: DateRanges.NextQuarter, text: translate('Equipment.dpRangeNextQuarter'), type: 'period' },
    { key: DateRanges.LastWeek, text: translate('Equipment.dpRangeLastWeek'), type: 'period' },
    { key: DateRanges.LastMonth, text: translate('Equipment.dpRangeLastMonth'), type: 'period' },
    { key: DateRanges.LastQuarter, text: translate('Equipment.dpRangeLastQuarter'), type: 'period' },
    { key: DateRanges.CloseToDue, text: translate('Equipment.dpRangeCloseToDue'), type: 'period' },
    { key: DateRanges.Good, text: translate('Equipment.dpRangeGood'), type: 'period' },
    { key: DateRanges.MissingDueDate, text: translate('Settings.NoDueDate'), type: 'period' },
    { key: DateRanges.Custom, text: translate('Equipment.dpRangeCustom'), type: 'period' },

    { key: DueDateClasses.DueMandatory, text: translate('Equipment.LegalJobDueDate'), type: 'class' },
    { key: DueDateClasses.DuePreventive, text: translate('Equipment.PreventiveJobDueDate'), type: 'class' },
    { key: DueDateClasses.DueOperator, text: translate('Equipment.OperatorJobDueDate'), type: 'class' },
    { key: DueDateClasses.DueIssue, text: translate('Equipment.IssueDueDate'), type: 'class' },
    { key: DueDateClasses.Custom, text: translate('Equipment.dpRangeCustom'), type: 'class' },
  ];

  return dueDateOptions;
};
