import jwtDecode from 'jwt-decode';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../../common/ui/Loading/Loading';
import { SettingKeyConstants } from '../../constants/setting-keys.constants';
import { AuthenState, AuthenTypes } from '../../enums/authen.enum';
import { authentication, getCurrentAuthenticationService, getToken, hasToken } from '../../services/authentication.service';
import { CommonService } from '../../services/common.service';
import { CompanyExpressSetupService } from '../../services/company-express-setup.service';
import {
  getAuthenTypes,
  getLoggedAlias,
  getLoggedCompanyId,
  getLoggedExpressId,
  getLoggedSupplierName,
  setLocalStorageItem,
  setNumberExpress,
} from '../../utils/localStorage.utils';
import { setSessionStorageItem } from '../../utils/sessionStorage.utils';
import MainLayout from '../Layout/MainLayout';
import NoAccess from '../NoAccess/NoAccess';
import NotAvailable from '../NotAvailable/NotAvailable';
import NotFound from '../NotFound/NotFound';

const AuthenticationOIDC = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const inputAlias = location.pathname.split('/')[1];
  const loggedAlias = getLoggedAlias();
  const [authenState, setAuthenState] = useState<AuthenState>(AuthenState.Loading);
  const navigate = useNavigate();
  const [loggedSupplierName, setLoggedSupplierName] = useState<string>();

  useEffect(() => {
    // Start by checking for the Express License.
    checkAccessRights();
  }, []);

  // PBI : 66760 : When Onix Express license = 0, users cannot access to Onix Express access URL (will be 404)
  // PBI : 69099,69100,69101 : If there is no Onix Express company in that supplier -> back to the Request a user page
  const checkAccessRights = useCallback(async () => {
    if (hasToken() && inputAlias === loggedAlias) {
      const result = await CommonService.checkAccessRights();

      if (!result.hasLicense) {
        setLoggedSupplierName(getLoggedSupplierName());
        setAuthenState(AuthenState.NotAvailable);
        return;
      }

      const authenticationService = getCurrentAuthenticationService();

      if (!result.expressIds || result.expressIds.length <= 0) {
        await authenticationService.refresh({ targetCompanyId: getLoggedCompanyId() });
        setAuthenState(AuthenState.NoAccess);
        return;
      }

      setNumberExpress(result.expressIds.length);

      if (!result.expressIds.includes(getLoggedExpressId())) {
        await authenticationService.refresh({ targetExpressCompanyId: result.expressIds[0] });
      }
    }

    // If the Express License check is successful, proceed to loadElement.
    loadElement();
  }, [navigate]);

  // proceed to loadElement.
  const loadElement = useCallback(async () => {
    const authenticationService = authentication[AuthenTypes.OIDC];
    const authenTypes = getAuthenTypes();

    // user has logged.
    if (hasToken()) {
      // switch uid --> friendlyAlias
      if (authenTypes && authenTypes === AuthenTypes.UID && inputAlias !== loggedAlias) {
        setLocalStorageItem(SettingKeyConstants.InputAlias, inputAlias);
        const currentAuthenticationService = getCurrentAuthenticationService();
        await currentAuthenticationService.logout();
        return;
      }

      if (inputAlias === loggedAlias) {
        // handle for this case : PBI 64819 - [Onix Express] - Onix Express - Renew the invitation flow - Invite a user - wrong navigation when back to login (activate user successfully)
        const slientlogoutParam = searchParams.get('slientlogout');
        const slientlogout = slientlogoutParam === 'true';
        if (slientlogout) {
          setLocalStorageItem(SettingKeyConstants.InputAlias, inputAlias);
          await authenticationService.logout({ isCancelCallLogout: true });
          return;
        }
        const { companyId } = jwtDecode(getToken()) as { companyId: number };
        if (companyId > 0) {
          setAuthenState(AuthenState.MainLayout);
          return;
        }
      }
    }

    const companyIdByAlias = await CompanyExpressSetupService.getCompanyId(inputAlias);
    if (!companyIdByAlias) {
      setAuthenState(AuthenState.NotFound);
    } else {
      setSessionStorageItem(SettingKeyConstants.InputAlias, inputAlias);
      setLocalStorageItem(SettingKeyConstants.InputAlias, inputAlias);
      setSessionStorageItem(SettingKeyConstants.CompanyId, companyIdByAlias.toString());
      if (!hasToken()) {
        authenticationService.login({ inputAlias: inputAlias });
        return;
      } else {
        await authenticationService.logout({ isCancelCallLogout: true });
      }
    }
  }, [inputAlias, loggedAlias]);

  return (
    <>
      {authenState === AuthenState.Loading && <Loading />}
      {authenState === AuthenState.NotFound && <NotFound />}
      {authenState === AuthenState.SingOut && <Navigate to={`/${loggedAlias}/sign-out`} replace={true} />}
      {authenState === AuthenState.MainLayout && <MainLayout />}
      {authenState === AuthenState.NoAccess && <NoAccess />}
      {authenState === AuthenState.NotAvailable && <NotAvailable supplierName={loggedSupplierName} />}
    </>
  );
};

export default AuthenticationOIDC;
