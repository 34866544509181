import i18n from 'i18next';
import { DueDateStatuses } from '../enums/due-date-statuses.enum';
import { QuantityUsage } from '../enums/quantity-usage.enum';
import { RentalStatuses } from '../enums/rental-status.enum';
import { StatusValues } from '../enums/status-value.enum';
import { gGetTransferUrl } from './url.helper';

export class EquipmentHelper {
  public static getActiveStatusText(value: number) {
    return i18n.t(`CaptionResource.EquipmentActiveStatus.${value}`);
  }

  public static getJobStatusText(value: number) {
    const statuses: Record<number, string> = {
      [StatusValues.None]: `? - ${i18n.t('StaticValues.CaptionStatusValueDescription_?')}`,
      [StatusValues.NC]: `NC - ${i18n.t('StaticValues.CaptionStatusValueDescription_NC')}`,
      [StatusValues.RC]: `RC - ${i18n.t('StaticValues.CaptionStatusValueDescription_RC')}`,
      [StatusValues.MO]: `MO - ${i18n.t('StaticValues.CaptionStatusValueDescription_MO')}`,
      [StatusValues.C]: `C - ${i18n.t('StaticValues.CaptionStatusValueDescription_C')}`,
      [StatusValues.OK]: `OK - ${i18n.t('StaticValues.CaptionStatusValueDescription_Ok')}`,
      [StatusValues.U]: `U - ${i18n.t('StaticValues.CaptionStatusValueDescription_U')}`,
      [StatusValues.I]: `I - ${i18n.t('StaticValues.CaptionStatusValueDescription_I')}`,
      [StatusValues.NA]: `NA - ${i18n.t('StaticValues.CaptionStatusValueDescription_NA')}`,
    };

    return statuses[value] !== undefined && statuses[value] !== null ? statuses[value] : '';
  }

  public static getEquipmentStatusIcon(statusValue: number): string {
    const iconStatuses: Record<number, string> = {
      [StatusValues.NC]: 'oi-status-square-nc status-icon status-1',
      [StatusValues.RC]: 'oi-status-square-rc status-icon status-2',
      [StatusValues.MO]: 'oi-status-square-mo status-icon status-3',
      [StatusValues.C]: 'oi-status-square-c status-icon status-4',
      [StatusValues.OK]: 'oi-status-square-ok status-icon status-5',
      [StatusValues.U]: 'oi-status-square-u status-icon status-6',
    };

    return statusValue >= StatusValues.NC && statusValue <= StatusValues.C ? iconStatuses[statusValue] : '';
  }

  public static getNextControlDueIcon(status: number): string {
    if (status !== DueDateStatuses.None) {
      return `oi-jc-legal due-status-${status}`;
    } else {
      return '';
    }
  }

  public static getNextPreventiveDueIcon(status: number): string {
    if (status !== DueDateStatuses.None) {
      return `oi-jc-maint-preventive due-status-${status}`;
    } else {
      return '';
    }
  }

  public static getNextOperatorDueIcon(status: number): string {
    if (status !== DueDateStatuses.None) {
      return `oi-jc-maint-operator due-status-${status}`;
    } else {
      return '';
    }
  }

  public static getIssueDueDateIcon(status: number): string {
    if (status !== DueDateStatuses.None && status !== DueDateStatuses.Good) {
      return `oi-exclamation-circle due-status-${status}`;
    } else {
      return '';
    }
  }

  public static getRentalStatusIcon(value: number | undefined): string {
    switch (value) {
      case RentalStatuses.Rented:
        return 'OIRentedEquipment';
      case RentalStatuses.RentedInternally:
        return 'OIRentedInternalEquipment';
      case RentalStatuses.RentedOut:
        return 'OIRentedOutEquipment';
      default:
        return '';
    }
  }

  public static getRentalStatusText(value: number | undefined): string {
    switch (value) {
      case RentalStatuses.Rented:
        return i18n.t('Equipment.Rented');
      case RentalStatuses.RentedInternally:
        return i18n.t('Equipment.RentedInternally');
      case RentalStatuses.RentedOut:
        return i18n.t('Equipment.RentedOut');
      default:
        return '';
    }
  }

  public static getQuantityUsageIcon(value: number): string {
    switch (value) {
      case QuantityUsage.Stock:
        return 'oi-stock-equipment';
      case QuantityUsage.Consumable:
        return 'oi-consumable-equipment';
      case QuantityUsage.Unique:
        return 'oi-unique-equipment';
      default:
        return '';
    }
  }

  public static generatePictureKey(equipmentId: any, imageContentId: any): string {
    if (equipmentId && imageContentId) {
      return equipmentId + '_' + imageContentId;
    }
    return '';
  }

  public static formatWLLSwl = (item: any, isTableMode: boolean) => {
    let wll = '',
      swl = '';
    if (item !== undefined && item !== null) {
      wll = item.wll === undefined || item.wll === null ? '' : item.wll.trim();
      swl = item.swl === undefined || item.swl === null ? '' : item.swl.trim();
    }

    /* BUG 41987: [OW] Equipment - Table/Split - Display field WLL/SWL must display in the correct way */
    if (isTableMode) {
      if (wll !== '' && swl !== '') {
        return wll + '/' + swl;
      } else if (wll !== '') {
        return 'WLL: ' + wll;
      } else if (swl !== '') {
        return 'SWL: ' + swl;
      } else {
        return '';
      }
    } else {
      if (wll !== '' && swl !== '') {
        return 'WLL/SWL: ' + wll + '/' + swl;
      } else if (wll !== '') {
        return 'WLL: ' + wll;
      } else if (swl !== '') {
        return 'SWL: ' + swl;
      } else {
        return 'WLL/SWL:';
      }
    }
  };
  

  public static getTransferUrlExportDocument = (taskQueueId: number) => {
    if (taskQueueId === undefined) return '';

    return `${gGetTransferUrl(`export`, 'export-xlsx-result')}?taskQueueId=${taskQueueId}`;
  };
}
