import { ApiConstants } from '../constants/api.constants';
import { UserAccountInformation } from '../models/user-account-information.model';
import { getAsync, putAsync } from './base.service';

const getInformationByIdAsync = async (userAccountId: number): Promise<UserAccountInformation> => {
  return await getAsync(ApiConstants.userAccount.getInformationByIdAsync(userAccountId));
};

const updatePreferredLanguage = async (languageId: number): Promise<boolean> => {
  return await putAsync(ApiConstants.userAccount.updatePreferredLanguage(languageId));
};

export const UserAccountService = {
  getInformationByIdAsync,
  updatePreferredLanguage,
};
