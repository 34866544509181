import {
  addDays,
  addHours,
  addMonths,
  addQuarters,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  isEqual,
  maxTime,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import moment from 'moment';
import { dateTimeFormat } from '../constants/dateTime.constant';
import { DateRanges } from '../enums/date-ranges.enum';

export const formatDate = (date: any, format = dateTimeFormat.momentDateFormat) => (date ? moment(date).format(format) : date);

export const formatDateTime = (date: any, format = dateTimeFormat.momentDateTimeFormat) => (date ? moment(date).format(format) : date);

export const formatDateTimeForAPI = (date: any) => (date ? moment(date).utc().format() : date);

export const formatDateTimeConversational = (date: any) => (date ? moment(date).fromNow() : date);

export const getToday = (): Date => {
  return new Date(new Date().toDateString());
};

export const getDateTimeToday = (): Date => {
  return new Date();
};

export const getFromToFromDateRange = (dateRangeValue: DateRanges): { from: Date; to: Date } => {
  let from = new Date(0);
  let to = new Date(maxTime);
  const today = getToday();

  switch (dateRangeValue) {
    case DateRanges.Today:
      from = today;
      to = today;
      break;
    case DateRanges.NextWeek:
      from = addWeeks(startOfWeek(today, { weekStartsOn: 1 }), 1);
      to = addWeeks(endOfWeek(today, { weekStartsOn: 1 }), 1);
      break;
    case DateRanges.NextMonth:
      from = startOfMonth(addMonths(today, 1));
      to = endOfMonth(addMonths(today, 1));
      break;
    case DateRanges.NextQuarter:
      from = startOfQuarter(addQuarters(today, 1));
      to = endOfQuarter(addQuarters(today, 1));
      break;
    case DateRanges.LastWeek:
      from = addWeeks(startOfWeek(today, { weekStartsOn: 1 }), -1);
      to = addWeeks(endOfWeek(today, { weekStartsOn: 1 }), -1);
      break;
    case DateRanges.LastMonth:
      from = startOfMonth(addMonths(today, -1));
      to = endOfMonth(addMonths(today, -1));
      break;
    case DateRanges.LastQuarter:
      from = startOfQuarter(addQuarters(today, -1));
      to = endOfQuarter(addQuarters(today, -1));
      break;
    case DateRanges.ThisWeek:
      from = startOfWeek(today, { weekStartsOn: 1 });
      to = endOfWeek(today, { weekStartsOn: 1 });
      break;
    case DateRanges.ThisMonth:
      from = startOfMonth(today);
      to = endOfMonth(today);
      break;
    case DateRanges.ThisQuarter:
      from = startOfQuarter(today);
      to = endOfQuarter(today);
      break;
    case DateRanges.ThisYear:
      from = startOfYear(today);
      to = endOfYear(today);
      break;
    case DateRanges.StartFromToday:
      from = today;
      break;
    case DateRanges.EndToday:
      to = today;
      break;
    case DateRanges.OverdueXDays:
      to = addDays(today, 30);
      break;
    case DateRanges.UntilEndOfThisWeek:
      to = endOfWeek(today, { weekStartsOn: 1 });
      break;
    case DateRanges.UntilEndOfThisMonth:
      to = endOfMonth(today);
      break;
    case DateRanges.UntilEndOfThisQuarter:
      to = endOfQuarter(today);
      break;
    case DateRanges.UntilEndOfThisYear:
      to = endOfYear(today);
      break;
    case DateRanges.CloseToDue:
      from = addDays(today, 1);
      to = addDays(today, 30);
      break;
    case DateRanges.Good:
      from = addDays(today, 31);
      break;
    default:
      //unexpected here.
      break;
  }

  if (!isEqual(to, new Date(maxTime))) {
    to = endOfDay(to);
  }
  return { from, to };
};

export const parseDate = (date: string | Date | undefined): Date | undefined => {
  const momentResult = moment(date, dateTimeFormat.momentDateFormat);

  if (momentResult.isValid()) {
    return momentResult.toDate();
  }

  return undefined;
};

export const getDateOnly = (date: string | Date | undefined): Date | undefined => {
  const parsedDate = parseDate(date);

  if (parsedDate) {
    parsedDate.setHours(0, 0, 0, 0);

    return parsedDate;
  }
};

export const isValidDate = (date: Date | undefined): boolean => !isNaN(date?.getTime() as any);

export const parseDateDrilldownDate = (date: string | undefined): Date | undefined => {
  const inputFormat = date?.includes('.') ? dateTimeFormat.momentDateFormat : dateTimeFormat.momentDateDashFormat;

  return moment(date, inputFormat).toDate();
};

// The time format must not have "+XXXX" behind.
// Valid: 2023-09-27T13:21:40.78
export function parseDateOffsetNorwayUTC(date: string): Date | undefined {
  const result = addHours(new Date(date), -1); // Offset by 1 hour due to server time.

  if (!result) {
    return undefined;
  }

  return result;
}
