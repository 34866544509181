import { WebStorageStateStore } from 'oidc-client';
import { EnvironmentSettings } from '../models/app-settings.model';

export const environment: EnvironmentSettings = {
  appName: 'onix-express',
  clientApplicationId: 20,
  environment: 'prod',
  apiUrl: 'https://onix-express-api.azurewebsites.net',
  myAccountUrl: 'https://myaccount.onix.com/',
  identityApiUrl: 'https://login.onix.com',
  transferUrl: 'https://onix-transfer.azurewebsites.net',
  enableBanner: false,
  applicationInsightKey: '9a7da13c-b490-48f1-b1cb-15f7306dc560',
  applicationInsightBufferKey: 'applicationInsightBuffer',
  oidcSetting: {
    authority: 'https://login.onix.com',
    client_id: 'yYunpu4gxXyU2aYAsKe3gZhCwafaDujX44',
    redirect_uri: 'https://express.onix.com/auth-callback',
    post_logout_redirect_uri: 'https://express.onix.com/logout',
    response_type: 'code',
    scope: 'openid email onixexpress offline_access',
    filterProtocolClaims: false,
    loadUserInfo: false,
    automaticSilentRenew: false,
    //checkSessionInterval: 2000,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    stateStore: new WebStorageStateStore({ store: window.localStorage }),
    monitorSession: true,
    includeIdTokenInSilentRenew: false,
    revokeAccessTokenOnSignout: true,
  },
};
