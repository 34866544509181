export enum JobClass {
    Mandatory = 1,
    Preventive = 2,
    Operator = 3
  }
  
  export enum JobFilingStatus {
    None = 0,
    NotInQueue = 1,
    InQueue = 2,
    Filed = 3
  }
  
  export enum JobViewFilterType {
    JobField = 0,
  }
  
  export enum JobViewDisplayFieldType {
    JobField = 0,
  }