import ApplicationSetting from "../constants/application.constants";
import CommonHelper from "./common-helper";

export const gGetUrl = (controller: string, action: string) => {
  if (controller === undefined || controller === null || controller === '' || action === undefined || action === null || action === '') {
    return '';
  }

  return `${ApplicationSetting.apiUrl}/api/${controller}/${action}`;
}

export const gGetTransferUrl = (controller: string, action: string = '') => {
  if (CommonHelper.isEmptyOrWhiteSpaces(controller)) {
    return '';
  }

  return `${ApplicationSetting.transferUrl}/${controller}/${action}`;
};