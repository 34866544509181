import { Link } from '@fluentui/react';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedEffect } from '../../../../common/hooks/useDebouncedEffect';
import { useTracking } from '../../../../common/hooks/useTracking';
import { DetailRow } from '../../../../common/ui/DetailRow/DetailRow';
import { Skeleton } from '../../../../common/ui/Skeleton/Skeleton';
import { EquipmentActiveStatusConstants } from '../../../../constants/equipment-active-status.constants';
import { EquipmentTagTypeToElement } from '../../../../constants/equipment-tag.constants';
import { Modules } from '../../../../constants/modules.constants';
import { SettingKeyConstants } from '../../../../constants/setting-keys.constants';
import { TrackingEvent } from '../../../../constants/tracking-event.constant';
import CommonHelper from '../../../../helpers/common-helper';
import { EquipmentTagHelper } from '../../../../helpers/equipment-tag-helper';
import { CommonService } from '../../../../services/common.service';
import { EquipmentDetailContext } from '../EquipmentDetail';
import './index.scss';

const formatDate = 'DD.MM.YYYY';

interface DisplayAllFieldLocalStorage {
  userAccountId: string;
  isHiddenFields: boolean;
}
const DisplayAllFieldLocalStorageKey = SettingKeyConstants.EquipmentDetailDisplayAllFields;

export const Detail = () => {
  const [translate] = useTranslation();
  const [isHiddenFields, setHiddenFields] = useState(false);
  const { equipment, isLoading } = useContext(EquipmentDetailContext);
  const { trackEvent } = useTracking({ module: Modules.Equipment });
  useEffect(() => {
    const dataStorage = CommonService.getLocalStorageByKey(DisplayAllFieldLocalStorageKey);
    let values = dataStorage ? (JSON.parse(dataStorage) as DisplayAllFieldLocalStorage[]) : [];
    if (!Array.isArray(values)) {
      values = [];
    }
    const valueByUserAccount = values.find((m) => m.userAccountId === CommonHelper.userAccountId);
    if (valueByUserAccount) {
      setHiddenFields(true);
    }
  }, []);

  useDebouncedEffect(
    () => {
      const newValue = {
        userAccountId: CommonHelper.userAccountId,
        isHiddenFields,
      } as DisplayAllFieldLocalStorage;
      const dataStorage = CommonService.getLocalStorageByKey(DisplayAllFieldLocalStorageKey);
      let values = dataStorage ? (JSON.parse(dataStorage) as DisplayAllFieldLocalStorage[]) : [];
      if (!Array.isArray(values)) {
        values = [];
      }
      const valueByUserAccount = values.find((m) => m.userAccountId === newValue.userAccountId);
      if (valueByUserAccount) {
        valueByUserAccount.isHiddenFields = isHiddenFields;
      } else {
        values = values.concat([newValue]);
        setHiddenFields(true);
      }
      CommonService.setLocalStorage(DisplayAllFieldLocalStorageKey, JSON.stringify(values));
    },
    [isHiddenFields],
    1000
  );

  const appearOrHiddenFields = useCallback(() => {
    const newValue = !isHiddenFields;
    setHiddenFields(newValue);
    trackEvent(TrackingEvent.Equipment.EquipmentDetail.Detail.EquipmentDetails.ShowHideAllFields, {
      ShowOrHide: isHiddenFields ? 'Show' : 'Hide',
    });
  }, [isHiddenFields]);

  const tagTypesElement = equipment?.tagTypes?.length ? (
    <div className="equipment-details-tag-info">
      {EquipmentTagHelper.sortTagList(equipment?.tagTypes)
        .map((tagType, index) => ({ ...EquipmentTagTypeToElement[tagType], index }))
        .map(({ text, label, icon, index }) => (
          <div key={index} className="tag-info">
            <i className={icon} />
            {text ?? translate(label)}
          </div>
        ))}
    </div>
  ) : undefined;

  return (
    <div className="equipment-detail-section section">
      {isLoading ? (
        <Skeleton className="section-row" numberElement={6} />
      ) : (
        <>
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblProductNo" value={equipment?.articleNo} />
          <DetailRow
            isHidden={isHiddenFields}
            caption="Equipment.lblActiveStatus"
            value={equipment?.activeStatus && translate(EquipmentActiveStatusConstants[equipment.activeStatus])}
          />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblSerialNo" value={equipment?.serialNumber} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblBatchNo" value={equipment?.batchNo} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblOwnerEquipmentId" value={equipment?.ownerEquipmentId} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblOnixID" value={equipment?.secondaryQRCode} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblEPC" value={equipment?.globalId} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblTagType" value={tagTypesElement} isDisplayColonOnCaption />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblControlCategory" value={equipment?.controlCategoryDescription} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblType" value={equipment?.type} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblEquipmentModel" value={equipment?.typeInfo} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.WLLDescription" isDisplayColonOnCaption value={equipment?.wll} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.SWLDescription" isDisplayColonOnCaption value={equipment?.swl} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblOwner" value={equipment?.companyOwnerDescription} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblLocation" value={equipment?.locationDescription} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblArea" value={equipment?.areaDescription} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblPlacement" value={equipment?.placement} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblContact" value={equipment?.contactName} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblComment" value={equipment?.comment} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblSupplier" value={equipment?.supplierDescription} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblVendor" isDisplayColonOnCaption value={equipment?.dealer} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblProducer" value={equipment?.producer} />
          <DetailRow
            isHidden={isHiddenFields}
            caption="Equipment.lblCertificateExpires"
            value={equipment?.certificateExpiry ? moment(equipment.certificateExpiry).format(formatDate) : ''}
          />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblEquipmentGroup" value={equipment?.equipmentGroup} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblCertificate" value={equipment?.certificate} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblCertifiedBy" value={equipment?.certifiedBy} />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblProducedAccordingTo" value={equipment?.producedAccTo} />
          <DetailRow
            isHidden={isHiddenFields}
            caption="Equipment.lblCertificateDate"
            value={equipment?.certificateDate ? moment(equipment.certificateDate).format(formatDate) : ''}
          />
          <DetailRow isHidden={isHiddenFields} caption="Equipment.lblProductionYear" value={equipment?.producedYear} />

          <div className="section-row">
            <Link className="hide-or-show-fields" onClick={appearOrHiddenFields}>
              {translate(isHiddenFields ? 'Equipment.ShowAllFields' : 'Equipment.HideIfEmpty')}
            </Link>
          </div>
        </>
      )}
    </div>
  );
};
