import { DefaultButton, IDropdownOption } from '@fluentui/react';
import { LanguageHelper, SupportedLanguageConstants } from '@onix/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useTracking } from '../../common/hooks/useTracking';
import PrimaryButton from '../../common/ui/Button/PrimaryButton';
import Dropdown from '../../common/ui/Dropdown/Dropdown';
import CommonModal from '../../common/ui/Modal/CommonModal';
import Title from '../../common/ui/Title/Title';
import { i18n, langParam } from '../../constants/languages.constants';
import { Modules } from '../../constants/modules.constants';
import { TrackingEvent } from '../../constants/tracking-event.constant';
import { UserAccountService } from '../../services/user-account.service';
import { LanguageShort } from '../../types/language.type';
import { ModalProps } from '../../types/modal.type';
import { isStringNotNullOrUndefined } from '../../utils/common.utils';
import { getLanguage } from '../../utils/localStorage.utils';

type Props = ModalProps;

const ChangeLanguage = ({ isOpen, onDismiss }: Props) => {
  const [currentLang, setCurrentLang] = useState(getLanguage());
  const [translate] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [languageOptions, setLanguageOptions] = useState<IDropdownOption[]>([]);

  const lang = searchParams.get(langParam);
  const { trackEvent } = useTracking({ module: Modules.NavigationPanel });

  useEffect(() => {
    const options = SupportedLanguageConstants.map((language) => {
      return {
        key: language.code,
        text: language.name,
      };
    });
    setLanguageOptions(options);
  }, []);

  const handleChange = (item: IDropdownOption<any> | undefined) => {
    if (item) {
      const newLang = item.key as string;
      setCurrentLang(newLang);
    }
  };

  const acceptFunc = async () => {
    if (currentLang !== getLanguage()) {
      const langId = LanguageHelper.getLanguageEnum(currentLang as LanguageShort);
      await UserAccountService.updatePreferredLanguage(langId);

      i18n.changeLanguage(currentLang as string);
      if (isStringNotNullOrUndefined(lang)) {
        setSearchParams((prev) => {
          return {
            ...prev,
            [langParam]: currentLang,
          };
        });
      }
    }
    onDismiss && onDismiss();
    trackEvent(TrackingEvent.NavigationPanel.ChangeLanguageAction, { OExpressLanguage: getLanguage() });
    document.location.reload();
  };

  return (
    <CommonModal
      isOpen={isOpen}
      header={<Title text={translate('CaptionResource.ChangeLanguage')} />}
      children={
        <div className="flex items-center gap-4 w-[570px] py-4">
          <span className="block font-medium">{translate('Settings.Language')}: </span>
          <Dropdown options={languageOptions} onChange={(_, option) => handleChange(option)} selectedKey={currentLang}></Dropdown>
        </div>
      }
      footer={
        <>
          <PrimaryButton text={translate('CaptionResource.CAPTION_OK')} className="uppercase" onClick={acceptFunc} />
          <DefaultButton text={translate('CaptionResource.Caption53')} onClick={onDismiss} />
        </>
      }
      footerGreyOut={false}
      onDismiss={onDismiss}
    />
  );
};

export default ChangeLanguage;
