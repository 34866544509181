import { useTranslation } from 'react-i18next';
import { showMessageBox } from '../../services/modal.service';
import { MessageBoxProps } from '../../types/modal.type';
import Title from '../ui/Title/Title';

export const useShowUpgradeOWMessage = () => {
  const [translate] = useTranslation();
  const upgradeBox = {
    header: <Title text={translate('CommonResource.CannotPerformAction')} />,
    children: <div className="w-auto" dangerouslySetInnerHTML={{ __html: translate('CaptionResource.UpgradeExpressToOWMessage') }}></div>,
  } as MessageBoxProps;

  const showUpgradeOWMessage = () => {
    showMessageBox(upgradeBox);
  };

  return { showUpgradeOWMessage };
};
