import React, { forwardRef, useEffect, useRef } from 'react';
import ViewerJS from 'viewerjs';
import 'viewerjs/dist/viewer.css';

interface RViewerJSProps {
  className?: string;
  options?: ViewerJS.Options;
  children?: React.ReactNode;
}

const RViewerJS = forwardRef(({ className, options, children }: RViewerJSProps, ref) => {
  const viewer = useRef<ViewerJS | null>(null);

  const onRef = (el: HTMLDivElement | null) => {
    if (!el) {
      return;
    }
    if (viewer.current) {
      setTimeout(() => {
        viewer.current?.update();
      }, 0);
    } else {
      viewer.current = new ViewerJS(el, options);
      (ref as any).current = viewer.current;
    }
  };

  useEffect(() => {
    if (viewer.current) {
      viewer.current.update();
    }

    return () => {
      if (viewer.current) {
        viewer.current.destroy();
      }
    };
  }, [options]);

  return (
    <div ref={onRef} className={className}>
      {children}
    </div>
  );
});

export default RViewerJS;
