import { LanguageHelper } from '@onix/common';
import jwtDecode from 'jwt-decode';
import { i18n } from '../constants/languages.constants';
import { SettingKeyConstants } from '../constants/setting-keys.constants';
import { AuthenTypes } from '../enums/authen.enum';
import { dashboardActions } from '../states/dashboard/dashboardSlice';
import { equipmentActions } from '../states/equipment/equipmentSlice';
import { appDispatch, store } from '../states/store';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../utils/localStorage.utils';
import { getSessionStorageItem, removeSessionStorageItem, setSessionStorageItem } from '../utils/sessionStorage.utils';
import { AuthenticationOidcService } from './authentication-oidc.service';
import { AuthenticationUidService } from './authentication-uid.service';
import { CommonService } from './common.service';

export interface IAuthenBase {
  login: (param: any) => void;
  logout: (param: any) => void;
}

export const authentication: Record<AuthenTypes, any> = {
  [AuthenTypes.OIDC]: new AuthenticationOidcService(),
  [AuthenTypes.UID]: new AuthenticationUidService(),
};

export const getCurrentAuthenticationService = () => {
  const authenTypesString = getLocalStorageItem(SettingKeyConstants.AuthenTypes);
  if (authenTypesString) {
    const authenTypes = parseInt(authenTypesString);
    if (isNaN(authenTypes) || !(authenTypes in AuthenTypes)) {
      return null;
    }
    return authentication[authenTypes as AuthenTypes];
  }
  return null;
};

export const hasToken = () => {
  const token = getToken();
  if (token === null || token === '') {
    return false;
  }
  return true;
};

export const getToken = () => {
  return getSessionStorageItem(SettingKeyConstants.AccessToken) || getLocalStorageItem(SettingKeyConstants.AccessToken) || '';
};

export const getRefreshToken = () => {
  return getSessionStorageItem(SettingKeyConstants.RefreshToken) || getLocalStorageItem(SettingKeyConstants.RefreshToken) || '';
};

export const setToken = (token: string, type: string, rememberMe: boolean = true) => {
  if (rememberMe) {
    setLocalStorageItem(type, token);
  } else {
    setSessionStorageItem(type, token);
  }
};

export const reloadUserProfile = async () => {
  if (hasToken()) {
    const contactInfo = await CommonService.getContactInfo();
    if (contactInfo) {
      const currentToken = getToken();
      const { username } = jwtDecode(currentToken) as { username: string };
      const currentLang = LanguageHelper.getLanguageCode(contactInfo?.contact?.languageId);
      contactInfo.contact.email = username;
      setLocalStorageItem(SettingKeyConstants.Language, currentLang);
      setLocalStorageItem(SettingKeyConstants.AuthenticatedUserInfo, JSON.stringify(contactInfo));
      i18n.changeLanguage(currentLang);

      // set Express Equipment license
      const hasEquipmentLicense = contactInfo?.expressEquipmentLicense === 1;
      appDispatch(equipmentActions.setEquipmentLicense(hasEquipmentLicense));
      setLocalStorageItem(SettingKeyConstants.ExpressEquipmentLicense, contactInfo?.expressEquipmentLicense?.toString());

      const hasExpressDashboardLicense = !!contactInfo?.expressDashboardLicense;
      appDispatch(dashboardActions.setDashboardLicense(hasExpressDashboardLicense));
      setLocalStorageItem(SettingKeyConstants.ExpressDashboardLicense, contactInfo?.expressDashboardLicense?.toString());
    }
  }
};

export const clearToken = () => {
  removeLocalStorageItem(SettingKeyConstants.AccessToken);
  removeLocalStorageItem(SettingKeyConstants.RefreshToken);
  removeSessionStorageItem(SettingKeyConstants.AccessToken);
  removeSessionStorageItem(SettingKeyConstants.RefreshToken);
};

export const clearLocalStorage = () => {
  const keys = [SettingKeyConstants.AccessToken, SettingKeyConstants.RefreshToken, SettingKeyConstants.AuthenticatedUserInfo];

  keys.forEach((key) => removeLocalStorageItem(key));
};

export const clearSessionStorage = () => {
  const keys = [
    SettingKeyConstants.FriendlyAlias,
    SettingKeyConstants.AccessToken,
    SettingKeyConstants.RefreshToken,
    SettingKeyConstants.AuthenticatedUserInfo,
    SettingKeyConstants.CompanyId,
  ];
  keys.forEach((key) => removeSessionStorageItem(key));
};

export const hasExpressEquipmentLicense = () => {
  const equipmentLicense = getLocalStorageItem(SettingKeyConstants.ExpressEquipmentLicense);
  return equipmentLicense === '1';
};

export const hasExpressDashboardLicense = () => {
  const dashboardLicense = getLocalStorageItem(SettingKeyConstants.ExpressDashboardLicense);
  return !!dashboardLicense && JSON.parse(dashboardLicense);
};

export const alreadyLoggedIn = () => store.getState().app.loggedIn && hasToken();
