import { SupportedLanguageConstants } from '@onix/common/src/constants/language.constants';
import i18next from 'i18next';
import { ApiConstants } from '../constants/api.constants';
import ApplicationSetting from '../constants/application.constants';
import { CompanyExpressContextInfo } from '../models/company-express-context.model';
import { ContactSurveyFormModel } from '../models/contact-survey-form.model';
import { HomeViewInfo } from '../models/home-view.model';
import { getLanguageId } from '../utils/localStorage.utils';
import { getAsync, postAsync } from './base.service';

export const getCurrentLanguage = () => {
  return i18next.language || 'en';
};

const setLocalStorage = (settingKey: string, value: string) => {
  const key = generateLocalKey(settingKey);
  localStorage.setItem(key, value);
};

export const generateLocalKeyByName = (name: string) => {
  return generateLocalKey(name);
};

export const getSupportedLanguages = () => {
  return SupportedLanguageConstants.map((language) => {
    return {
      id: language.id,
      name: language.name,
      code: language.code,
    };
  });
};

const generateLocalKey = (key: string) => {
  return '{appName}.{env}.{key}'
    .replace('{appName}', ApplicationSetting.appName)
    .replace('{env}', ApplicationSetting.environment)
    .replace('{key}', key);
};

export const isAndroid = () => {
  return /android/i.test(navigator.userAgent);
};

export const isiOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

const getContactInfo = (): Promise<CompanyExpressContextInfo> => {
  return getAsync(ApiConstants.common.getContactInfo());
};

const getHomeViewInfo = (): Promise<HomeViewInfo> => {
  const languageId = getLanguageId();
  return getAsync(ApiConstants.common.getHomeViewInfo(languageId));
};

const getLocalStorageByKey = (settingKey: string) => {
  const key = generateLocalKey(settingKey);
  return localStorage.getItem(key);
};

const checkIfAlreadyHasRequest = (): Promise<boolean> => {
  return getAsync(ApiConstants.common.checkIfAlreadyHasRequest());
};

const requestAUser = (): Promise<boolean> => {
  return postAsync(ApiConstants.common.requestAUser());
};

const checkAccessRights = (): Promise<any> => {
  return getAsync(ApiConstants.common.checkAccessRights());
};

const saveContactSurveyForm = (model: ContactSurveyFormModel): Promise<boolean> => {
  return postAsync(ApiConstants.common.saveContactSurveyForm(), model);
};

const checkShowContactSurveyForm = (): Promise<boolean> => {
  return getAsync(ApiConstants.common.checkShowContactSurveyForm());
};

export const CommonService = {
  getContactInfo,
  getHomeViewInfo,
  checkIfAlreadyHasRequest,
  requestAUser,
  checkAccessRights,
  generateLocalKey,
  getLocalStorageByKey,
  setLocalStorage,
  saveContactSurveyForm,
  checkShowContactSurveyForm,
};
