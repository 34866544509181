import { useTranslation } from 'react-i18next';
import { setPageTitle } from '../../utils/browser.utils';

const NotFound = () => {
  const [translate] = useTranslation();
  setPageTitle(translate('AppInspect.NotFound'));
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center gap-1">
      <h1 className="text-[80px] mb-5 Segoe UI font-semibold">{translate('CommonResource.PageNotFound')}</h1>
      <p className="text-xl font-normal">{translate('CommonResource.OnixExpressCouldNotFindThePage')}</p>
      <p className="text-xl font-normal">{translate('CommonResource.OnixExpressContactSup')}</p>
    </div>
  );
};

export default NotFound;
