import { Dropdown as FluentDropdown, IDropdownProps } from '@fluentui/react';

import '../../../assets/css/global.css';

type Props = IDropdownProps;

const Dropdown = ({ ...props }: Props) => {
  return <FluentDropdown {...props} className="w-full"></FluentDropdown>;
};

export default Dropdown;
