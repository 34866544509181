import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Loading from '../../common/ui/Loading/Loading';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { CompanyExpressPreviewContextProvider } from '../../context/CompanyExpressPreviewContext';
import { appSelector } from '../../states/app/appSlice';

const LayoutPreview = () => {
  const { isLoading } = useSelector(appSelector);
  return (
    <CompanyExpressPreviewContextProvider>
      <Header preview={true} />
      <div className="w-screen h-[calc(100vh-70px)] flex">
        <Outlet />
      </div>
      <Footer />
      {isLoading && <Loading />}
    </CompanyExpressPreviewContextProvider>
  );
};

export default LayoutPreview;
