import { IButtonStyles, IModalStyles, IconButton, Modal } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { v4 as uuid } from 'uuid';
import { KeyBoardCodes } from '../../../constants/common.constants';
import { AlignType } from '../../../types/common.type';
import { ModalProps } from '../../../types/modal.type';
import { cn } from '../../../utils/css.utils';

export type CommonModalProps = ModalProps & {
  header?: string | React.ReactElement | undefined;
  children?: string | React.ReactElement;
  styles?: IModalStyles;
  footerClass?: string | undefined;
  showCloseButton?: boolean;
};

type Props = CommonModalProps & {
  footer?: string | React.ReactElement | undefined;
  footerAlign?: AlignType;
  footerGreyOut?: boolean;
};
const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '8px',
  },
};

const CommonModal = ({
  isOpen,
  header,
  children,
  footer,
  onDismiss,
  onEscape,
  footerAlign = 'right',
  footerGreyOut = true,
  styles,
  footerClass,
  showCloseButton = true,
}: Props) => {
  const newKey = useId(uuid());

  const handleKeyDown = (event: any) => {
    const { key } = event;

    switch (key) {
      case KeyBoardCodes.ESCAPE: {
        if (onEscape) {
          onEscape();
          return;
        }
        onDismiss && onDismiss();
        break;
      }
    }
  };

  return (
    <Modal key={newKey} isOpen={isOpen} isBlocking className="common-dialog" styles={styles}>
      <div className="flex flex-col flex-nowrap" onKeyDown={handleKeyDown}>
        <div className="flex items-center pl-4 pr-2 bg-primarybg text-primarytext min-h-[45px]">
          {header}
          {showCloseButton === true && (
            <IconButton
              className="oex-btn-icon-close-modal"
              iconProps={{ iconName: 'Cancel' }}
              styles={iconButtonStyles}
              ariaLabel="Close popup modal"
              onClick={onDismiss}
            />
          )}
        </div>
        <div className="px-4">{children}</div>
        {footer && (
          <div
            className={cn('px-2 py-4 flex items-center gap-2', footerClass || '', {
              'bg-grey30': footerGreyOut === true,
              'justify-end': footerAlign === 'right',
              'justify-center': footerAlign === 'center',
            })}
          >
            {footer}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CommonModal;
