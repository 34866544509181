import { forwardRef } from 'react';

import DocumentFilters from '../../components/Documents/Filters/DocumentFilters';
import { DocumentPreview } from '../../components/Documents/Preview/DocumentPreview';
import DocumentTableContent from '../../components/Documents/TableContent/DocumentTableContent';

const Document = forwardRef((props?: any, ref?: any) => {
  return (
    <div className="h-full bg-white">
      <div className="wrapper flex h-full border border-t-0">
        <div className="search-filter border-r basis-[300px] min-w-[260px]">
          <DocumentFilters />
        </div>
        <div className="flex-1 flex">
          <div className="document-split-table flex-[1] min-w-[300px] border-r">
            <DocumentTableContent />
          </div>
          <div className="document-preview flex-[3] min-w-[300px] border-r">
            <DocumentPreview />
          </div>
        </div>
      </div>
    </div>
  );
});

export default Document;
