import { Callout, IStyle, Text, getTheme, mergeStyleSets } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import * as React from 'react';
import { useState } from 'react';
import { color } from '../../assets/styles/styles';

interface ICalloutProps {
  calloutTitle: string;
  calloutMessage: string;
  isCalloutVisible: boolean;
  calloutStyle: IStyle;
  onExportedCalloutDismiss: () => void;
}
export const OnixCallout = (props: ICalloutProps) => {
  const [isCalloutVisible, setCalloutVisible] = useState(false);
  const buttonId = useId('callout-button');
  const labelId = useId('callout-label');

  React.useEffect(() => {
    setCalloutVisible(props.isCalloutVisible);

    if (props.isCalloutVisible) {
      setTimeout(() => {
        toggleIsCalloutVisible();
      }, 3000);
    }
  }, [props.isCalloutVisible]);

  const toggleIsCalloutVisible = () => {
    setCalloutVisible(false);
    props.onExportedCalloutDismiss();
  };

  return (
    <>
      {isCalloutVisible && (
        <Callout
          coverTarget
          ariaLabelledBy={labelId}
          role="dialog"
          className={styles.callout}
          styles={{ root: props.calloutStyle }}
          onDismiss={toggleIsCalloutVisible}
          target={`#${buttonId}`}
          isBeakVisible={false}
          setInitialFocus
        >
          <Text block variant="xLarge" className={styles.title} id={labelId}>
            {props.calloutTitle}
          </Text>
          <Text block variant="small" className={styles.content} id={labelId}>
            {props.calloutMessage}
          </Text>
        </Callout>
      )}
    </>
  );
};

const theme = getTheme();
const styles = mergeStyleSets({
  callout: {
    width: 320,
    padding: '20px 24px',
    background: theme.semanticColors.bodyBackground,
    bottom: 'unset !important',
    left: 'unset !important',
  },
  title: {
    marginBottom: 12,
    color: color.$grey190,
  },
  content: {
    marginBottom: 12,
    fontSize: 14,
  },
  actions: {
    marginTop: 20,
  },
});
