import i18n from 'i18next';
import { isNumber } from 'lodash';
// import { getJobFieldColumnByKey } from '../../models/grid/job-field-columns';
// import { ControlRegimeForInspection } from '../enums/control-regime.enum';
import { JobClass, JobFilingStatus } from '../enums/job.enum';
import { StatusValues } from '../enums/status-value.enum';
// import { gGetTransferUrl } from './url-helper';
export class JobHelper {
  public static getJobStatusValueIconName(status: number) {
    //define icon for status value
    if (status !== undefined && status !== null) {
      status = status == StatusValues.U ? StatusValues.None : status;      
      if (StatusValues.I == status || (StatusValues.None <= status && status <= StatusValues.U)) return `OIStatusValue${status}`;
    }
    return '';
  }

  public static getJobStatusValues() {
    //get job status value
    const allValue = Object.values(StatusValues).filter((x) => isNumber(x));
    return allValue.filter((x) => StatusValues.I == x || (StatusValues.None <= x && x <= StatusValues.U));
  }

  public static getJobClassIconName(jobClassId: number) {
    // define icon for job class
    return `OIJobClass${jobClassId}`;
  }

  public static getJobClassText(jobClassId: number) {
    switch (jobClassId) {
      case JobClass.Mandatory:
        return i18n.t('Settings.LegalInspection');
      case JobClass.Preventive:
        return i18n.t('Settings.PreventiveMaintenance');
      case JobClass.Operator:
        return i18n.t('Settings.OperatorMaintenance');
      default:
        return '';
    }
  }

  public static getChecklistProgressText(progress: number) {
    switch (progress) {
      case -2:
        return i18n.t('CommonResource.NoCheklist');
      case -1:
        return i18n.t('Job.NotShared');
      case 0:
        return i18n.t('Job.Completed');
      case 1:
        return i18n.t('Job.Uncompleted');
      default:
        return '';
    }
  }

  public static getDueStatusIconClassName(status: number) {
    // defined color for status due
    return `due-status-${status}`;
  }

  public static getJobFilingStatusIconClassName(status: number) {
    // defined color for job filing
    let result = '';
    switch (status) {
      case JobFilingStatus.NotInQueue:
        result += 'gray';
        break;
      case JobFilingStatus.InQueue:
        result += 'orange';
        break;
      case JobFilingStatus.Filed:
        result += 'purple';
        break;
      default:
        result += ' no-display';
        break;
    }
    return result;
  }

  public static getArchivedStatusText(status: number) {
    let control = '';
    switch (status) {
      case JobFilingStatus.None:
        control = 'JavascriptMessages.lblOpen';
        break;
      case JobFilingStatus.NotInQueue:
        control = 'Job.FromOnixInspect';
        break;
      case JobFilingStatus.InQueue:
        control = 'Job.FilingJob';
        break;
      case JobFilingStatus.Filed:
        control = 'Job.ArchivedJob';
        break;
    }
    return i18n.t(control);
  }

//   public static getControlRegimeText(value: number) {
//     if (value === ControlRegimeForInspection.LOLER) return 'LOLER';
//     if (value === ControlRegimeForInspection.EKH) return 'EKH';
//     return i18n.t('Settings.EUStandard');
//   }

  public static getStatisticCheckpointsText(checklistId: number, countOfCheckpoints: number, countOfEmptyCheckpoints: number): string {
    if (checklistId > 0) {
      countOfCheckpoints = countOfCheckpoints ?? 0;
      countOfEmptyCheckpoints = countOfEmptyCheckpoints ?? 0;

      if (countOfCheckpoints > 0) {
        const completedCheckpoint = countOfCheckpoints - countOfEmptyCheckpoints;
        const value = (completedCheckpoint * 100.0) / countOfCheckpoints;
        return `${completedCheckpoint}/${countOfCheckpoints} (${Math.round(value).toFixed(0)}%)`;
      } else {
        return '0/0 (100%)';
      }
    } else {
      return '';
    }
  }

//   public static getTransferUrlExportDocument = (taskQueueId: number) => {
//     if (taskQueueId === undefined) return '';

//     return `${gGetTransferUrl(`export`, 'export-xlsx-result')}?taskQueueId=${taskQueueId}`;
//   };

  // public static parseJobFieldInfoByName(name: string) {
  //   let field: number = 0;
  //   let fieldType: number = JobViewDisplayFieldType.JobField;
  //   let fieldName: string = '';

  //   if (name.indexOf('_') === 1) {
  //     const parts = name.split('_');
  //     if (parts.length === 3) {
  //       fieldType = Number(parts[0]);
  //       field = Number(parts[1]);
  //       fieldName = parts[2];
  //     }
  //   } else {
  //     const fieldItem = name !== undefined ? getJobFieldColumnByKey(name) : undefined;
  //     fieldType = JobViewDisplayFieldType.JobField;
  //     field = fieldItem !== undefined ? fieldItem.field : 0;
  //     fieldName = fieldItem !== undefined ? fieldItem.fieldName : '';
  //   }

  //   return { field, fieldType, fieldName };
  // }
}
