import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useTracking } from '../../../common/hooks/useTracking';
import PrimaryButton from '../../../common/ui/Button/PrimaryButton';
import { renderControlFunctions } from '../../../common/ui/FilterControls/FilterControls';
import { Icon } from '../../../common/ui/Icon/Icon';
import { OnixTooltip } from '../../../common/ui/OnixTooltip/OnixTooltip';
import { Modules } from '../../../constants/modules.constants';
import { TrackingEvent } from '../../../constants/tracking-event.constant';
import { DateRanges } from '../../../enums/date-ranges.enum';
import { GetDocumentDto } from '../../../models/document.model';
import { FieldFilter, ISearchField } from '../../../models/filter-controls/search-field.model';
import { documentActions, documentStateSelector } from '../../../states/document/documentSlice';
import { appDispatch } from '../../../states/store';
import { DocumentFilterFields, getDocumentFilterFields, renderTitleCustomFunctions, searchFunctions } from './FilterFields';
interface IDocumentFilters {}
const DocumentFilters = (props: IDocumentFilters) => {
  const { totalItems } = useSelector(documentStateSelector);
  const [translate] = useTranslation();
  const [searchFields, setSearchFields] = useState<ISearchField[]>([]);
  const [changeId, setChangeId] = useState<string | undefined>(undefined);
  const { trackEvent } = useTracking({ module: Modules.Documents });
  const [searchParams] = useSearchParams();
  const module = searchParams.get('module');
  const searchSerialNo = searchParams.get('searchSerialNo');
  const searchBatchNo = searchParams.get('searchBatchNo');
  const searchEquipmentId = searchParams.get('searchEquipmentId');
  const searchCertificateNo = searchParams.get('searchCertificateNo');
  const searchOrderNo = searchParams.get('searchOrderNo');
  const searchTypeModel = searchParams.get('searchTypeModel');

  useEffect(() => {
    let fields = setFilters(getDocumentFilterFields());
    setSearchFields(fields);
  }, []);

  useEffect(() => {
    if (module === '7') {
      setChangeId(uuid());
    }
  }, [searchFields]);

  useEffect(() => {
    if (module === '7') {
      onApplyFilters();
    }
  }, [changeId]);

  const setFilters = (fields: ISearchField[]) => {
    if (module === '7') {
      fields.forEach((item) => {
        // eslint-disable-next-line eqeqeq
        if (item.key == DocumentFilterFields[DocumentFilterFields.equipmentIdentifier]) {
          item.value = searchEquipmentId;
          if (!item.value) {
            item.value = searchSerialNo;
          }
          if (!item.value) {
            item.value = searchBatchNo;
          }
        }
        // eslint-disable-next-line eqeqeq
        else if (item.key == DocumentFilterFields[DocumentFilterFields.orderNo]) {
          item.value = searchOrderNo;
        }
        // eslint-disable-next-line eqeqeq
        else if (item.key == DocumentFilterFields[DocumentFilterFields.typeModel]) {
          item.value = searchTypeModel;
        }
      });
    }
    return fields;
  };

  const getFilters = useCallback(() => {
    let result: FieldFilter[] = [];
    searchFields.forEach((item) => {
      if (item.controlRef && typeof item.controlRef.getFilter === 'function') {
        const filter = item.controlRef.getFilter();
        if (filter) {
          result.push(filter);
        }
      }
    });

    return result;
  }, [searchFields]);

  const onDataChange = () => {
    setChangeId(uuid());
  };

  const onApplyFilters = () => {
    const filters = getFilters();

    const formData = {} as GetDocumentDto;
    filters.forEach((filter) => {
      const key = filter.fieldName as keyof GetDocumentDto;
      (formData[key] as string) = filter.fieldValue;
    });
    if (enableSearch) {
      appDispatch(documentActions.updateDocumentFilter(formData));
      appDispatch(documentActions.updateSearchId());
      trackEvent(TrackingEvent.Documents.SearchAction, { filters: filters });
    }
  };

  const onRenderFilterControl = (field?: ISearchField) => {
    if (field !== undefined) {
      const EquipmentFilterControl = renderControlFunctions[field.control];
      if (EquipmentFilterControl !== undefined) {
        const keys = getDocumentFilterFields();
        let labelAdditionalInfo = null;
        if (keys) {
          if (field.key === DocumentFilterFields[DocumentFilterFields.equipmentIdentifier]) {
            const content = () => {
              return (
                <>
                  <h5 style={{ fontWeight: 500 }}>{translate('CaptionResource.Caption56')}</h5>
                  <div dangerouslySetInnerHTML={{ __html: translate('CaptionResource.SearchDocumentById') }}></div>
                </>
              );
            };

            labelAdditionalInfo = (
              <>
                {' '}
                <OnixTooltip content={content()}>
                  <Icon style={{ marginLeft: 4 }} iconName="Info" />
                </OnixTooltip>
              </>
            );
          }
          if (field.key === DocumentFilterFields[DocumentFilterFields.secondaryQRCode]) {
            const content = () => {
              return (
                <>
                  <h5 style={{ fontWeight: 500 }}>{translate('CaptionResource.Caption56')}</h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: translate('CaptionResource.SearchDocumentByOnixID'),
                    }}
                  ></div>
                </>
              );
            };

            labelAdditionalInfo = (
              <>
                {' '}
                <OnixTooltip content={content()} closeDelay={500}>
                  <Icon style={{ marginLeft: 4 }} iconName="Info" />
                </OnixTooltip>
              </>
            );
          }
        }

        const onSearchFunc = searchFunctions[field.key];
        const onRenderTitleFunc = renderTitleCustomFunctions[field.key];

        return (
          <>
            <EquipmentFilterControl
              key={field.key}
              field={field}
              label={field.label}
              value={field.value}
              labelAdditionalInfo={labelAdditionalInfo}
              onSearch={onSearchFunc}
              onRenderTitleCustom={onRenderTitleFunc}
              onDataChanged={onDataChange}
              onInvokeApplyFilters={onApplyFilters}
            />
          </>
        );
      }
    }
    return undefined;
  };

  const fillDataSetting = {};

  const fieldDateHasData = (field: FieldFilter) => {
    const data = JSON.parse(field.fieldValue);
    if (data && data.period) {
      if (data.period === DateRanges.Custom && !data.from && !data.to) {
        return false;
      }
      return true;
    }
    return false;
  };

  const enableSearch = useMemo(() => {
    if (changeId !== undefined) {
      const filters = getFilters();
      const dateFields = filters.filter((x) => x.fieldName === DocumentFilterFields[DocumentFilterFields.createdDate]);
      const stringFields = filters.filter((x) => x.fieldName !== DocumentFilterFields[DocumentFilterFields.createdDate]);
      return stringFields.some((x) => x.fieldValue) || dateFields.some((x) => fieldDateHasData(x));
    }
    return false;
  }, [changeId, getFilters]);

  const filterTitle = `${translate('CommonResource.Filter')}`;

  return (
    <div className="px-5">
      <div className="header border-b pb-3 sticky top-0 z-[1] bg-white overflow-hidden py-[20px]">
        <div className="flex items-center justify-between">
          <span className="text-[20px] font-semibold text-grey160">{filterTitle}</span>
        </div>
        <div className="text-[12px] mt-1">
          {totalItems} {translate('CommonResource.lblItem')}
        </div>
        <PrimaryButton
          disabled={!enableSearch}
          className="w-[78px] mt-3 ml-[1px]"
          text={translate('CommonResource.Apply')}
          onClick={onApplyFilters}
        />
      </div>
      <div className="content py-3">
        <div className="flex flex-col">
          {searchFields.map((item) => (
            <div key={item.key}>{onRenderFilterControl(item)}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DocumentFilters;
