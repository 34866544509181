import { FC } from 'react';
import { WidgetCss } from '../../models/dashboard/widget.model';
import { WidgetChartRenderer } from './WidgetChartRenderer';

export interface WidgetPlaceholderProps {
  widgetSize: WidgetCss;
}

export const WidgetPlaceholder: FC<WidgetPlaceholderProps> = ({ widgetSize }) => {
  return <WidgetChartRenderer chartOptions={{}} hasData={false} isLoading widgetCss={widgetSize} />;
};
