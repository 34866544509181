import { WebStorageStateStore } from 'oidc-client';
import { EnvironmentSettings } from '../models/app-settings.model';

export const environment: EnvironmentSettings = {
  appName: 'onix-express',
  clientApplicationId: 20,
  environment: 'dev',
  apiUrl: 'https://localhost/api-express-local',
  myAccountUrl: 'http://localhost:3000',
  identityApiUrl: 'https://localhost/identity-local',
  transferUrl: 'https://localhost:7077',
  enableBanner: true,
  applicationInsightKey: 'cf5858f3-61ee-441f-a504-7fed78440c71',
  applicationInsightBufferKey: 'applicationInsightBuffer',
  oidcSetting: {
    authority: 'https://localhost/identity-local',
    client_id: 'D1T3s9CdzvN22pb75rk6fUZDZ1uyYvV7de',
    redirect_uri: 'http://localhost:3002/auth-callback',
    post_logout_redirect_uri: 'http://localhost:3002/logout',
    response_type: 'code',
    scope: 'openid email onixexpress offline_access',
    filterProtocolClaims: false,
    loadUserInfo: false,
    automaticSilentRenew: false,
    //checkSessionInterval: 2000,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    stateStore: new WebStorageStateStore({ store: window.localStorage }),
    monitorSession: false,
    includeIdTokenInSilentRenew: false,
    revokeAccessTokenOnSignout: true,
  },
};
