/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect, useState } from 'react';
import { useEquipmentId } from '../../../../common/hooks/useEquipmentId';
import { useTracking } from '../../../../common/hooks/useTracking';
import { Skeleton } from '../../../../common/ui/Skeleton/Skeleton';
import { ApiConstants } from '../../../../constants/api.constants';
import { ImagePaths } from '../../../../constants/image-path.constants';
import { Modules } from '../../../../constants/modules.constants';
import { TrackingEvent } from '../../../../constants/tracking-event.constant';
import { QuantityUsage } from '../../../../enums/quantity-usage.enum';
import { equipmentActions } from '../../../../states/equipment/equipmentSlice';
import { appDispatch } from '../../../../states/store';
import { EquipmentDetailContext } from '../EquipmentDetail';
import './index.scss';
export interface IEquipmentInfoProps {
  onRefresh?: () => void;
  isHiddenRelatedRow?: boolean;
  onCloseEquipmentDetail?: () => void;
}

export const EquipmentInfo = (props: IEquipmentInfoProps) => {
  const { equipment, isLoading } = useContext(EquipmentDetailContext);
  const [imageUrl, setImageUrl] = useState(ImagePaths.NoImageAvailableBig);
  const equipmentId = useEquipmentId(equipment);
  const emptyGuid = '00000000-0000-0000-0000-000000000000';
  const { trackEvent } = useTracking({ module: Modules.Equipment });

  useEffect(() => {
    const fetchImage = async () => {
      let url = ImagePaths.NoImageAvailableBig;
      if (equipment?.image?.imageContentId && equipment?.image?.imageContentId !== emptyGuid) {
        const imageResult = ApiConstants.equipment.getThumbnailImageFileByImageContentId(equipment.image.imageContentId);
        trackingImageValid(imageResult);
      } else {
        setImageUrl(url);
      }
    };
    fetchImage();
  }, [equipment]);

  const trackingImageValid = async (url: string) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        // 404 Not Found
        setImageUrl(ImagePaths.NoImageAvailableBig);
      } else {
        const text = await response.text();
        if (!text.trim()) {
          setImageUrl(ImagePaths.NoImageAvailableBig);
        } else {
          setImageUrl(url);
        }
      }
    } catch (err) {
      setImageUrl(ImagePaths.NoImageAvailableBig);
    }
  };

  // eslint-disable-next-line jsx-a11y/alt-text
  return (
    <div className="equipment-info" style={{ position: 'relative' }}>
      <div style={{ display: 'flex', gap: 10, minHeight: 164 }}>
        <div className="equipment-picture">
          <img
            src={imageUrl}
            className={imageUrl === ImagePaths.NoImageAvailableBig ? 'no-image' : ''}
            onClick={
              isLoading
                ? undefined
                : () => {
                    appDispatch(
                      equipmentActions.setShowPictureManagement({ isOpen: true, imageContentId: equipment.image.imageContentId })
                    );
                    trackEvent(TrackingEvent.Equipment.EquipmentDetail.Detail.Picture);
                  }
            }
          />
        </div>

        <div className="information">
          {isLoading ? (
            <Skeleton numberElement={6} />
          ) : (
            <>
              <div className={'owner-id ' + (equipmentId !== '' ? 'has-owner-equipment' : '')}>
                {equipmentId !== '' && <span>{equipmentId}</span>}
              </div>

              {equipment?.quantityUsage === QuantityUsage.Unique ? (
                <div className="sort-info">
                  {equipment?.articleNo && (
                    <div className="product-no-wrapper">
                      <div className="product-no">{equipment.articleNo}</div>&nbsp;
                      {equipment?.articleCompanyName && (
                        <div className="company">
                          <div>(</div>
                          <div className="company-name">{equipment.articleCompanyName}</div>
                          <div>)</div>
                        </div>
                      )}
                    </div>
                  )}
                  {equipment?.ownerEquipmentId && <span>{equipment.ownerEquipmentId}</span>}
                  {equipment?.serialNumber && <span>{equipment?.serialNumber}</span>}
                  {equipment?.batchNo && <span>{equipment?.batchNo}</span>}
                  {equipment?.type && <span>{equipment?.type}</span>}
                  {equipment?.typeInfo && <span>{equipment?.typeInfo}</span>}
                </div>
              ) : (
                <div className="sort-info">
                  {equipment?.articleNo && (
                    <div className="product-no-wrapper">
                      <div className="product-no">{equipment.articleNo}</div>&nbsp;
                      {equipment?.articleCompanyName && (
                        <div className="company">
                          <div>(</div>
                          <div className="company-name">{equipment.articleCompanyName}</div>
                          <div>)</div>
                        </div>
                      )}
                    </div>
                  )}
                  {equipment?.type && <span>{equipment?.type}</span>}
                  {equipment?.typeInfo && <span>{equipment?.typeInfo}</span>}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
