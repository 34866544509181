import jwtDecode from 'jwt-decode';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../../common/ui/Loading/Loading';
import { SettingKeyConstants } from '../../constants/setting-keys.constants';
import { AuthenState, AuthenTypes } from '../../enums/authen.enum';
import { authentication, getCurrentAuthenticationService, getToken, hasToken } from '../../services/authentication.service';
import { CommonService } from '../../services/common.service';
import {
  getAuthenTypes,
  getAuthenticatedProfile,
  getLoggedAlias,
  getLoggedCompanyId,
  getLoggedExpressId,
  getLoggedSupplierName,
  getLoggedUidAndUpdExpertParamUrl,
  setLocalStorageItem,
  setNumberExpress,
} from '../../utils/localStorage.utils';
import MainLayout from '../Layout/MainLayout';
import NoAccess from '../NoAccess/NoAccess';
import NotAvailable from '../NotAvailable/NotAvailable';
import NotFound from '../NotFound/NotFound';

const Authentication = () => {
  const [authenState, setAuthenState] = useState<AuthenState>(AuthenState.Loading);
  const [loggedSupplierName, setLoggedSupplierName] = useState<string>();
  const [searchParams] = useSearchParams();
  const inputUid = searchParams.get('uid');
  const udpExpert = searchParams.get('udpexpert');
  const module = searchParams.get('module');
  const searchSerialNo = searchParams.get('searchSerialNo');
  const searchBatchNo = searchParams.get('searchBatchNo');
  const searchEquipmentId = searchParams.get('searchEquipmentId');
  const searchCertificateNo = searchParams.get('searchCertificateNo');
  const searchOrderNo = searchParams.get('searchOrderNo');
  const searchTypeModel = searchParams.get('searchTypeModel');
  const navigate = useNavigate();

  useEffect(() => {
    // Start by checking for the Express License.
    checkAccessRights();
  }, []);

  // PBI : 66760 : When Onix Express license = 0, users cannot access to Onix Express access URL (will be 404)
  // PBI : 69099,69100,69101 : If there is no Onix Express company in that supplier -> back to the Request a user page
  const checkAccessRights = useCallback(async () => {
    if (!hasToken()) {
      loadElement();
      return;
    }

    const result = await CommonService.checkAccessRights();
    const isUidMatched = inputUid && getUidAndUpdExpertParamUrl(inputUid, udpExpert) === getLoggedUidAndUpdExpertParamUrl();

    if (!result.hasLicense && isUidMatched) {
      setLoggedSupplierName(getLoggedSupplierName());
      setAuthenState(AuthenState.NotAvailable);
      return;
    }

    if (!result.expressIds || result.expressIds.length <= 0) {
      await getCurrentAuthenticationService().refresh({ targetCompanyId: getLoggedCompanyId() });
      setAuthenState(AuthenState.NoAccess);
      return;
    }

    setNumberExpress(result.expressIds.length);
    if (!result.expressIds.includes(getLoggedExpressId())) {
      await getCurrentAuthenticationService().refresh({ targetExpressCompanyId: result.expressIds[0] });
    }

    // If the Express License check is successful, proceed to loadElement.
    loadElement();
  }, [navigate]);

  const handleSuccessfulLogin = useCallback(() => {
    const { companyId } = jwtDecode(getToken()) as { companyId: number };
    if (udpExpert) setLocalStorageItem(SettingKeyConstants.InputUdpExpert, udpExpert);
    setLocalStorageItem(SettingKeyConstants.InputUid, inputUid);
    setLocalStorageItem(SettingKeyConstants.InputAlias, getLoggedAlias());
    setLocalStorageItem(SettingKeyConstants.AuthenTypes, AuthenTypes.UID.toString());

    if (!companyId) {
      setAuthenState(AuthenState.NoAccess);
    } else {
      if (module === '7') {
        loadPathDocument();
      } else {
        navigate(`/${getLoggedAlias()}`);
      }
    }
  }, [udpExpert, inputUid, navigate]);

  const getUidAndUpdExpertParamUrl = useCallback((uid: string, updExpert: string | null) => {
    let path = '';
    if (uid) {
      path += `uid=${uid}`;
      if (updExpert) path += `&udpexpert=${updExpert}`;
    }

    return path;
  }, []);

  const loadPathDocument = () => {
    let path = `${getLoggedAlias()}/documents?module=7`;
    if (searchSerialNo) path += `&searchSerialNo=${searchSerialNo}`;
    if (searchBatchNo) path += `&searchBatchNo=${searchBatchNo}`;
    if (searchEquipmentId) path += `&searchEquipmentId=${searchEquipmentId}`;
    if (searchOrderNo) path += `&searchOrderNo=${searchOrderNo}`;
    if (searchTypeModel) path += `&searchTypeModel=${searchTypeModel}`;
    if (searchCertificateNo) path += `&searchCertificateNo=${searchCertificateNo}`;
    console.log(path);
    navigate(`/${path}`);
  };

  const loadElement = useCallback(async () => {
    const authenticationService = authentication[AuthenTypes.UID];

    // user has logged.
    if (hasToken()) {
      // friendlyAlias ->  uid
      const authenTypes = getAuthenTypes();
      if (authenTypes != null && authenTypes === AuthenTypes.OIDC) {
        const currentAuthenticationService = getCurrentAuthenticationService();
        if (udpExpert) setLocalStorageItem(SettingKeyConstants.InputUdpExpert, udpExpert);
        setLocalStorageItem(SettingKeyConstants.InputUid, inputUid);
        setLocalStorageItem(SettingKeyConstants.AuthenTypes, AuthenTypes.UID.toString());
        await currentAuthenticationService.logout({ isCancelCallLogout: true });
        return;
      }

      // user has logged && the same iud,udpExpert
      if (inputUid && getUidAndUpdExpertParamUrl(inputUid, udpExpert) === getLoggedUidAndUpdExpertParamUrl()) {
        const { companyId } = jwtDecode(getToken()) as { companyId: number };
        if (companyId > 0) {
          if (module === '7') {
            loadPathDocument();
          }
          setAuthenState(AuthenState.MainLayout);
          return;
        }
      }
    }

    // login with uid and udpExpert
    var result = await authenticationService.login({ uid: inputUid, udpexpert: udpExpert });
    if (result.isSuccess) {
      const { companyExpressSetup } = getAuthenticatedProfile();
      if (companyExpressSetup) {
        // Tai Prcoess
        handleSuccessfulLogin();
      } else {
        localStorage.clear();
        setAuthenState(AuthenState.NotFound);
        return;
      }
    } else {
      localStorage.clear();
      setAuthenState(AuthenState.NotFound);
    }
  }, [inputUid, udpExpert, getUidAndUpdExpertParamUrl, handleSuccessfulLogin]);

  return (
    <>
      {authenState === AuthenState.Loading && <Loading />}
      {authenState === AuthenState.NotFound && <NotFound />}
      {authenState === AuthenState.MainLayout && <MainLayout />}
      {authenState === AuthenState.NoAccess && <NoAccess />}
      {authenState === AuthenState.NotAvailable && <NotAvailable supplierName={loggedSupplierName} />}
    </>
  );
};

export default Authentication;
