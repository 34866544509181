export const toUpperFirstLetter = (value?: string): string => {
  if (!value) {
    return '';
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const toLowerFirstLetter = (value?: string): string => {
  if (!value) {
    return '';
  }
  return value.charAt(0).toLowerCase() + value.slice(1);
};

export const removeColon = (value: string): string => {
  if (value != null) {
    return value.replace(/\:$/, '');
  } else {
    return '';
  }
};

export const isStringNotNullOrUndefined = (str: string | null | undefined) => str !== undefined && str !== null && str !== '';
