import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { DocumentDto } from '../../models/document.model';
import { RootState } from '../store';

type State = {
  searchId: string | undefined;
  searchText: string | undefined;
  documentTypeIds: number[];
  selectedDocuments: DocumentDto[];
  totalItems: number;
  isShowPreviewDocument: boolean;
};

const INTIAL_STATE: State = {
  searchId: undefined,
  searchText: '',
  documentTypeIds: [],
  selectedDocuments: [],
  totalItems: 0,
  isShowPreviewDocument: false,
};

export const equipmentDocumentSlice = createSlice({
  name: 'equipmentDocumentDocument',
  initialState: INTIAL_STATE,
  reducers: {
    updateSearchId(state) {
      state.searchId = uuid();
    },
    updateSearchText(state, { payload }: PayloadAction<string>) {
      state.searchText = payload;
    },
    updateDocumentTypeIds(state, { payload }: PayloadAction<number[]>) {
      state.documentTypeIds = payload;
    },
    resetData(state) {
      return {
        ...INTIAL_STATE,
      };
    },
    updateSelectedDocuments(state, { payload }: PayloadAction<DocumentDto[]>) {
      state.selectedDocuments = payload;
    },
    setTotalItems: (state, { payload }: PayloadAction<number>) => {
      state.totalItems = payload;
    },
    setShowPreviewDocument: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowPreviewDocument = payload;
    },
  },
});

export const equipmentDocumentActions = equipmentDocumentSlice.actions;
export const equipmentDocumentReducer = equipmentDocumentSlice.reducer;

const selectSelf = (state: RootState) => state;

export const equipmentDocumentSelector = createSelector(selectSelf, (state) => state.equipmentDocument);
