import { LanguageEnum, LanguageHelper } from '@onix/common';
import { EnglishLanguage } from '@onix/common/src/constants/language.constants';
import i18next, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { SettingKeyConstants } from '../constants/setting-keys.constants';
import { getAsync } from '../services/base.service';
import { LocalStorageService } from '../services/local-storage.service';

export default async function languageConfiguration(url: string) {
  return i18next.use(LanguageDetector).use(HttpApi).use(initReactI18next).init(getOptions(url));
}

function getOptions(baseUrlLoadResource: string) {
  return {
    interpolation: {
      prefix: '{',
      suffix: '}',
    },
    fallbackLng: EnglishLanguage.code,
    cleanCode: false,
    load: 'languageOnly',
    backend: {
      loadPath: (languages: string[]) => convertLanguageParams(baseUrlLoadResource, languages),
      crossDomain: false,
      withCredentials: false,
      // overrideMimeType sets request.overrideMimeType("application/json")
      overrideMimeType: false,
      request: function (options: any, url: any, payload: any, callback: any) {
        try {
          getAsync(url, {}, false).then((response: any) => {
            callback(null, { data: response, status: 200 });
          });
        } catch (e) {}
      },
    },
    detection: {
      order: ['querystring', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

      lookupLocalStorage: LocalStorageService.getLocalStorageKey(SettingKeyConstants.Language),
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      // caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode', 'localStorage'], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      // cookieMinutes: 10,
      // cookieDomain: 'myDomain',

      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement,
      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: '/', sameSite: 'strict' },
    },
    react: { useSuspense: true },
    parseMissingKeyHandler: (key: string, defaultValue?: string) => {
      if (defaultValue) {
        return defaultValue;
      }
      return '';
    },
  } as InitOptions;
}

function convertLanguageParams(baseUrlLoadResource: string, languages: string[]): string {
  let languageId = LanguageEnum.English;
  if (languages[0]) {
    languageId = LanguageHelper.getLanguageEnum(languages[0]);
  }
  return `${baseUrlLoadResource}/${languageId}`;
}
