import { forwardRef, useRef } from 'react';
import { useSelector } from 'react-redux';
import { EquipmentDetail, EquipmentDetailRef } from '../../components/Equipment/Detail/EquipmentDetail';
import EquipmentDocuments from '../../components/Equipment/Documents/EquipmentDocuments';
import EquipmentFilters from '../../components/Equipment/Filters/EquipmentFilters';
import { PictureManagement } from '../../components/Equipment/PictureManagement/PictureManagement';
import EquipmentTableContent, { EquipmentTableImperative } from '../../components/Equipment/TableContent/EquipmentTableContent';
import EquipmentToolbar from '../../components/Equipment/Toolbar/EquipmentToolbar';
import { equipmentFilterSelector } from '../../states/equipment/equipmentFilterSlice';
import { equipmentSelector } from '../../states/equipment/equipmentSlice';

export const Equipment = forwardRef((props?: any, ref?: any) => {
  const { isShowPanel } = useSelector(equipmentFilterSelector);
  const { isShowPanelDetail, selectedEquipmentId, isShowDocumentManagementPanel, showPictureManagement } = useSelector(equipmentSelector);

  const tableRef = useRef<EquipmentTableImperative>(null);
  const equipmentDetailRef = useRef<EquipmentDetailRef>(null);

  const clearSelectedEquipments = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.clearSelectedEquipments();
    }
  };

  return (
    <div className="h-full bg-white">
      <div className="wrapper flex h-full border border-t-0">
        {isShowPanel && (
          <div className="search-filter border-r max-w-[300px] min-w-[260px] box-border overflow-auto basis-full">
            <EquipmentFilters equipmentDetailPanelRef={equipmentDetailRef} />
          </div>
        )}
        <div className="w-full h-[calc(100vh-120px)]">
          <EquipmentToolbar clearSelectedEquipment={clearSelectedEquipments} />
          <EquipmentTableContent ref={tableRef} equipmentDetailPanelRef={equipmentDetailRef} />
        </div>

        {isShowPanelDetail && <EquipmentDetail ref={equipmentDetailRef} equipmentId={selectedEquipmentId} />}
        {showPictureManagement?.isOpen && <PictureManagement equipmentId={selectedEquipmentId} />}
        {isShowDocumentManagementPanel && <EquipmentDocuments />}
      </div>
    </div>
  );
});
