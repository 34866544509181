import { ActionButton, Icon } from '@fluentui/react';
import i18n from 'i18next';
import { ForwardedRef, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedEffect } from '../../../../common/hooks/useDebouncedEffect';
import { useShowUpgradeOWMessage } from '../../../../common/hooks/useShowUpgradeOWMessage';
import { useTracking } from '../../../../common/hooks/useTracking';
import { Collapse } from '../../../../common/ui/Collapse/Collapse';
import { LinkButton } from '../../../../common/ui/LinkButton/LinkButton';
import { Modules } from '../../../../constants/modules.constants';
import { SettingKeyConstants } from '../../../../constants/setting-keys.constants';
import { TrackingEvent } from '../../../../constants/tracking-event.constant';
import { DueDateStatuses } from '../../../../enums/due-date-statuses.enum';
import { CaptionForm } from '../../../../enums/language.enum';
import { QuantityUsage } from '../../../../enums/quantity-usage.enum';
import CommonHelper from '../../../../helpers/common-helper';
import { JobHelper } from '../../../../helpers/job-helper';
import { Equipment } from '../../../../models/equipmentDetail/equipment.model';
import { CommonService } from '../../../../services/common.service';
import { CreatedModified } from '../CreatedModified/CreatedModified';
import { EquipmentDetailContext } from '../EquipmentDetail';
import { Detail } from '../EquipmentDetail/EquipmentDetail';
import { JobInterval, JobIntervalRef } from '../JobInterval/JobInterval';
import { Properties, PropertiesRef } from '../Properties/Properties';
import { RelatedRows } from '../RelatedRows/RelatedRows';
import './index.scss';

const collapseProps = {
  className: 'equipment-detail-collapse',
};

export interface EquipmentContentRef {
  readonly jobIntervalRef: JobIntervalRef | null;
  readonly propertiesRef: PropertiesRef | null;
}

export interface IEquipmentContentProps {
  isHiddenRelatedRow?: boolean;
  isOpenFromRelatedEquipmentDetail?: boolean;
  openFrom?: string;
}

interface IJobIntervalProps {
  count: number;
  equipment: Equipment;
}

const JobInterValTitle = (props: IJobIntervalProps) => {
  const title = i18n.t('Equipment.JobIntervals') + ' (' + props.count + ')';
  const [operatorDueStatus, setOperatorDueStatus] = useState(DueDateStatuses.None);
  const [preventiveDueStatus, setPreventiveDueStatus] = useState(DueDateStatuses.None);
  const [mandatoryDueStatus, setMandatoryDueStatus] = useState(DueDateStatuses.None);

  useEffect(() => {
    if (props.equipment) {
      setOperatorDueStatus(props.equipment.operatorDueStatus ?? DueDateStatuses.None);
      setPreventiveDueStatus(props.equipment.preventiveDueStatus ?? DueDateStatuses.None);
      setMandatoryDueStatus(props.equipment.legalJobDueStatus ?? DueDateStatuses.None);
    } else {
      setOperatorDueStatus(DueDateStatuses.None);
      setPreventiveDueStatus(DueDateStatuses.None);
      setMandatoryDueStatus(DueDateStatuses.None);
    }
  }, [props.equipment]);

  return (
    <>
      <div className="title-section">{title}</div>
      <div className="d-flex" style={{ marginLeft: 5, marginRight: 'auto' }}>
        {operatorDueStatus !== DueDateStatuses.None && (
          <Icon className={`icon oi-jc-maint-operator ${JobHelper.getDueStatusIconClassName(operatorDueStatus)}`} />
        )}
        {preventiveDueStatus !== DueDateStatuses.None && (
          <Icon className={`icon oi-jc-maint-preventive ${JobHelper.getDueStatusIconClassName(preventiveDueStatus)}`} />
        )}
        {mandatoryDueStatus !== DueDateStatuses.None && (
          <Icon className={`icon oi-jc-legal ${JobHelper.getDueStatusIconClassName(mandatoryDueStatus)}`} />
        )}
      </div>
    </>
  );
};

export const EquipmentContent = forwardRef((_props: IEquipmentContentProps = {}, ref: ForwardedRef<EquipmentContentRef>) => {
  const [translate] = useTranslation();
  const { equipment, isLoading } = useContext(EquipmentDetailContext);
  const jobIntervalRef = useRef<JobIntervalRef>(null);
  const propertiesRef = useRef<PropertiesRef>(null);
  const [propertiesCount, setPropertiesCount] = useState(0);
  const [jobInterValCount, setJobInterValCount] = useState(0);
  const [isOpenDetailSection, setOpenDetailSection] = useState(false);
  const [isOpenJobIntervalSection, setOpenIntervalSection] = useState(false);
  const [isOpenPropertiesSection, setOpenPropertiesSection] = useState(false);
  const [isOpenAdditionalSection, setOpenAdditionalSection] = useState(false);
  const [isOpenCreatedModifiedSection, setOpenCreatedModifiedSection] = useState(false);
  const [uniqueEquipment, setUniqueEquipment] = useState<boolean>(true);
  const { showUpgradeOWMessage } = useShowUpgradeOWMessage();
  const { trackEvent } = useTracking({ module: Modules.Equipment });

  useImperativeHandle(
    ref,
    () =>
      ({
        get jobIntervalRef() {
          return jobIntervalRef.current;
        },
        get propertiesRef() {
          return propertiesRef.current;
        },
        refresh() {
          jobIntervalRef.current?.refresh();
        },
      } as EquipmentContentRef)
  );

  useEffect(() => {
    setPropertiesCount(equipment?.propertiesCount || 0);
    setJobInterValCount(equipment?.jobInterValCount || 0);
    if (equipment) {
      const isUnique = equipment.quantityUsage === QuantityUsage.Unique;
      setUniqueEquipment(isUnique);
    }
  }, [equipment]);

  useEffect(() => {
    const dataStorage = CommonService.getLocalStorageByKey(SettingKeyConstants.EquipmentDetailExpandCollapse);
    let datas = dataStorage ? (JSON.parse(dataStorage) as any[]) : [];
    if (!Array.isArray(datas)) {
      datas = [];
    }
    const dataByUserAccount = datas.find((m) => m.userAccountId === CommonHelper.userAccountId);
    if (dataByUserAccount) {
      if (dataByUserAccount?.isOpenDetailSection) {
        setOpenDetailSection(dataByUserAccount?.isOpenDetailSection);
      }
      if (dataByUserAccount?.isOpenJobIntervalSection) {
        setOpenIntervalSection(dataByUserAccount?.isOpenJobIntervalSection);
      }
      if (dataByUserAccount?.isOpenPropertiesSection) {
        setOpenPropertiesSection(dataByUserAccount?.isOpenPropertiesSection);
      }
      if (dataByUserAccount?.isOpenAdditionalSection) {
        setOpenAdditionalSection(dataByUserAccount?.isOpenAdditionalSection);
      }
      if (dataByUserAccount?.isOpenCreatedModifiedSection) {
        setOpenCreatedModifiedSection(dataByUserAccount?.isOpenCreatedModifiedSection);
      }
    } else {
      setIsOpenSection(true);
    }
  }, []);

  useDebouncedEffect(
    () => {
      const expandCollapseData = {
        isOpenDetailSection,
        isOpenJobIntervalSection,
        isOpenPropertiesSection,
        isOpenAdditionalSection,
        isOpenCreatedModifiedSection,
        userAccountId: CommonHelper.userAccountId,
      };
      const dataStorage = CommonService.getLocalStorageByKey(SettingKeyConstants.EquipmentDetailExpandCollapse);
      let datas = dataStorage ? (JSON.parse(dataStorage) as any[]) : [];
      if (!Array.isArray(datas)) {
        datas = [];
      }
      if (datas.some((m) => m.userAccountId === expandCollapseData.userAccountId)) {
        datas.forEach((data, index) => {
          if (data.userAccountId === expandCollapseData.userAccountId) {
            datas[index] = expandCollapseData;
          }
        });
      } else {
        datas = datas.concat([expandCollapseData]);
      }
      CommonService.setLocalStorage(SettingKeyConstants.EquipmentDetailExpandCollapse, JSON.stringify(datas));
    },
    [isOpenDetailSection, isOpenJobIntervalSection, isOpenPropertiesSection, isOpenAdditionalSection, isOpenCreatedModifiedSection],
    500
  );

  const setIsOpenSection = (isOpen: boolean = false) => {
    setOpenDetailSection(isOpen);
    setOpenIntervalSection(isOpen);
    setOpenPropertiesSection(isOpen);
    setOpenAdditionalSection(isOpen);
    setOpenCreatedModifiedSection(isOpen);
  };

  const onClickMarketingButton = (isDetailSection: boolean, ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    showUpgradeOWMessage();
    if (isDetailSection) {
      trackEvent(TrackingEvent.Equipment.EquipmentDetail.Detail.EquipmentDetails.Edit);
    } else {
      trackEvent(TrackingEvent.Equipment.EquipmentDetail.Detail.Properties.Edit);
    }
  };

  return (
    <div className="equipment-content">
      {(_props.isHiddenRelatedRow === undefined || _props.isHiddenRelatedRow === false) && (
        <RelatedRows isOpenFromRelatedEquipmentDetail={_props.isOpenFromRelatedEquipmentDetail} />
      )}
      <div className="content-sections">
        <div className="expand-collapse-buttons">
          <ActionButton
            iconProps={{ iconName: 'ChevronDown' }}
            text={translate('CaptionResource.Expand')}
            onClick={() => setIsOpenSection(true)}
          ></ActionButton>
          <ActionButton
            iconProps={{ iconName: 'ChevronUp' }}
            text={translate('CaptionResource.Collapse')}
            onClick={() => setIsOpenSection(false)}
          ></ActionButton>
        </div>
        <div className="sections">
          <Collapse
            {...collapseProps}
            isOpen={isOpenDetailSection}
            setOpen={setOpenDetailSection}
            title={
              <div className="detail-header">
                <span className="title-section">{translate('Equipment.headerEquipmentDetails')}</span>
                <div className="detail-header-button">
                  <LinkButton onClick={(ev) => onClickMarketingButton(true, ev)}>{translate('CommonResource.Edit')}</LinkButton>
                </div>
              </div>
            }
          >
            <Detail />
          </Collapse>
          {uniqueEquipment && (
            <Collapse
              {...collapseProps}
              className="job-interval"
              isOpen={isOpenJobIntervalSection}
              setOpen={setOpenIntervalSection}
              title={<JobInterValTitle count={jobInterValCount} equipment={equipment} />}
            >
              <JobInterval
                ref={jobIntervalRef}
                isAllowLoadData={isOpenJobIntervalSection}
                equipmentId={equipment?.equipmentId}
                setJobInterValCount={setJobInterValCount}
              />
            </Collapse>
          )}
          <Collapse
            {...collapseProps}
            isOpen={isOpenPropertiesSection}
            setOpen={setOpenPropertiesSection}
            title={
              <div className="detail-header">
                <span className="title-section">
                  {translate(`${CaptionForm.FormEquipment}.lblProperties`)} ({propertiesCount})
                </span>
                <div className="detail-header-button">
                  <LinkButton onClick={(ev) => onClickMarketingButton(false, ev)}>{translate('CommonResource.Edit')}</LinkButton>
                </div>
              </div>
            }
          >
            <Properties
              ref={propertiesRef}
              setPropertiesCount={setPropertiesCount}
              equipmentId={equipment?.equipmentId}
              isLoading={isLoading}
              isAllowLoadData={isOpenPropertiesSection}
            />
          </Collapse>
          <Collapse
            {...collapseProps}
            isOpen={isOpenCreatedModifiedSection}
            setOpen={setOpenCreatedModifiedSection}
            title={<span className="title-section">{translate('Equipment.CreatedModifiedGroupHeader')}</span>}
            className="created-modified-region"
          >
            <CreatedModified />
          </Collapse>
        </div>
      </div>
    </div>
  );
});

EquipmentContent.displayName = 'EquipmentContent';
