import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SettingKeyConstants } from '../../constants/setting-keys.constants';
import { AuthenTypes } from '../../enums/authen.enum';
import { getAuthenTypes, getLocalStorageItem } from '../../utils/localStorage.utils';

const Logout = () => {
  const navigate = useNavigate();
  const alias = getLocalStorageItem(SettingKeyConstants.InputAlias);
  const udpExpert = getLocalStorageItem(SettingKeyConstants.InputUdpExpert);
  const uid = getLocalStorageItem(SettingKeyConstants.InputUid);

  useEffect(() => {
    const authenTypes = getAuthenTypes();
    if (authenTypes && authenTypes === AuthenTypes.UID) {
      let path = `/?uid=${uid}`;
      if (udpExpert) path += `&udpexpert=${udpExpert}`;
      navigate(path);
      return;
    }

    navigate(`/${alias}`);
  }, []);

  return <>Logout is in processing...</>;
};

export default Logout;
