import { DefaultButton, IButtonProps } from '@fluentui/react/lib/Button';
import './ButtonToggle.scss';

export const ButtonToggle: React.FunctionComponent<IButtonProps> = (props) => {
  const style = {
    border: 'none',
    padding: '0 4px',
    minWidth: '60px',
  };
  return (
    <>
      <DefaultButton
        style={style}
        styles={{
          rootCheckedHovered: { backgroundColor: 'rgb(225, 223, 221)' },
          label: props.data.fontWeightNormal ? { fontWeight: 'normal' } : {},
        }}
        toggle
        {...props}
      />
    </>
  );
};
