import { useTranslation } from 'react-i18next';
import { useTracking } from '../../common/hooks/useTracking';
import { ApplicationVersion } from '../../constants/application.constants';
import { Modules } from '../../constants/modules.constants';
import { TrackingEvent } from '../../constants/tracking-event.constant';

const Footer = () => {
  const [translate] = useTranslation();
  const { trackEvent } = useTracking({ module: Modules.Footer });

  const onClickPowerBy = () => {
    trackEvent(TrackingEvent.Footer.PoweredByOnixWorkAction);
  };

  return (
    <footer className="w-full h-5 fixed bottom-0 left-0 text-primarytext text-center bg-primarybg border-t px-6 text-mini">
      {`${translate('CaptionResource.PoweredBy')} `}
      <a onClick={onClickPowerBy} href="https://www.onix.com" className="font-medium underline" target="blank">
        Onix Work
      </a>
      <span className="absolute top-0 right-6">
        {translate('Home.SystemVersion')}: {ApplicationVersion}
      </span>
    </footer>
  );
};

export default Footer;
