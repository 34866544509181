import { CommandBar as FluentCommandBar, ICommandBarProps } from '@fluentui/react';

type Props = ICommandBarProps;

const CommandBar = (props: Props) => {
  return (
    <FluentCommandBar
      styles={{ root: { height: 32, padding: '0px', minWidth: 300 }, primarySet: { color: 'black' } }}
      {...props}
    ></FluentCommandBar>
  );
};

export default CommandBar;
