import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { DocumentDto } from '../../models/document.model';
import { RootState } from '../store';

export interface IDocumentState {
  totalItems: number;
  searchId: string | undefined;
  filterData: DocumentDto | undefined;
  selectedDocument: DocumentDto | undefined;
  dataFetch: any[] | undefined;
}

const initialState: IDocumentState = {
  totalItems: 0,
  searchId: undefined,
  filterData: undefined,
  selectedDocument: undefined,
  dataFetch: undefined,
};

export const documentSlice = createSlice({
  name: 'document',
  initialState: initialState,
  reducers: {
    setTotalItems: (state, { payload }: PayloadAction<number>) => {
      state.totalItems = payload;
    },
    updateSearchId(state) {
      state.searchId = uuid();
    },
    setNoSearch(state) {
      state.searchId = undefined;
    },
    updateDocumentFilter(state, { payload }) {
      state.filterData = payload;
    },
    selectDocument(state, { payload }) {
      state.selectedDocument = payload;
    },
    setDataFetch(state, { payload }) {
      const data = [...(state.dataFetch ?? []), ...(payload as any)];
      state.dataFetch = data;
    },
    resetData(state) {
      return initialState;
    },
  },
});

export const documentReducer = documentSlice.reducer;
const selectSelf = (state: RootState) => state.document;

export const documentActions = documentSlice.actions;

// Selectors
export const documentStateSelector = createSelector(selectSelf, (state) => state);
