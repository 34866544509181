export const globalAppVariables = {
  NULL_INT: -2147483647,
  IGNORE_VALUE_INT: -2147483646,
  NONE_EXISTING_VALUE_INT: -2147483645,
  KEEP_VALUE_OR_SET_NULL: -2147483639,

  FAVORITE_LOCATIONS: -2147483641,
  FAVORITE_AREAS: -2147483642,

  NULL_STRING: "-1.7973E+308",
  IGNORE_VALUE_STRING: "-1.7972E+308",
  NONE_EXISTING_VALUE_STRING: "-1.7971E+308",

  NOT_AVAILABLE_DATE_STRING: '9999-99-99',

  MY_COMPANY_OPTION: -1,
  OTHER_COMPANIES_OPTION: -2,
  LOGGED_IN_USER_OPTION: -3,

  DEFAULT_PAGE_SIZE: 50,
  DEFAULT_GROUP_SIZE: 30,
  DOCUMENT_FILE_MAX_SIZE: 15,
  IMAGE_FILE_MAX_SIZE: 4,
  DEFAULT_INPUT_MAX_LENGTH: 50,

  TABLE_LIMIT_FIELDS: 29,
  SPLIT_LIMIT_FIELDS: 5,

  EDIT_PROPERTES_LIMIT_ITEMS: 20,

  PROPERTIES_SYSTEM_TYPE: 0,

  IGNORE_VALUE_DATE: new Date(100, 0, 31),
  NULL_DATE: new Date(100, 1, 1),

  MY_CONTROL_CATEGORIES_OPTION: -4,
  LOGGED_IN_COMPANY_OPTION: -5,
  OTHER_COMPANIESANDTOOLSTORE_OPTION: -6,

  ALL_INSPECTION_FORMS: -10,
  ALL_CERTIFICATE_FORMS: -11,

  RECENTLY_VIEWS_LIMIT_ITEMS :5,
};
