import i18n from 'i18next';
import { ApiConstants } from '../constants/api.constants';
import { ContentTypeIcons } from '../constants/content-type.constants';
import { FileConstants, SIZE_10MB } from '../constants/file.constants';
import { ContentType } from '../enums/content-type.enum';
import { makeValidFileName } from '../utils/file.utils';
import { gGetUrl } from './url.helper';

interface IDocumentPreviewModel {
  contentType?: string;
  description: string;
  documentId: number;
}

const showDocument = (doc: any) => {
  if (doc === undefined || doc === null) return;

  const officeTypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
  ];

  if (doc !== undefined) {
    if (doc.contentType === null || doc.contentType === 'application/pdf') {
      showPdfDocument(doc);
    } else if (officeTypes.includes(doc.contentType)) {
      showMicrosoftDocument(doc);
    } else {
      showImageViewer(doc);
    }
  }
};

const showPdfDocument = (doc: IDocumentPreviewModel) => {
  if (doc) {
    hideImageViewer();
    const pdfViewer = document.getElementById('pdfViewer');
    if (pdfViewer) {
      const urlViewer = getUrlPdfDocument(doc);
      pdfViewer.setAttribute('src', urlViewer);
    }
  }
};

const showMicrosoftDocument = (doc: IDocumentPreviewModel) => {
  if (doc) {
    hideImageViewer();
    const pdfViewer = document.getElementById('pdfViewer');
    if (pdfViewer) {
      const urlViewer = getUrlMicrosoftDocument(doc);
      pdfViewer.setAttribute('src', urlViewer);
    }
  }
};

const showImageViewer = (doc: IDocumentPreviewModel) => {
  if (doc) {
    hidePdfViewer();
    const imagePreview = document.getElementById('divDocumentImagePreview');
    if (imagePreview) {
      let img = new Image();
      img.src = getUrlImageDocument(doc);
      img.id = 'documentImage';
      img.alt = doc.description;
      img.onload = function () {
        imagePreview.append(img);
        // CommonHelper.showImageViewer(img);
      };
    }
  }
};

const hideImageViewer = () => {
  const imageViewer = document.getElementById('divDocumentImagePreview');
  if (imageViewer !== undefined) {
    imageViewer?.remove();
  }
};

const hidePdfViewer = () => {
  const pdfViewer = document.getElementById('pdfViewer');
  if (pdfViewer !== undefined) {
    pdfViewer?.remove();
  }
};

const getUrlPdfDocument = (doc: IDocumentPreviewModel) => {
  if (doc === undefined || doc === null) return '';

  return `${gGetUrl('Document', 'GetDocumentContent')}/${makeValidFileName(doc.description)}?id=${doc.documentId}`;
};

const getUrlMicrosoftDocument = (doc: IDocumentPreviewModel) => {
  if (doc === undefined || doc === null) return '';

  let urlDocument = '';
  const urlPdfFile = getUrlPdfDocument(doc);
  const urlPdfEncode = urlPdfFile.replace(':', '%3A');
  urlDocument = FileConstants.MICROSOFT_DOCUMENT_VIEWER_URL + urlPdfEncode;

  // if (doc.FileSize > size10MB) {
  //   urlDocument = `${gGetUrl('Home', 'ShowDownloadHeavyDocumentMessage')}?urlDownloadDocument=${encodeURIComponent(urlPdfFile)}`;
  // } else {
  //   const urlPdfEncode = urlPdfFile.replace(':', '%3A');
  //   urlDocument = FileConstants.MICROSOFT_DOCUMENT_VIEWER_URL + urlPdfEncode;
  // }
  return urlDocument;
};

const getUrlImageDocument = (doc: any) => {
  if (doc === undefined || doc === null) return '';

  return `${gGetUrl('Equipment', 'DocumentImageFile')}?documentId=${doc.documentId}`;
};

const getUrlDownloadDocument = (doc: any) => {
  if (doc === undefined || doc === null) return '';

  return `${gGetUrl('Home', 'GetDocumentBinary')}?documentId=${doc.documentId}`;
};

const openDocument = (doc: any) => {
  if (doc) {
    const contentType = getDocumentContentType(doc.contentType);
    const microsoftTypes = [ContentType.Excel, ContentType.Word];
    if (contentType === ContentType.Image) {
      openImageWindow(doc);
    }
    if (contentType === ContentType.Pdf) {
      openPdfWindow(doc);
    }
    if (microsoftTypes.includes(contentType)) {
      openMicrosoftDocWindow(doc);
    }
  }
};

const openPdfWindow = (doc: any) => {
  if (doc) {
    const url = getUrlPdfDocument(doc);
    window.open(url, '_blank', 'width=800,height=600,resizable=1');
  }
};

const openMicrosoftDocWindow = (doc: any) => {
  if (doc.fileSize > SIZE_10MB) {
    openHeavyMicrosoftDocWindow(doc);
  } else {
    const url = getUrlMicrosoftDocument(doc);
    window.open(url, '_blank', 'width=800,height=600,resizable=1');
  }
};

const openHeavyMicrosoftDocWindow = (doc: any) => {
  const url = getUrlPdfDocument(doc);
  var newWindow = window.open(
    'http://localhost:3002/94A4CC22-29F0-4DD7-8A4D-512670842CCE/equipment',
    '_blank',
    'width=800,height=600,resizable=1'
  );
  const heavyDocumentTranslation = i18n.t('CaptionResource.HeavyDocumentFileMessage');
  const heavyDocumentDescription = heavyDocumentTranslation.replace('{link}', url);
  // HTML content to be written in the new window
  var htmlContent = `
      <html>
      <head>
          <title>Document</title>
          <style>
            .open-doc-container {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              font-size: 15px;
              font-family: 'Segoe UI', Verdana, Helvetica, Sans-Serif;
            }
          </style>
      </head>
      <body>
          <div class="open-doc-container">${heavyDocumentDescription}</div>
      </body>
      </html>
  `;
  // Write the HTML content to the new window
  newWindow?.document.write(htmlContent);
  newWindow?.document.write('<script>window.focus();</script>');
  newWindow?.focus();
};

const openImageWindow = (doc: any) => {
  if (doc) {
    const url = ApiConstants.document.getImageDocument(doc.documentId);
    var newWindow = window.open(url, '_blank', 'width=800,height=600,resizable=1');
    newWindow?.focus();
  }
};

const isPdfType = (doc: any) => {
  if (doc) {
    const pdfTypes = [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
    ];

    return doc.contentType === null || pdfTypes.includes(doc.contentType);
  }
};

const getDocumentIconName = (contentType: string) => {
  if (!contentType) return 'PDF';
  let res = ContentTypeIcons.find((x) => contentType && x.searchText.some((x) => contentType.includes(x)));
  if (res) return res.iconName;
  return '';
};

const getDocumentContentTypeName = (item: IDocumentPreviewModel) => {
  if (!item.contentType) return 'pdf';
  let res = ContentTypeIcons.find((x) => item.contentType && x.searchText.some((x) => item.contentType?.includes(x)));
  if (res) return res.name;
  return 'pdf';
};

const getDocumentContentType = (contentType?: string): ContentType => {
  if (!contentType) return ContentType.Pdf;
  let res = ContentTypeIcons.find((x) => contentType && x.searchText.some((x) => contentType.includes(x)));
  if (res) return res.type;
  return ContentType.Pdf;
};

export const DocumentHelper = {
  showDocument,
  getUrlMicrosoftDocument,
  getUrlPdfDocument,
  getUrlDownloadDocument,
  openDocument,
  isPdfType,
  getDocumentIconName,
  getDocumentContentTypeName,
  getDocumentContentType,
};
