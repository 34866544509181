import { SettingKeyConstants } from '../constants/setting-keys.constants';
import { getSessionStorageItem } from '../utils/sessionStorage.utils';
const getErrorMessage = (value: any): string | undefined => {
  if (value?.Message) {
    return value.Message;
  }
  return undefined;
};

const isEmptyOrWhiteSpaces = (input: string): boolean => {
  if (input === undefined || input === null) {
    return true;
  } else {
    return input.match(/^ *$/) !== null;
  }
};

const CommonHelper = {
  userAccountId: getSessionStorageItem(SettingKeyConstants.AccountId),
  /*
   * Get error message from API response
   */
  getErrorMessage,
  isEmptyOrWhiteSpaces,

  isNotNullOrUndefined: <T>(value: T | null | undefined): value is T => value !== null && value !== undefined,

  lowercaseFirstChar: (value: string) => value.charAt(0).toLowerCase() + value.slice(1),

  getDistinctValueField<T, U>(list: T[], selector: (item: T) => U) {
    return Array.from(new Set(list.map(selector)));
  },

  groupBy<T, U>(list: T[], selector: (item: T) => U): Map<U, T[]> {
    return list.reduce((map, item) => {
      const selectorValue = selector(item);
      const groupList = map.get(selectorValue) ?? [];

      groupList.push(item);

      return map.set(selectorValue, groupList);
    }, new Map<U, T[]>());
  },

  distinctBy<T, U>(list: T[], selector: (item: T) => U): T[] {
    return Array.from(
      list
        .reduce((map, item) => {
          const key = selector(item);

          return map.has(key) ? map : map.set(key, item);
        }, new Map<U, T>())
        .values()
    );
  },

  sort<T>(list: Array<T>, ...selectors: Array<(item: T) => any>) {
    for (const fn of selectors) {
      list = list.sort((a, b) => {
        const vala = fn(a);
        const valb = fn(b);

        // Value zero-0 to make this is a stable sort.
        return vala > valb ? 1 : vala < valb ? -1 : 0;
      });
    }

    return list;
  },
  boolToString(bool: boolean) {
    return (!!bool).toString() as 'true' | 'false';
  },

  sum(list: number[]) {
    return list.reduce((sum, val) => sum + val, 0);
  },

  sumBy<T>(list: T[], selector: (item: T) => number) {
    if (!list.length) {
      return 0;
    }

    return list.reduce((acc, item) => acc + selector(item), 0);
  },

  stripUndefined<T extends Record<string, any | undefined>>(obj: T): Record<string, NonNullable<any>> {
    const entries = Object.entries(obj).filter((entry) => entry[1] !== undefined);
    return Object.fromEntries(entries);
  },
};

export default CommonHelper;
