import ApplicationSetting from "../constants/application.constants";
import { SettingKeyConstants } from "../constants/setting-keys.constants";

const getLocalStorageKey = (key: string): string => `${ApplicationSetting.appName}.${ApplicationSetting.environment}.${key}`;

const getItemString = (key: string): string | null => localStorage.getItem(getLocalStorageKey(key));

const getItem = <V>(key: string): V | null => {
  const item = getItemString(key);
  if (item === null) {
    return item;
  }

  return JSON.parse(item);
};
type PrimitiveType = number | string | boolean;
type ItemOrDefault<V> = V extends object | PrimitiveType ? V : (() => V);

const getItemOrDefault = <V>(key: string, defaultValue: ItemOrDefault<V>): V =>
  getItem<V>(key) ?? (typeof defaultValue !== "function" ? defaultValue : defaultValue());

const shouldLog = () => getItemOrDefault(SettingKeyConstants.ShowDebugLog, false);


export const LocalStorageService = {
  getLocalStorageKey,
  getItemString,
  getItem,
  getItemOrDefault,
  shouldLog
}
