import { useTranslation } from 'react-i18next';
import { WidgetConstants } from '../constants/dashboard.constants';
import { FormsCaption } from '../enums/form-caption.enum';
import { WidgetType } from '../enums/widget.enum';
import { ECOption } from '../models/dashboard/echart-data.model';
import { WidgetData, WidgetDataItem, WidgetId, WidgetInfo } from '../models/dashboard/widget.model';
import CommonHelper from './common-helper';
import { DashboardHelper } from './dashboard-helper';

type Translator = ReturnType<typeof useTranslation>;

export class WidgetHelper {
  public static translateWidgetDataLabel([translator]: Translator, widgetDataString: string) {
    const pattern = /({{).*?(}})/g;
    const matches = widgetDataString.match(pattern) ?? [];

    for (const specificCaption of matches) {
      const [formIdString, ...controlList] = specificCaption.replaceAll('{{', '').replaceAll('}}', '').split('_');

      const formName = FormsCaption[parseInt(formIdString)];
      if (!formName) {
        continue;
      }

      const captionControl = controlList.join('_');
      const translatedCaption = translator(`${formName}.${captionControl}`);

      widgetDataString = widgetDataString.replaceAll(specificCaption, translatedCaption);
    }

    return widgetDataString;
  }

  public static parseWidgetData(widgetId: WidgetId, widgetData: WidgetData, translator: Translator): WidgetInfo | undefined {
    const widget = DashboardHelper.makeWidgetInfo(widgetId, widgetData);

    const widgetDataListList: (string | number)[][] = widget.rawData
      ? JSON.parse(this.translateWidgetDataLabel(translator, widget.rawData))
      : [];

    // Just have to check with simple validation.
    // If there is anything wrong with the format, it should consider bad data of the WidgetData.
    const minimumDataListLength = 2;
    const isWidgetDataListListValid = widgetDataListList?.length >= minimumDataListLength && widgetDataListList[0]?.length > 0;
    if (!isWidgetDataListListValid) {
      return;
    }

    const [headerList, ...dataListList] = widgetDataListList;

    widget.headers = headerList.map((header) => CommonHelper.lowercaseFirstChar(header.toString()));
    widget.data = this.convertWidgetDataToArrayObject(dataListList, widget.headers);
    widget.total = widget.data.reduce((total, data) => total + data.value, 0);

    return widget;
  }

  private static convertWidgetDataToArrayObject(dataListList: (string | number)[][], headers: string[]) {
    const widgetDataItemListIntoObject = (dataList: (string | number)[]) =>
      Object.fromEntries(dataList.map((value, index) => [headers[index], value])) as unknown as WidgetDataItem;

    // Re-create object with new WidgetDataItem(...) is needed since we're going to use its methods.
    const results = dataListList.map((dataList) => DashboardHelper.makeUniformWidgetDataItem(widgetDataItemListIntoObject(dataList)));

    return results;
  }

  public static getEquipmentFromViewChartOptions(widget: WidgetInfo): ECOption {
    const widgetData = widget.data;

    switch (widget.levels[0]?.widgetType) {
      case WidgetType.Bar:
        return DashboardHelper.generateDonutChartOptions(widgetData, {
          colorSelector: (it) => it.itemColor,
          itemUniqueIdSelector: (it) => it.uniqueIdentifier,
          valueSelector: (it) => it.value,
          labelSelector: (it) => it.itemLabel,
        });

      case WidgetType.Donut:
        return DashboardHelper.generateDonutChartOptions(widgetData, {
          colorSelector: (it) => it.itemColor,
          itemUniqueIdSelector: (it) => it.uniqueIdentifier,
          valueSelector: (it) => it.value,
          labelSelector: (it) => it.itemLabel,
        });

      case WidgetType.Column:
        return DashboardHelper.generateColumnChartOptions(widgetData, {
          itemUniqueIdSelector: (item) => item.uniqueIdentifier,
          valueSelector: (item) => item.value,
          xAxisLabelSelector: (item) => item.itemLabel ?? '',
          colorSelector: (item) => item.itemColor ?? WidgetConstants.defaultLegendColor,
          labelSelector: (item) => item.itemLabel ?? '',
        });

      default:
        return {};
    }
  }

  public static getIssuesByStatusChartOptions(widget: WidgetInfo): ECOption {
    return DashboardHelper.generateDonutChartOptions(widget.data, {
      valueSelector: (item) => item.value,
      labelSelector: (item) => item.itemLabel,
      colorSelector: (item) => item.itemColor,
      itemUniqueIdSelector: (item) => item.uniqueIdentifier,
    });
  }

  public static isPieChart(chartOptions: ECOption) {
    return !Array.isArray(chartOptions.series) && chartOptions.series?.type === 'pie';
  }
}
