import { DefaultButton, Icon, Label, PanelType } from '@fluentui/react';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTracking } from '../../common/hooks/useTracking';
import PrimaryButton from '../../common/ui/Button/PrimaryButton';
import { PanelWithScrollableBody } from '../../common/ui/PanelWithScrollableBody/PanelWithScrollableBody';
import TextField from '../../common/ui/TextField/TextField';
import Title from '../../common/ui/Title/Title';
import { KeyBoardCodes, MaximumInvitationUser } from '../../constants/common.constants';
import { Modules } from '../../constants/modules.constants';
import { TrackingEvent } from '../../constants/tracking-event.constant';
import { ColleagueItem } from '../../models/colleague.model';
import { NewUserExpressItem } from '../../models/invite-express-user.model';
import { CompanyService } from '../../services/company.service';
import { showDiscardChanges, showMessageBox } from '../../services/modal.service';
import { ModalProps } from '../../types/modal.type';
import { isEmail, isEqualSimple, isStringNotNullOrUndefined, makeEmailsFromStr } from '../../utils/common.utils';

type Props = ModalProps & {
  colleagues?: ColleagueItem[];
  onAfterAdd: (newItems: ColleagueItem[]) => void;
};

type UserFieldType = 'email' | 'firstName' | 'lastName';

const AddNewUser = ({ isOpen, onDismiss, onAfterAdd, colleagues = [] }: Props) => {
  const [translate] = useTranslation();
  const remainingUser = MaximumInvitationUser - colleagues?.filter((x) => x.isUser).length;
  const existedUserEmails = colleagues?.filter((x) => x.isUser).map((x) => x.email);
  const [emailsInput, setEmailsInput] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const { trackEvent } = useTracking({ module: Modules.InviteColleaguesPanel });

  const {
    getValues,
    setValue,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      users: [] as NewUserExpressItem[],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });
  const formValueWatch = useWatch({ control });

  const hasChanges = !isEqualSimple(formValueWatch, { users: [] }) || emailsInput !== '';

  const handleAddEmails = () => {
    const cloneEmailsInput = emailsInput;
    if (isStringNotNullOrUndefined(cloneEmailsInput)) {
      const emails = makeEmailsFromStr(cloneEmailsInput);
      const newItems = emails.map((email) => {
        return {
          email: email,
          firstName: '',
          lastName: '',
        } as NewUserExpressItem;
      });
      append([...newItems]);
      setEmailsInput('');
    }
  };

  useEffect(() => {
    setCanSubmit(hasChanges && isValid);
  }, [hasChanges, isValid]);

  const isDupNewEmails = (email: string) => getValues()?.users?.filter((x) => x.email === email).length > 1;

  const emailValidator = (email: string) => {
    if (!isStringNotNullOrUndefined(email)) return translate('CaptionResource.Required');
    if (!isEmail(email)) return translate('CommonResource.InvalidEmail');
    if (existedUserEmails?.includes(email) || isDupNewEmails(email)) return translate('CommonResource.Duplicated');
    if (email.length > 100) return translate('JavascriptMessages.ValueIsTooLong');
    return '';
  };
  const requiredValidtor = (str: string) => {
    if (!isStringNotNullOrUndefined(str)) return translate('CaptionResource.Required');
    return '';
  };
  const validators = {
    email: emailValidator,
    firstName: requiredValidtor,
    lastName: requiredValidtor,
  };

  const trimText = (onBlur: () => void, fieldName: UserFieldType, index: number, value: string) => {
    if (onBlur) onBlur();

    if (isStringNotNullOrUndefined(value) && value.trim() !== '') {
      setValue(`users.${index}.${fieldName}`, value.trim());
    } else {
      setValue(`users.${index}.${fieldName}`, '');
    }
  };

  const submit = async () => {
    const { users } = getValues();
    if (users && users.length > 0) {
      if (users.length > remainingUser) {
        showMessageBox({
          header: <Title text={translate('CommonResource.UserLimitReached')} />,
          children: translate('CommonResource.cannotPerformActionWhenHitMaxExpressUser').replace('{0}', MaximumInvitationUser.toString()),
        });
        return;
      }
      const result = await CompanyService.addNewUsersExpress(users);
      if (result) {
        onAfterAdd && onAfterAdd(result);
        trackEvent(TrackingEvent.InviteColleaguesPanel.InviteAction, { OExpressNumberofUsersAdded: result.length });
      }
    }
  };

  const renderFieldControl = ({ field, index, validator }: { field: UserFieldType; index: number; validator: (val: any) => string }) => {
    return (
      <Controller
        control={control}
        name={`users.${index}.${field}`}
        rules={{ validate: { required: (value: any) => !isStringNotNullOrUndefined(validator(value)) } }}
        render={({ field: { value, onChange, onBlur } }) => (
          <TextField
            name={field}
            value={value}
            onBlur={(event) => trimText(onBlur, field, index, event.target.value)}
            onChange={(_, value) => onChange(value)}
            errorMessage={validator(value)}
          />
        )}
      />
    );
  };

  const closeModal = useCallback(() => {
    if (hasChanges) {
      showDiscardChanges({
        onDiscard: onDismiss,
      });
    } else {
      onDismiss && onDismiss();
    }
  }, [onDismiss, hasChanges]);

  return (
    <>
      <PanelWithScrollableBody
        isOpen={isOpen}
        onDismiss={closeModal}
        title={translate('CommonResource.AddNewUser')}
        renderAdditionalHeader={() => (
          <div className="text-mini flex flex-col gap-3">
            <p>{translate('Settings.MsgUserAddWelcomeInfor')}</p>
            <span>
              {translate('CommonResource.YouHaveMaxNumUsersAndRemainingNumUsers')
                .replace('{0}', MaximumInvitationUser.toString())
                .replace('{1}', remainingUser.toString())}
            </span>
          </div>
        )}
        panelProps={{
          type: PanelType.medium,
        }}
        renderBody={() => (
          <>
            <div className="border-b pt-1 pb-5">
              <Label>{translate('CaptionResource.Email')}</Label>
              <div className="flex justify-between mb-2">
                <TextField
                  placeholder="abc@example.com"
                  autoFocus={true}
                  value={emailsInput}
                  onChange={(_, newValue) => setEmailsInput(newValue || '')}
                  className="mr-2"
                  maxLength={1000}
                  onKeyDown={(e) => e.key === KeyBoardCodes.ENTER && handleAddEmails()}
                />
                <PrimaryButton text={translate('CommonResource.cmdAdd')} onClick={handleAddEmails} />
              </div>
              <span className="text-mini">{translate('CommonResource.CommasOrSemicolonsSeperateMultiEmails')}</span>
            </div>
            <div className="flex flex-col gap-3 mt-3 pr-2">
              {fields.length === 0 && <p className="text-mini">{translate('Settings.AddOneOrMoreEmails')}</p>}
              <form onSubmit={handleSubmit(() => submit())}>
                {fields.map((user: NewUserExpressItem, index) => (
                  <div key={user.id} className="flex justify-around gap-3">
                    <div className="w-[40%]">
                      <Label>{translate('CaptionResource.Email')}</Label>
                      {renderFieldControl({
                        field: 'email',
                        index: index,
                        validator: validators.email,
                      })}
                    </div>
                    <div className="w-[30%]">
                      <Label>{translate('CommonResource.FirstName')}</Label>
                      {renderFieldControl({
                        field: 'firstName',
                        index: index,
                        validator: validators.firstName,
                      })}
                    </div>
                    <div className="w-[30%]">
                      <Label>{translate('CommonResource.LastName')}</Label>
                      <div className="flex justify-between gap-4">
                        {renderFieldControl({
                          field: 'lastName',
                          index: index,
                          validator: validators.lastName,
                        })}
                        <Icon iconName="Delete" className="cursor-pointer pt-[6px]" onClick={() => remove(index)} />
                      </div>
                    </div>
                  </div>
                ))}
              </form>
            </div>
          </>
        )}
        renderFooter={() => (
          <>
            <PrimaryButton
              text={translate('CommonResource.Invite')}
              className="mr-4"
              disabled={!canSubmit}
              onClick={handleSubmit(submit)}
            />
            <DefaultButton onClick={closeModal} text={translate('CaptionResource.Caption53')} />
          </>
        )}
      />
    </>
  );
};

export default AddNewUser;
