import { color } from '@onix/common/src/assets/styles';
import { useTranslation } from 'react-i18next';

interface INoData {
  text?: string;
}

export const NoData = (props: INoData) => {
  const [translate] = useTranslation();

  return <div style={{ color: color.$grey110, fontSize: '12px' }}>{props.text ? props.text : translate('CommonResource.Nodata')}</div>;
};
