import { PanelType } from '@fluentui/react';
import { PanelWithScrollableBody } from '@onix/common/src/components/PanelWithScrollableBody/PanelWithScrollableBody';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { equipmentDocumentActions, equipmentDocumentSelector } from '../../../states/equipment/equipmentDocumentSlice';
import { equipmentActions, equipmentSelector } from '../../../states/equipment/equipmentSlice';
import PreviewDocumentPanel from './Preview/PreviewDocument';
import EquipmentDocumentsTableContent, { EquipmentDocumentsTableImperative } from './TableContent/TableContent';
import EquipmentDocumentsToolbar from './Toolbar/Toolbar';
import './index.scss';

const EquipmentDocuments = () => {
  const [translate] = useTranslation();
  const dispatch = useDispatch();
  const { numOfSelectedEquipments } = useSelector(equipmentSelector);
  const { isShowPreviewDocument, selectedDocuments } = useSelector(equipmentDocumentSelector);
  const selectedDocument = selectedDocuments[0];

  const tableRef = useRef<EquipmentDocumentsTableImperative>(null);

  const onDismissPanel = useCallback(() => {
    dispatch(equipmentActions.setShowDocumentManagementPanel(false));
  }, []);

  const onRenderHeader = useCallback(() => {
    return (
      <>
        <span className="title">
          {translate('Equipment.EditDocumentItemSelected', {
            0: numOfSelectedEquipments,
          })}
        </span>
      </>
    );
  }, []);

  const onRenderBody = useCallback(() => {
    return (
      <>
        <EquipmentDocumentsToolbar clearSelectedDocuments={clearSelectedDocuments} />
        <EquipmentDocumentsTableContent ref={tableRef} />
        {isShowPreviewDocument && (
          <PreviewDocumentPanel
            isOpen={true}
            document={selectedDocument}
            onDismiss={() => {
              dispatch(equipmentDocumentActions.setShowPreviewDocument(false));
            }}
          ></PreviewDocumentPanel>
        )}
      </>
    );
  }, [dispatch, isShowPreviewDocument, selectedDocument]);

  const clearSelectedDocuments = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.clearSelectedDocuments();
    }
  };

  return (
    <PanelWithScrollableBody
      className="equipment-documents-panel"
      isOpen={true}
      onDismiss={onDismissPanel}
      hideSeparator={true}
      panelProps={{
        type: PanelType.extraLarge,
        isBlocking: true,
        isLightDismiss: true,
      }}
      renderHeader={onRenderHeader}
      renderBody={onRenderBody}
    />
  );
};

export default EquipmentDocuments;
