import { useTranslation } from 'react-i18next';
import './index.scss';

interface IDetailRow {
  isHidden?: boolean;
  caption: string;
  captionCustom?: () => JSX.Element;
  value?: string | JSX.Element;
  isDisplayColonOnCaption?: boolean;
  captionWidth?: number;
  className?: string;
}

export const DetailRow = (props: IDetailRow) => {
  const [translate] = useTranslation();
  const { isHidden, caption, value, isDisplayColonOnCaption, className } = props;

  return (
    <>
      {(!isHidden || value) && (
        <div className={`section-row ${className ?? ''}`}>
          <div className="caption" style={{ width: props.captionWidth }}>
            {props.captionCustom
              ? props.captionCustom()
              : translate(caption) != null && translate(caption) !== undefined && translate(caption) !== ''
              ? translate(caption)
              : caption}
            {isDisplayColonOnCaption && ':'}
          </div>
          <div className="field">{value}</div>
        </div>
      )}
    </>
  );
};
