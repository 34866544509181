export const TrackingEvent = {
  Documents: {
    SearchAction: 'OExpress.Documents.SearchAction',
  },
  Equipment: {
    ReportActions: {
      ExportToExcel: 'OExpress.Report.ExportToExcelAction',
    },
    Toolbar: {
      Filter: 'OExpress.Equipment.Toolbar.Filter',
      SingleEdit: 'OExpress.Equipment.Toolbar.SingleEdit',
      SingleDocuments: 'OExpress.Equipment.Toolbar.SingleDocuments',
      MultipleEditAll: 'OExpress.Equipment.Toolbar.MultipleEditAll',
      MultipleDocuments: 'OExpress.Equipment.Toolbar.MultipleDocuments',
      Reports: {
        ExportToExcel: 'OExpress.Equipment.Toolbar.Reports.ExportToExcel',
      },
    },
    EquipmentDetail: {
      Detail: {
        Picture: 'OExpress.Equipment.EquipmentDetails.EquipmentPicture',
        // Document: 'OExpress.Equipment.EquipmentDetails.EquipmentDocument',
        RelatedSection: {
          Documents: 'OExpress.Equipment.EquipmentDetails.RelatedSection.Documents',
          Pictures: 'OExpress.Equipment.EquipmentDetails.RelatedSection.Pictures',
        },
        Properties: {
          Edit: 'OExpress.Equipment.EquipmentDetails.Properties.Edit',
        },
        EquipmentDetails: {
          Edit: 'OExpress.Equipment.EquipmentDetails.EquipmentDetails.Edit',
          ShowHideAllFields: 'OExpress.Equipment.EquipmentDetails.EquipmentDetails.ShowHideFields',
        },
        JobIntervals: {
          Add: 'OExpress.Equipment.EquipmentDetails.JobIntervals.AddJobInterval',
        },
        CreatedModified: {
          ViewFull: 'OExpress.Equipment.EquipmentDetails.CreatedModified.ViewFullEquipmentHistory',
        },
      },
      Pictures: {
        AddPicture: 'OExpress.Equipment.EquipmentDetails.Pictures.AddPicture',
        EditPicture: 'OExpress.Equipment.EquipmentDetails.Pictures.Edit',
        DeletePicture: 'OExpress.Equipment.EquipmentDetails.Pictures.Delete',
        Preview: {
          Download: 'OExpress.Equipment.EquipmentDetails.Pictures.Preview.Download',
          Edit: 'OExpress.Equipment.EquipmentDetails.Pictures.Preview.Edit',
          Delete: 'OExpress.Equipment.EquipmentDetails.Pictures.Preview.Delete',
        },
      },
      Documents: {
        AddAction: 'OExpress.Equipment.EquipmentDetails.Documents.AddDocument',
        SendAction: 'OExpress.Equipment.EquipmentDetails.Documents.Send',
        SearchAction: 'OExpress.Equipment.EquipmentDetails.Documents.SearchAction',
        FilterAction: 'OExpress.Equipment.EquipmentDetails.Documents.FilterAction',
        DownloadAction: 'OExpress.Equipment.EquipmentDetails.Documents.Preview.Download',
      },
    },
    SearchAction: 'OExpress.Equipment.SearchAction',
    EquipmentListSort: 'OExpress.Equipment.SortAction',
    EquipmentListGroup: 'OExpress.Equipment.GroupAction',
  },
  RequestAUserPage: {
    RequestUserAction: 'OExpress.RequestAUserPage.RequestUserAction',
  },
  NavigationPanel: {
    HomepageAction: 'OExpress.NavigationPanel.HomepageAction',
    DashboardAction: 'OExpress.NavigationPanel.DashboardAction',
    DocumentsAction: 'OExpress.NavigationPanel.DocumentsAction',
    EquipmentAction: 'OExpress.NavigationPanel.EquipmentAction',
    AlertsAction: 'OExpress.NavigationPanel.AlertsAction',
    ChangeLanguageAction: 'OExpress.NavigationPanel.ChangeLanguageAction',
    GetHelpAction: 'OExpress.NavigationPanel.GetHelpAction',
    OnixCommunityAction: 'OExpress.NavigationPanel.OnixCommunityAction',
    MarketingBannerAction: 'OExpress.NavigationPanel.MarketingBannerAction',
  },
  Header: {
    ChangeOrgAction: 'OExpress.Header.ChangeOrgAction',
    MyOnixAccountAction: 'OExpress.Header.MyOnixAccountAction',
    SignOutAction: 'OExpress.Header.SignOutAction',
  },
  HomePage: {
    InviteColleaguesAction: 'OExpress.HomepagePage.InviteColleaguesAction',
    MarketingBannerAction: 'OExpress.HomepagePage.MarketingBannerAction',
  },
  InviteColleaguesPanel: {
    InviteThisContactAction: 'OExpress.InviteColleaguesPanel.InviteThisContactAction',
    AddNewUserAction: 'OExpress.InviteColleaguesPanel.AddNewUserAction',
    InviteAction: 'OExpress.InviteColleaguesPanel.AddNewUserPanel.InviteAction',
  },
  Footer: {
    PoweredByOnixWorkAction: 'OExpress.Footer.PoweredByOnixWorkAction',
  },
  UnsubscribePage: {
    UnsubscribePage: 'OExpress.UnsubscribePage',
  },
};
