import { Icon } from '@fluentui/react';
import './index.scss';

interface ISpinLoaderProps {
  iconName: string;
  loadingText: string;
}

export const SpinLoader = (props: ISpinLoaderProps) => {
  return (
    <div className="spin-loader">
      <Icon className="rotate-icon" iconName={props.iconName} styles={{ root: { fontSize: '20px' } }}></Icon>
      <span className="loadingText">{props.loadingText}</span>
    </div>
  );
};
