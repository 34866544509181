import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonIcon from '../../../common/ui/Button/ButtonIcon';
import SideBar from '../../../components/SideBar/SideBar';
import { CompanyExpressPreviewContext } from '../../../context/CompanyExpressPreviewContext';
import { isStringNotNullOrUndefined } from '../../../utils/common.utils';
import { getCompanyLogo } from '../../../utils/img.utils';

const Home = () => {
  const [translate] = useTranslation();
  const previewInfo = useContext(CompanyExpressPreviewContext);
  const logoModel = {
    imageInfoId: previewInfo.logoImageInfoId,
    imageContent: previewInfo.logoImageContent,
    companyLogo: previewInfo.companyLogo,
  };
  const showLogo = logoModel.imageInfoId > 0 || isStringNotNullOrUndefined(logoModel.companyLogo?.toString());

  return (
    <>
      <SideBar preview></SideBar>
      <div className="w-full bg-grey10 min-h-full overflow-auto">
        <div className="flex flex-col gap-4 p-6 pb-4 h-full lg:gap-10">
          <div className="flex flex-col gap-6">
            <h4 className="text-[20px]">{translate('CommonResource.TitleDocumentDistributionPortalOf')}</h4>
            <div className="flex flex-col gap-4 text-sm lg:h-[235px] lg:flex-row">
              <div className="flex flex-col justify-between bg-white shadow-depth16 rounded-sm lg:flex-row lg:w-2/3">
                {showLogo && (
                  <div className="bg-primarybg flex justify-center items-center flex-1 min-h-[138px] rounded-sm p-6 lg:py-0">
                    <img src={getCompanyLogo(logoModel)} alt="" className="max-h-[85%] object-contain" />
                  </div>
                )}
                <div className="flex flex-col justify-center leading-5 flex-1 text-mini p-4">
                  <h5 className="font-medium uppercase mb-3 text-sm">{previewInfo.companyName}</h5>
                  <span>
                    {previewInfo?.businessRoles
                      ?.sort((x) => x.businessRoleOrder)
                      ?.map((x) => x.captionLanguageDescription)
                      ?.join(', ')}
                  </span>
                  <p>{previewInfo.address1}</p>
                </div>
              </div>
              <div className="shadow-depth16 rounded-sm p-6 lg:w-1/3 bg-white">
                <div className="flex flex-col justify-center items-center gap-2 text-center h-full w-full">
                  <h5 className="uppercase font-medium">{translate('CommonResource.ExpressCompanyName')}</h5>
                  <div className="text-mini">
                    <span className="font-medium">{translate('CaptionResource.ManagedBy')}:</span>
                    <p>{translate('CommonResource.ManagingContactName')}</p>
                    <p>{translate('CommonResource.ManagingContactEmail')}</p>
                  </div>
                  <ButtonIcon
                    icon="PeopleAdd"
                    text={translate('Home.InviteColleagues')}
                    className={'mt-3 shadow-depth8 hover:cursor-default'}
                    onClick={() => {}}
                  ></ButtonIcon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
