const isNotNullOrUndefined = <T>(obj: T | null | undefined): obj is T => !!obj;

const isStringNotNullOrUndefined = (str: string | null | undefined) => str !== undefined && str !== null && str !== '';

const isEqualSimple = <T extends Record<string, any>>(left: T, right: T, ...excludeKeys: (keyof T)[]): boolean => {
  const leftEntries = Object.entries(left);
  const rightEntries = Object.entries(right);
  if (leftEntries.length !== rightEntries.length) {
    return false;
  }

  for (const [lkey, lvalue] of leftEntries) {
    if (excludeKeys.includes(lkey)) {
      continue;
    }

    const rvalue = (right as any)[lkey];
    if (rvalue !== lvalue) {
      // If rvalue && lvalue is Date, check equal by Date.getTime()
      const checkDateIsEqual = rvalue instanceof Date && lvalue instanceof Date && rvalue.getTime() === lvalue.getTime();
      if (checkDateIsEqual) {
        continue;
      }

      const checkArrayIsEqual = rvalue instanceof Array && lvalue instanceof Array && isListEqualSimple(rvalue, lvalue);
      if (checkArrayIsEqual) {
        continue;
      }

      return false;
    }
  }

  return true;
};

const isListEqualSimple = <T>(left: T[], right: T[]) =>
  left?.length === right?.length &&
  left.every((lvalue, lidx) => {
    return typeof lvalue === 'string' || typeof lvalue === 'number'
      ? lvalue === right[lidx]
      : isEqualSimple(lvalue as any, right[lidx] as any);
  });

const emailExpression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const isEmail = (email: string | undefined | null) => emailExpression.test(email || '');

const makeEmailsFromStr = (str: string | undefined | null): string[] => {
  let emails: string[] = [];
  if (str !== undefined && str !== null && str !== '') {
    emails = str.replace(':', ',').replace(';', ',').split(',');
    emails = emails.filter((x) => isStringNotNullOrUndefined(x)).map((x) => x.trim().replaceAll(' ', ''));
    emails = [...new Set(emails)];
  }

  return emails;
};

export { isEmail, isEqualSimple, isListEqualSimple, isNotNullOrUndefined, isStringNotNullOrUndefined, makeEmailsFromStr };
