export enum FormsCaption {
  CaptionResource = 0,
  Contact = 7,
  Department = 10,
  ListContacts = 16,
  AddDatasheetItem = 18,
  SelectEquipment = 27,
  Articlsearchlist = 43,
  EmailAttachment = 67,
  MainFrame = 68,
  Area = 79,
  CompanyConnection = 84,
  RentalProject = 86,
  StaticValues = 999,
  JavascriptMessages = 3000,
  CompanyJobCounter = 4000,
  ControlCategory = 2000,

  Login = 4001,
  CommonResource = 4002, //COMMON_CAPTION
  AddPicture = 4003,
  AddDocument = 4004,
  Menu = 4005,
  Equipment = 4006,
  Job = 4007,
  Document = 4008,
  SearchArticle = 4009,
  Company = 4010,
  Location = 4011,
  ErrorMessage = 4012,
  Contact6 = 4013,
  List = 4014,
  StaticValue = 4015,
  EquipmentGroup = 4016,
  RibbonMenu = 4017,
  Settings = 4018,
  Home = 4019, //Home or FrontPage , Form_Home
  Article6 = 4020,
  EmailTemplate = 4021,
  Rental = 4022,
  Order = 4023,
  Trial = 4024,
  Feedback = 4025,
  Tutorial = 4026,
  AppInspect = 4027,
  Audit = 3001,
}
