import { CommandButton, PanelType, Separator } from '@fluentui/react';
import { FileHelper, PanelWithScrollableBody } from '@onix/common';
import { IOnixTablePagination } from '@onix/common/src/components/OnixTable/IOnixTable';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShowUpgradeOWMessage } from '../../../../common/hooks/useShowUpgradeOWMessage';
import { useTracking } from '../../../../common/hooks/useTracking';
import { ImageViewer } from '../../../../common/ui/ImageViewer/ImageViewer';
import { Modules } from '../../../../constants/modules.constants';
import { TrackingEvent } from '../../../../constants/tracking-event.constant';
import { gGetUrl } from '../../../../helpers/url.helper';
import { IPicture } from '../../../../models/equipmentDetail/equipment-image.model';
import { equipmentService } from '../../../../services/equipment.service';
import './index.scss';

export interface IPreviewPicturePanelProps {
  isOpen: boolean;
  pagedItems: IOnixTablePagination;
  equipmentId: number;
  selectedKey?: string;
  onDismiss?: (ev?: any) => void;
}

const LOAD_MORE_THRESHOLD = 10; // Threshold for triggering loading of more items
const getFileUrl = (id: string) => `${gGetUrl('Equipment', 'GetEquipmentImageFile')}?id=${id}`;

export const PreviewPicturePanel = (props: IPreviewPicturePanelProps) => {
  const { onDismiss } = props;
  const [translate] = useTranslation();
  const [pagedItems, setPagedItems] = useState<IOnixTablePagination>(props.pagedItems);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [src, setSrc] = useState<string>('');

  const isLoadingRef = useRef(false);
  const itemByIndex = pagedItems.items[selectedIndex] as IPicture;

  const { showUpgradeOWMessage } = useShowUpgradeOWMessage();
  const { trackEvent } = useTracking({ module: Modules.Equipment });

  const dismissPanel = useCallback(() => {
    if (onDismiss) {
      onDismiss();
    }
  }, [onDismiss]);

  useEffect(() => {
    if (props.selectedKey && props.pagedItems) {
      const items = props.pagedItems.items as IPicture[];
      const foundKey = items.findIndex((x) => x.key === props.selectedKey);
      if (foundKey !== -1) {
        setSelectedIndex(foundKey);
        setSrc(getFileUrl(items[foundKey].imageContentId));
      }
    }
  }, [props.pagedItems, props.selectedKey]);

  useEffect(() => {
    if (selectedIndex >= 0) {
      setSrc(getFileUrl(itemByIndex.imageContentId));
    }
  }, [itemByIndex.imageContentId, selectedIndex]);

  const onDownloadPicture = async () => {
    if (src === undefined || src === null) return '';
    const url = getFileUrl(itemByIndex.imageContentId);
    FileHelper.downloadFileByUrl(url, itemByIndex.description);
    trackEvent(TrackingEvent.Equipment.EquipmentDetail.Pictures.Preview.Download);
  };

  const onPreviousPicture = debounce(() => {
    if (selectedIndex > 0) {
      setSelectedIndex((prev) => prev - 1);
    }
  }, 100);

  const onNextPicture = debounce(async () => {
    let totalItems = pagedItems.totalItems;
    if (!isLoadingRef.current && pagedItems && !pagedItems.isLastPage && selectedIndex >= pagedItems.items.length - LOAD_MORE_THRESHOLD) {
      isLoadingRef.current = true;
      const payload = {
        columnName: 'createdDate',
        isDescending: true,
        pageNumber: pagedItems.pageNumber + 1,
        pageSize: pagedItems.pageSize,
        equipmentIds: [props.equipmentId],
      };
      try {
        const result = await equipmentService.getPagedEquipmentPictures(payload);
        if (result) {
          totalItems = result.totalItems;
          setPagedItems((prev) => ({
            ...result,
            items: [...(prev?.items ?? []), ...result.items],
          }));
        }
      } finally {
        isLoadingRef.current = false;
      }
    }

    if (selectedIndex < totalItems - 1) {
      setSelectedIndex((prev) => prev + 1);
    }
  }, 100);

  const onRenderHeader = () => (
    <div className="tool-bar">
      <div className="tool-bar-left">
        <CommandButton iconProps={{ iconName: 'Download' }} onClick={onDownloadPicture}>
          {translate('AppInspect.Download')}
        </CommandButton>
        <CommandButton iconProps={{ iconName: 'Edit' }} onClick={onEditPicture}>
          {translate('CommonResource.Edit')}
        </CommandButton>
        <CommandButton iconProps={{ iconName: 'Delete' }} onClick={onDeletePicture}>
          {translate('CommonResource.Delete')}
        </CommandButton>
      </div>
      <div className="tool-bar-right">
        <Separator className="separator" vertical={true}></Separator>
        <div className="navigation-controls">
          <CommandButton
            iconProps={{ iconName: 'Previous' }}
            onClick={() => {
              onPreviousPicture();
            }}
          />
          <span>
            {selectedIndex + 1}/{pagedItems?.totalItems ?? 0}
          </span>
          <CommandButton
            iconProps={{ iconName: 'Next' }}
            onClick={() => {
              onNextPicture();
            }}
            style={{ marginRight: 0 }}
          />
        </div>
        <Separator className="separator" vertical={true}></Separator>
      </div>
    </div>
  );

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (pagedItems && pagedItems.items.length > 0) {
        if (event.key === 'ArrowLeft') onPreviousPicture();
        if (event.key === 'ArrowRight') onNextPicture();
      }
    },
    [onNextPicture, onPreviousPicture, pagedItems]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);

  const image = [
    {
      src: src,
      description: pagedItems.items[selectedIndex]?.description,
    },
  ];

  const onDeletePicture = () => {
    showUpgradeOWMessage();
    trackEvent(TrackingEvent.Equipment.EquipmentDetail.Pictures.Preview.Delete);
  };

  const onEditPicture = () => {
    showUpgradeOWMessage();
    trackEvent(TrackingEvent.Equipment.EquipmentDetail.Pictures.Preview.Edit);
  };

  return (
    <>
      <PanelWithScrollableBody
        className="preview-picture-panel"
        isOpen={props.isOpen}
        onDismiss={dismissPanel}
        hideSeparator={true}
        panelProps={{
          type: PanelType.smallFluid,
          isBlocking: true,
        }}
        renderBody={() => (
          <div className="preview-picture-container flex flex-1">
            <div className="picture-preview flex-[1]">
              <ImageViewer images={image} />
            </div>
          </div>
        )}
        renderHeader={onRenderHeader}
      />
    </>
  );
};
