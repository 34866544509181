export enum FilterControl {
  TEXTFIELD = 1,
  DROPDOWN_SINGLE_NOSEARCH,
  DROPDOWN_SINGLE_WITHSEARCH,
  DROPDOWN_MULTI_NOSEARCH,
  DROPDOWN_MULTI_WITHSEARCH,
  OWNER_SHIP,
  SUPPLIER,
  COMMONDATE,
  DUEDATE,
  JOB_FILE_INCLUDED,
  TEXTFIELD_MULTI_WITH_CLEAR_OPTION,
  OWNER_SHIP_MULTI_WITHSEARCH,
  SOURCE_OF_CHANGE
}