import { Callout, DirectionalHint, IPersonaSharedProps, Persona } from '@fluentui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTracking } from '../../common/hooks/useTracking';
import { Icon } from '../../common/ui/Icon/Icon';
import { DefaultPersona } from '../../constants/common.constants';
import { Modules } from '../../constants/modules.constants';
import { SettingKeyConstants } from '../../constants/setting-keys.constants';
import { TrackingEvent } from '../../constants/tracking-event.constant';
import { CompanyExpressPreviewContext } from '../../context/CompanyExpressPreviewContext';
import { CompanyExpressContext } from '../../pages/Layout/MainLayout';
import { hasToken } from '../../services/authentication.service';
import { cn } from '../../utils/css.utils';
import { getCompanyLogo, getImageUrlBase64 } from '../../utils/img.utils';
import { getLoggedAccountId, getLoggedAlias } from '../../utils/localStorage.utils';
import { getSessionStorageItem, setSessionStorageItem } from '../../utils/sessionStorage.utils';
import SwitchOrg from '../SwitchOrg/SwitchOrg';
import UserCard from '../UserCard/UserCard';

type Props = {
  preview?: boolean;
};

const Header = ({ preview = false }: Props) => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking({ module: Modules.Header });
  const { info } = useContext(CompanyExpressContext);
  const { companyExpressSetup, contact, numberExpress } = info;
  const previewInfo = useContext(CompanyExpressPreviewContext);
  const personaData: IPersonaSharedProps = {
    imageUrl: getImageUrlBase64(contact?.pictureBase64String),
    ...DefaultPersona,
  };
  const [openUserCard, setOpenUserCard] = useState(false);
  const [openSwitchOrg, setOpenSwitchOrg] = useState(false);
  const avatarRef = useRef(null);
  const toggleUserCard = (): void => setOpenUserCard((prev) => !prev);
  const toggleSwitchOrg = () => {
    if (numberExpress && numberExpress > 1) {
      setOpenSwitchOrg((prev) => !prev);
      trackEvent(TrackingEvent.Header.ChangeOrgAction);
      return;
    }
    setOpenSwitchOrg(false);
  };

  useEffect(() => {
    const checkLoginAndNavigate = () => {
      if (!preview && hasToken()) {
        const storedAlias = getSessionStorageItem(SettingKeyConstants.InputAlias);
        const accountId = getSessionStorageItem(SettingKeyConstants.AccountId);
        const loggedAlias = getLoggedAlias();
        const loggedAccountId = getLoggedAccountId() ? getLoggedAccountId()?.toString() : undefined;

        // Case : open new tab.
        if (!storedAlias) setSessionStorageItem(SettingKeyConstants.InputAlias, loggedAlias);
        if (!accountId) setSessionStorageItem(SettingKeyConstants.AccountId, loggedAccountId);

        if (
          loggedAlias &&
          loggedAccountId &&
          (loggedAlias !== getSessionStorageItem(SettingKeyConstants.InputAlias) ||
            loggedAccountId !== getSessionStorageItem(SettingKeyConstants.AccountId))
        ) {
          setSessionStorageItem(SettingKeyConstants.InputAlias, loggedAlias);
          setSessionStorageItem(SettingKeyConstants.AccountId, loggedAccountId);
          navigate(`/${getLoggedAlias()}`);
          window.location.reload();
        }
      }
    };

    const intervalId = setInterval(checkLoginAndNavigate, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const logoModel = {
    imageInfoId: preview ? previewInfo.logoImageInfoId : companyExpressSetup?.logoImageInfoId,
    imageContent: preview ? previewInfo.logoImageContent : companyExpressSetup?.logoImageContent,
    companyLogo: preview ? previewInfo.companyLogo : companyExpressSetup?.companyLogo,
  };

  const getCompanyName = () => {
    if (preview) return previewInfo.companyName;
    return info?.expressName ? `${companyExpressSetup?.companyName} - ${info?.expressName}` : companyExpressSetup?.companyName;
  };

  return (
    <header>
      <div className="px-8 py-1 bg-primarybg border-b">
        <nav className="flex items-center justify-between text-primarytext">
          <div
            className={cn('flex items-center gap-3 h-[39px]', {
              'hover:cursor-pointer': !preview,
            })}
          >
            <img
              src={getCompanyLogo(logoModel)}
              alt=""
              className="w-[100px] md:w-[157px] h-full object-contain"
              onClick={!preview ? () => navigate(`/${getLoggedAlias()}`) : undefined}
            />
            <div className="w-[2px] h-full bg-primarytext"></div>
            <div className="max-w-[230px] md:max-w-[400px] flex items-center gap-3" onClick={toggleSwitchOrg}>
              <span className="font-medium uppercase truncate  md:text-lg">{getCompanyName()}</span>
              {numberExpress > 1 && <Icon iconName="ChevronDownMed" className="text-[10px] md:text-sm"></Icon>}
            </div>
          </div>
          {!preview && (
            <div className="flex items-center hover:cursor-pointer">
              <Persona {...personaData} onClick={toggleUserCard} ref={avatarRef}></Persona>
            </div>
          )}
        </nav>
        {!preview && (
          <div>
            {openUserCard && (
              <Callout
                target={avatarRef}
                doNotLayer
                isBeakVisible={false}
                directionalHint={DirectionalHint.bottomRightEdge}
                gapSpace={10}
                onDismiss={toggleUserCard}
              >
                <UserCard isOpen={openUserCard} />
              </Callout>
            )}
            {openSwitchOrg && <SwitchOrg isOpen={openSwitchOrg} onDismiss={toggleSwitchOrg} />}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
