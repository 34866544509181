import { CommandBarButton, IIconProps } from '@fluentui/react';
import { FileHelper } from '@onix/common/src/utils/file';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useShowUpgradeOWMessage } from '../../../common/hooks/useShowUpgradeOWMessage';
import { useTracking } from '../../../common/hooks/useTracking';
import { ButtonToggle } from '../../../common/ui/Button/ButtonToggle/ButtonToggle';
import CommandBar from '../../../common/ui/CommandBar/CommandBar';
import { Icon } from '../../../common/ui/Icon/Icon';
import { Modules } from '../../../constants/modules.constants';
import { TrackingEvent } from '../../../constants/tracking-event.constant';
import { EquipmentColumnsExcel } from '../../../enums/equipment-columns.enum';
import { CaptionForm } from '../../../enums/language.enum';
import { TaskQueueStatusValues } from '../../../enums/task-queue-status.enum';
import { EquipmentHelper } from '../../../helpers/equipment-helper';
import { ExportXlsxDataRequest, ExportXlsxHeaderData } from '../../../models/export-xlsx-data-request.model';
import { getEquipmentFieldColumnByKey } from '../../../models/grid/equipment-field-columns';
import { showCommonDialog } from '../../../services/dialog.service';
import { equipmentService } from '../../../services/equipment.service';
import { equipmentFilterActions, equipmentFilterSelector } from '../../../states/equipment/equipmentFilterSlice';
import { equipmentActions, equipmentSelector, numOfSelectedEquipmentsSelector } from '../../../states/equipment/equipmentSlice';
import { getLanguageId } from '../../../utils/localStorage.utils';
import { OnixCallout } from '../../Callout/Callout';
import { SpinLoader } from '../../SpinLoader/SpinLoader';
import DownloadMenu from '../DownloadMenu/DownloadMenu';

const NUM_OF_LIMIT_EXPORTING_EQUIPMENT = 10000;

interface Props {
  clearSelectedEquipment?: () => void;
}

const EquipmentToolbar = ({ clearSelectedEquipment }: Props) => {
  const [translate] = useTranslation();
  const { isShowPanel, hasFilters, filtersList } = useSelector(equipmentFilterSelector);
  const { totalFilters, totalItems } = useSelector(equipmentSelector);
  const dispatch = useDispatch();

  const filtersLength = hasFilters ? totalFilters : 0;
  const numOfSelectedEquipments = useSelector(numOfSelectedEquipmentsSelector);
  const { isShowPanelDetail } = useSelector(equipmentSelector);

  const [isExporting, setIsExporting] = useState(false);
  const [isDisplayExportedCallout, setDisplayExportedCallout] = useState(false);
  const numOfSelectedRow: number = useSelector(numOfSelectedEquipmentsSelector);
  const [isExportedSuccessfully, setIsExportedSuccess] = useState(false);

  const { refinedBy, searchFilterDefault } = useSelector(equipmentSelector);

  const getIconFilterProps = () => {
    const hasFilters = filtersLength > 0;
    const iconFilterProps: IIconProps = {
      iconName: hasFilters ? 'FilterSolid' : 'Filter',
      //style: { color: color.$primaryColor },
    };

    return iconFilterProps;
  };

  const handleFilterClick = () => {
    if (isShowPanel) {
      dispatch(equipmentFilterActions.hidePanel());
      trackEvent(TrackingEvent.Equipment.Toolbar.Filter + 'Hide');
    } else {
      dispatch(equipmentFilterActions.showPanel());
      trackEvent(TrackingEvent.Equipment.Toolbar.Filter + 'Show');
    }
  };

  const clearSelectedEquipments = useCallback(() => {
    clearSelectedEquipment && clearSelectedEquipment();
  }, [clearSelectedEquipment]);

  const showNoRecordsToExportDialog = () => {
    showCommonDialog(
      translate(`${CaptionForm.FormEquipment}.CannotDoAction`),
      translate(`${CaptionForm.FormEquipment}.FilterEquipmentBeforeExport`)
    );
  };

  const showLimitNumberExportingItemDialog = (limitNumber: number) => {
    showCommonDialog(
      translate(`${CaptionForm.FormEquipment}.CannotDoAction`),
      translate(`${CaptionForm.CommonCaption}.LimitMaximumNumberItems`).replace('{numbers}', limitNumber.toString())
    );
  };

  const getEquipmentDisplayFieldHeadersInTableMode = () => {
    const displayFieldHeaders: ExportXlsxHeaderData[] = [];
    const displayFields: ExportXlsxHeaderData[] = getDisplayEquipmentTableColumns();
    displayFields.forEach((displayField) => {
      let field: number = 0;
      let fieldType: number = 0;
      let fieldCaption: string = '';
      let fieldName: string = '';

      if (displayField && displayField.fieldName) {
        let fieldNameReplaced: string = displayField.fieldName;
        if (displayField.fieldName === 'StatusValue') {
          fieldNameReplaced = 'IssueStatus';
        } else if (displayField.fieldName === 'ArticleNo') {
          fieldNameReplaced = 'ProductNo';
        } else if (displayField.fieldName === 'TypeInfo') {
          fieldNameReplaced = 'Model';
        } else if (displayField.fieldName === 'ControlCategoryDescription') {
          fieldNameReplaced = 'ControlCategory';
        } else if (displayField.fieldName === 'LocationDescription') {
          fieldNameReplaced = 'Location';
        } else if (displayField.fieldName === 'Certificate') {
          fieldNameReplaced = 'CertificateNo';
        } else if (displayField.fieldName === 'NextControlDue') {
          fieldNameReplaced = 'DueMandatory';
        }
        const fieldItem = displayField !== undefined ? getEquipmentFieldColumnByKey(fieldNameReplaced) : undefined;
        field = fieldItem !== undefined ? fieldItem.field : 0;
        fieldType = 0;
        fieldCaption = fieldItem!.captionKey;
        fieldName = fieldItem!.fieldName;
      }

      const fieldHeader: ExportXlsxHeaderData = {
        fieldCaption: translate(fieldCaption),
        fieldValue: field,
        fieldType: fieldType,
        fieldName: fieldName,
      };

      displayFieldHeaders.push(fieldHeader);
    });

    return displayFieldHeaders;
  };

  const getDisplayEquipmentTableColumns = () => {
    const columns: ExportXlsxHeaderData[] = [];
    columns.push({
      fieldValue: EquipmentColumnsExcel.ID,
      fieldName: 'EquipmentIdentifier',
      fieldCaption: 'ID',
      fieldType: 0,
    });
    columns.push({
      fieldValue: EquipmentColumnsExcel.IssueDueStatus,
      fieldName: 'IssueDueStatus',
      fieldCaption: 'Issue due status',
      fieldType: 0,
    });
    columns.push({
      fieldValue: EquipmentColumnsExcel.IssueStatus,
      fieldName: 'StatusValue',
      fieldCaption: 'Issue status',
      fieldType: 0,
    });
    columns.push({
      fieldValue: EquipmentColumnsExcel.MandatoryDueStatus,
      fieldName: 'MandatoryDueStatus',
      fieldCaption: 'Mandatory due status',
      fieldType: 0,
    });
    columns.push({
      fieldValue: EquipmentColumnsExcel.PreventiveDueStatus,
      fieldName: 'PreventiveDueStatus',
      fieldCaption: 'Preventive due status',
      fieldType: 0,
    });
    columns.push({
      fieldValue: EquipmentColumnsExcel.OperatorDueStatus,
      fieldName: 'OperatorDueStatus',
      fieldCaption: 'Operator due status',
      fieldType: 0,
    });
    columns.push({
      fieldValue: EquipmentColumnsExcel.ProductNo,
      fieldName: 'ArticleNo',
      fieldCaption: 'Product No.',
      fieldType: 0,
    });
    columns.push({
      fieldValue: EquipmentColumnsExcel.Type,
      fieldName: 'Type',
      fieldCaption: 'Type',
      fieldType: 0,
    });
    columns.push({
      fieldValue: EquipmentColumnsExcel.Model,
      fieldName: 'TypeInfo',
      fieldCaption: 'Model',
      fieldType: 0,
    });
    columns.push({
      fieldValue: EquipmentColumnsExcel.ControlCategory,
      fieldName: 'ControlCategoryDescription',
      fieldCaption: 'Control category',
      fieldType: 0,
    });
    columns.push({
      fieldValue: EquipmentColumnsExcel.WLLSWL,
      fieldName: 'WLLSWL',
      fieldCaption: 'WLL/SWL (tonnes)',
      fieldType: 0,
    });
    columns.push({
      fieldValue: EquipmentColumnsExcel.CertificateNo,
      fieldName: 'Certificate',
      fieldCaption: 'Certificate No.',
      fieldType: 0,
    });
    columns.push({
      fieldValue: EquipmentColumnsExcel.Location,
      fieldName: 'LocationDescription',
      fieldCaption: 'Location',
      fieldType: 0,
    });
    columns.push({
      fieldValue: EquipmentColumnsExcel.DueMandatory,
      fieldName: 'NextControlDue',
      fieldCaption: 'Due mandatory',
      fieldType: 0,
    });
    columns.push({
      fieldValue: EquipmentColumnsExcel.LatestMandatoryJobDate,
      fieldName: 'LatestMandatoryJobDate',
      fieldCaption: 'Latest mandatory job date',
      fieldType: 0,
    });
    columns.push({
      fieldValue: EquipmentColumnsExcel.LatestMandatoryJobStatus,
      fieldName: 'LatestMandatoryJobStatus',
      fieldCaption: 'Latest mandatory job status',
      fieldType: 0,
    });
    return columns;
  };

  const onDownLoadEquipmentCallback = async (equipmentIds: number[]) => {
    try {
      if (!isCanPerformExportingEquipment(NUM_OF_LIMIT_EXPORTING_EQUIPMENT)) {
        return;
      }
      setIsExporting(true);

      const exportDataRequest: ExportXlsxDataRequest = {
        equipmentSearchCriteria: {
          filters: filtersList,
          refinedBy: refinedBy,
          displayFields: [
            'EquipmentIdentifier',
            'IssueDueStatus',
            'IssueStatus',
            'MandatoryDueStatus',
            'PreventiveDueStatus',
            'OperatorDueStatus',
            'Type',
            'TypeInfo',
            'ControlCategoryDescription',
            'WLLSWL',
            'NextControlDue',
            'CompanyOwnerDescription',
            'LocationDescription',
          ],
          columnName: searchFilterDefault?.sortedColumn.columnName,
          isDescending: searchFilterDefault?.sortedColumn.isDescending,
          equipmentIds: equipmentIds ?? [],
        },
        headers: getEquipmentDisplayFieldHeadersInTableMode(),
        companyId: 0,
        contactId: 0,
        languageId: getLanguageId(),
        equipmentIds: equipmentIds ?? [],
      };

      const taskQueueId = await equipmentService.createExportRequestAsync(exportDataRequest);
      let status = 0;
      while (status != TaskQueueStatusValues.Finished && status !== TaskQueueStatusValues.Error) {
        //Prevent calling to much
        await new Promise((resolve) => setTimeout(resolve, 1000));
        status = await equipmentService.checkStatusExportingAsync(taskQueueId);
      }
      if (status === TaskQueueStatusValues.Error) {
        downloadErrorHandle();
      } else {
        const urlDownload = EquipmentHelper.getTransferUrlExportDocument(taskQueueId);
        FileHelper.downloadFileByUrlIncludeFileName(urlDownload);
        setIsExportedSuccess(true);
        setDisplayExportedCallout(true);
      }
    } catch (err: any) {
      downloadErrorHandle();
      console.log(err.message);
    } finally {
      setIsExporting(false);
    }
  };

  const onExportedCalloutDismiss = () => {
    setDisplayExportedCallout(false);
  };

  const downloadErrorHandle = () => {
    setIsExportedSuccess(false);
    setDisplayExportedCallout(true);
  };

  const isCanPerformExportingEquipment = (limitEquipmentNum: number | undefined) => {
    const totalSearchedItems = totalItems;
    if (totalSearchedItems <= 0) {
      showNoRecordsToExportDialog();
      return false;
    } else if (limitEquipmentNum && numOfSelectedRow > 0 && numOfSelectedRow > limitEquipmentNum) {
      showLimitNumberExportingItemDialog(limitEquipmentNum);
      return false;
    } else if (!numOfSelectedRow && limitEquipmentNum && totalSearchedItems > limitEquipmentNum) {
      showLimitNumberExportingItemDialog(limitEquipmentNum);
      return false;
    }

    return true;
  };

  const { showUpgradeOWMessage } = useShowUpgradeOWMessage();
  const { trackEvent } = useTracking({ module: Modules.Equipment });

  return (
    <div className="tool-bar px-3 py-2 border-b-[1px] min-h-[49px]">
      <div className="tool-bar-left">
        <ButtonToggle
          checked={isShowPanel}
          iconProps={getIconFilterProps()}
          onClick={handleFilterClick}
          text={filtersLength > 0 ? `${translate('CommonResource.Filter')} (${filtersLength})` : translate('CommonResource.Filter')}
          data={{ fontWeightNormal: filtersLength === 0 }}
        />
        {numOfSelectedEquipments === 1 && !isShowPanelDetail && (
          <CommandBar
            items={[
              {
                key: 'Edit',
                text: translate('CommonResource.Edit'),
                iconProps: { iconName: 'Edit' },
                onClick: () => {
                  showUpgradeOWMessage();
                  trackEvent(TrackingEvent.Equipment.Toolbar.SingleEdit);
                },
              },
              {
                key: 'Document',
                text: translate('CaptionResource.Caption110047'),
                iconProps: { iconName: 'Documentation' },
                onClick: () => {
                  dispatch(equipmentActions.setShowDocumentManagementPanel(true));
                  trackEvent(TrackingEvent.Equipment.Toolbar.SingleDocuments);
                },
              },
            ]}
          />
        )}

        {numOfSelectedEquipments > 1 && !isShowPanelDetail && (
          <CommandBar
            items={[
              {
                key: 'EditAll',
                text: translate('CaptionResource.EditAll'),
                iconProps: { iconName: 'Edit' },
                onClick: () => {
                  showUpgradeOWMessage();
                  trackEvent(TrackingEvent.Equipment.Toolbar.MultipleEditAll);
                },
              },
              {
                key: 'Document',
                text: translate('CaptionResource.Caption110047'),
                iconProps: { iconName: 'Documentation' },
                onClick: () => {
                  showUpgradeOWMessage();
                  trackEvent(TrackingEvent.Equipment.Toolbar.MultipleDocuments);
                },
              },
            ]}
          />
        )}
      </div>
      {!isShowPanelDetail && (
        <div className="tool-bar-right">
          {isExporting && (
            <CommandBarButton>
              <SpinLoader iconName="Sync" loadingText={translate('CommonResource.Exporting')}></SpinLoader>
            </CommandBarButton>
          )}
          {isDisplayExportedCallout && (
            <CommandBarButton>
              <Icon iconName="CheckMark" style={{ fontSize: '20px', color: 'var(--black-color)' }}></Icon>
            </CommandBarButton>
          )}

          <OnixCallout
            calloutMessage={translate(`${CaptionForm.CommonCaption}.GenerateFileSuccessfully`)}
            calloutTitle={translate(`${CaptionForm.CaptionResource}.Caption56`)}
            isCalloutVisible={isExportedSuccessfully && isDisplayExportedCallout}
            calloutStyle={{ top: 101, right: 30 }}
            onExportedCalloutDismiss={onExportedCalloutDismiss}
          />

          <OnixCallout
            calloutMessage={translate(`${CaptionForm.CommonCaption}.UnExpectedError`)}
            calloutTitle={translate(`${CaptionForm.CaptionResource}.CAPTION_ERROR`)}
            isCalloutVisible={!isExportedSuccessfully && isDisplayExportedCallout}
            calloutStyle={{ top: 101, right: 30 }}
            onExportedCalloutDismiss={onExportedCalloutDismiss}
          />
          {numOfSelectedEquipments > 0 && (
            <>
              <CommandBarButton
                iconProps={{ iconName: 'Clear' }}
                text={translate('CommonResource.ItemsSelected', { 0: numOfSelectedEquipments })}
                onClick={clearSelectedEquipments}
              />
            </>
          )}
          <DownloadMenu
            onDownloadCallback={onDownLoadEquipmentCallback}
            isDisableExportMenuItem={isExporting}
            canExportEquipment={isCanPerformExportingEquipment}
          />
        </div>
      )}
    </div>
  );
};

export default EquipmentToolbar;
