import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTracking } from '../../common/hooks/useTracking';
import Shimmer from '../../common/ui/Shimmer/Shimmer';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { Modules } from '../../constants/modules.constants';
import { TrackingEvent } from '../../constants/tracking-event.constant';
import { CompanyExpressContextInfo } from '../../models/company-express-context.model';
import { CompanyExpressSetup } from '../../models/company-express-setup.model';
import { Contact } from '../../models/user-account-information.model';
import { getToken } from '../../services/authentication.service';
import { CommonService } from '../../services/common.service';
import { setPageTitle } from '../../utils/browser.utils';
import { setupAppColor } from '../../utils/css.utils';
import { getAuthenticatedProfile } from '../../utils/localStorage.utils';
import { CompanyExpressContext } from '../Layout/MainLayout';

const NoAccess = () => {
  const [translate] = useTranslation();
  const [message, setMessage] = useState('');
  const [showRequestButton, setShowRequestButton] = useState(false);
  const [hasRequest, setHasRequest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expressInfo, setExpressInfo] = useState<CompanyExpressContextInfo>({
    companyExpressSetup: {} as CompanyExpressSetup,
    expressId: 0,
    expressName: '',
    contact: {} as Contact,
  } as CompanyExpressContextInfo);

  const authenProfile = getAuthenticatedProfile();
  const { companyExpressSetup } = authenProfile;
  const { trackEvent } = useTracking({ module: Modules.RequestAUserPage });

  useEffect(() => {
    const tokenData = jwtDecode(getToken()) as { companyId?: number; companyexpert?: number };
    const companyId = tokenData.companyId || 0;
    const companyexpert = tokenData.companyexpert || 0;
    const userSession = {
      UserPrivileges: [],
      Company: companyId,
      CompanyOwner: companyexpert,
      CompanyOwnerName: '',
    };

    if (authenProfile) {
      setExpressInfo({ ...authenProfile });
      setupAppColor({
        primary: authenProfile?.companyExpressSetup?.primaryColor,
        primaryText: authenProfile?.companyExpressSetup?.primaryTextColor,
      });
      setPageTitle(authenProfile?.companyExpressSetup?.companyName);
    }

    if (userSession.Company <= 0) {
      if (userSession.CompanyOwner > 0) {
        const messageWithHTML = translate('CommonResource.NotHaveAccessToOnixExpress');
        const updatedMessage = messageWithHTML.replace('{0}', companyExpressSetup?.companyName);
        setMessage(updatedMessage);
        setShowRequestButton(true);
      }
    }
    checkHandRequestUser();
  }, []);

  const checkHandRequestUser = async () => {
    setLoading(true);
    var result = await CommonService.checkIfAlreadyHasRequest();
    setShowRequestButton(!result);
    setHasRequest(result);
    setLoading(false);
  };
  const requestAccount = async () => {
    setLoading(true);
    var result = await CommonService.requestAUser();
    if (result) {
      setHasRequest(true);
      trackEvent(TrackingEvent.RequestAUserPage.RequestUserAction, {
        OExpressSupplierRequest: expressInfo.companyExpressSetup.companyName,
      });
    }
    setLoading(false);
  };

  return (
    <CompanyExpressContext.Provider value={{ info: expressInfo, setInfo: setExpressInfo }}>
      <Header />
      <div className="w-screen h-[calc(100vh-70px)] flex">
        <div className="w-screen flex flex-col items-center text-center justify-center gap-3">
          {loading ? (
            <div className="w-2/4">
              <Shimmer width={'50%'} lines={7} />
            </div>
          ) : (
            <>
              {showRequestButton && (
                <>
                  {message && <p className="text-[20px]" dangerouslySetInnerHTML={{ __html: message }} />}

                  {!hasRequest && (
                    <>
                      <p className="text-[20px]">{translate('EmailTemplate.SelfInviteEmailLine2')}</p>
                      <div className="underline cursor-pointer text-[20px] text-purple-800" onClick={requestAccount}>
                        {translate('EmailTemplate.SelfInviteLinkCaption')}
                      </div>
                    </>
                  )}

                  {hasRequest && (
                    <>
                      <p className="text-[20px]">
                        {translate('CommonResource.RequestUserSent').replace('{0}', companyExpressSetup?.companyName)}
                      </p>
                    </>
                  )}
                </>
              )}

              {!showRequestButton && hasRequest && (
                <>
                  {message && <p className="text-[20px]" dangerouslySetInnerHTML={{ __html: message }} />}
                  <p className="text-[20px]">
                    {translate('CommonResource.RequestUserSent').replace('{0}', companyExpressSetup?.companyName)}
                  </p>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </CompanyExpressContext.Provider>
  );
};

export default NoAccess;
