export const defaultTheme = {
  palette: {
    themePrimary: '#201f1e',
    themeLighterAlt: '#faf4fb',
    themeLighter: '#edd6ed',
    themeLight: '#ddb5dd',
    themeTertiary: '#bb78bc',
    themeSecondary: '#9b4a9c',
    themeDarkAlt: '#7f3381',
    themeDark: '#6b2b6d',
    themeDarker: '#4f2050',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralSecondaryAlt: '#8a8886',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
};
