import { Spinner, SpinnerSize } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

type Props = {
  label?: string;
};

const Loading = ({ label }: Props) => {
  const [translate] = useTranslation();
  return (
    <div className={`absolute inset-0 z-[1000009] w-screen h-screen bg-darkoverlay opacity-50 flex justify-center items-center`}>
      <Spinner
        size={SpinnerSize.large}
        styles={{
          circle: { width: 50, height: 50, borderWidth: 4, borderColor: '#52525b #f4f4f5 #fafafa' },
          label: { color: 'white' },
        }}
        label={label || `${translate('CommonResource.Loading')}...`}
      ></Spinner>
    </div>
  );
};

export default Loading;
