import { Spinner, SpinnerSize } from '@fluentui/react'
import { ECElementEvent } from 'echarts'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEcharts } from '../../common/hooks/useEcharts'
import { useElementResizeWatcher } from '../../common/hooks/useElementResizeWatcher'
import { WidgetConstants } from '../../constants/dashboard.constants'
import { WidgetHelper } from '../../helpers/widget-helper'
import { ECOption } from '../../models/dashboard/echart-data.model'
import { WidgetCss, WidgetInfo } from '../../models/dashboard/widget.model'
import { cn } from '../../utils/css.utils'

export type WidgetChartRendererProps = {
  widget?: WidgetInfo;
  chartOptions: ECOption;
  hasData: boolean;
  widgetCss: WidgetCss;
  isLoading: boolean;
  onSeriesClick?: (event: ECElementEvent) => void;
};

export type WidgetChartRendererRef = {
  get instance(): echarts.ECharts | null;
  get chartElement(): HTMLElement | null;
};

export const WidgetChartRenderer = forwardRef<WidgetChartRendererRef, WidgetChartRendererProps>((props, ref) => {
  const { chartOptions, hasData, widgetCss, isLoading, widget } = props;

  const [t] = useTranslation();

  const [elementSize, setElementSize] = useState<ResizeObserverSize>();

  const legendWidth =
    WidgetHelper.isPieChart(chartOptions) && elementSize
      ? (WidgetConstants.donut.compact.legendWidthInPercentage / 100) * elementSize.inlineSize
      : undefined;

  const { chartElementRef, chartElement, chartInstance } = useEcharts({
    onSeriesClick: props.onSeriesClick,
    chartOptions,
    maximumLegendWidth: legendWidth,
  });

  useElementResizeWatcher({
    element: chartElement,
    onResize: (size) => {
      setElementSize(size);

      setTimeout(() => {
        chartInstance?.resize();
      });
    },
  });

  useImperativeHandle(ref, () => ({
    get instance() {
      return chartInstance;
    },
    get chartElement() {
      return chartElement;
    },
  }));

  const widgetTitle = widget?.title ? t(`CommonResource.${widget.title}`) : '';

  return (
    <div
      className={cn(
        'inline-flex shadow rounded-md bg-white flex-col',
        (isLoading || !hasData) && 'overflow-hidden justify-center items-center'
      )}
      style={{
        ...widgetCss,
      }}
    >
      {isLoading && <WidgetLoadingSpinner />}
      {!isLoading && (
        <>
          <div className="self-start font-medium px-2 pt-1">{widgetTitle}</div>
          {hasData && widget?.isPlotTimeData && (
            <div className="left-1/2 relative text-xs flex gap-1 items-center w-max">
              <div className="rounded-[4px] w-[25px] h-[14px]" style={{ backgroundColor: widget.data[0]?.itemColor }}></div>
              <div className="">{widget.plotTimeLabel}</div>
            </div>
          )}
          {hasData && (
            <div
              // key={`size-${elementSize.blockSize}-${elementSize.inlineSize}`}
              // data-size={`${elementSize.blockSize}-${elementSize.inlineSize}`}
              ref={chartElementRef}
              className="size-full self-start p-2 flex-grow"
            />
          )}

          {!hasData && <div className="self-center m-auto">{t('Home.NoDataAvailable')}</div>}
        </>
      )}
    </div>
  );
});

const WidgetLoadingSpinner = () => (
  <Spinner
    size={SpinnerSize.large}
    styles={{
      circle: { width: 30, height: 30, borderWidth: 2, borderColor: 'var(--primary-color) #faf9f8 #faf9f8' },
    }}
  />
);
