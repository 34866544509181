export enum LanguageEnum {
    Norwegian = 0,
    English = 1,
    French = 2,
    Swedish = 3,
    Danish = 4,
    Dutch = 5,
    German = 6,
    Spanish = 7,
    Finnish = 8,
    Portuguese = 9,
  }
  
  export enum CaptionForm {
    CaptionResource = 'CaptionResource',
    CommonCaption = 'CommonResource',
    FormEquipment = 'Equipment',
    FormDocument = 'Document',
    FormJob = 'Job',
    Menu = 'Menu',
    StaticValues = 'StaticValues',
    FORMSETTINGS = 'Settings',
    AddDatasheetItem = 'AddDatasheetItem',
    Article6 = 'Article6',
    Order = 'Order',
  }  