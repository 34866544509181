import { ApiConstants } from '../constants/api.constants';
import { images } from '../constants/common.constants';
import { isStringNotNullOrUndefined } from './common.utils';

export const getImageUrl = (imageContent: string) => ApiConstants.files.getFileAsync(imageContent.toLocaleUpperCase());

export const getImageUrlBase64 = (imageContent: string | Uint8Array) => `${images.base64},${imageContent}`;

export const getCompanyLogo = ({
  imageInfoId,
  imageContent,
  companyLogo,
}: {
  imageInfoId: number;
  imageContent: string;
  companyLogo: Uint8Array;
}) => {
  if (imageInfoId > 0) return getImageUrl(imageContent);
  if (isStringNotNullOrUndefined(companyLogo?.toString())) return getImageUrlBase64(companyLogo);
  return images.defaultLogo;
};
