import * as echarts from 'echarts';
import { BarSeriesOption, EChartsInitOpts, PieSeriesOption, XAXisComponentOption, YAXisComponentOption } from 'echarts';
import { CSSProperties } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DashboardConstants, WidgetConstants } from '../constants/dashboard.constants';
import { WidgetType } from '../enums/widget.enum';
import { DashboardLayoutItem, DashboardLayoutResponseItem } from '../models/dashboard/dashboard.model';
import { ECOption } from '../models/dashboard/echart-data.model';
import { WidgetData, WidgetDataItem, WidgetId, WidgetInfo, WidgetLegendItem } from '../models/dashboard/widget.model';
import { parseDateOffsetNorwayUTC } from '../utils/dateTime';
import CommonHelper from './common-helper';

export class DashboardHelper {
  public static getLegendDefaultOptions = {
    legendSelector: (it) => it.item,
    labelSelector: (it) => it.itemLabel ?? '',
    colorSelector: (it) => it.itemColor ?? '',
  } satisfies Parameters<typeof DashboardHelper.getLegend>[1];

  public static getLegend(
    widgetDataList: WidgetDataItem[],
    options: {
      legendSelector: (it: WidgetDataItem) => number | string | undefined | null;
      labelSelector: (it: WidgetDataItem) => string;
      colorSelector: (it: WidgetDataItem) => string | undefined;
      sortSelector?: (it: WidgetDataItem) => any;
    } = this.getLegendDefaultOptions
  ) {
    const widgetDataListDistinctByLegend = CommonHelper.distinctBy(widgetDataList, options.legendSelector);

    options.sortSelector && CommonHelper.sort(widgetDataListDistinctByLegend, options.sortSelector);

    const result = widgetDataListDistinctByLegend.map(
      (item) =>
        ({
          legendId: options.legendSelector(item) ?? 0,
          name: options.labelSelector(item),
          color: options.colorSelector(item) ?? '',
        } satisfies WidgetLegendItem)
    );

    return result;
  }

  public static getOption<T extends { expand: T['expand']; compact: T['compact'] }>(opt: T) {
    // return opt[WidgetConstants.isExpand[CommonHelper.boolToString(isExpand)]];
    return opt.compact;
  }

  public static calculateChartHeight(
    widgetDataList: WidgetDataItem[],
    chartOptions: {
      entryUniqueIdSelector: (item: WidgetDataItem) => string | number | null;
      isExpandMode: boolean;
    }
  ) {
    const numberOfEntries = CommonHelper.distinctBy(widgetDataList, chartOptions.entryUniqueIdSelector).length;

    const generalConst = this.getOption(WidgetConstants.general);

    const chartHeight = numberOfEntries * generalConst.minimumBarWidth + Math.abs(numberOfEntries - 1) * generalConst.gap;

    return chartHeight;
  }

  public static calculateChartWidth(
    widgetDataList: WidgetDataItem[],
    chartOptions: {
      /** Entry selector to determine how many columns/bars which is displaying. */
      entryUniqueIdSelector: (item: WidgetDataItem) => string | number;
      isExpandMode: boolean;
    }
  ) {
    const numberOfEntries = CommonHelper.distinctBy(widgetDataList, chartOptions.entryUniqueIdSelector).length;

    const generalConst = this.getOption(WidgetConstants.general);

    const chartWidth = numberOfEntries * generalConst.minimumColumnWidth + Math.abs(numberOfEntries - 1) * generalConst.gap;

    return chartWidth;
  }

  public static generateStackbarChartOptions(
    widgetDataList: WidgetDataItem[],
    chartOptions: {
      /** This selector is for differentiate one bar of the chart. E.g.: a stack bar chart with many bars with company+location for each bar. */
      entryUniqueIdSelector: (item: WidgetDataItem) => string | number;

      /** Id selector for an item, will be passed onclick event: `<echarts_onclick_arg>.data.id` */
      itemUniqueIdSelector: (item: WidgetDataItem) => string | number;

      /** Label for current item. */
      // labelSelector: (item: WidgetDataItem) => string | undefined;

      /** Color for current item. */
      colorSelector: (item: WidgetDataItem) => string | undefined;

      /** Value selector from current item. */
      valueSelector: (item: WidgetDataItem) => number;

      /** Label for a whole stackbar. */
      yAxisLabelSelector: (item: WidgetDataItem) => string;

      /** Determine to group items into a stackbar. */
      stackbarSelector: (item: WidgetDataItem) => string | number | undefined | null;

      /** Render in expand mode or compact mode. */
      // isExpandMode: boolean;
    }
  ): ECOption {
    const generalConsts = this.getOption(WidgetConstants.general);
    const stackbarConsts = this.getOption(WidgetConstants.stackbar);

    const entryMap = CommonHelper.groupBy(widgetDataList, chartOptions.entryUniqueIdSelector);

    if (entryMap.has(undefined as any)) {
      console.log(
        `DashboardHelper.generateStackbarChartOptions: WARN: undefined as key appears in the entryMap, there could be something wrong!`
      );
    }

    const yAxisObjList = Array.from(entryMap.entries()).map(([idStr, list]) => ({
      id: idStr,
      label: chartOptions.yAxisLabelSelector(list[0]),
    }));
    const yAxisData = yAxisObjList.map((item) => item.label).filter(CommonHelper.isNotNullOrUndefined);

    const stackbarIdList = yAxisObjList.map(({ id }) => id);

    const getDataListForStackbar = (stackbarId: ReturnType<typeof chartOptions.stackbarSelector>) =>
      stackbarIdList
        .map((id) => entryMap.get(id)?.find((item) => chartOptions.stackbarSelector(item) === stackbarId))
        .map((widgetItem) => ({
          id: widgetItem ? chartOptions.itemUniqueIdSelector(widgetItem) : `not-available_${uuidv4()}`,
          value: widgetItem ? chartOptions.valueSelector(widgetItem) ?? 0 : 0,
        })) satisfies BarSeriesOption['data'];

    const stackbarDataMap = CommonHelper.groupBy(widgetDataList, chartOptions.stackbarSelector);
    const stackbarList = [...stackbarDataMap.entries()].map(([stackbarId, list]) => ({
      stackbarId,
      // name: chartOptions.labelSelector(list[0]),
      color: chartOptions.colorSelector(list[0]),
      data: getDataListForStackbar(stackbarId),
    }));

    const maxStackbarValue = Math.max(...Array.from(entryMap.values()).map((item) => CommonHelper.sumBy(item, chartOptions.valueSelector)));

    const series = stackbarList.map(
      (s) =>
        ({
          // name: s.name,
          id: s.stackbarId ?? undefined,
          type: 'bar',
          stack: 'total',
          label: {
            ...WidgetConstants.stackbar.seriesLabelOptions,
            formatter: WidgetConstants.stackbar.seriesLabelFormatterGenFn(
              maxStackbarValue,
              WidgetConstants.stackbar.labelDisplayThresholdPercentage
            ),
          },
          color: s.color,
          labelLayout: {
            hideOverlap: true,
          },
          emphasis: stackbarConsts.emphasis,
          data: s.data,
        } satisfies BarSeriesOption)
    ) satisfies ECOption['series'];

    return {
      tooltip: {
        show: false,
      },
      legend: {
        show: false,
      },
      grid: generalConsts.barGridOptions,
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        data: yAxisData,
        inverse: true,
        axisLabel: stackbarConsts.axisLabelOptions,
      },
      series,
    };
  }

  public static generateDonutChartOptions(
    widgetDataList: WidgetDataItem[],
    chartOptions: {
      /** Id selector for an item, will be passed onclick event: `<echarts_onclick_arg>.data.id` */
      itemUniqueIdSelector: (item: WidgetDataItem) => string | number;

      /** Label for current item. */
      labelSelector: (item: WidgetDataItem) => string | undefined;

      /** Color for current item. */
      colorSelector: (item: WidgetDataItem) => string | undefined;

      /** Value selector from current item. */
      valueSelector: (item: WidgetDataItem) => number;

      // TODO(knta): add option to calc legend width
    }
  ): ECOption {
    // const generalConsts = this.getOption(WidgetConstants.general, chartOptions.isExpandMode);
    const donutConsts = this.getOption(WidgetConstants.donut);
    const circlePosition = donutConsts.circlePosition.slice();
    const circleRadius = donutConsts.circleRadius.slice();

    const chartData = widgetDataList.map((data) => ({
      id: chartOptions.itemUniqueIdSelector(data),
      value: chartOptions.valueSelector(data),
      name: chartOptions.labelSelector(data),
      itemStyle: { color: chartOptions.colorSelector(data), textAlign: 'center' },
    })) satisfies PieSeriesOption['data'];

    const total = CommonHelper.sumBy(widgetDataList, chartOptions.valueSelector);

    return {
      tooltip: {
        show: false,
      },
      legend: donutConsts.legend,

      series: {
        type: 'pie',
        center: circlePosition,
        radius: circleRadius,
        markPoint: {
          tooltip: { show: false },
          label: {
            show: true,
            formatter: '{b}',
            color: 'black',
            fontSize: 12,
          },
          data: [
            {
              name: total.toString(),
              value: '-',
              symbol: 'circle',
              itemStyle: { color: 'transparent' },
              x: circlePosition[0],
              y: circlePosition[1],
            },
          ],
        },
        avoidLabelOverlap: true,
        label: {
          show: true,
          position: 'inside',
          silent: true,
          color: WidgetConstants.donut.labelColor,
          formatter: WidgetConstants.donut.seriesLabelFormatterGenFn(total, donutConsts.labelDisplayThresholdPercentage),
          fontSize: 12,
        },
        labelLayout: {
          moveOverlap: 'shiftY',
        },
        labelLine: {
          show: false,
        },
        emphasis: {
          scale: false,
        },
        data: chartData,
      },
    };
  }

  public static base64Img(data: string) {
    return `data:image/png;base64,${data}`;
  }

  public static generateColumnChartOptions(
    widgetDataList: WidgetDataItem[],
    chartOptions: {
      itemUniqueIdSelector: (item: WidgetDataItem) => string | number;
      valueSelector: (item: WidgetDataItem) => number;
      labelSelector: (item: WidgetDataItem) => string;
      colorSelector: (item: WidgetDataItem) => string;
      xAxisLabelSelector: (item: WidgetDataItem) => string;
      horizontal?: boolean;
    }
  ): ECOption {
    const generalConsts = this.getOption(WidgetConstants.general);

    const barColumnConsts = chartOptions.horizontal ? WidgetConstants.bar : WidgetConstants.column;
    const chartConsts = this.getOption(barColumnConsts);

    const labels = widgetDataList.map(chartOptions.xAxisLabelSelector);
    const data = widgetDataList.map((w) => ({
      id: w ? chartOptions.itemUniqueIdSelector(w) : `not-available_${uuidv4()}`,
      value: w ? chartOptions.valueSelector(w) ?? 0 : 0,
    })) satisfies BarSeriesOption['data'];
    const colors = widgetDataList.map(chartOptions.colorSelector);

    const maxColValue = Math.max(...data.map((d) => d.value));

    const labelFormatter = barColumnConsts.seriesLabelFormatterGenFn(maxColValue, barColumnConsts.labelDisplayThresholdPercentage);

    const categoryAxis = {
      type: 'category',
      data: labels,
      axisLabel: chartConsts.axisLabelOptions,
      inverse: chartOptions.horizontal,
    } satisfies YAXisComponentOption | XAXisComponentOption;

    const valueAxis = {
      type: 'value',
      minInterval: 1,
    } satisfies YAXisComponentOption | XAXisComponentOption;

    const axisOptions = {
      xAxis: chartOptions.horizontal ? valueAxis : categoryAxis,
      yAxis: chartOptions.horizontal ? categoryAxis : valueAxis,
    } satisfies ECOption;

    const gridOptions = chartOptions.horizontal ? generalConsts.barGridOptions : generalConsts.columnGridOptions;

    return {
      ...axisOptions,
      grid: gridOptions,
      tooltip: {
        show: false,
      },
      // legend: barColumnConsts.compact.legend,
      legend: { show: false },
      series: [
        {
          data,
          itemStyle: {
            color: (item) => colors[item.dataIndex % colors.length],
          },
          label: {
            ...barColumnConsts.seriesLabelOptions,
            formatter: labelFormatter,
          },
          type: 'bar',
        },
      ],
    };
  }

  public static getWidgetType(ty: string): WidgetType | undefined {
    return WidgetConstants.widgetTypeFromString[ty.toLowerCase()];
  }

  public static makeWidgetInfo(widgetId?: WidgetId, widgetData?: WidgetData): WidgetInfo {
    return {
      data: [],
      total: 0,
      headers: [],

      id: widgetId ?? 0,
      title: widgetData?.title?.trim() ?? '',
      widgetTemplateId: widgetData?.widgetTemplateId ?? null,
      levels: widgetData?.levels ?? [],
      backgroundColor: widgetData?.backgroundColor ?? null,
      lastPopulatedDate: widgetData?.lastPopulatedDate ? parseDateOffsetNorwayUTC(widgetData?.lastPopulatedDate) : undefined,
      shouldRefreshUsingOnixWork: !!widgetData?.shouldRefreshUsingOnixWork,
      isPlotTimeData: widgetData?.additionalData?.isPlotTimeData ?? false,
      plotTimeLabel: widgetData?.additionalData?.plotTimeLabel ?? '',
      rawData: widgetData?.data ?? '',
    };
  }

  public static makeUniformWidgetDataItem(item: WidgetDataItem): WidgetDataItem {
    return {
      item: item?.item ?? 0,
      itemLabel: item?.itemLabel ?? '',
      value: item?.value ?? 0,
      itemColor: item?.itemColor ?? WidgetConstants.defaultLegendColor,
      itemSort: item?.itemSort,
      sortBy: item?.sortBy,
      subItem: item?.subItem,
      subItemLabel: item?.subItemLabel,
      subItemColor: item?.subItemColor,
      category: item?.category,
      categoryLabel: item?.categoryLabel,
      company: item?.company,
      locationLabel: item?.locationLabel,
      location: item?.location,
      uniqueIdentifier: item?.uniqueIdentifier ?? uuidv4(),
    };
  }

  public static parseLayout(layout: string | null | undefined): DashboardLayoutItem[] {
    if (!layout?.trim()) {
      return [];
    }

    let layoutResult: DashboardLayoutItem[] = [];

    try {
      const layoutDataList: DashboardLayoutResponseItem[] = JSON.parse(layout);

      const dashboardLayoutItems = layoutDataList.map((item) => ({
        x: Number(item?.x ?? 0),
        y: Number(item?.y ?? 0),
        width: Number(item?.width ?? 0),
        height: Number(item?.height ?? 0),
        minHeight: Number(item?.minHeight ?? 0),
        minWidth: Number(item?.minWidth ?? 0),
        widgetId: Number(item?.id ?? 0),
      }));

      layoutResult = CommonHelper.sort(
        dashboardLayoutItems,
        (item) => item.x,
        (item) => item.y
      );
    } catch (err) {
      console.log(`Dashboard.parseLayout: parse error:`, err);
    }

    return layoutResult;
  }

  public static calculateWidgetWidth(layoutItem: DashboardLayoutItem): CSSProperties {
    const flexGrow = Math.floor(layoutItem.width / DashboardConstants.minGridWidth);
    const maxFlexBasis = 100;

    const flexBasis = (layoutItem.width / DashboardConstants.maxGridWidth) * maxFlexBasis;

    const maxWidgetPerRow = Math.floor(DashboardConstants.maxGridWidth / DashboardConstants.minGridWidth);
    const maxNumberOfGaps = maxWidgetPerRow - 1;

    return {
      flexGrow,
      minWidth: WidgetConstants.widgetMinWidthInPx, //`calc(${flexBasis}% - ${DashboardConstants.gridGapInPx}px)`,
      flexBasis: `calc(${flexBasis}% - ${DashboardConstants.gridGapInPx}px * ${maxNumberOfGaps} / 3)`,
      height: WidgetConstants.widgetFixedHeightInPx,
    };
  }

  public static initEcharts(chartElementToBeRenderedOn: HTMLElement, renderOptions: EChartsInitOpts = WidgetConstants.rendererOptions) {
    const chartInst = echarts.init(chartElementToBeRenderedOn, undefined, renderOptions);

    return chartInst;
  }

  public static disposeEcharts(chartInst: echarts.ECharts | null | undefined) {
    if (!chartInst?.isDisposed()) {
      chartInst?.dispose();
    }
  }
}
