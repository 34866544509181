import { Checkbox } from '@fluentui/react';
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ICommonListTilesProps } from './ListTiles';

export interface IListTileItemProps extends ICommonListTilesProps {
  item: any;
  index: number | undefined;
  selectedItemIds?: number[];
  onSelect?: (index: number | undefined, item: any, checked?: boolean) => void;
  disableSelection?: boolean;
}

export const ListTileItem = React.memo((props: IListTileItemProps) => {
  const { item, index, onSelect, onClick, onRenderItem, disableSelection } = props;
  const [hover, setHover] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(false);
  const isCheckedRef = useRef(false);

  useEffect(() => {
    if (item?.isSelected !== undefined) {
      isCheckedRef.current = item?.isSelected;
      setHover(item?.isSelected);
      setIsChecked(item?.isSelected);
    }
  }, [item]);

  const onChange = useCallback(
    (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
      if (!disableSelection && checked !== undefined) {
        isCheckedRef.current = checked;
        setIsChecked(checked);
        onSelect?.(index, item, checked);
      }
    },
    [index, item, onSelect, disableSelection]
  );

  const onMouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    if (!isCheckedRef.current) setHover(false);
  }, []);

  const onClickPicture = useCallback(() => {
    onClick && onClick(index, item);
  }, [index, item, onClick]);

  const customOverlayStyle = useMemo(
    (): CSSProperties => ({
      pointerEvents: 'none',
      opacity: hover ? 0.2 : 0,
      position: 'absolute',
      height: '100%',
      width: '100%',
      background: '#323130',
    }),
    [hover]
  );

  return (
    <div className="tile-item-container" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className="tile-item">
        <div style={{ position: 'absolute', width: '100%', height: '100%', zIndex: '3' }} onClick={onClickPicture}></div>
        <div className="tile-overlay" style={customOverlayStyle}></div>
        <div className="tile-gradient-overlay"></div>
        <div className="tile-icon-bar" style={{ display: hover ? 'block' : 'none' }}>
          {!disableSelection && <Checkbox checked={isChecked} onChange={onChange} />}
        </div>

        <div onClick={onClickPicture}>{onRenderItem && onRenderItem(index, item)}</div>
      </div>
    </div>
  );
});
