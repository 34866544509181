/* eslint-disable react-hooks/rules-of-hooks */
import { DirectionalHint, IColumn, Link, Sticky, TooltipHost } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import i18n from 'i18next';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { color } from '../../../assets/styles/styles';

export interface IColumnData {
  captionKey?: string;
  groupSortField?: number;
  isHasToolTip?: boolean;
  renderCustomToolTip?: (item: any) => JSX.Element;
}
export interface IColumnCellRegistEvent {
  key: string;
  func: any;
}

const calloutProps = { gapSpace: 0 };
export const standarColumnWidth = 150;
export const smallColumnWidth = 100;
export const extraSmallColumnWidth = 70;
export const largeColumnWidth = 230;
export const columnMinWidth = 40;
export const iconWidth = 20;

export const onCustomItemHeaderRender = (data: { name: string }, onRenderContent?: any) => {
  return (
    <TooltipHost id={data?.name} content={data?.name} directionalHint={DirectionalHint.bottomCenter} calloutProps={calloutProps}>
      {onRenderContent ? onRenderContent() : <span>{data?.name}</span>}
    </TooltipHost>
  );
};

const getInnerText = (value: string | JSX.Element) => {
  if (typeof value == 'string') return value;
  if (React.isValidElement(value)) {
    const html = ReactDOMServer.renderToStaticMarkup(value);
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.innerText;
  }
  return '';
};

export const onCustomItemCellRender: any = (
  item: any,
  index: number,
  column: IColumn,
  onRender: (item?: any, index?: number, column?: IColumn) => any,
  handleClick?: any
) => {
  const tooltipId = useId('tooltip');
  const spanId = useId('targetSpan');
  const calloutProps = {
    gapSpace: 1,
    target: `#${spanId}`,
    isBreak: false,
    directionalHint: DirectionalHint.bottomLeftEdge,
  };

  const content = item[column.fieldName as string];
  const contentNode = onRender ? onRender(item, index, column) : (content as JSX.Element);
  const toolTipContent = column?.data?.renderCustomToolTip ? column?.data?.renderCustomToolTip(item) : contentNode;

  return column.data.isHasToolTip ? (
    <TooltipHost key={column.key} id={tooltipId} content={toolTipContent} calloutProps={calloutProps}>
      <div data-tooltip={column.isIconOnly ? getInnerText(toolTipContent) : ''} className="content" aria-describedby={tooltipId}>
        {handleClick ? (
          <Link
            className="cell-link"
            style={{ color: color.$linkColor }}
            onClick={(e) => {
              handleClick(item);
            }}
            underline={true}
          >
            <span id={spanId}>{contentNode}</span>
          </Link>
        ) : (
          <span id={spanId}>{contentNode}</span>
        )}
      </div>
    </TooltipHost>
  ) : (
    <>{contentNode}</>
  );
};

export const onRegistEventForCell: any = (events: IColumnCellRegistEvent[], column: IColumn) => {
  const event = events.find((x) => x.key == column.key);
  if (!event) return;

  const prevRender = column?.data?.initialOnRender ? (column?.data?.initialOnRender as any).bind({}) : undefined;
  column.onRender = (item, index, column) => onCustomItemCellRender(item, index, column, prevRender, event?.func);
};

export const onCreateColumn = (column: IColumn): IColumn => {
  const prevRender = column.onRender ? (column.onRender as any).bind({}) : undefined;
  return {
    ...column,
    minWidth: column.minWidth ?? columnMinWidth,
    maxWidth: column.maxWidth ?? standarColumnWidth,
    key: column.fieldName as string,
    name: column.name,
    isResizable: column.isResizable != undefined ? column.isResizable : true,
    columnActionsMode: column.columnActionsMode,
    onRender: (item?: any, index?: number, column?: IColumn) => onCustomItemCellRender(item, index, column, prevRender),
    onRenderHeader: column.onRenderHeader
      ? column.onRenderHeader
      : () =>
          onCustomItemHeaderRender({
            name: column.data?.captionKey ? i18n.t(column.data?.captionKey) : column.name,
          }),
    data: {
      ...column.data,
      isHasToolTip: column?.data?.isHasToolTip !== undefined ? column?.data?.isHasToolTip : true,
      captionKey: column?.data?.captionKey || '',
      initialOnRender: prevRender,
    },
  };
};

export const handleRenderDetailsHeaderSticky = (headerProps: any, defaultRender: any) => {
  return <Sticky>{defaultRender(headerProps)}</Sticky>;
};
