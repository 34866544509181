import { PanelType } from '@fluentui/react';
import { PanelWithScrollableBody } from '@onix/common/src/components/PanelWithScrollableBody/PanelWithScrollableBody';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useShowUpgradeOWMessage } from '../../../../common/hooks/useShowUpgradeOWMessage';
import { useTracking } from '../../../../common/hooks/useTracking';
import CommandBar from '../../../../common/ui/CommandBar/CommandBar';
import { PreviewDocument } from '../../../../common/ui/PreviewDocument/PreviewDocument';
import { ApiConstants } from '../../../../constants/api.constants';
import { Modules } from '../../../../constants/modules.constants';
import { TrackingEvent } from '../../../../constants/tracking-event.constant';
import { DocumentHelper } from '../../../../helpers/document.helper';
import { equipmentDocumentSelector } from '../../../../states/equipment/equipmentDocumentSlice';
import { downloadFileByUrl } from '../../../../utils/file.utils';

type Props = {
  isOpen: boolean;
  document?: any;
  onDismiss?: () => void;
};

const PreviewDocumentPanel = ({ isOpen, document, onDismiss }: Props) => {
  const [translate] = useTranslation();
  const { selectedDocuments } = useSelector(equipmentDocumentSelector);
  const selectedDocument = selectedDocuments[0];
  const { showUpgradeOWMessage } = useShowUpgradeOWMessage();

  const { trackEvent } = useTracking({ module: Modules.Equipment });

  const onDismissPanel = useCallback(() => {
    onDismiss && onDismiss();
  }, [onDismiss]);

  const onRenderHeader = useCallback(() => {
    return (
      <>
        <CommandBar
          items={[
            {
              key: 'open-document',
              text: translate('RibbonMenu.Open'),
              iconProps: { iconName: 'OpenInNewWindow' },
              onClick: () => {
                DocumentHelper.openDocument(selectedDocument);
              },
            },
            {
              key: 'download-document',
              text: translate('CaptionResource.Download'),
              iconProps: { iconName: 'Download' },
              onClick: () => {
                const urlDownload = ApiConstants.document.getUrlDownloadDocument(selectedDocument.documentId);
                downloadFileByUrl(urlDownload);
                trackEvent(TrackingEvent.Equipment.EquipmentDetail.Documents.DownloadAction);
              },
            },
            {
              key: 'send',
              text: translate('CommonResource.send'),
              iconProps: { iconName: 'Mail' },
              onClick: () => {
                showUpgradeOWMessage();
                trackEvent(TrackingEvent.Equipment.EquipmentDetail.Documents.SendAction);
              },
            },
          ]}
        />
      </>
    );
  }, [selectedDocument, showUpgradeOWMessage, translate]);

  const onRenderBody = useCallback(() => {
    return <div className="border h-[calc(100%-20px)]">{document && <PreviewDocument {...document}></PreviewDocument>}</div>;
  }, []);

  return (
    <PanelWithScrollableBody
      className="preview-document-panel"
      isOpen={isOpen}
      onDismiss={onDismissPanel}
      hideSeparator={true}
      panelProps={{
        type: PanelType.smallFluid,
        isBlocking: true,
      }}
      renderHeader={onRenderHeader}
      renderBody={onRenderBody}
    />
  );
};

export default PreviewDocumentPanel;
