import { TextField as FluentTextField, ITextFieldProps } from '@fluentui/react';
import { cn } from '../../../utils/css.utils';
type Props = ITextFieldProps & {
  className?: string;
};
const TextField = ({ className: classNameProp, ...otherProps }: Props) => {
  const className = classNameProp ?? '';
  return <FluentTextField {...otherProps} className={cn('oex-input', className)} />;
};

export default TextField;
