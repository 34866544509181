/* eslint-disable react-hooks/rules-of-hooks */
import { DirectionalHint, FontIcon, IColumn, Link, TooltipHost } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { format } from 'date-fns';
import { OnixTooltip } from '../../../../common/ui/OnixTooltip/OnixTooltip';
import { colors } from '../../../../constants/common.constants';
import { dateTimeFormat } from '../../../../constants/dateTime.constant';
import { i18n } from '../../../../constants/languages.constants';
import { LinkStyles } from '../../../../constants/style';
import { TableColumnConstant } from '../../../../constants/table-column.constant';
import { EquipmentDocumentsColumns } from '../../../../enums/equipment-document-columns.enum';
import { DocumentHelper } from '../../../../helpers/document.helper';
import { DocumentDto } from '../../../../models/document.model';
import { equipmentDocumentActions } from '../../../../states/equipment/equipmentDocumentSlice';
import { appDispatch } from '../../../../states/store';

const onRenderFileType = (item: DocumentDto) => {
  return (
    <OnixTooltip content={DocumentHelper.getDocumentContentTypeName(item)}>
      <FontIcon
        aria-label="Compass"
        className={
          item.documentSource === 0 || DocumentHelper.getDocumentContentTypeName(item) === 'image'
            ? ''
            : item.documentSource === 1
            ? 'purple'
            : 'blue'
        }
        iconName={DocumentHelper.getDocumentIconName(item.contentType)}
        style={{ fontSize: 16, color: colors.grey160 }}
      />
    </OnixTooltip>
  );
};

const onRenderHeaderFileType = () => {
  return (
    <OnixTooltip content={i18n.t(`Document.Documentfiletype`)}>
      <FontIcon aria-label="Compass" iconName={'Page'} style={{ fontSize: 16 }} />
    </OnixTooltip>
  );
};

const onRenderDocumentDescription = (document: DocumentDto): JSX.Element | undefined => {
  return (
    <div>
      <OnixTooltip content={document.description}>
        <Link
          style={LinkStyles}
          underline
          onClick={() => {
            appDispatch(equipmentDocumentActions.setShowPreviewDocument(true));
          }}
        >
          {document.description}
        </Link>
      </OnixTooltip>
    </div>
  );
};

const onRenderTextField = (equipment?: any, column?: IColumn | undefined): JSX.Element | undefined => {
  const contentId = useId(column?.fieldName ?? '');
  const tooltipId = useId('tooltipId');

  const calloutProps = {
    gapSpace: 1,
    target: `#${contentId}`,
    isBreak: false,
    directionalHint: DirectionalHint.bottomLeftEdge,
  };

  if (column) {
    let textValue = equipment[column.fieldName ?? ''];
    switch (column.fieldName) {
      case PropertyKeys.DocumentType:
        textValue = i18n.t(`CaptionResource.DocumentType.Content${equipment.documentType}`);
        break;
      case PropertyKeys.CreatedDate:
        textValue = format(new Date(equipment.documentDate), dateTimeFormat.dateTimeFormat);
        break;
      default:
        break;
    }
    return (
      <TooltipHost id={tooltipId} content={textValue} calloutProps={calloutProps}>
        <div id={contentId} aria-describedby={tooltipId} className="text-three-dots">
          {textValue}
        </div>
      </TooltipHost>
    );
  }
  return undefined;
};

export const PropertyKeys = {
  ContentType: 'contentType',
  Description: 'description',
  DocumentType: 'documentType',
  CreatedDate: 'documentDate',
};

export const EquipmentDocumentsTableColumns = [
  {
    id: EquipmentDocumentsColumns.ContentType,
    propertyKey: PropertyKeys.ContentType,
    isIconOnly: true,
    onRender: onRenderFileType,
    onRenderHeader: onRenderHeaderFileType,
  },
  {
    id: EquipmentDocumentsColumns.Description,
    propertyKey: PropertyKeys.Description,
    defaultWidth: TableColumnConstant.LargeColumnDimension,
    onRender: onRenderDocumentDescription,
  },
  {
    id: EquipmentDocumentsColumns.DocumentType,
    propertyKey: PropertyKeys.DocumentType,
    onRender: onRenderTextField,
  },
  {
    id: EquipmentDocumentsColumns.CreatedDate,
    propertyKey: PropertyKeys.CreatedDate,
    onRender: onRenderTextField,
  },
];
