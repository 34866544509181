import { HttpClient } from '@onix/common';
import { StatusCodes } from 'http-status-codes';
import { appActions } from '../states/app/appSlice';
import { appDispatch } from '../states/store';
import { getToken } from './authentication.service';

export const getAsync = (url: string, request: any = {}, isLoading: boolean = true, headers: any = getAuthorizationHeaders()) => {

  detectShowLoading(isLoading);
  return HttpClient.getAsync(url, request, headers)
    .catch(async (err: any) => {
      return handleError(err);
    })
    .finally(() => {
      detectHiddenLoading(isLoading);
    });
};

export const postAsync = (url: string, request: any = {}, isLoading: boolean = true, headers: any = getAuthorizationHeaders()) => {
  detectShowLoading(isLoading);
  return HttpClient.postAsync(url, request, headers)
    .catch(async (err: any) => {
      return handleError(err);
    })
    .finally(() => {
      detectHiddenLoading(isLoading);
    });
};

export const putAsync = (url: string, request: any = {}, isLoading: boolean = true, headers: any = getAuthorizationHeaders()) => {
  detectShowLoading(isLoading);
  return HttpClient.putAsync(url, request, headers)
    .catch(async (err: any) => {
      return handleError(err);
    })
    .finally(() => {
      detectHiddenLoading(isLoading);
    });
};

export const deleteAsync = (url: string, isLoading: boolean = true, headers: any = getAuthorizationHeaders()) => {
  detectShowLoading(isLoading);
  return HttpClient.deleteAsync(url, headers)
    .catch(async (err: any) => {
      return await handleError(err);
    })
    .finally(() => {
      detectHiddenLoading(isLoading);
    });
};

function getAuthorizationHeaders() {
  const token = getToken();
  return {
    Authorization: `Bearer ${token}`,
  };
}

const detectShowLoading = (isLoading: boolean) => {
  if (isLoading) {
    appDispatch(appActions.showLoading());
  }
};

const detectHiddenLoading = (isLoading: boolean) => {
  if (isLoading) {
    appDispatch(appActions.hideLoading());
  }
};

const handleError = async (response: any) => {
  if (response?.status === StatusCodes.UNAUTHORIZED) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }

  if (response?.data) {
    return Promise.reject(response.data);
  }
  return Promise.reject(response);
};
