import { ApiConstants } from '../constants/api.constants';
import { CompanyExpressSetup } from '../models/company-express-setup.model';
import { getAsync } from './base.service';

const getCompanyExpressSetupInfo = (companyId: number): Promise<CompanyExpressSetup> => {
  return getAsync(ApiConstants.companyExpressSetup.getCompanyExpressSetupInfo(companyId));
};

const getCompanyId = (alias: string): Promise<any> => {
  return getAsync(ApiConstants.companyExpressSetup.getCompanyId(alias));
};

export const CompanyExpressSetupService = {
  getCompanyExpressSetupInfo,
  getCompanyId,
};
