import { DefaultButton, IButtonProps } from '@fluentui/react';
import { CSSProperties } from 'react';
import { cn } from '../../../utils/css.utils';

type Props = IButtonProps & {
  text: string;
  title?: string;
  onClick?: VoidFunction;
  className?: string;
  style?: CSSProperties;
  props?: IButtonProps;
};

const PrimaryButton = ({ text, title, onClick, className, style, ...props }: Props) => {
  return (
    <DefaultButton
      text={text}
      title={title}
      onClick={onClick}
      className={cn(
        'border-none bg-primarybg text-primarytext min-w-fit shadow-depth4 mr-2 hover:bg-primarybg hover:text-primarytext',
        className || ''
      )}
      style={style}
      {...props}
    />
  );
};

export default PrimaryButton;
