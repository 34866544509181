import { ApiConstants } from '../constants/api.constants';
import { DashboardData } from '../models/dashboard/dashboard.model';
import { WidgetData } from '../models/dashboard/widget.model';
import { getAsync } from './base.service';

const getExpressStandardDashboard = (): Promise<DashboardData> => {
  return getAsync(ApiConstants.dashboard.getDashboardInfo());
};

const getWidgetData = (widgetId: number, languageId: number, isLoading: boolean = false): Promise<WidgetData> => {
  return getAsync(ApiConstants.dashboard.getWidgetData(widgetId, languageId), undefined, isLoading);
};

const refresh = (): Promise<boolean> => {
  return getAsync(ApiConstants.dashboard.refresh());
};

export const DashboardService = {
  getExpressStandardDashboard,
  getWidgetData,
  refresh,
};
