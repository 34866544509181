import { IDropdownOption, IIconProps, IconButton } from '@fluentui/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { DivFlexColumn, DivFlexRow, StyledDatePicker } from '../../../assets/styles/layouts';
import { StyledDatePicker } from '@onix/common/src/assets/layouts';
import { color } from '../../../../assets/styles/styles';
import { dateTimeFormat, getDatePickerStrings } from '../../../../constants/dateTime.constant';
import { globalAppVariables } from '../../../../constants/global-variables.constants';
import { DateRanges } from '../../../../enums/date-ranges.enum';
import { FieldFilter, FilterControlRef, ISearchFieldProps } from '../../../../models/filter-controls/search-field.model';
import { formatDate, getFromToFromDateRange } from '../../../../utils/dateTime';
import DropdownSelect from '../../Dropdown/DropdownWithClear/DropdownSelect';
import './index.scss';

const CommonDate = (props: ISearchFieldProps) => {
  const [translate] = useTranslation();
  const [period, setPeriod] = useState<Number>(globalAppVariables.IGNORE_VALUE_INT);
  const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
  const [toDate, setToDate] = useState<Date | undefined>(undefined);
  const [disabledDatePicker, setDisabledDatePicker] = useState(true);

  const onChangeDropdown = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
    if (item) {
      if (Number(item.key as string) === globalAppVariables.IGNORE_VALUE_INT) {
        onResetData();
        return;
      }

      const dateRangeValue = Number(item.key as string);
      setPeriod(dateRangeValue);

      switch (dateRangeValue) {
        case DateRanges.Custom:
        case DateRanges.NoDate:
          const isNoDate = dateRangeValue === DateRanges.NoDate;
          setDisabledDatePicker(isNoDate);
          props.field.value = { ...props.field.value, period: dateRangeValue, from: undefined, to: undefined };
          setFromDate(undefined);
          setToDate(undefined);
          break;
        default:
          setDisabledDatePicker(true);
          const tuple = getFromToFromDateRange(dateRangeValue);
          const fromDateValue = dateRangeValue === DateRanges.EndToday ? undefined : tuple.from;
          setFromDate(fromDateValue);
          setToDate(tuple.to);

          props.field.value = {
            ...props.field.value,
            period: dateRangeValue,
            from: fromDateValue === undefined ? undefined : formatDate(fromDateValue, dateTimeFormat.momentDateDatabaseFormat),
            to: formatDate(tuple.to, dateTimeFormat.momentDateDatabaseFormat),
          };
          break;
      }

      if (typeof props.onDataChanged === 'function') {
        setTimeout(props.onDataChanged, 0);
      }
    }
  };

  const onSelectFromDate = (date: any) => {
    if (date) {
      props.field.value.from = date === undefined ? undefined : formatDate(date, dateTimeFormat.momentDateDatabaseFormat);
      setFromDate(date);
      if (typeof props.onDataChanged === 'function') {
        setTimeout(props.onDataChanged, 0);
      }
    }
  };

  const onSelectToDate = (date: any) => {
    if (date) {
      props.field.value.to = date === undefined ? undefined : formatDate(date, dateTimeFormat.momentDateDatabaseFormat);
      setToDate(date);
      if (typeof props.onDataChanged === 'function') {
        setTimeout(props.onDataChanged, 0);
      }
    }
  };

  const onResetData = () => {
    setPeriod(globalAppVariables.IGNORE_VALUE_INT);
    setFromDate(undefined);
    setToDate(undefined);
    setDisabledDatePicker(true);
    props.field.value = undefined;
    if (typeof props.onDataChanged === 'function') {
      setTimeout(props.onDataChanged, 0);
    }
  };

  const getFilter = (): FieldFilter | undefined => {
    const value = props.field.value;
    if (value !== undefined) {
      const filter = new FieldFilter();
      filter.fieldName = props.field.key;
      filter.type = props.field.type;
      filter.fieldValue = JSON.stringify(value);
      return filter;
    }
    return undefined;
  };

  useEffect(() => {
    const controlRef = new FilterControlRef();
    controlRef.onClearData = onResetData;
    controlRef.getFilter = getFilter;
    props.field.controlRef = controlRef;
    if (props.field.value !== null && props.field.value !== undefined && props.field.value !== '') {
      const parseValue = JSON.parse(props.field.value);
      props.field.value = parseValue;
      setPeriod(parseValue.period);

      if (parseValue.period) {
        if (parseValue.period === DateRanges.Custom) {
          setDisabledDatePicker(false);

          setFromDate(parseValue.from ? new Date(parseValue.from) : undefined);
          setToDate(parseValue.to ? new Date(parseValue.to) : undefined);
        } else if (parseValue.period === DateRanges.NoDate) {
          setDisabledDatePicker(true);

          setFromDate(undefined);
          setToDate(undefined);
        } else {
          setDisabledDatePicker(true);

          const tuple = getFromToFromDateRange(parseValue.period);
          const dateRangesToSetDefaultFromDate = [DateRanges.EndToday, DateRanges.OverdueXDays, DateRanges.MissingDueDate];
          const dateRangesToSetDefaultToDate = [DateRanges.StartFromToday, DateRanges.Good, DateRanges.MissingDueDate];
          const fromDateValue = dateRangesToSetDefaultFromDate.includes(parseValue.period) ? undefined : tuple.from;
          const toDateValue = dateRangesToSetDefaultToDate.includes(parseValue.period) ? undefined : tuple.to;
          setFromDate(fromDateValue);
          setToDate(toDateValue);

          parseValue.from = fromDateValue === undefined ? undefined : formatDate(fromDateValue, dateTimeFormat.momentDateDatabaseFormat);
          parseValue.to = toDateValue === undefined ? undefined : formatDate(toDateValue, dateTimeFormat.momentDateDatabaseFormat);
        }
      } else {
        setDisabledDatePicker(true);
        setFromDate(undefined);
        setToDate(undefined);
      }
    }
  }, []);

  const options = useMemo(() => {
    const data: IDropdownOption[] = props.field.options ?? [];
    return data.map((x) => {
      return { ...x, text: translate(x.text) };
    });
  }, [props.field.options, translate]);

  //#region Datetime clearing button
  const defaultClearIcon = useMemo((): IIconProps => {
    return {
      iconName: 'Cancel',
      style: { cursor: 'pointer', fontSize: 14, color: color.$grey110 },
    };
  }, []);

  const fromDateId = 'dpCommonFromDate';
  const toDateId = 'dpCommonToDate';

  const clearDateValue = (name: string) => {
    switch (name) {
      case `${fromDateId}-label`:
        setFromDate(undefined);
        break;
      case `${toDateId}-label`:
        setToDate(undefined);
        break;
    }
  };

  const renderSuffixDate = (item: any) => {
    return (
      <IconButton
        className="btn-clear-text btn-clear-color"
        style={{ cursor: 'pointer', backgroundColor: color.$white, height: '100%' }}
        iconProps={defaultClearIcon}
        onClick={() => {
          clearDateValue(item.id);
        }}
      />
    );
  };
  //#endregion

  return (
    <div className="common-date">
      <DropdownSelect
        label={translate(props.field.label)}
        placeholder={translate(props.field.placeholder ?? '')}
        options={options}
        onChange={onChangeDropdown}
        onClearSelectedKeys={onResetData}
        selectedKey={period}
        disabled={props.field.disabled}
      />
      <div className="flex" style={{ marginTop: '10px' }}>
        <div className="flex flex-col" style={{ width: '50%' }}>
          <StyledDatePicker
            value={fromDate}
            placeholder={translate('CaptionResource.From')}
            strings={getDatePickerStrings()}
            formatDate={formatDate}
            disabled={disabledDatePicker}
            onSelectDate={onSelectFromDate}
            maxDate={toDate}
            firstDayOfWeek={dateTimeFormat.firstDayOfWeek}
            className={`oex-input datePicker ${disabledDatePicker ? '' : 'common-date-picker'}`}
            id={fromDateId}
            textField={fromDate && !(props.field.disabled || disabledDatePicker) ? { onRenderSuffix: renderSuffixDate } : {}}
          />
        </div>
        <div className="flex flex-col" style={{ width: '50%', marginLeft: '10px' }}>
          <StyledDatePicker
            value={toDate}
            placeholder={translate('CaptionResource.To')}
            strings={getDatePickerStrings()}
            formatDate={formatDate}
            disabled={disabledDatePicker}
            onSelectDate={onSelectToDate}
            minDate={fromDate}
            firstDayOfWeek={dateTimeFormat.firstDayOfWeek}
            className={`oex-input datePicker ${disabledDatePicker ? '' : 'common-date-picker'}`}
            id={toDateId}
            textField={toDate && !(props.field.disabled || disabledDatePicker) ? { onRenderSuffix: renderSuffixDate } : {}}
          />
        </div>
      </div>
    </div>
  );
};

export default CommonDate;
