import { css } from '@fluentui/react';
import { PanelWithScrollableBody } from '@onix/common/src/components';
import { createContext, ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedEffect } from '../../../common/hooks/useDebouncedEffect';
import CommonHelper from '../../../helpers/common-helper';
import { Equipment } from '../../../models/equipmentDetail/equipment.model';
import { MaintenancePlan } from '../../../models/equipmentDetail/job-intervals/job-interval.model';
import { showCommonDialog } from '../../../services/dialog.service';
import { equipmentService } from '../../../services/equipment.service';
import { equipmentActions } from '../../../states/equipment/equipmentSlice';
import { appDispatch } from '../../../states/store';
import { getLanguageId } from '../../../utils/localStorage.utils';
import { EquipmentContent, EquipmentContentRef } from './Content/EquipmentContent';
import { EquipmentHeader } from './Header/EquipmentHeader';
import './index.scss';
import { EquipmentInfo } from './Info/EquipmentInfo';

const DEFAULT_ASYNC_FN = async () => {};

interface EquipmentDetailProps {
  equipmentId: number;
  onDismiss?: () => void;
  isHiddenRelatedRow?: boolean;
  isOpenFromRelatedEquipmentDetail?: boolean;
  openFrom?: string;
}

export interface EquipmentDetailRef {
  refresh(hasSkeleton?: boolean, hasSpinner?: boolean): Promise<void>;
  refreshAll(hasSkeleton?: boolean, hasSpinner?: boolean): Promise<void>;
  onDismissPanel(): void;
}

export const EquipmentDetailContext = createContext({
  refresh: DEFAULT_ASYNC_FN,
  refreshAll: DEFAULT_ASYNC_FN,
  refreshProperties: DEFAULT_ASYNC_FN,
  refreshJobInterval: DEFAULT_ASYNC_FN,

  equipment: {} as Equipment,
  setEquipment: (equipment: Equipment) => {},
  isLoading: false,
  jobIntervals: undefined as MaintenancePlan[] | undefined,
  setJobIntervals: (jobIntervals: MaintenancePlan[] | undefined) => {},
});

export const EquipmentDetail = forwardRef((props: EquipmentDetailProps, ref: ForwardedRef<EquipmentDetailRef>) => {
  const { equipmentId } = props;
  const [translate] = useTranslation();
  const [equipment, setEquipment] = useState<Equipment>();
  const [isLoading, setIsLoading] = useState(false);
  const [jobIntervals, setJobIntervals] = useState<MaintenancePlan[] | undefined>(undefined);
  const equipmentContentRef = useRef<EquipmentContentRef>(null);

  const refresh = async (hasSkeleton = false, hasSpinner = true) => {
    await getEquipmentDetailById(equipmentId, getLanguageId(), hasSkeleton, hasSpinner);
  };

  const refreshAll = async (hasSkeleton = false, hasSpinner = true) => {
    const { jobIntervalRef, propertiesRef } = equipmentContentRef.current ?? {};
    await refresh(hasSkeleton, hasSpinner);
    await jobIntervalRef?.refresh();
    await propertiesRef?.refresh();
  };

  const refreshProperties = async () => {
    const { propertiesRef } = equipmentContentRef.current ?? {};
    if (propertiesRef) {
      await propertiesRef.refresh();
    }
  };

  const refreshJobInterval = async () => {
    const { jobIntervalRef } = equipmentContentRef.current ?? {};
    if (jobIntervalRef) {
      await jobIntervalRef.refresh();
    }
  };

  useImperativeHandle(
    ref,
    () =>
      ({
        refresh,
        refreshAll,
        onDismissPanel,
      } as EquipmentDetailRef)
  );

  useDebouncedEffect(
    () => {
      if (equipmentId) {
        getEquipmentDetailById(equipmentId, getLanguageId());
      }
      return () => {
        setEquipment(undefined);
      };
    },
    [equipmentId],
    800
  );

  useEffect(() => {
    if (equipmentId) {
      setIsLoading(true);
    }
    return () => {
      setIsLoading(false);
    };
  }, [equipmentId]);

  const getEquipmentDetailById = async (equipmentId: number, languageId: number, hasSkeleton = true, hasSpinner = false) => {
    try {
      if (hasSkeleton) setIsLoading(true);
      const equipment = await equipmentService.getEquipmentDetailById(equipmentId, languageId, hasSpinner);
      setEquipment(equipment);
      if (hasSkeleton) setIsLoading(false);
    } catch (err: any) {
      const message = CommonHelper.getErrorMessage(err);
      if (message) {
        showCommonDialog(translate('CaptionResource.CAPTION_ERROR'), translate(message));
      }
    }
  };

  const headerHasBottom = (equipment?.tagTypes?.length ?? 0) > 0;

  const onDismissPanel = () => {
    appDispatch(equipmentActions.setShowHidePanel(false));
    appDispatch(equipmentActions.setSelectedEquipmentId(0));
  };

  return (
    <PanelWithScrollableBody
      className="edit-detail-equipment"
      isOpen={true}
      width="835px"
      onDismiss={onDismissPanel}
      onRefresh={() => {
        if (equipmentId) {
          refreshAll(true, false);
        }
      }}
      hideSeparator={true}
      renderBody={() => (
        <EquipmentDetailContext.Provider
          value={{
            refresh,
            refreshAll,
            refreshProperties,
            equipment: equipment as any,
            setEquipment,
            isLoading,
            jobIntervals,
            refreshJobInterval,
            setJobIntervals,
          }}
        >
          <div className="equipment-detail-container">
            <div className={css('header-info', 'detail-table-mode', headerHasBottom && 'has-bottom')}>
              <EquipmentHeader equipment={equipment} isLoading={isLoading} />
              <EquipmentInfo onRefresh={refresh} isHiddenRelatedRow={props.isHiddenRelatedRow} onCloseEquipmentDetail={props.onDismiss} />
            </div>
            <EquipmentContent
              ref={equipmentContentRef}
              isHiddenRelatedRow={props.isHiddenRelatedRow && !props.isOpenFromRelatedEquipmentDetail}
              isOpenFromRelatedEquipmentDetail={props.isOpenFromRelatedEquipmentDetail}
              openFrom={props.openFrom}
            />
          </div>
        </EquipmentDetailContext.Provider>
      )}
      panelProps={{
        isBlocking: false,
      }}
    />
  );
});

EquipmentDetail.displayName = 'EquipmentDetail';
