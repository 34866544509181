export enum EquipmentColumns {
  ID = 1,
  IssueDueStatus = 2,
  IssueStatus = 3,
  MandatoryDueStatus = 4,
  PreventiveDueStatus = 5,
  OperatorDueStatus = 6,
  ProductNo = 7,
  Type = 8,
  Model = 9,
  ControlCategory = 10,
  WLLSWL = 11,
  CertificateNo = 12,
  Location = 13,
  DueMandatory = 14,
  LatestMandatoryJobDate = 15,
  LatestMandatoryJobStatus = 16,
  EquipmentIdentifier = 17,
}

export const EquipmentDisplayFieldKeyCaptions: Record<number, string> = {
  // JobMainInfo
  [EquipmentColumns.ID]: 'CaptionResource.ID',
  [EquipmentColumns.IssueDueStatus]: 'Equipment.IssueDueStatus',
  [EquipmentColumns.IssueStatus]: 'Equipment.JobStatus',
  [EquipmentColumns.MandatoryDueStatus]: 'Equipment.DueStatus',
  [EquipmentColumns.PreventiveDueStatus]: 'Equipment.PreventiveJobDueStatus',
  [EquipmentColumns.OperatorDueStatus]: 'Equipment.OperatorJobDueStatus',
  [EquipmentColumns.ProductNo]: 'Equipment.ProductNo',
  [EquipmentColumns.Type]: 'CaptionResource.Caption5',
  [EquipmentColumns.Model]: 'Equipment.Model',
  [EquipmentColumns.ControlCategory]: 'Equipment.ControlCategory',
  [EquipmentColumns.WLLSWL]: 'Article6.Main.WllSwl',
  [EquipmentColumns.CertificateNo]: 'Equipment.CertificateNo',
  [EquipmentColumns.Location]: 'CaptionResource.Caption7',
  [EquipmentColumns.DueMandatory]: 'Equipment.lblIncludeDueDate',
  [EquipmentColumns.LatestMandatoryJobDate]: 'Equipment.LatestMandatoryJobDate',
  [EquipmentColumns.LatestMandatoryJobStatus]: 'Equipment.LatestMandatoryJobStatus',
};

export const EquipmentNotGroupingColumn = [];

export enum EquipmentColumnsExcel
{
    ID = 0,
    IssueDueStatus = 1, // ActiveStatus / Issue due status
    IssueStatus = 2,
    MandatoryDueStatus = 3,
    PreventiveDueStatus = 4,
    OperatorDueStatus = 5,
    ProductNo = 6,
    Type = 7,
    Model = 8,
    ControlCategory = 9,
    WLLSWL = 10,
    CertificateNo = 11,
    Location = 12,
    DueMandatory = 13,
    LatestMandatoryJobDate = 14,
    LatestMandatoryJobStatus = 15,
    CompanyOwnerDescription = 16,
    EquipmentIdentifier = 17,
    WLL = 18,
    SWL = 19,
    ActiveStatus = 20
}

// export const jobIconSplitColumns = [JobColumns.FilingStatus, JobColumns.JobStatus, JobColumns.JobClass];
