import { modalActions } from '../states/modal/modalSlice';
import { appDispatch } from '../states/store';
import { ConfirmModalProps, DiscardModalProps, MessageBoxProps } from '../types/modal.type';

const dispatch = appDispatch;

const showDiscardChanges = (discard: DiscardModalProps) => {
  dispatch(modalActions.showDiscard(discard));
};

const showMessageBox = (box: MessageBoxProps) => {
  dispatch(modalActions.showBox(box));
};

const showConfirm = (confirm: ConfirmModalProps) => {
  dispatch(modalActions.showConfirm(confirm));
};

const hideModal = (id: string) => {
  dispatch(modalActions.hideModal(id));
};

export { hideModal, showConfirm, showDiscardChanges, showMessageBox };
