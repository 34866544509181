import { cn } from '../../../utils/css.utils';

type Props = {
  type?: 'vertical' | 'horizontal';
  className?: string;
};

const Divider = ({ type = 'vertical', className }: Props) => {
  return (
    <div
      className={cn(
        'border',
        {
          'h-[2px] w-full': type === 'vertical',
          'w-[2px] h-full': type === 'horizontal',
        },
        className || ''
      )}
    ></div>
  );
};

export default Divider;
