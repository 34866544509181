import { useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const refObject = useRef<T>();

  useEffect(() => {
    refObject.current = value;
  });

  return refObject.current;
};
