import { Selection } from '@fluentui/react';
import { RefObject, useEffect, useState } from 'react';

export interface IViewDetailPanelRef {
  onDismissPanel: () => void;
  //item id of panel detail that the same key in selection object of table list
  itemId: number;
}
interface IUseSelectionToViewDetailPanel<T extends IViewDetailPanelRef> {
  /**
   * this is list selected items from table
   */
  selectedItems: any[];
  /**
   * the panel ref that need to be open
   */
  itemDetailPanelRef: RefObject<T> | undefined; // Specify RefObject with type T
  /**
   * this function will be called to close detail panel instead of itemDetailPanelRef?.current?.onDismissPanel();
   */
  onDismissDetailPanel?: () => void;
  /**
   * this function will be called to open detail panel
   */
  onOpenDetailPanel: () => void;
  /**
   * this function will be called to update selected equipment to detail panel if needed
   */
  updateSelectedItemToDetailPanel?: () => void;
  /**
   * some panel don't dimiss when item is null, this flag is used to dismiss panel when selectedItems is empty
   * value true: that mean the itemDetailPanel don't dismiss automatically when IViewDetailPanelRef.itemId is null
   * @default True
   */
  isDismissPanelForNoneItem?: boolean;
  /**
   * selection object of table list
   */
  selection?: Selection;
}

// Custom hook for handling selection to view detail panel
export const useSelectionToViewDetailPanel = <T extends IViewDetailPanelRef>(props: IUseSelectionToViewDetailPanel<T>) => {
  const { selectedItems, itemDetailPanelRef, updateSelectedItemToDetailPanel, onOpenDetailPanel, selection } = props;
  // Keep the detail panel open if opening the detail panel, then select other items then unselect again.
  const [keepEquipmentDetailRef, setKeepEquipmentDetailRef] = useState(false);
  const isDismissPanelForNoneItem = props.isDismissPanelForNoneItem ?? true;

  const onDismissDetailPanel = () => {
    if (props.onDismissDetailPanel) {
      props.onDismissDetailPanel();
    } else {
      itemDetailPanelRef?.current?.onDismissPanel();
    }
  };

  useEffect(() => {
    if (selectedItems.length === 0) {
      //case none selected items and exist itemDetailPanelRef:  need to close the panel
      if (isDismissPanelForNoneItem) {
        onDismissDetailPanel();
      }
      setKeepEquipmentDetailRef(false);
    } else if (selectedItems.length === 1) {
      //case only one selected item: need to clear remember updateKeepEquipmentDetailRef
      //if not exist itemDetail and keepEquipmentDetailRef is true then open the panel again. at this time itemDetailPanelRef will have value
      setKeepEquipmentDetailRef(false);
      if (!itemDetailPanelRef?.current && keepEquipmentDetailRef) {
        onOpenDetailPanel();
      }

      //update selected equipment to detail panel if needed
      updateSelectedItemToDetailPanel?.();
    } else {
      // multiple selected items: close the panel and remember keepEquipmentDetailRef true
      if (itemDetailPanelRef?.current) {
        onDismissDetailPanel();
        setKeepEquipmentDetailRef(true);
      }
    }
  }, [selectedItems]);

  useEffect(() => {
    // clear other selection when open detail panel
    if (itemDetailPanelRef?.current && itemDetailPanelRef?.current.itemId && selectedItems.length > 1) {
      selection?.setAllSelected(false);
      selection?.setKeySelected(itemDetailPanelRef?.current.itemId.toString(), true, false);
    }
  }, [itemDetailPanelRef?.current]);
};
