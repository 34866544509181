import { CommandBarButton, CommandButton, ICommandBarItemProps, IContextualMenuProps, IIconProps } from '@fluentui/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../../common/ui/Button/PrimaryButton';
import { CaptionForm } from '../../../../enums/language.enum';
import { IPicture } from '../../../../models/equipmentDetail/equipment-image.model';
import './index.scss';

enum PictureActionEnum {
  SwitchLayout = 'switchLayout',
  SwitchSplitLayout = 'switchSplitLayout',
  SwitchTiles = 'switchTiles',
  SetAsDefault = 'setAsDefault',
  EditPictureInfo = 'editPictureInfo',
  DeleteItem = 'deleteItem',
}

interface IToolbar {
  pictures?: IPicture[];
  isMultipleEquipmentsSelected?: boolean;
  onAddPicture?: () => void;
  onEditPicture?: () => void;
  onDeletePicture?: () => void;
  onClearAllSelected?: () => void;
}

export const PictureToolBar = (props: IToolbar) => {
  const [translate] = useTranslation();
  const [items, setItems] = useState<ICommandBarItemProps[]>();

  const renderExpandAllButton = useCallback((items: ICommandBarItemProps[] = []) => {
    return items.map((item) => {
      return (
        <CommandButton
          style={{ backgroundColor: 'none' }}
          iconProps={item.iconProps}
          key={item.key}
          disabled={item.disabled}
          onClick={() => {
            if (typeof item?.onClick === 'function') {
              item.onClick();
            }
          }}
          menuProps={item.subMenuProps}
          className={item.className}
        >
          {item.onRenderContent ? item.onRenderContent(undefined as any, undefined as any) : <>{item.text}</>}
        </CommandButton>
      );
    });
  }, []);

  const createCommandBarItem = useCallback(
    (
      key: PictureActionEnum,
      captionKey: string,
      iconProps?: IIconProps,
      onClick?: () => void,
      subMenuProps?: IContextualMenuProps,
      className?: string
    ): ICommandBarItemProps => {
      return {
        key: key,
        text: translate(captionKey),
        iconProps: iconProps,
        preferMenuTargetAsEventTarget: true,
        onClick,
        subMenuProps,
        className,
      };
    },
    [translate]
  );

  useEffect(() => {
    const buttons: ICommandBarItemProps[] = [];

    if (props.pictures) {
      if (props.pictures.length > 0) {
        buttons.push(createCommandBarItem(PictureActionEnum.DeleteItem, 'CommonResource.Edit', { iconName: 'Edit' }, props.onEditPicture));
        buttons.push(
          createCommandBarItem(PictureActionEnum.EditPictureInfo, 'CommonResource.Delete', { iconName: 'Delete' }, props.onDeletePicture)
        );
      }
    }

    setItems(buttons);
  }, [createCommandBarItem, props.onDeletePicture, props.onEditPicture, props.pictures]);

  return (
    <>
      <div className="picture-toolbar">
        <div className="tool-bar-left">
          <PrimaryButton
            style={{ minWidth: 161 }}
            iconProps={{ iconName: 'Add' }}
            text={translate(`${CaptionForm.FormEquipment}.CheckPointAddPicture`)}
            onClick={props.onAddPicture}
          />

          <>{renderExpandAllButton(items)}</>
        </div>
        <div className="tool-bar-right">
          {props.pictures && props.pictures?.length > 0 && (
            <CommandBarButton
              iconProps={{ iconName: 'Clear' }}
              text={translate('CommonResource.ItemsSelected', {
                0: props.pictures?.length,
              })}
              onClick={props.onClearAllSelected}
            />
          )}
        </div>
      </div>
    </>
  );
};
