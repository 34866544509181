import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../common/ui/Loading/Loading';
import { AuthenTypes } from '../../enums/authen.enum';
import { alreadyLoggedIn, authentication } from '../../services/authentication.service';
import { getLoggedAlias } from '../../utils/localStorage.utils';

const AuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    async function completeAuth() {
      const authenticationService = authentication[AuthenTypes.OIDC];
      const isSuccess = await authenticationService?.completeAuthentication();
      if (isSuccess) {
        navigate(`/${getLoggedAlias()}`);
      }
    }
    completeAuth();
  }, []);

  return (
    <div>
      {!alreadyLoggedIn() && <Loading />}
      {alreadyLoggedIn() && <>Authentication callback successfully!</>}
    </div>
  );
};

export default AuthCallback;
