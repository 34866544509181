export const makeValidFileName = (name: any) => {
  var result = name.replace(/[^a-z0-9\s]/gi, '_').replace(/ /g, '');
  return result;
};

export const downloadFileByUrl = (url: string) => {
  if (url === undefined || url === null || url === '') return;

  let downloadEle = document.createElement('a');
  downloadEle.href = url;
  downloadEle.download = url;
  document.body.appendChild(downloadEle);
  downloadEle.click();
  document.body.removeChild(downloadEle);
};
