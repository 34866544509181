export const Modules = {
  CoreInsights: 'Onix Express - Core insights',
  RequestAUserPage: 'Onix Express - Request a user page',
  NavigationPanel: 'Onix Express - Navigation panel',
  Documents: 'Onix Express - Documents',
  Equipment: 'Onix Express - Equipment',
  Header: 'Onix Express - Header',
  HomePage: 'Onix Express - Homepage',
  InviteColleaguesPanel: 'Onix Express - Invite colleagues panel',
  Footer: 'Onix Express - Footer',
  UnsubscribePage: 'Onix Express - Unsubscribe page',
};
