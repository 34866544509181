import { useEffect } from "react";

type CleanUpFn = VoidFunction | undefined | void

export const useDebouncedEffect = (func: () => CleanUpFn, deps: any[] = [], timeout = 100) => {
  let timer: any;

  useEffect(() => {
    const cleanUp = { fn: undefined as CleanUpFn }

    if (func) {
      timer = setTimeout(() => {
        cleanUp.fn = func();
      }, timeout)
    }
    return () => {
      clearTimeout(timer);
      cleanUp.fn?.()
    }
  }, deps);
}