import { IDropdownOption, IDropdownProps } from '@fluentui/react';
import { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldFilter, FilterControlRef, ISearchField, ISearchFieldProps } from '../../../../models/filter-controls/search-field.model';
import DropdownSelect from '../../Dropdown/DropdownWithClear/DropdownSelect';
interface IDropdownMultiNoSearch extends ISearchFieldProps {
  dropdownProps?: IDropdownProps;
}

export interface IDropdownMultiNoSearchRef {
  field: ISearchField;
  setDisable: (value: boolean) => void;
  setFieldValue: (value: string) => void;
  getFilter: () => FieldFilter | undefined;
}

const DropdownMultiNoSearch = forwardRef((props: IDropdownMultiNoSearch, ref?: ForwardedRef<IDropdownMultiNoSearchRef>) => {
  const [translate] = useTranslation();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const { field, onRenderTitleCustom } = props;
  const { key, type, label, placeholder, options, value: valueProp, disabled } = field;
  useImperativeHandle(ref, () => ({
    field: field,
    setDisable: setDisable,
    setFieldValue: setFieldValue,
    getFilter: getFilter,
  }));

  const onChangeDropdown = useCallback(
    (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
      if (item) {
        const newSelectedKeys = item.selected ? [...selectedKeys, item.key as string] : selectedKeys.filter((key) => key !== item.key);
        setSelectedKeys(newSelectedKeys);
        field.value = newSelectedKeys.join(',');

        if (typeof props.onDataChanged === 'function') {
          console.log('DropdownMultiNoSearch: onChangeDropdown: props.onDataChanged');
          setTimeout(props.onDataChanged, 100);
        }
      }
    },
    [field, props.onDataChanged, selectedKeys]
  );

  const onClearDropdown = useCallback(() => {
    setSelectedKeys([]);
    field.value = '';

    if (typeof props.onDataChanged === 'function') {
      setTimeout(props.onDataChanged, 100);
    }
  }, [field, props.onDataChanged]);

  const getFilter = (): FieldFilter | undefined => {
    const value = (field.value ?? '').trim();
    if (value !== '') {
      const filter = new FieldFilter();
      filter.fieldName = key;
      filter.type = type;
      filter.fieldValue = value;
      return filter;
    }
    return undefined;
  };

  const onSetUpControlRef = () => {
    const controlRef = new FilterControlRef();
    controlRef.onClearData = onClearDropdown;
    controlRef.getFilter = getFilter;
    field.controlRef = controlRef;
  };

  useEffect(() => {
    onSetUpControlRef();
    if (valueProp !== undefined && valueProp !== '') {
      const keys = valueProp.parseToArrayOfNumbers();
      setSelectedKeys(keys);
    }
  }, [field]);

  useEffect(() => {
    onSetUpControlRef();
    if (valueProp !== undefined && valueProp !== '') {
      const keys = valueProp.parseToArrayOfNumbers();
      setSelectedKeys(keys);
    }
  }, [field.value]);

  const setDisable = (isDisable: boolean) => {};

  const setFieldValue = (value: any) => {
    onSetUpControlRef();
    if (value !== undefined && value !== '') {
      const keys = value.parseToArrayOfNumbers();
      field.value = value;
      setSelectedKeys(keys);
    }
  };

  return (
    <DropdownSelect
      multiSelect={true}
      placeholder={props.dropDownPlaceHolder ?? translate(placeholder ?? '')}
      label={translate(label)}
      selectedKeys={selectedKeys}
      onChange={onChangeDropdown}
      onClearSelectedKeys={onClearDropdown}
      options={options}
      onRenderTitleCustom={onRenderTitleCustom}
      disabled={disabled}
      dropdownProps={props.dropdownProps as any}
    />
  );
});

export default DropdownMultiNoSearch;
