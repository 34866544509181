import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '../../../../common/ui/Skeleton/Skeleton';
import { DataSheetItemEquipment } from '../../../../models/data-values/data-sheet-item-equipment';
import { equipmentService } from '../../../../services/equipment.service';
import { getLanguageId } from '../../../../utils/localStorage.utils';
import './index.scss';

export interface PropertiesRef {
  refresh(): Promise<void>;
}

interface IPropertiesProps {
  equipmentId: number;
  isLoading?: boolean;
  isAllowLoadData?: boolean;
  setPropertiesCount?: (count: number) => void;
}

export const Properties = forwardRef((props: IPropertiesProps, ref: ForwardedRef<PropertiesRef>) => {
  const [translate] = useTranslation();
  const [properties, setProperties] = useState<DataSheetItemEquipment[]>([]);
  const [loadedEquipmentId, setLoadedEquipmentId] = useState<number | null>(null);
  const { equipmentId, isLoading, isAllowLoadData, setPropertiesCount } = props;

  const fetchData = async () => {
    if (equipmentId) {
      const dataSheetItemEquipment = await equipmentService.getPropertiesById(equipmentId, getLanguageId(), false);
      setLoadedEquipmentId(equipmentId);
      if (setPropertiesCount) {
        setPropertiesCount(dataSheetItemEquipment.length);
      }
      setProperties(dataSheetItemEquipment);
    }
  };

  useImperativeHandle(
    ref,
    () =>
      ({
        refresh: fetchData,
      } as PropertiesRef)
  );

  useEffect(() => {
    if (equipmentId && isAllowLoadData && equipmentId !== loadedEquipmentId) {
      fetchData();
    }
  }, [equipmentId, isAllowLoadData, loadedEquipmentId]);

  return (
    <div className="section">
      {isLoading ? (
        <Skeleton numberElement={properties?.length > 0 ? properties?.length : 5} className="section-row" />
      ) : (
        <>
          {properties?.length > 0 && (
            <>
              {properties.map((property, index) => (
                <div className="section-row" key={index}>
                  <div className="caption">{property.caption}:</div>
                  <div className="field">{property?.value || ''}</div>
                </div>
              ))}
            </>
          )}

          {properties?.length === 0 && <div style={{ color: '#0000008a' }}>{translate('CommonResource.Nodata')}</div>}
        </>
      )}
    </div>
  );
});

Properties.displayName = 'Properties';
