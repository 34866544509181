import { createFilterFieldItem } from '../../../common/ui/FilterControls/FilterControls';
import { getCommonDateOptions } from '../../../common/ui/FilterControls/FilterOptions';
import { FilterControl } from '../../../enums/filter-control.enum';
import { ISearchField } from '../../../models/filter-controls/search-field.model';

export enum DocumentFilterFields {
  equipmentIdentifier,
  typeModel, //example: Bolth
  createdDate,
  orderNo,
  customerOrderNo,
  secondaryQRCode,
}

export const getDocumentFilterFields = () => {
  const fields: ISearchField[] = [
    createFilterFieldItem(DocumentFilterFields[DocumentFilterFields.equipmentIdentifier], FilterControl.TEXTFIELD, 'CaptionResource.ID'),
    createFilterFieldItem(DocumentFilterFields[DocumentFilterFields.secondaryQRCode], FilterControl.TEXTFIELD, 'Equipment.OnixID'),
    createFilterFieldItem(DocumentFilterFields[DocumentFilterFields.typeModel], FilterControl.TEXTFIELD, 'CaptionResource.TypeModel'),
    createFilterFieldItem(DocumentFilterFields[DocumentFilterFields.orderNo], FilterControl.TEXTFIELD, 'Equipment.OrderNo'),
    createFilterFieldItem(DocumentFilterFields[DocumentFilterFields.customerOrderNo], FilterControl.TEXTFIELD, 'Equipment.CustomerOrderNo'),
    createFilterFieldItem(
      DocumentFilterFields[DocumentFilterFields.createdDate],
      FilterControl.COMMONDATE,
      'Equipment.CreatedDate',
      getCommonDateOptions(),
      'Equipment.SelectPeriod'
    ),
  ];
  return fields;
};

export const searchFunctions: Record<string, (props: any) => any> = {};

export const renderTitleCustomFunctions: Record<string, (props: any) => any> = {};
