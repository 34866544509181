export enum OrganizationSizeEnum {
  SIZE_0_1 = 1,
  SIZE_2_10 = 2,
  SIZE_11_50 = 3,
  SIZE_51_200 = 4,
  SIZE_201_500 = 5,
  SIZE_501_1000 = 6,
  SIZE_OVER_1000 = 7,
}

export enum NumberEquipmentSizeEnum {
  SIZE_0_200 = 1,
  SIZE_200_500 = 2,
  SIZE_500_1000 = 3,
  SIZE_1000_5000 = 4,
  SIZE_OVER_5000 = 5,
}
