import { IDropdown, IDropdownOption, IDropdownStyles, IIconProps, ISelectableOption, IconButton, Text } from '@fluentui/react';
import { DivFlexColumn, DivFlexRow, StyledDatePicker } from '@onix/common/src/assets/layouts';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { color } from '../../../../../assets/styles/styles';
import { dateTimeFormat, getDatePickerStrings } from '../../../../../constants/dateTime.constant';
import { globalAppVariables } from '../../../../../constants/global-variables.constants';
import { DateRanges } from '../../../../../enums/date-ranges.enum';
import { DueDateClasses } from '../../../../../enums/due-date-classes.enum';
import { FieldFilter, FilterControlRef, ISearchFieldProps } from '../../../../../models/filter-controls/search-field.model';
import { formatDate, getFromToFromDateRange } from '../../../../../utils/dateTime';
import DropdownSelect from '../../../Dropdown/DropdownWithClear/DropdownSelect';
import './index.scss';

const DueDate = (props: ISearchFieldProps) => {
  const [translate] = useTranslation();
  const [dueClasses, setDueClasses] = useState<string[]>([]);
  const [period, setPeriod] = useState<Number>(globalAppVariables.IGNORE_VALUE_INT);
  const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
  const [toDate, setToDate] = useState<Date | undefined>(undefined);
  const [disabledPeriod, setDisabledPeriod] = useState(true);
  const [disabledDatePicker, setDisabledDatePicker] = useState(true);
  const [isOpenJobIntervalPanel, setOpenJobIntervalPanel] = useState(false);
  //const [jobIntervalItem, setJobIntervalItem] = useState<ConfigJobIntervalItem | undefined>(undefined);
  const dropdownRef = useRef<IDropdown>(null);

  const dueClassOptions = props.field.options.filter((item: any) => item?.type === 'class');
  const periodOptions = props.field.options.filter((item: any) => item?.type === 'period');

  const onChangePeriodDropdown = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
    if (item) {
      if (Number(item.key as string) === globalAppVariables.IGNORE_VALUE_INT) {
        onClearDueClass();
        return;
      }

      const dateRangeValue = Number(item.key as string);
      setPeriod(dateRangeValue);

      switch (dateRangeValue) {
        case DateRanges.Custom:
          setDisabledDatePicker(false);
          props.field.value = { ...props.field.value, period: dateRangeValue, from: undefined, to: undefined };
          setFromDate(undefined);
          setToDate(undefined);
          break;
        default:
          setDisabledDatePicker(true);
          const tuple = getFromToFromDateRange(dateRangeValue);
          const dateRangesToSetDefaultFromDate = [DateRanges.EndToday, DateRanges.OverdueXDays, DateRanges.MissingDueDate];
          const dateRangesToSetDefaultToDate = [DateRanges.StartFromToday, DateRanges.Good, DateRanges.MissingDueDate];
          const fromDateValue = dateRangesToSetDefaultFromDate.includes(dateRangeValue) ? undefined : tuple.from;
          const toDateValue = dateRangesToSetDefaultToDate.includes(dateRangeValue) ? undefined : tuple.to;
          setFromDate(fromDateValue);
          setToDate(toDateValue);

          props.field.value = {
            ...props.field.value,
            period: dateRangeValue,
            from: fromDateValue === undefined ? undefined : formatDate(fromDateValue, dateTimeFormat.momentDateDatabaseFormat),
            to: toDateValue === undefined ? undefined : formatDate(toDateValue, dateTimeFormat.momentDateDatabaseFormat),
          };
          break;
      }

      if (typeof props.onDataChanged === 'function') {
        setTimeout(props.onDataChanged, 100);
      }
    }
  };

  const onSelectFromDate = (date: any) => {
    if (date) {
      props.field.value = {
        ...props.field.value,
        from: date === undefined ? undefined : formatDate(date, dateTimeFormat.momentDateDatabaseFormat),
      };
      setFromDate(date);

      if (typeof props.onDataChanged === 'function') {
        setTimeout(props.onDataChanged, 100);
      }
    }
  };

  const onSelectToDate = (date: any) => {
    if (date) {
      props.field.value = {
        ...props.field.value,
        to: date === undefined ? undefined : formatDate(date, dateTimeFormat.momentDateDatabaseFormat),
      };
      setToDate(date);

      if (typeof props.onDataChanged === 'function') {
        setTimeout(props.onDataChanged, 100);
      }
    }
  };

  const getFilter = (): FieldFilter | undefined => {
    const value = props.field.value;
    if (value !== undefined) {
      const filter = new FieldFilter();
      filter.fieldName = props.field.key;
      filter.type = props.field.type;
      filter.fieldValue = JSON.stringify(value);
      return filter;
    }
    return undefined;
  };

  const periodDropdownStyle: Partial<IDropdownStyles> = {
    root: {
      marginTop: '10px',
    },
    dropdown: {
      marginTop: '10px',
    },
  };

  const onChangeDueClassDropdown = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
    if (item) {
      const newSelectedKeys = item.selected ? [...dueClasses, item.key as string] : dueClasses.filter((key) => key !== item.key);
      setDueClasses(newSelectedKeys);
      if (newSelectedKeys.length === 0) {
        onClearDueClass();
      } else {
        props.field.value = {
          ...props.field.value,
          dueClass: newSelectedKeys.join(','),
        };
        setDisabledPeriod(false);
      }
      //setJobIntervalItem(undefined);

      if (typeof props.onDataChanged === 'function') {
        setTimeout(props.onDataChanged, 100);
      }
    }
  };

  const onClearDueClass = () => {
    setDueClasses([]);
    props.field.value = undefined;
    setDisabledPeriod(true);
    setPeriod(globalAppVariables.IGNORE_VALUE_INT);
    setFromDate(undefined);
    setToDate(undefined);
    setDisabledDatePicker(true);
    //setJobIntervalItem(undefined);

    if (typeof props.onDataChanged === 'function') {
      setTimeout(props.onDataChanged, 100);
    }
  };

  const onClearPeriod = () => {
    setPeriod(globalAppVariables.IGNORE_VALUE_INT);
    setFromDate(undefined);
    setToDate(undefined);
    setDisabledDatePicker(true);
    props.field.value = { ...props.field.value, period: globalAppVariables.IGNORE_VALUE_INT, from: undefined, to: undefined };

    if (typeof props.onDataChanged === 'function') {
      setTimeout(props.onDataChanged, 100);
    }
  };

  useEffect(() => {
    const controlRef = new FilterControlRef();
    controlRef.onClearData = onClearDueClass;
    controlRef.getFilter = getFilter;
    props.field.controlRef = controlRef;
  }, []);

  useEffect(() => {
    const valueProp = props.field.value;
    const isValidValueProp = valueProp !== null && valueProp !== undefined && valueProp !== '' && typeof valueProp === 'string';
    if (isValidValueProp) {
      const parseValue = JSON.parse(props.field.value);
      props.field.value = parseValue;
      // if (parseValue.hasOwnProperty('dueClass')) {
      //   const dueClassesValue = parseValue.dueClass.parseToArrayOfNumbers();
      //   setDueClasses(dueClassesValue);
      //   setDisabledPeriod(false);
      //   if (dueClassesValue.length === 1 && dueClassesValue[0] == DueDateClasses.Custom) {
      //     const interval: ConfigJobIntervalItem = { jobClass: parseValue.jobClass, unit: parseValue.unit, interval: parseValue.interval };
      //     setJobIntervalItem(interval);
      //   }
      // }

      if (parseValue.hasOwnProperty('period')) {
        setPeriod(parseValue.period);
      }

      if (parseValue.period) {
        if (parseValue.period === DateRanges.Custom) {
          setDisabledDatePicker(false);

          if (parseValue.hasOwnProperty('from')) {
            setFromDate(parseValue.from ? new Date(parseValue.from) : undefined);
          }

          if (parseValue.hasOwnProperty('to')) {
            setToDate(parseValue.to ? new Date(parseValue.to) : undefined);
          }
        } else if (parseValue.period === DateRanges.NoDate) {
          setDisabledDatePicker(true);
          setFromDate(undefined);
          setToDate(undefined);
        } else {
          setDisabledDatePicker(true);

          const tuple = getFromToFromDateRange(parseValue.period);
          const dateRangesToSetDefaultFromDate = [DateRanges.EndToday, DateRanges.OverdueXDays, DateRanges.MissingDueDate];
          const dateRangesToSetDefaultToDate = [DateRanges.StartFromToday, DateRanges.Good, DateRanges.MissingDueDate];
          const fromDateValue = dateRangesToSetDefaultFromDate.includes(parseValue.period) ? undefined : tuple.from;
          const toDateValue = dateRangesToSetDefaultToDate.includes(parseValue.period) ? undefined : tuple.to;
          setFromDate(fromDateValue);
          setToDate(toDateValue);

          parseValue.from = fromDateValue === undefined ? undefined : formatDate(fromDateValue, dateTimeFormat.momentDateDatabaseFormat);
          parseValue.to = toDateValue === undefined ? undefined : formatDate(toDateValue, dateTimeFormat.momentDateDatabaseFormat);
        }
      } else {
        setDisabledDatePicker(true);
        setFromDate(undefined);
        setToDate(undefined);
      }
    }
  }, [props.field.value]);

  // const onDismissPanelJobInterval = (item?: ConfigJobIntervalItem) => {
  //   if (item) {
  //     setJobIntervalItem(item);
  //     setDueClasses([DueDateClasses.Custom as unknown as string]);
  //     setDisabledPeriod(false);
  //     props.field.value = { ...props.field.value, dueClass: DueDateClasses.Custom.toString(), ...item };
  //   }
  //   setOpenJobIntervalPanel(false);
  // };
  const onRenderItemCustom = useCallback((value: ISelectableOption, defaultRender: any) => {
    if (value.key == DueDateClasses.Custom) {
      return (
        <div
          style={{ padding: 10, cursor: 'pointer', borderTop: `1px solid ${color.$grey30}` }}
          onClick={() => {
            dropdownRef.current?.dismissMenu();
            setOpenJobIntervalPanel(true);
          }}
        >
          <Text style={{ color: color.$black }}>{translate('Equipment.dpRangeCustom')}</Text>
        </div>
      );
    }
    return defaultRender(value);
  }, []);
  const onRenderTitleCustom = (options: IDropdownOption[]) => {
    // if (jobIntervalItem) {
    //   const text = EquipmentHelper.getJobIntervalText(jobIntervalItem.jobClass, jobIntervalItem.unit, jobIntervalItem.interval);
    //   return <span>{text}</span>;
    // }
    return (
      <span>
        {options
          .filter((a) => a.key != DueDateClasses.Custom)
          .map((prop: any) => prop.text)
          .join(', ')}
      </span>
    );
  };

  //#region Datetime clearing button
  const defaultClearIcon = useMemo((): IIconProps => {
    return {
      iconName: 'Cancel',
      style: { cursor: 'pointer', fontSize: 14, color: color.$grey110 },
    };
  }, []);

  const fromDateId = 'dpFromDate';
  const toDateId = 'dpToDate';

  const clearDateValue = (name: string) => {
    switch (name) {
      case `${fromDateId}-label`:
        setFromDate(undefined);
        break;
      case `${toDateId}-label`:
        setToDate(undefined);
        break;
    }
  };

  const renderSuffixDate = (item: any) => {
    return (
      <IconButton
        className="btn-clear-text btn-clear-color"
        style={{ cursor: 'pointer', backgroundColor: color.$white, height: '100%' }}
        iconProps={defaultClearIcon}
        onClick={() => {
          clearDateValue(item.id);
        }}
      />
    );
  };

  const getShowMultipleDate = (dueClasses: string[], period: Number) => {
    if (period != DateRanges.CloseToDue && period != DateRanges.Good) {
      return false;
    }

    const dueClassNumberValues = dueClasses.map((item) => Number(item));
    const periodsToCheck = [DueDateClasses.Custom, DueDateClasses.DueMandatory, DueDateClasses.DuePreventive, DueDateClasses.DueOperator];
    const enabledMultipleDate = dueClassNumberValues.some((item) => periodsToCheck.includes(item));
    return enabledMultipleDate;
  };

  const enabledMultipleDate = getShowMultipleDate(dueClasses, period);

  //#endregion

  return (
    <div className="due-date-control">
      <DropdownSelect
        ref={dropdownRef}
        multiSelect={true}
        placeholder={translate('Settings.SelectValueDropdown')}
        label={translate(props.field.label)}
        disabled={props.field.disabled}
        selectedKeys={dueClasses}
        onChange={onChangeDueClassDropdown}
        onClearSelectedKeys={onClearDueClass}
        options={dueClassOptions}
        onRenderItem={onRenderItemCustom}
        onRenderTitleCustom={onRenderTitleCustom}
      />
      {/* {isOpenJobIntervalPanel && (
        <EditJobIntervalPanel
          item={
            jobIntervalItem ?? {
              jobClass: JobClasses.LegalInspection,
              unit: UnitOfMeasure.Month,
              interval: 0,
            }
          }
          onDismiss={onDismissPanelJobInterval}
        />
      )} */}
      <div className="mt-[10px]">
        <DropdownSelect
          multiSelect={false}
          placeholder={translate('Equipment.SelectPeriod')}
          selectedKey={period}
          onChange={onChangePeriodDropdown}
          onClearSelectedKeys={onClearPeriod}
          options={periodOptions}
          styles={periodDropdownStyle}
          disabled={props.field.disabled || disabledPeriod}
        />
      </div>
      <DivFlexRow style={{ marginTop: '10px', justifyContent: 'space-between' }}>
        <DivFlexColumn style={{ width: '48%' }}>
          <StyledDatePicker
            value={enabledMultipleDate ? undefined : fromDate}
            placeholder={enabledMultipleDate ? translate('CaptionResource.Multiple') : translate('CaptionResource.From')}
            strings={getDatePickerStrings()}
            formatDate={formatDate}
            disabled={enabledMultipleDate ? true : props.field.disabled || disabledDatePicker}
            onSelectDate={onSelectFromDate}
            maxDate={toDate}
            firstDayOfWeek={dateTimeFormat.firstDayOfWeek}
            id={fromDateId}
            textField={fromDate && !(props.field.disabled || disabledDatePicker) ? { onRenderSuffix: renderSuffixDate } : {}}
            className="due-date-picker"
          />
        </DivFlexColumn>
        <DivFlexColumn style={{ width: '48%' }}>
          <StyledDatePicker
            value={enabledMultipleDate ? undefined : toDate}
            placeholder={enabledMultipleDate ? translate('CaptionResource.Multiple') : translate('CaptionResource.To')}
            strings={getDatePickerStrings()}
            formatDate={formatDate}
            disabled={enabledMultipleDate || props.field.disabled || disabledDatePicker}
            onSelectDate={onSelectToDate}
            minDate={fromDate}
            firstDayOfWeek={dateTimeFormat.firstDayOfWeek}
            id={toDateId}
            textField={toDate && !(props.field.disabled || disabledDatePicker) ? { onRenderSuffix: renderSuffixDate } : {}}
            className="due-date-picker"
          />
        </DivFlexColumn>
      </DivFlexRow>
    </div>
  );
};

export default DueDate;
