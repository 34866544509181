export const useDismissPanel = (dismissHandler?: (ev?: any) => void, ignoreHandler?: () => void): (() => void) => {
  const dismissPanel = (ev?: any) => {
    if (ev?.nativeEvent?.srcElement) {
      const srcElement = ev.nativeEvent.srcElement as Element;
      if (
        srcElement?.className.indexOf('ms-Panel-closeButton') === -1 &&
        srcElement?.className.indexOf('ms-Button-flexContainer') === -1 &&
        srcElement?.className.indexOf('ms-Button-icon') === -1 &&
        srcElement?.className.indexOf('close-button') === -1
      ) {
        if (ignoreHandler) {
          ignoreHandler();
        }
        return;
      }
      if (dismissHandler) {
        dismissHandler(ev);
      }
    }
  };
  return dismissPanel;
};
