import { ECElementEvent, ECharts } from 'echarts';
import { RefCallback, useEffect, useRef, useState } from 'react';
import { WidgetConstants } from '../../constants/dashboard.constants';
import { DashboardHelper } from '../../helpers/dashboard-helper';
import { ECOption } from '../../models/dashboard/echart-data.model';

export interface UseEchartsProps {
  onSeriesClick?: (event: ECElementEvent) => void;
  chartOptions?: ECOption;
  maximumLegendWidth?: number;
}

export interface UseEchartsReturn {
  chartElementRef: RefCallback<HTMLElement>;
  chartElement: HTMLElement | null;
  chartInstance: ECharts | null;
  redrawChart(): void;
}

export const useEcharts = (props: UseEchartsProps): UseEchartsReturn => {
  const { onSeriesClick, chartOptions, maximumLegendWidth } = props;

  const [chartElement, setChartElement] = useState<HTMLElement | null>(null);
  const echartInstance = useRef<ECharts | null>(null);

  const clearAndDrawChart = () => {
    const chartInst = echartInstance.current;
    if (!chartInst || chartInst.isDisposed()) {
      return;
    }

    chartInst.clear();

    if (!chartOptions) {
      return;
    }

    const modifiedChartOptions = { ...chartOptions, animation: false };

    if (maximumLegendWidth && !Array.isArray(modifiedChartOptions.legend)) {
      modifiedChartOptions.legend = {
        ...modifiedChartOptions.legend,
        textStyle: {
          ...modifiedChartOptions.legend?.textStyle,
          width: maximumLegendWidth,
        },
      };
    }

    chartInst.setOption(modifiedChartOptions);
  };

  const initChart = () => {
    if (!chartElement) {
      return;
    }

    const chartInst = DashboardHelper.initEcharts(chartElement);

    chartInst.on('click', (event: ECElementEvent) => {
      event.event?.stop();

      if (event.componentType === WidgetConstants.componentType.series) {
        onSeriesClick?.(event);
        return;
      }
    });

    echartInstance.current = chartInst;

    return () => {
      chartInst.off('click');
      DashboardHelper.disposeEcharts(chartInst);
    };
  };

  const disposeChart = () => {
    const chartInst = echartInstance.current;
    if (!chartInst) {
      return;
    }

    chartInst.off('click');
    DashboardHelper.disposeEcharts(chartInst);
  };

  // Redraw chart when options change.
  useEffect(() => {
    initChart();
    clearAndDrawChart();

    return disposeChart;
  }, [chartElement, chartOptions, maximumLegendWidth]);

  const redrawChart = () => {
    clearAndDrawChart();
  };

  return {
    chartElementRef: setChartElement,
    chartElement,
    chartInstance: echartInstance.current,
    redrawChart,
  };
};
