import { ApiConstants } from '../constants/api.constants';
import { DataSheetItemEquipment } from '../models/data-values/data-sheet-item-equipment';
import { ControlCategoriesSearchResultItem, EquipmentListItem } from '../models/equipment.model';
import { EquipmentImage } from '../models/equipmentDetail/equipment-image.model';
import { Equipment } from '../models/equipmentDetail/equipment.model';
import { MaintenancePlan } from '../models/equipmentDetail/job-intervals/job-interval.model';
import { ExportXlsxDataRequest } from '../models/export-xlsx-data-request.model';
import { GroupPagination } from '../models/group-pagination.model';
import { Pagination } from '../models/pagination.model';
import { getLanguageId } from '../utils/localStorage.utils';
import { getAsync, postAsync } from './base.service';

//#region equipment details
const getEquipmentDetailById = (id: number, languageId: number, isLoading: boolean): Promise<Equipment> => {
  const url = ApiConstants.equipment.getEquipmentDetailById(id, languageId);
  return getAsync(url, {}, isLoading);
};
//#endregion

const getEquipments = (payload: any): Promise<Pagination<EquipmentListItem>> => {
  return postAsync(ApiConstants.equipment.getEquipments(), payload);
};

const getGroupEquipments = (payload: any): Promise<GroupPagination<EquipmentListItem>> => {
  return postAsync(ApiConstants.equipment.getGroupEquipments(), payload);
};

//#region properties
const getPropertiesById = (id: number, languageId: number, isLoading = true): Promise<DataSheetItemEquipment[]> => {
  return getAsync(`${ApiConstants.equipment.getProperties(id, languageId)}`, {}, isLoading);
};
//#endregion

//#region equipment image
const getEquipmentImage = (id: string): Promise<EquipmentImage> => {
  return getAsync(`${ApiConstants.equipment.getThumbnailImageFileByImageContentId(id)}`, {});
};
//#endregion

//#region job interval list
const getJobInterValList = (id: number, languageId: number, isLoading = true): Promise<MaintenancePlan[]> => {
  return getAsync(`${ApiConstants.equipment.getJobInterValList(id, languageId)}`, {}, isLoading);
};
//#endregion job interval list

const searchControlCategoriesByText = (
  text: string = '',
  isLoading = true,
  excludeNA = false
): Promise<ControlCategoriesSearchResultItem[]> => {
  const currentLanguage = getLanguageId();
  return getAsync(ApiConstants.equipment.searchControlCategoriesByText(text, currentLanguage, excludeNA), {}, isLoading);
};

const searchLocationsByText = (companyId: number, text?: string) => {
  return getAsync(ApiConstants.equipment.searchLocationsByText(companyId, text));
};

const getPagedEquipmentPictures = (data: any): Promise<any> => {
  return postAsync(ApiConstants.equipment.getPagedEquipmentPictures(), data);
};

const getEquipmentInfoById = (data: any): Promise<any> => {
  return getAsync(ApiConstants.equipment.getEquipmentInfoById(data), {}, false);
};

const createExportRequestAsync = (request: ExportXlsxDataRequest): Promise<number> => {
  return postAsync(ApiConstants.equipment.createExportMessage(), request, false);
};

const checkStatusExportingAsync = (taskQueueId: number): Promise<number> => {
  return getAsync(ApiConstants.equipment.checkStatusExporting(taskQueueId), undefined, false);
};

export const equipmentService = {
  getEquipmentDetailById,
  getPropertiesById,
  getEquipments,
  getGroupEquipments,
  searchControlCategoriesByText,
  searchLocationsByText,
  getPagedEquipmentPictures,
  getEquipmentImage,
  getEquipmentInfoById,
  getJobInterValList,
  createExportRequestAsync,
  checkStatusExportingAsync,
};
