import { useSelector } from 'react-redux';
import { modalSelector } from '../../../states/modal/modalSlice';
import ConfirmModal from './ConfirmModal';
import DiscardChangesModal from './DiscardChangesModal';
import MessageBoxModal from './MessageBoxModal';

const ModalContainer = () => {
  const { modals } = useSelector(modalSelector);

  return (
    <>
      {modals.map((modal) => (
        <div key={`base-mo-${modal.id}`}>
          {modal.type === 'discard' && <DiscardChangesModal {...modal} {...modal.props} />}
          {modal.type === 'box' && <MessageBoxModal {...modal} {...modal.props} />}
          {modal.type === 'confirm' && <ConfirmModal {...modal} {...modal.props} />}
        </div>
      ))}
    </>
  );
};

export default ModalContainer;
