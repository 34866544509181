import { LanguageHelper } from '@onix/common';
import { SettingKeyConstants } from '../constants/setting-keys.constants';
import { AuthenTypes } from '../enums/authen.enum';
import { CompanyExpressContextInfo } from '../models/company-express-context.model';
import { generateLocalKeyByName } from '../services/common.service';
import { LanguageShort } from '../types/language.type';
import { isNotNullOrUndefined, isStringNotNullOrUndefined } from './common.utils';

const setLocalStorageItem = (key: string, value: string | null | undefined) =>
  localStorage.setItem(generateLocalKeyByName(key), value ?? '');

const getLocalStorageItem = (key: string) => localStorage.getItem(generateLocalKeyByName(key));

const removeLocalStorageItem = (key: string) => localStorage.removeItem(generateLocalKeyByName(key));

const getAuthenticatedProfile = () => {
  const profileLocal = getLocalStorageItem(SettingKeyConstants.AuthenticatedUserInfo);
  if (!isStringNotNullOrUndefined(profileLocal)) return {} as CompanyExpressContextInfo;
  return JSON.parse(profileLocal!) as CompanyExpressContextInfo;
};

const getLoggedAlias = () => {
  const profile = getAuthenticatedProfile();
  return isNotNullOrUndefined(profile) ? profile?.companyExpressSetup?.friendlyAlias : undefined;
};

const getLoggedSupplierName = () => {
  const profile = getAuthenticatedProfile();
  return isNotNullOrUndefined(profile) ? profile?.companyExpressSetup?.companyName : '';
};

const getLoggedAccountId = () => {
  const profile = getAuthenticatedProfile();
  return isNotNullOrUndefined(profile) ? profile?.contact.userAccountId : undefined;
};

const getLoggedContactId = () => {
  const profile = getAuthenticatedProfile();
  return isNotNullOrUndefined(profile) ? profile?.contact.contactId : undefined;
};

const getLoggedCompanyId = () => {
  const profile = getAuthenticatedProfile();
  return isNotNullOrUndefined(profile) ? profile?.companyExpressSetup?.companyId : undefined;
};

const getAuthenTypes = () => {
  const authenTypesString = localStorage.getItem(generateLocalKeyByName(SettingKeyConstants.AuthenTypes));
  if (authenTypesString) {
    const authenTypes = parseInt(authenTypesString);
    if (isNaN(authenTypes) || !(authenTypes in AuthenTypes)) {
      return null;
    }
    return authenTypes as AuthenTypes;
  }
  return null;
};

const getLoggedUidAndUpdExpertParamUrl = () => {
  let path = '';
  const udpExpert = getLocalStorageItem(SettingKeyConstants.InputUdpExpert);
  const uid = getLocalStorageItem(SettingKeyConstants.InputUid);
  if (uid) {
    path += `uid=${uid}`;
    if (udpExpert) path += `&udpexpert=${udpExpert}`;
  }

  return path;
};

const getLoggedExpressId = () => {
  const profile = getAuthenticatedProfile();
  return isNotNullOrUndefined(profile) ? profile?.expressId : undefined;
};

const getLanguageId = () => {
  const lang = getLocalStorageItem(SettingKeyConstants.Language);
  return LanguageHelper.getLanguageEnum(lang as LanguageShort);
};

const getLanguage = () => {
  const langId = LanguageHelper.getLanguageEnum(getLocalStorageItem(SettingKeyConstants.Language) as LanguageShort);
  return LanguageHelper.getLanguageCode(langId);
};

const setNumberExpress = (value: number) => {
  const profileLocal = getAuthenticatedProfile();
  if (profileLocal) {
    profileLocal.numberExpress = value;
    setLocalStorageItem(SettingKeyConstants.AuthenticatedUserInfo, JSON.stringify(profileLocal));
  }
};

export {
  getAuthenTypes,
  getAuthenticatedProfile,
  getLanguage,
  getLanguageId,
  getLocalStorageItem,
  getLoggedAccountId,
  getLoggedAlias,
  getLoggedCompanyId,
  getLoggedContactId,
  getLoggedExpressId,
  getLoggedSupplierName,
  getLoggedUidAndUpdExpertParamUrl,
  removeLocalStorageItem,
  setLocalStorageItem,
  setNumberExpress,
};
