import { configureStore } from '@reduxjs/toolkit';
import { appReducer } from './app/appSlice';
import { dashboardReducer } from './dashboard/dashboardSlice';
import { documentReducer } from './document/documentSlice';
import { equipmentDocumentReducer } from './equipment/equipmentDocumentSlice';
import { equipmentFilterReducer } from './equipment/equipmentFilterSlice';
import { equipmentReducer } from './equipment/equipmentSlice';
import { modalReducer } from './modal/modalSlice';

export type RootState = ReturnType<typeof store.getState>;

export const store = configureStore({
  reducer: {
    app: appReducer,
    document: documentReducer,
    equipment: equipmentReducer,
    equipmentFilter: equipmentFilterReducer,
    equipmentDocument: equipmentDocumentReducer,
    modal: modalReducer,
    dashboard: dashboardReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const appDispatch = store.dispatch;
