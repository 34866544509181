import { ITag } from '@fluentui/react';
import { EquipmentTagTypeOrder, EquipmentTagTypeToElement } from '../constants/equipment-tag.constants';
import { EquipmentTagType } from '../enums/equipment-tag.enum';

export const EquipmentTagHelper = {
  parseTagListFromComaSeparatedString(tagList: string | undefined) {
    return (tagList ?? '')
      .split(',')
      .map((tag) => Number(tag.trim()))
      .filter((tag) => !Number.isNaN(tag)) as EquipmentTagType[];
  },

  sortTagList(tagList: EquipmentTagType[] | undefined) {
    return tagList?.length ? EquipmentTagTypeOrder.filter((tag) => tagList.includes(tag)) : [];
  },

  getTagListIcon(tagList: EquipmentTagType[] | undefined) {
    const sortedTagList = this.sortTagList(tagList);
    return sortedTagList.map((tag) => EquipmentTagTypeToElement[tag].icon);
  },

  getTagListLabel(tagList: EquipmentTagType[] | undefined, translate: (key: string) => string, ignoreSort = false) {
    const sortedTagList = !ignoreSort ? this.sortTagList(tagList) : tagList ?? [];
    return sortedTagList.map((tag) => EquipmentTagTypeToElement[tag]).map((tag) => tag.text ?? translate(tag.label));
  },

  getAllTagListForTagPicker(translate: (key: string) => string): ITag[] {
    return EquipmentTagTypeOrder.map(
      (tag) =>
        ({
          key: tag,
          name: EquipmentTagTypeToElement[tag].text ?? translate(EquipmentTagTypeToElement[tag].label ?? ''),
        } as ITag)
    );
  },
};
