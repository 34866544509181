import {
  ColumnActionsMode,
  ContextualMenu,
  DefaultButton,
  DetailsList,
  IColumn,
  PanelType,
  Selection,
  SelectionMode,
} from '@fluentui/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTable } from '../../common/hooks/useTable';
import { useTracking } from '../../common/hooks/useTracking';
import ButtonIcon from '../../common/ui/Button/ButtonIcon';
import CommandBar from '../../common/ui/CommandBar/CommandBar';
import { OnixTooltip } from '../../common/ui/OnixTooltip/OnixTooltip';
import { PanelWithScrollableBody } from '../../common/ui/PanelWithScrollableBody/PanelWithScrollableBody';
import Title from '../../common/ui/Title/Title';
import { COLUMN_SIZE, MaximumInvitationUser } from '../../constants/common.constants';
import { Modules } from '../../constants/modules.constants';
import { TrackingEvent } from '../../constants/tracking-event.constant';
import { ColleagueItem } from '../../models/colleague.model';
import { CompanyExpressContext } from '../../pages/Layout/MainLayout';
import { CompanyService } from '../../services/company.service';
import { showConfirm, showMessageBox } from '../../services/modal.service';
import { ModalProps } from '../../types/modal.type';
import AddNewUser from '../AddNewUser/AddNewUser';

type Props = ModalProps;

const columnsKey = {
  firstName: 'FirstName',
  lastName: 'LastName',
  email: 'Email',
  type: 'Type',
};

const InviteColleagues = ({ isOpen, onDismiss }: Props) => {
  const [translate] = useTranslation();
  const { trackEvent } = useTracking({ module: Modules.InviteColleaguesPanel });

  const { headerColumnClick, sortedColumn, contextualMenuProps } = useTable();

  const onRenderHeader = useCallback((props?: any, defaultRender?: (props?: any) => JSX.Element | null): JSX.Element | null => {
    return <OnixTooltip content={props?.column?.name}>{defaultRender!(props) ?? <></>}</OnixTooltip>;
  }, []);

  const onRenderColumn = useCallback((item?: any, index?: number, column?: IColumn) => {
    return <OnixTooltip content={item?.[column?.fieldName as string]}>{item?.[column?.fieldName as string]}</OnixTooltip>;
  }, []);

  const collums: IColumn[] = [
    {
      key: columnsKey.firstName,
      name: translate('CommonResource.FirstName'),
      fieldName: 'firstName',
      minWidth: COLUMN_SIZE.SMALL,
      maxWidth: COLUMN_SIZE.SMALL,
      className: 'has-tooltip',
      headerClassName: 'has-tooltip',
      isResizable: true,
      isSorted: sortedColumn?.columnName === columnsKey.firstName,
      isSortedDescending: sortedColumn?.columnName === columnsKey.firstName && sortedColumn.isDescending,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      onRenderHeader: onRenderHeader,
      onRender: onRenderColumn,
      onColumnClick: headerColumnClick,
    },
    {
      key: columnsKey.lastName,
      name: translate('CommonResource.LastName'),
      fieldName: 'lastName',
      minWidth: COLUMN_SIZE.SMALL,
      maxWidth: COLUMN_SIZE.SMALL,
      className: 'has-tooltip',
      headerClassName: 'has-tooltip',
      isResizable: true,
      isSorted: sortedColumn?.columnName === columnsKey.lastName,
      isSortedDescending: sortedColumn?.columnName === columnsKey.lastName && sortedColumn.isDescending,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      onRenderHeader: onRenderHeader,
      onRender: onRenderColumn,
      onColumnClick: headerColumnClick,
    },
    {
      key: columnsKey.email,
      name: translate('CaptionResource.Email'),
      fieldName: 'email',
      minWidth: COLUMN_SIZE.SMALL,
      maxWidth: COLUMN_SIZE.SMALL,
      className: 'has-tooltip',
      headerClassName: 'has-tooltip',
      isResizable: true,
      isSorted: sortedColumn?.columnName === columnsKey.email,
      isSortedDescending: sortedColumn?.columnName === columnsKey.email && sortedColumn.isDescending,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      onRenderHeader: onRenderHeader,
      onRender: onRenderColumn,
      onColumnClick: headerColumnClick,
    },
    {
      key: columnsKey.type,
      name: translate('CaptionResource.Caption5'),
      fieldName: 'isUser',
      minWidth: COLUMN_SIZE.MINI,
      maxWidth: COLUMN_SIZE.MINI,
      className: 'has-tooltip',
      headerClassName: 'has-tooltip',
      isResizable: true,
      isSorted: sortedColumn?.columnName === columnsKey.type,
      isSortedDescending: sortedColumn?.columnName === columnsKey.type && sortedColumn.isDescending,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      onRenderHeader: onRenderHeader,
      onRender: (item: ColleagueItem) => {
        const text = item.isUser ? translate('CaptionResource.User') : '-';
        return <OnixTooltip content={text}>{text}</OnixTooltip>;
      },
      onColumnClick: headerColumnClick,
    },
  ];

  const [selectedUser, setSelectedUser] = useState<any>();

  const [selection] = useState(
    new Selection({
      getKey: (item: any) => item.id,
      onSelectionChanged: () => {
        setSelectedUser(selection.getSelection()[0]);
      },
    })
  );

  const [openNewUser, setOpenNewUser] = useState(false);
  const [colleagues, setColleagues] = useState<ColleagueItem[]>([]);

  const { info } = useContext(CompanyExpressContext);
  const { companyExpressSetup, expressId } = info;

  const remainingUser = MaximumInvitationUser - colleagues?.filter((x) => x.isUser).length;

  const handleInviteContact = () => {
    if (remainingUser <= 0) {
      showMessageBox({
        header: <Title text={translate('CommonResource.UserLimitReached')} />,
        children: translate('CommonResource.cannotPerformActionWhenHitMaxExpressUser').replace('{0}', MaximumInvitationUser.toString()),
      });
      return;
    }

    showConfirm({
      children: translate('RibbonMenu.MsgConfirmInvitation'),
      onAccept: () => {
        if (selectedUser) {
          const { contactId } = selectedUser;
          CompanyService.inviteExpressUser(contactId).then((result) => {
            if (result && result?.isSuccess) {
              const newColleagues = colleagues.map((item) =>
                item.contactId !== contactId
                  ? item
                  : {
                      ...item,
                      isUser: true,
                      userAccountId: result?.userAccountId,
                    }
              );

              setColleagues(newColleagues);
              trackEvent(TrackingEvent.InviteColleaguesPanel.InviteThisContactAction);
            }
          });
        }
      },
    });
  };

  const searchColleaguesList = useCallback(async () => {
    const result = await CompanyService.getContactList(expressId, companyExpressSetup?.companyId, sortedColumn);
    if (result) {
      setColleagues(result);
      selection.setAllSelected(false);
    }
  }, [sortedColumn, companyExpressSetup, expressId]);

  useEffect(() => {
    searchColleaguesList();
  }, [searchColleaguesList]);

  const haveMaxUserAndRemainUserText = translate('CommonResource.YouHaveMaxNumUsersAndRemainingNumUsers')
    .replace('{0}', MaximumInvitationUser.toString())
    .replace('{1}', remainingUser.toString());

  return (
    <PanelWithScrollableBody
      isOpen={isOpen}
      title={translate('Home.InviteColleagues')}
      content={`${translate('CommonResource.AddNewUserOrRegisteredContacts')} ${haveMaxUserAndRemainUserText}`}
      renderAdditionalHeader={() => (
        <div className="flex gap-2 mt-4 mb-2">
          <ButtonIcon
            icon="PeopleAdd"
            text={translate('CommonResource.AddNewUser')}
            onClick={() => {
              trackEvent(TrackingEvent.InviteColleaguesPanel.AddNewUserAction);
              setOpenNewUser(true);
            }}
            className="font-semibold"
          ></ButtonIcon>
          {selectedUser && !selectedUser?.isUser && (
            <CommandBar
              items={[
                {
                  key: 'inviteContact',
                  text: translate('CommonResource.InviteThisContact'),
                  iconProps: { iconName: 'AddFriend' },
                  onClick: () => handleInviteContact(),
                },
              ]}
              styles={{ root: { height: 38, padding: '0px 5px', minWidth: 300 } }}
            />
          )}
        </div>
      )}
      panelProps={{
        type: PanelType.medium,
      }}
      renderBody={() => (
        <>
          <div>
            <DetailsList
              items={colleagues}
              columns={collums}
              selection={selection}
              selectionMode={SelectionMode.single}
              setKey="invite-colleage-table"
              styles={{
                headerWrapper: {
                  position: 'sticky',
                  top: '0px',
                  zIndex: 2,
                },
                root: {
                  position: 'relative',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                },
              }}
              className="h-[calc(100vh-250px)]"
            />
            {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
          </div>
          {openNewUser && (
            <AddNewUser
              isOpen={openNewUser}
              colleagues={colleagues}
              onDismiss={() => setOpenNewUser(false)}
              onAfterAdd={(newItems) => {
                if (newItems) {
                  const newEmails = newItems?.map((x) => x.email);
                  setOpenNewUser(false);
                  const newColleagues = [...newItems, ...colleagues?.filter((x) => !newEmails.includes(x.email))];
                  setColleagues(newColleagues);
                }
              }}
            />
          )}
        </>
      )}
      renderFooter={() => <DefaultButton onClick={onDismiss} text={translate('CaptionResource.Caption54')} />}
      onDismiss={onDismiss}
    />
  );
};

export default InviteColleagues;
