import { ApiConstants } from '../constants/api.constants';
import { SaveEventAlert } from '../models/alert.model';
import { CapturedEventAlertByEmail } from '../models/captured-event-alert.model';
import { getAsync, postAsync } from './base.service';

const getEventAlertByEmail = async (companyOwner: number, email: string, languageId: number): Promise<CapturedEventAlertByEmail> => {
  return await getAsync(ApiConstants.capturedEventAlert.getEventAlertByEmail(companyOwner, email, languageId));
};

const getEventAlertByEmailPublic = async (companyOwner: number, param: string, languageId: number): Promise<CapturedEventAlertByEmail> => {
  return await getAsync(ApiConstants.capturedEventAlert.getEventAlertByEmailPublic(companyOwner, param, languageId));
};

const saveEventAlert = async (data: SaveEventAlert) => {
  return await postAsync(ApiConstants.capturedEventAlert.saveEventAlert(), data);
};

const saveEventAlertPublic = async (data: SaveEventAlert) => {
  return await postAsync(ApiConstants.capturedEventAlert.saveEventAlertPublic(), data);
};

export const CapturedEventAlertService = {
  getEventAlertByEmail,
  getEventAlertByEmailPublic,
  saveEventAlert,
  saveEventAlertPublic,
};
