import { FilterControl } from '../../../enums/filter-control.enum';
import { ISearchField } from '../../../models/filter-controls/search-field.model';
import CommonDate from './Date/CommonDate';
import DueDate from './Date/DueDate/DueDate';
import DropdownMultiNoSearch from './Dropdown/DropdownMultiNoSearch';
import MultipleWithSearchFilterField from './MultipleWithSearchFilterField/MultipleWithSearchFilterField';
import { NewOwnership } from './NewOwnership/NewOwnership';
import TextBox from './TextBox/TextBox';

export const renderControlFunctions: Record<number, React.FunctionComponent<any>> = {
  [FilterControl.TEXTFIELD]: TextBox,
  [FilterControl.COMMONDATE]: CommonDate,
  [FilterControl.DUEDATE]: DueDate,
  [FilterControl.DROPDOWN_MULTI_NOSEARCH]: DropdownMultiNoSearch,
  [FilterControl.DROPDOWN_MULTI_WITHSEARCH]: MultipleWithSearchFilterField,
  [FilterControl.OWNER_SHIP]: NewOwnership,
};

export const createFilterFieldItem = (
  key: string,
  control: FilterControl,
  label: string,
  options?: any,
  placeholder?: string
): ISearchField => {
  return {
    key: key,
    control: control,
    label: label,
    placeholder: placeholder,
    options: options,
    type: 0, // unknown to set value
  };
};
