import { Icon, IDropdown, IDropdownOption, IDropdownProps, Stack } from '@fluentui/react';
import React, { ForwardedRef, forwardRef, useImperativeHandle } from 'react';
import { colors } from '../../../../constants/common.constants';
import { globalAppVariables } from '../../../../constants/global-variables.constants';
import Dropdown from '../Dropdown';

export interface IDropdownSelectProps {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  styles?: {};
  options?: IDropdownOption<any>[];
  hideOptions?: boolean;
  multiSelect?: boolean;
  selectedKey?: any;
  selectedKeys?: any[];
  dropdownProps?: IDropdownProps;
  onClick?: () => void;
  onChange?: (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined) => void;
  onClearSelectedKeys?: () => void;
  onRenderTitleCustom?: (value: any) => JSX.Element;
  onRenderList?: (containerProps: any, defaultRender: any) => JSX.Element;
  onRenderItem?: (containerProps: any, defaultRender: any) => JSX.Element;
}

const iconStyle = {
  root: { color: colors.grey130, '&:hover': { fontWeight: 800 } },
};

const DropdownSelect = forwardRef((props: IDropdownSelectProps, ref: ForwardedRef<IDropdown>) => {
  const {
    label,
    placeholder,
    disabled = false,
    styles,
    options = [],
    hideOptions = false,
    multiSelect = false,
    selectedKey = globalAppVariables.IGNORE_VALUE_INT,
    selectedKeys,
    dropdownProps,
    onClick,
    onChange,
    onClearSelectedKeys,
    onRenderTitleCustom,
    onRenderList,
    onRenderItem,
  } = props;

  const onRenderCaretDown = (dropdownProps: IDropdownProps | undefined, defaultRender?: any): JSX.Element => {
    return (
      <Stack horizontal verticalAlign={'center'}>
        {isShowCancelIcon() && (
          <Icon
            iconName={'Cancel'}
            styles={iconStyle}
            onClick={(event) => {
              event.stopPropagation();
              if (onClearSelectedKeys) {
                onClearSelectedKeys();
              }
            }}
          />
        )}
        {isShowChevronDown() && (
          <Icon iconName={'ChevronDown'} styles={iconStyle} onClick={(event: any) => event.currentTarget.parentNode.onClick} />
        )}
      </Stack>
    );
  };

  const isShowCancelIcon = () => {
    const count = selectedKeys?.length ?? 0;

    return (
      ((multiSelect === true && count > 0) || (multiSelect === false && selectedKey !== globalAppVariables.IGNORE_VALUE_INT)) &&
      disabled === false
    );
  };

  const isShowChevronDown = () => {
    const count = selectedKeys?.length ?? 0;

    return (
      (multiSelect === true && count === 0) || (multiSelect === false && selectedKey === globalAppVariables.IGNORE_VALUE_INT) || disabled
    );
  };

  const onRenderTitleDefault = (value: any) => {
    return <span>{value.map((prop: any) => prop.text).join(', ')}</span>;
  };

  useImperativeHandle(ref, () => ref1.current ?? ({} as IDropdown), []);
  const ref1 = React.useRef<IDropdown>(null);

  return (
    <Dropdown
      componentRef={ref1}
      placeholder={placeholder}
      label={label}
      disabled={disabled}
      multiSelect={multiSelect}
      selectedKeys={selectedKeys}
      selectedKey={multiSelect === false ? selectedKey : undefined}
      onChange={onChange}
      onRenderCaretDown={onRenderCaretDown}
      options={options}
      styles={styles}
      onClick={(event) => {
        if (hideOptions) {
          event.preventDefault();
          if (onClick && disabled !== true) {
            onClick();
          }
        }
      }}
      onRenderTitle={(value) => {
        if (onRenderTitleCustom !== undefined) {
          return onRenderTitleCustom(value);
        }
        return onRenderTitleDefault(value);
      }}
      onRenderList={(containerProps: any, defaultRender: any) => {
        if (onRenderList !== undefined) {
          return onRenderList(containerProps, defaultRender);
        }
        return defaultRender(containerProps);
      }}
      onRenderItem={(containerProps: any, defaultRender: any) => {
        if (onRenderItem !== undefined) {
          return onRenderItem(containerProps, defaultRender);
        }
        return defaultRender(containerProps);
      }}
      {...dropdownProps}
    />
  );
});

export default DropdownSelect;
