import { IShimmerProps, Shimmer } from '@fluentui/react';

export interface ISkeleton extends IShimmerProps {
  numberElement?: number;
}

const DefaultNumber = 1;

export const Skeleton = (props: ISkeleton) => {
  const { numberElement, children, ...shimmerProps } = props;

  const number = numberElement ?? DefaultNumber;

  return (
    <>
      {shimmerProps.isDataLoaded ? (
        children ?? <></>
      ) : (
        <>
          {Array(number)
            .fill(0)
            .map((_, index) => (
              <Shimmer {...shimmerProps} key={index}></Shimmer>
            ))}
        </>
      )}
    </>
  );
};
