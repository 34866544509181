import {
  ColumnActionsMode,
  ConstrainMode,
  ContextualMenu,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IGroup,
  IGroupShowAllProps,
  Label,
  Link,
  ScrollablePane,
  SelectionMode,
} from '@fluentui/react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { FluentConstant } from '../../../constants/fluent.constants';
import { useInfinityScroll } from '../../hooks/useInfinityScroll';
import { useTable } from '../../hooks/useTable';
import {
  IOnixGroupPagination,
  IOnixGroupPaginationItem,
  IOnixTableImperative,
  IOnixTablePagination,
  IOnixTableRequest,
} from '../../ui/OnixTable/IOnixTable';
import { IOnixSplitTable } from './IOnixSplitTable';
import './index.scss';

const FirstPageNumber = 0;

export const OnixSplitTable = forwardRef((props: IOnixSplitTable, ref: React.Ref<IOnixTableImperative>) => {
  const [translate] = useTranslation();
  const [items, setItems] = useState<any[]>([]);

  const [groupItems, setGroupItems] = useState<IOnixGroupPaginationItem[]>([]);

  const { sortedColumn, groupColumnName, contextualMenuProps, groups, setGroups, renderDetailsHeader } = useTable({
    sortedColumn: props.sortableColumn ?? { columnName: '', isDescending: true },
    groupColumnKeys: props?.groupColumnKeys ?? [],
  });

  const [fetchMoreId, onScroll] = useInfinityScroll();
  const [paginationInfo, setPaginationInfo] = useState<{ pageNumber: number; pageSize: number; totalItems: number; isLastPage: boolean }>({
    pageNumber: 0,
    totalItems: 0,
    pageSize: 0,
    isLastPage: false,
  });

  const scrollablePanelId = useMemo(() => {
    return `scrollable-pane-${uuid().substring(0, 13)}`;
  }, []);

  useImperativeHandle(ref, () => {
    const result: IOnixTableImperative = {
      scrollToTop,
      selectItems,
      getAllItems,
      getItem,
      addNewItems,
      updateItems,
      removeItems,
    } as any;
    return result;
  });

  const selectItems = (keys: any[]) => {
    const currentMode = props.selection.mode;
    if (currentMode === SelectionMode.none || (currentMode === SelectionMode.single && keys.length > 1)) {
      return;
    }
    const selection = props.selection;
    selection.setAllSelected(false);

    keys.forEach((key) => {
      const currentItemIndex = selection.getItems().findIndex((m: any) => props.predicateToGetKey(m) === key);
      if (currentItemIndex > -1) {
        selection.setIndexSelected(currentItemIndex, true, true);
      }
    });
  };

  const scrollToTop = useCallback(() => {
    const scrollable = document.querySelector(`.${scrollablePanelId} ${FluentConstant.ScrollPaneContainer}`);
    if (scrollable) {
      scrollable.scrollTo({
        top: 0,
      });
    }
  }, [scrollablePanelId]);

  const getAllItems = useCallback(() => {
    return items.map((m) => Object.assign({}, m));
  }, [items]);

  const getItem = useCallback(
    (key: any) => {
      const itemByKey = items.find((m) => props.predicateToGetKey(m) === key);
      if (itemByKey) {
        return Object.assign({}, itemByKey);
      }
      return undefined;
    },
    [items]
  );

  const addNewItems = async (items: any[]) => {
    if (items.length === 0) {
      return;
    }
    if (groupColumnName) {
      await groupAddNewItems(items);
      return;
    }

    setItems((prevState) => items.concat(prevState));
    if (paginationInfo.isLastPage) {
      setPaginationInfo({
        ...paginationInfo,
        totalItems: paginationInfo.totalItems + items.length,
      });
    }
  };

  const updateItems = async (updatingItems: any[], isAutoSelect: boolean = false, isEditMultipleItems: boolean = false) => {
    if (updatingItems.length === 0) {
      return;
    }
    const keys = updatingItems.map((m) => props.predicateToGetKey(m));
    if (groupColumnName) {
      let smallestGroupIndex = undefined;
      if (isEditMultipleItems) {
        smallestGroupIndex = groupItems.findIndex((groupItem) => {
          const currentItemKeys = groupItem.items.map((item) => props.predicateToGetKey(item));
          return currentItemKeys.some((m) => keys.includes(m));
        });
      }
      await groupUpdateItems(updatingItems, smallestGroupIndex);
    } else {
      normalUpdateItems(updatingItems);
    }
    if (isAutoSelect) {
      setTimeout(() => {
        selectItems(keys);
      }, 200);
    }
  };

  const removeItems = (keys: any[]) => {
    if (keys.length > 0) {
      if (!groupColumnName) {
        setItems((prevState) => {
          const newState = prevState.filter((m) => !keys.includes(props.predicateToGetKey(m)));
          return newState;
        });
      } else {
        groupRemoveItems(keys);
      }
      setPaginationInfo({
        ...paginationInfo,
        totalItems: paginationInfo.totalItems - keys.length,
      });
      if (!props.disableUnselectAllOnSetItems) {
        props.selection.setAllSelected(false);
      }
    }
  };

  const groupAddNewItems = async (items: any[]) => {
    if (!groupColumnName || items.length === 0) {
      return;
    }
    // IMPORTANT Get previous state when execute multiple in the same time
    let newGroupItems: IOnixGroupPaginationItem[] = [];
    let newGroups: IGroup[] = [];

    await setGroupItems((prevState) => {
      if (prevState) {
        newGroupItems = [...prevState];
      }
      return prevState;
    });
    await setGroups((prevState) => {
      if (prevState) {
        newGroups = [...prevState];
      }
      return prevState;
    });

    items.forEach((item) => {
      if (props.onGetInfoToAddNewGroup) {
        // IMPORTANT: Delete rowNumber property, because related to loadedRowNumber to fetch more item inside group
        if (item?.rowNumber) {
          delete item.rowNumber;
        }
        const { groupName, groupValue } = props.onGetInfoToAddNewGroup(item, groupColumnName);

        const groupOfItem = newGroupItems.find((m) => m.groupValue === groupValue);
        if (groupOfItem) {
          groupOfItem.items.unshift(item);

          // Open current group
          const openingGroup = newGroups.find((m) => m.key === groupOfItem.groupValue);
          if (openingGroup) {
            openingGroup.isCollapsed = false;
          }
        } else {
          // Add new group and open this
          newGroups.unshift({
            key: groupValue,
            isCollapsed: false,
          } as IGroup);

          newGroupItems.unshift({
            groupName: getGroupName(groupName, groupOfItem),
            groupValue: groupValue,
            hasMoreItems: true,
            items: [item],
          } as IOnixGroupPaginationItem);
        }
      }
    });

    setGroups(newGroups);
    setGroupItems(newGroupItems);
  };

  const normalUpdateItems = (updatingItems: any[]) => {
    if (updatingItems.length > 0) {
      setItems((prevState) => {
        const newItems = [...prevState];

        updatingItems.forEach((updateItem) => {
          const currentItem = newItems.find((m) => props.predicateToGetKey(m) === props.predicateToGetKey(updateItem));
          if (currentItem) {
            Object.keys(updateItem).forEach((key) => {
              if (currentItem[key] !== undefined) {
                currentItem[key] = updateItem[key];
              }
            });
          }
        });

        return newItems;
      });
    }
  };

  const groupUpdateItems = async (updatingItems: any[], newGroupIndex?: number) => {
    if (updatingItems.length === 0) {
      return;
    }

    // IMPORTANT Get previous state when execute multiple in the same time
    let newGroupItems: IOnixGroupPaginationItem[] = [];
    let newGroups: IGroup[] = [];

    await setGroupItems((prevState) => {
      if (prevState) {
        newGroupItems = [...prevState];
      }
      return prevState;
    });
    await setGroups((prevState) => {
      if (prevState) {
        newGroups = [...prevState];
      }
      return prevState;
    });

    updatingItems.forEach((updatingItem) => {
      const oldItem = items.find((m) => props.predicateToGetKey(m) === props.predicateToGetKey(updatingItem));
      const newItem = Object.assign({}, oldItem);

      Object.keys(updatingItem).forEach((key) => {
        if (newItem[key] !== undefined) {
          newItem[key] = updatingItem[key];
        }
      });

      if (oldItem && newItem && props.onGetInfoToAddNewGroup) {
        const { groupValue: oldGroupValue } = props.onGetInfoToAddNewGroup(oldItem, groupColumnName ?? '');
        const { groupValue: newGroupValue, groupName: newGroupName } = props.onGetInfoToAddNewGroup(newItem, groupColumnName ?? '');
        if (oldGroupValue !== newGroupValue) {
          const groupItemWithOldGroupValue = newGroupItems?.find((m) => m.groupValue === oldGroupValue);
          // Remove current item in the group
          if (groupItemWithOldGroupValue) {
            groupItemWithOldGroupValue.items = groupItemWithOldGroupValue.items.filter(
              (m) => props.predicateToGetKey(m) !== props.predicateToGetKey(updatingItem)
            );
            // if (groupItemWithOldGroupValue.items.length === 0) {
            //   const index = newGroupItems.indexOf(groupItemWithOldGroupValue);
            //   if (index > -1) {
            //     newGroupItems.splice(index, 1);
            //   }
            // }
          }

          // IMPORTANT: Delete rowNumber property, because related to loadedRowNumber to fetch more item inside group
          if (newItem?.rowNumber) {
            delete newItem.rowNumber;
          }

          const groupItemWithNewGroupValue = newGroupItems?.find((m) => m.groupValue === newGroupValue);
          if (groupItemWithNewGroupValue) {
            groupItemWithNewGroupValue.items.unshift(newItem);

            const existedGroup = newGroups.find((m) => m.key === groupItemWithNewGroupValue.groupValue);
            if (existedGroup) {
              existedGroup.isCollapsed = false;
            }
          } else {
            // Create new group and locate to before current group
            let index = 0;
            if (newGroupIndex !== undefined) {
              index = newGroupIndex;
            } else {
              if (groupItemWithOldGroupValue) {
                const oldGroupIndex = groupItems.findIndex((m) => m.groupValue === oldGroupValue);
                if (oldGroupIndex > -1) {
                  index = oldGroupIndex;
                }
              }
            }

            const newGroupItem = {
              groupName: newGroupName,
              groupValue: newGroupValue,
              items: [newItem],
              hasMoreItems: true,
            } as IOnixGroupPaginationItem;
            newGroupItems.splice(index, 0, newGroupItem);
            newGroups.push({ key: newGroupValue, isCollapsed: false } as IGroup);
          }
        } else {
          // Just update item inside current group
          const currentGroup = newGroupItems.find((m) => m.groupValue === oldGroupValue);
          if (currentGroup) {
            const neededUpdateItemIndex = currentGroup.items.findIndex(
              (m) => props.predicateToGetKey(m) === props.predicateToGetKey(newItem)
            );
            if (neededUpdateItemIndex > -1) {
              currentGroup.items.splice(neededUpdateItemIndex, 1, newItem);
            }
          }
        }
      }
    });
    setGroups(newGroups);
    setGroupItems(newGroupItems);
  };

  const groupRemoveItems = async (keys: any[]) => {
    const keySet = new Set(keys);

    // IMPORTANT Get previous state when execute multiple in the same time
    let newGroupItems: IOnixGroupPaginationItem[] = [];
    await setGroupItems((prevState) => {
      if (prevState) {
        newGroupItems = [...prevState];
      }
      return prevState;
    });

    newGroupItems.forEach((groupItem) => {
      groupItem.items = groupItem.items.filter((m) => !keySet.has(props.predicateToGetKey(m)));
    });

    setGroupItems(newGroupItems);
  };

  //   const columns = useMemo(() => {
  //     return [] as IColumn[];
  //       let columns: IColumn[] = [];
  //       if (props.columns) {
  //         columns = props.columns.map((m) => {
  //           let maxWidth = m.maxWidth ?? 60;
  //           const isSorted = sortedColumn?.columnName === m.key;
  //           const isGrouped = groupColumnName && groupColumnName === m.key;
  //           if (m.isIconOnly) {
  //             if (isSorted) {
  //               maxWidth += 20;
  //             }
  //             if (isGrouped) {
  //               maxWidth += 20;
  //             }
  //             m.headerClassName = 'width' + maxWidth;
  //             m.className = 'width' + maxWidth;
  //           }
  //           return {
  //             ...m,
  //             minWidth: 40,
  //             maxWidth,
  //             isSorted,
  //             isSortedDescending: isSorted && sortedColumn.isDescending,
  //             isGrouped,
  //             onColumnClick: !props.disableColumnHeaderMenu ? headerColumnClick : undefined,
  //             onRenderHeader: m.onRenderHeader ?? onRenderHeader,
  //           } as IColumn;
  //         });
  //       }

  //       return [...columns.map((m) => Object.assign({}, m))];
  //   }, [props.disableColumnHeaderMenu, sortedColumn, groupColumnName]);

  const columns = [] as IColumn[];

  const totalItems = useMemo((): JSX.Element => {
    let totalItems = '';
    if (groupColumnName) {
      let groupCounter = 0;
      groupItems.forEach((groupItem) => (groupCounter += groupItem.items.length));

      const isLoadedAllGroups = paginationInfo.isLastPage && groupItems.every((m) => !m.hasMoreItems);
      totalItems = `${groupCounter}${isLoadedAllGroups ? '' : '+'}`;
    } else {
      totalItems = `${items.length}${items.length ? '+' : ''}`;
      if (paginationInfo.isLastPage) {
        totalItems = `${paginationInfo.totalItems}`;
      }
    }
    return (
      <div className="total-items">
        <span>{`${totalItems} ${translate('CommonResource.lblItem')}`}</span>
      </div>
    );
  }, [groupItems, items, paginationInfo, groupColumnName, translate]);

  const splitColumns = useMemo(() => {
    return [
      {
        key: 'SplitHeaderColumn',
        isSorted: false,
        isGrouped: false,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item?: any, index?: number | undefined, column?: IColumn | undefined) => {
          return <div className="onix-split-row">{props.onRenderRowContent(item, index, column)}</div>;
        },
        onRenderHeader: (propsHeader, defaultRender) => {
          return (
            <div className="onix-split-header">
              <div className="onix-split-header-content">{props.onRenderHeaderContent(propsHeader, defaultRender)}</div>
              {totalItems}
            </div>
          );
        },
      },
    ] as IColumn[];
  }, [totalItems, props.onRenderHeaderContent, props.onRenderRowContent]);

  const getGroupName = useCallback((groupName: string, groupedColumn?: IColumn): string => {
    if (!groupedColumn) {
      return groupName;
    }

    const groupedByColumn = `${(groupedColumn.name || '').replace('.', '')}`;

    let finalGroupName = groupName;
    if (groupName?.toString().trim() === '') {
      finalGroupName = `${translate('CaptionResource.Caption44')}`;
    }
    return `${groupedByColumn}: ${finalGroupName}`;
  }, []);

  useEffect(() => {
    const newGroups: IGroup[] = [];
    const newItems: any[] = [];
    const groupedColumn = columns.find((m) => m.key === groupColumnName);

    let startIndex = 0;
    groupItems.forEach((group) => {
      if (group.items.length > 0 || group.hasMoreItems) {
        const previousGroup = groups?.find((m) => m.key === group.groupValue);

        const groupName = getGroupName(group.groupName, groupedColumn);

        const groupMapping = {
          key: group.groupValue,
          name: groupName,
          level: 0,
          startIndex: startIndex,
          count: group.items.length,
          hasMoreData: group.hasMoreItems,
          data: group,
          isCollapsed: previousGroup?.isCollapsed ?? true,
        } as IGroup;
        newGroups.push(groupMapping);
        newItems.push(...group.items);
        startIndex += group.items.length;
      }
    });

    if (newGroups.length > 0) {
      setGroups(newGroups);
      setItems(newItems);
    } else {
      setGroups(undefined);
      if (groupColumnName) {
        setItems([]);
      }
    }
  }, [groupItems]);

  useEffect(() => {
    if (fetchMoreId !== undefined && !paginationInfo.isLastPage) {
      if (props?.onGetItems) {
        const pageNumber = paginationInfo.pageNumber + 1;
        const request = {
          sortedColumn,
          pageNumber: pageNumber,
          groupPageNumber: pageNumber,
          groupColumnName: groupColumnName,
        } as IOnixTableRequest;

        props.onGetItems(request).then((res: any) => {
          if (res) {
            if (!groupColumnName) {
              const onixTablePagination = Object.assign({}, res) as IOnixTablePagination;
              setPaginationInfo({ ...onixTablePagination });
              if (onixTablePagination.items) {
                setItems((prevState) => prevState.concat(onixTablePagination.items));
              }
            } else {
              const onixGroupPagination = Object.assign({}, res) as IOnixGroupPagination;

              setGroupItems((prevState) => {
                const loadedGroupValues = prevState.map((m) => m.groupValue);
                const newState = [...prevState];

                const addGroups = onixGroupPagination.groups.filter((m) => !loadedGroupValues.includes(m.groupValue));
                const mergeGroups = onixGroupPagination.groups.filter((m) => loadedGroupValues.includes(m.groupValue));

                mergeGroups.forEach((mergeGroup) => {
                  const currentGroup = newState.find((m) => m.groupValue === mergeGroup.groupValue);
                  if (currentGroup) {
                    const loadedGroupKeys = currentGroup.items.map((m) => props.predicateToGetKey(m));
                    const addItems = mergeGroup.items.filter((m) => !loadedGroupKeys.includes(props.predicateToGetKey(m)));
                    if (addItems.length > 0) {
                      currentGroup.items = currentGroup.items.concat(addItems);
                    }
                    currentGroup.hasMoreItems = mergeGroup.hasMoreItems;
                    currentGroup.groupNumber = mergeGroup.groupNumber;
                  }
                });

                return prevState.concat(addGroups);
              });
              setPaginationInfo({
                ...onixGroupPagination,
                isLastPage: !onixGroupPagination.hasMoreGroups,
              });
            }
          }
        });
      }
    }
  }, [fetchMoreId]);

  useEffect(() => {
    if (props.onSortableColumnChanges) {
      props.onSortableColumnChanges(sortedColumn);
    }
    if (props.onGetItems) {
      if (!props.disableUnselectAllOnSetItems) {
        props.selection.setAllSelected(false);
      }

      const request = {
        sortedColumn,
        pageNumber: FirstPageNumber,
        groupPageNumber: FirstPageNumber,
        groupColumnName: groupColumnName,
      } as IOnixTableRequest;
      props.onGetItems(request).then((res: any) => {
        if (res) {
          scrollToTop();
          if (!groupColumnName) {
            setGroupItems([]);
            setGroups([]);

            const onixTablePagination = Object.assign({}, res) as IOnixTablePagination;
            setPaginationInfo({
              ...onixTablePagination,
            });
            setItems(onixTablePagination.items || []);
          } else {
            const onixGroupPagination = Object.assign({}, res) as IOnixGroupPagination;
            setGroupItems(onixGroupPagination.groups);
            setPaginationInfo({
              isLastPage: !onixGroupPagination.hasMoreGroups,
              pageNumber: onixGroupPagination.pageNumber,
              pageSize: onixGroupPagination.pageSize,
              totalItems: onixGroupPagination.totalItems,
            });
          }
        }
      });
    }
  }, [sortedColumn, groupColumnName, props.dependencyArrayToGetItems, props.disableUnselectAllOnSetItems]);

  const groupFetchMoreItems = (group?: IGroup) => {
    if (props?.onGetItems && group) {
      const currentItems = (group.data?.items as any[]) ?? [];

      // IMPORTANT: Loaded row number ignore all items add or update recently (Not fetch from API, don't know row number)
      const loadedRowNumber = currentItems.filter((m: any) => m?.rowNumber !== undefined).length;

      const request = {
        isFetchInsideGroup: true,
        sortedColumn,
        groupColumnName: groupColumnName,
        groupValue: group?.data?.groupValue,
        loadedRowNumber,
      } as IOnixTableRequest;
      props.onGetItems(request).then((res: any) => {
        if (res) {
          const firstGroup = (res as IOnixGroupPagination).groups[0];
          if (firstGroup) {
            const loadedKeys = currentItems.map((m) => props.predicateToGetKey(m));
            const addItems = firstGroup.items.filter((m) => !loadedKeys.includes(props.predicateToGetKey(m)));
            setGroupItems((prevState) => {
              const groupChanges = prevState.find((m) => m.groupValue === firstGroup.groupValue);
              if (groupChanges) {
                if (addItems.length > 0) {
                  groupChanges.items = groupChanges.items.concat(addItems);
                }
                groupChanges.hasMoreItems = firstGroup.hasMoreItems;
              }
              return [...prevState];
            });
            return;
          }
          // Exception case for cannot load groups
          setGroupItems((prevState) => {
            const groupChanges = prevState.find((m) => m.groupValue === group?.data?.groupValue);
            if (groupChanges) {
              groupChanges.hasMoreItems = false;
            }
            return [...prevState];
          });
        }
      });
    }
  };

  const onRenderShowAll = (groupShowAllProps?: IGroupShowAllProps) => {
    if (!groupShowAllProps?.group?.isCollapsed && groupShowAllProps?.group?.hasMoreData) {
      return (
        <Link className="load-more-button-group" underline onClick={() => groupFetchMoreItems(groupShowAllProps.group)}>
          {translate('Equipment.LoadMore')}
        </Link>
      );
    }
    return <></>;
  };

  const handleGetGroupHeight = useCallback(
    (group: IGroup, headerHeightParam: number = 40, itemHeight: number = 44, footerHeightParam: number = 30): number => {
      const hasMoreItems = group.hasMoreData ?? false;
      const isCollapsed = group.isCollapsed ?? false;

      const headerHeight = headerHeightParam; //40;
      const height = isCollapsed == true ? 0 : group.count * itemHeight; //44;
      let footerHeight = 5; // default footer when collapsed

      if (isCollapsed === false && hasMoreItems === true) {
        footerHeight = footerHeightParam; //30;
      }
      return headerHeight + height + footerHeight;
    },
    []
  );

  return (
    <div className="onix-split-table-container">
      <div className="onix-split-table-wrapper">
        {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}

        <ScrollablePane onScroll={onScroll} className={scrollablePanelId}>
          <DetailsList
            {...props}
            useReducedRowRenderer
            setKey="set"
            columns={splitColumns}
            items={items}
            groups={groups}
            selectionPreservedOnEmptyClick
            groupProps={{
              showEmptyGroups: true,
              onRenderShowAll: onRenderShowAll,
              isAllGroupsCollapsed: groups?.some((m) => m.isCollapsed),
            }}
            getGroupHeight={(group: IGroup) => handleGetGroupHeight(group)}
            className={`onix-table onix-split-table ${props.className || ''}`}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            constrainMode={ConstrainMode.horizontalConstrained}
            onRenderDetailsHeader={renderDetailsHeader}
            selectionMode={props.selection?.mode}
          />
        </ScrollablePane>

        {items.length === 0 && (
          <div className="empty-table">
            {props.onRenderEmpty ? props.onRenderEmpty() : <Label>{translate(`CommonResource.EmptyMessageHits`)}</Label>}
          </div>
        )}
      </div>
    </div>
  );
});
