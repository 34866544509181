export enum WidgetType {
  Donut = 'donut',
  Number = 'number',
  Image = 'image',
  Bar = 'bar',
  StackBar = 'stackbar',
  Column = 'column',
  Dynamic = 'dynamic',
}

export enum WidgetTemplateId {
  IssuesByStatus = 1, // Open issues by issues status
  NumberOfIssues = 2, // Open issues number
  IssuesByCustomers = 3, // Open issues of my customers
  IssuesByCCAndDueDate = 4, // Open issues by issue due date -- Two-levels-chart!
  Image = 5, // Image
  IssuesByCCAndStatus = 6, // Open issues by control category, issue status
  EquipmentFromView = 7, // Equipment from view
  JobFromView = 8, // Job from view
}
