import { CommandBarButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useShowUpgradeOWMessage } from '../../../../common/hooks/useShowUpgradeOWMessage';
import { useTracking } from '../../../../common/hooks/useTracking';
import PrimaryButton from '../../../../common/ui/Button/PrimaryButton';
import CommandBar from '../../../../common/ui/CommandBar/CommandBar';
import { SearchBox } from '../../../../common/ui/SearchBox/SearchBox';
import { Modules } from '../../../../constants/modules.constants';
import { TrackingEvent } from '../../../../constants/tracking-event.constant';
import { DocumentHelper } from '../../../../helpers/document.helper';
import { FieldFilter } from '../../../../models/filter-controls/search-field.model';
import { equipmentDocumentActions, equipmentDocumentSelector } from '../../../../states/equipment/equipmentDocumentSlice';
import { appDispatch } from '../../../../states/store';
import { DocumentFilterButton, IDocumentFilterButtonValue } from './Filters/DocumentFilterButton';

interface Props {
  clearSelectedDocuments?: () => void;
}

const EquipmentDocumentsToolbar = ({ clearSelectedDocuments }: Props) => {
  const [translate] = useTranslation();
  const { showUpgradeOWMessage } = useShowUpgradeOWMessage();
  const { trackEvent } = useTracking({ module: Modules.Equipment });

  const { selectedDocuments } = useSelector(equipmentDocumentSelector);
  const numberSelectedDocuments = selectedDocuments.length;
  const selectedDocument = selectedDocuments[0];

  const searchTextChange = (newValue: string | undefined, isTriggerSearch: boolean = false) => {
    const searchText = newValue || '';
    appDispatch(equipmentDocumentActions.updateSearchText(searchText));

    if (isTriggerSearch) {
      appDispatch(equipmentDocumentActions.updateSearchId());
    }
  };

  const mapToFieldFilters = (value: IDocumentFilterButtonValue): FieldFilter[] => {
    const fields: FieldFilter[] = [];
    for (const key of Object.keys(value) as (keyof IDocumentFilterButtonValue)[]) {
      fields.push({
        fieldName: key,
        fieldValue: Array.isArray(value[key]) ? value[key].join(',') : String(value[key]),
      });
    }

    return fields;
  };

  const onChangeFilter = (value: IDocumentFilterButtonValue) => {
    appDispatch(equipmentDocumentActions.updateDocumentTypeIds(value.documentTypeIds));
    appDispatch(equipmentDocumentActions.updateSearchId());
    const filters = mapToFieldFilters(value);
    trackEvent(TrackingEvent.Equipment.EquipmentDetail.Documents.FilterAction, { filters: filters });
  };

  const onClearSelectedDocuments = () => {
    clearSelectedDocuments && clearSelectedDocuments();
  };

  const onAddDocBtnClick = () => {
    showUpgradeOWMessage();
    trackEvent(TrackingEvent.Equipment.EquipmentDetail.Documents.AddAction);
  };

  const onSearch = (newValue?: string) => {
    searchTextChange(newValue, true);
    trackEvent(TrackingEvent.Equipment.EquipmentDetail.Documents.SearchAction, { searchingText: newValue });
  };

  return (
    <div className="equipment-documents-toolbar tool-bar py-2 min-h-[49px] ">
      <>
        <div className="tool-bar-left">
          <PrimaryButton autoFocus iconProps={{ iconName: 'Add' }} onClick={onAddDocBtnClick} text={translate('Equipment.AddDocument')} />

          {numberSelectedDocuments > 0 && (
            <CommandBar
              items={[
                {
                  key: 'send',
                  text: translate('CommonResource.send'),
                  iconProps: { iconName: 'Mail' },
                  onClick: () => {
                    showUpgradeOWMessage();
                    trackEvent(TrackingEvent.Equipment.EquipmentDetail.Documents.SendAction);
                  },
                },
                {
                  key: 'preview-document',
                  text: translate('Settings.Preview'),
                  iconProps: { iconName: 'Preview' },
                  onClick: () => {
                    appDispatch(equipmentDocumentActions.setShowPreviewDocument(true));
                  },
                  hidden: selectedDocuments.length !== 1,
                },
                {
                  key: 'open-document',
                  text: translate('RibbonMenu.Open'),
                  iconProps: { iconName: 'OpenInNewWindow' },
                  onClick: () => {
                    DocumentHelper.openDocument(selectedDocument);
                  },
                  hidden: selectedDocuments.length !== 1,
                },
              ]}
            />
          )}
        </div>
        <div className="tool-bar-right">
          {numberSelectedDocuments > 0 && (
            <CommandBarButton
              iconProps={{ iconName: 'Clear' }}
              text={translate('CommonResource.ItemsSelected', { 0: numberSelectedDocuments })}
              onClick={onClearSelectedDocuments}
            />
          )}
          <SearchBox
            placeholder={'CaptionResource.SearchForDescription'}
            classNames="min-w-[300px] border-[var(--grey-110)]"
            onChange={(_, newValue) => searchTextChange(newValue)}
            onSearch={onSearch}
            onClear={() => {
              searchTextChange('', true);
            }}
          />
          <DocumentFilterButton onValueChanges={onChangeFilter} />
        </div>
      </>
    </div>
  );
};

export default EquipmentDocumentsToolbar;
