import { IDialogContentProps, PrimaryButton } from '@fluentui/react';
import { Dialog } from '@onix/common/src/components';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DialogTypeEnum } from './enums/dialog-type.enum';
import { hiddenCommonDialog, hiddenErrorDialog } from './services/dialog.service';
import { commonSelector, errorSelector } from './states/app/appSlice';

export function CommonGlobal() {
  const [translate] = useTranslation();

  const errorDialog = useSelector(errorSelector);
  const commonDialog = useSelector(commonSelector);

  const errorDialogContentProps = useMemo(() => {
    let title = errorDialog.title;
    if (errorDialog.type === DialogTypeEnum.Error) {
      title = translate('CaptionResource.CAPTION_ERROR');
    } else if (errorDialog.type === DialogTypeEnum.Warning) {
      title = translate('CaptionResource.CAPTION_WARNING');
    }
    return {
      title: title,
      subText: errorDialog.message !== '' ? errorDialog.message : translate(errorDialog.messageCaption),
    } as IDialogContentProps;
  }, [errorDialog, translate]);

  const commonDialogContentProps = useMemo(() => {
    return {
      title: commonDialog.title,
      subText: commonDialog.message,
      showCloseButton: true,
    } as IDialogContentProps;
  }, [commonDialog, translate]);

  const onDismissCommonDialog = useCallback(() => {
    hiddenCommonDialog();
  }, []);

  const onDismissErrorDialog = useCallback(() => {
    hiddenErrorDialog();
  }, []);

  return (
    <>
      <Dialog
        isHidden={commonDialog.isHidden}
        dialogContentProps={commonDialogContentProps}
        onDismiss={onDismissCommonDialog}
        modalProps={{ isBlocking: true }}
        maxWidth={560}
        footerChildren={<PrimaryButton onClick={onDismissCommonDialog} text={translate('CommonResource.cmdOk')} />}
      />

      <Dialog
        isHidden={errorDialog.isHidden}
        dialogContentProps={errorDialogContentProps}
        onDismiss={onDismissErrorDialog}
        footerChildren={<PrimaryButton onClick={onDismissErrorDialog}>{translate('CaptionResource.CAPTION_OK')}</PrimaryButton>}
      />
    </>
  );
}
