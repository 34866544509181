import i18n, { TFunction } from 'i18next';
import { createFilterFieldItem } from '../../../common/ui/FilterControls/FilterControls';
import {
  getActiveStatusOptions,
  getCommonDateOptions,
  getDueDateOptions,
  getEquipmentStatusOptions,
} from '../../../common/ui/FilterControls/FilterOptions';
import { OwnerFilterData } from '../../../common/ui/FilterControls/NewOwnership/NewOwnership';
import { globalAppVariables } from '../../../constants/global-variables.constants';
import { DueDateClasses } from '../../../enums/due-date-classes.enum';
import { FilterControl } from '../../../enums/filter-control.enum';
import { IFilterItem } from '../../../models/filter-controls/filter-item.model';
import { ISearchField } from '../../../models/filter-controls/search-field.model';
import { equipmentService } from '../../../services/equipment.service';
import { RenderTitleEquipmentIssueStatus } from './RenderCustomTitleOption';

export enum EquipmentFilterFields {
  EquipmentIdentifier,
  SecondaryQRCode,
  SerialBatchNo,
  EquipmentOwner,
  TypeModel,
  ControlCategory,
  OrderNo,
  CustomerOrderNo,
  CertificateNo,
  IssueStatus,
  ActiveStatus,
  JobDate,
  DueDate,
}

export const getEquipmentFilterFields = (translate: TFunction<'translation', undefined>) => {
  const fields: ISearchField[] = [
    createFilterFieldItem(EquipmentFilterFields[EquipmentFilterFields.EquipmentIdentifier], FilterControl.TEXTFIELD, 'CaptionResource.ID'),
    createFilterFieldItem(EquipmentFilterFields[EquipmentFilterFields.SecondaryQRCode], FilterControl.TEXTFIELD, 'Equipment.OnixID'),
    createFilterFieldItem(EquipmentFilterFields[EquipmentFilterFields.SerialBatchNo], FilterControl.TEXTFIELD, 'Equipment.SerialBatchNo'),
    createFilterFieldItem(
      EquipmentFilterFields[EquipmentFilterFields.EquipmentOwner],
      FilterControl.OWNER_SHIP,
      'CaptionResource.BusinessRole.2'
    ),
    createFilterFieldItem(EquipmentFilterFields[EquipmentFilterFields.TypeModel], FilterControl.TEXTFIELD, 'CaptionResource.TypeModel'),
    createFilterFieldItem(
      EquipmentFilterFields[EquipmentFilterFields.ControlCategory],
      FilterControl.DROPDOWN_MULTI_WITHSEARCH,
      'Equipment.ControlCategory'
    ),
    createFilterFieldItem(EquipmentFilterFields[EquipmentFilterFields.OrderNo], FilterControl.TEXTFIELD, 'Equipment.OrderNo'),
    createFilterFieldItem(
      EquipmentFilterFields[EquipmentFilterFields.CustomerOrderNo],
      FilterControl.TEXTFIELD,
      'Equipment.CustomerOrderNo'
    ),
    createFilterFieldItem(EquipmentFilterFields[EquipmentFilterFields.CertificateNo], FilterControl.TEXTFIELD, 'Equipment.CertificateNo'),
    createFilterFieldItem(
      EquipmentFilterFields[EquipmentFilterFields.IssueStatus],
      FilterControl.DROPDOWN_MULTI_NOSEARCH,
      'Equipment.JobStatus',
      getEquipmentStatusOptions(translate)
    ),
    createFilterFieldItem(
      EquipmentFilterFields[EquipmentFilterFields.ActiveStatus],
      FilterControl.DROPDOWN_MULTI_NOSEARCH,
      'Equipment.ActiveStatus',
      getActiveStatusOptions(translate)
    ),
    createFilterFieldItem(
      EquipmentFilterFields[EquipmentFilterFields.JobDate],
      FilterControl.COMMONDATE,
      'AppInspect.sortJobDate',
      getCommonDateOptions()
    ),
    createFilterFieldItem(
      EquipmentFilterFields[EquipmentFilterFields.DueDate],
      FilterControl.DUEDATE,
      'Equipment.DueDate',
      getDueDateOptions(translate).filter((x) => x.key !== DueDateClasses.Custom)
    ),
  ];
  return fields;
};

export const searchControlCategories = async (text: string, includeMyControlCate: boolean = false, excludeNA: boolean = false) => {
  const result: IFilterItem[] = [];

  if (includeMyControlCate) {
    result.push({
      key: globalAppVariables.MY_CONTROL_CATEGORIES_OPTION,
      text: `- ${i18n.t('Equipment.myControlCategories')} -`,
      title: `- ${i18n.t('Equipment.myControlCategories')} -`,
    });
  }
  const data: any = await equipmentService.searchControlCategoriesByText(text, true, excludeNA);
  data.forEach((item: any) => {
    result.push({ key: item.value, text: item.text, title: item.title });
  });
  return result;
};

export const searchOwnerShip = async (param: any) => {
  let result: any = null;
  switch (param.field) {
    case OwnerFilterData.OwnersProperty:
      //result = await equipmentService.searchCompaniesForOwner(param.text ?? '', param.excludeExpress ?? false);
      break;

    case OwnerFilterData.LocationProperty:
      result = await equipmentService.searchLocationsByText(param.companyId, param.text ?? '');
      break;

    // case OwnerFilterData.AreaProperty:
    //   result = await equipmentService.searchAreas(param.companyId, param.locationIds, param.text ?? '');
    //   break;

    // case OwnerFilterData.ContactProperty:
    //   result = await equipmentService.searchContacts(param.companyId, param.text ?? '');
    //   break;

    // case OwnerFilterData.ProjectProperty:
    //   result = await equipmentService.searchProjects(param.companyId, param.text ?? '');
    //   break;
  }
  return result;
};

export const searchFunctions: Record<string, (props: any) => any> = {
  [EquipmentFilterFields[EquipmentFilterFields.ControlCategory]]: searchControlCategories,
  [EquipmentFilterFields[EquipmentFilterFields.EquipmentOwner]]: searchOwnerShip,
};

export const renderTitleCustomFunctions: Record<string, (props: any) => any> = {
  [EquipmentFilterFields[EquipmentFilterFields.IssueStatus]]: RenderTitleEquipmentIssueStatus,
};
