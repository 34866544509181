import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useShowUpgradeOWMessage } from '../../../../common/hooks/useShowUpgradeOWMessage';
import { useTracking } from '../../../../common/hooks/useTracking';
import { LinkButton } from '../../../../common/ui/LinkButton/LinkButton';
import { Skeleton } from '../../../../common/ui/Skeleton/Skeleton';
import { Modules } from '../../../../constants/modules.constants';
import { TrackingEvent } from '../../../../constants/tracking-event.constant';
import { formatDateTime } from '../../../../utils/dateTime';
import { EquipmentDetailContext } from '../EquipmentDetail';
import './index.scss';

const CreatedModifiedLoadingSkeleton = 4;

export const CreatedModified = () => {
  const [translate] = useTranslation();
  const { equipment, isLoading } = useContext(EquipmentDetailContext);
  const { showUpgradeOWMessage } = useShowUpgradeOWMessage();
  const { trackEvent } = useTracking({ module: Modules.Equipment });

  const onClickMarketingButton = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    showUpgradeOWMessage();
    trackEvent(TrackingEvent.Equipment.EquipmentDetail.Detail.CreatedModified.ViewFull);
  };

  return (
    <div className="created-modified-section section">
      {isLoading ? (
        <Skeleton numberElement={CreatedModifiedLoadingSkeleton} className="section-row" />
      ) : (
        <>
          <div className="section-row">
            <div className="caption">{translate('Equipment.CreatedBy')}</div>
            <div className="field">
              {equipment?.createdByName}
              {equipment?.createdByCompanyName && (
                <span className="company-name">
                  {' ('}
                  {equipment?.createdByCompanyName}
                  {')'}
                </span>
              )}
            </div>
          </div>
          <div className="section-row">
            <div className="caption">{translate('Document.lblCreatedDate')}</div>
            <div className="field pre-wrap">{formatDateTime(equipment?.createdDate)}</div>
          </div>
          <div className="section-row">
            <div className="caption">{translate('CaptionResource.ModifiedBy')}:</div>
            <div className="field">
              {equipment?.modifiedByName}
              {equipment?.modifiedByCompanyName && (
                <span className="company-name">
                  {' ('}
                  {equipment.modifiedByCompanyName}
                  {')'}
                </span>
              )}
            </div>
          </div>
          <div className="section-row">
            <div className="caption">{translate('Equipment.ModifiedDate')}:</div>
            <div className="field">{formatDateTime(equipment?.modifiedDate)}</div>
          </div>
          <div className="section-row">
            <LinkButton onClick={(ev) => onClickMarketingButton(ev)}>{translate('Equipment.ViewFullActivity')}</LinkButton>
          </div>
        </>
      )}
    </div>
  );
};
