import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { FieldFilter } from '../../models/filter-controls/search-field.model';
import { RootState } from '../store';

type State = {
  isShowPanel: boolean;
  hasFilters: boolean;
  filtersList: FieldFilter[];
};

const INTIAL_STATE: State = {
  isShowPanel: true,
  hasFilters: false,
  filtersList: [],
};

export const equipmentFilterSlice = createSlice({
  name: 'equipmentFilter',
  initialState: INTIAL_STATE,
  reducers: {
    showPanel: (state) => {
      state.isShowPanel = true;
    },
    hidePanel: (state) => {
      state.isShowPanel = false;
    },
    setHasFilters: (state, action) => {
      state.hasFilters = action.payload;
    },
    setFiltersList: (state, action: PayloadAction<FieldFilter[]>) => {
      state.filtersList = action.payload;
    },
    resetData: () => {
      return {
        ...INTIAL_STATE,
      };
    },
  },
});

export const equipmentFilterActions = equipmentFilterSlice.actions;
export const equipmentFilterReducer = equipmentFilterSlice.reducer;

const selectSelf = (state: RootState) => state.equipmentFilter;

export const equipmentFilterSelector = createSelector(selectSelf, (state) => state);
