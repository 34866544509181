import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PrimaryButton from '../../common/ui/Button/PrimaryButton';
import EditAlerts from '../../components/Alerts/EditAlerts';
import { CompanyExpressPreviewContext } from '../../context/CompanyExpressPreviewContext';
import { getAlertSetting } from '../../helpers/captured-event-alert.helper';
import { AlertSetting, KeysOfAlertSetting, SaveEventAlert } from '../../models/alert.model';
import { CapturedEventAlertService } from '../../services/captured-event-alert.service';
import { getFirstAlias } from '../../utils/browser.utils';
import { isEqualSimple, isStringNotNullOrUndefined } from '../../utils/common.utils';
import { getLanguageId } from '../../utils/localStorage.utils';

const Unsubscribe = () => {
  const [translate] = useTranslation();
  const [searchParams] = useSearchParams();
  const param = searchParams.get('param') || '';
  const alias = getFirstAlias();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [key, setKey] = useState('');
  const previewInfo = useContext(CompanyExpressPreviewContext);

  const initialSetting = { isInspection: false, isOrderRecevied: false } as AlertSetting;
  const [originSetting, setOriginSetting] = useState(initialSetting);
  const [alertSetting, setAlertSetting] = useState(initialSetting);

  const hasChanges = !isEqualSimple(alertSetting, originSetting);

  const handleToggle = (field: KeysOfAlertSetting, checked?: boolean | undefined) => {
    if (checked !== undefined) {
      setAlertSetting((prev) => {
        return {
          ...prev,
          [field]: checked,
        };
      });
    }
  };

  const handleSaveData = async () => {
    const data = {
      companyOwner: previewInfo.companyId,
      email: email,
      key: key,
      languageId: getLanguageId(),
      isOrderRecevied: alertSetting.isOrderRecevied,
      isInspection: alertSetting.isInspection,
    } as SaveEventAlert;
    const saveResult = await CapturedEventAlertService.saveEventAlertPublic(data);
    if (saveResult === true) {
      setOriginSetting(alertSetting);
    }
  };

  useEffect(() => {
    const loadSetting = async () => {
      try {
        const result = await CapturedEventAlertService.getEventAlertByEmailPublic(previewInfo.companyId, param, getLanguageId());
        if (result) {
          const { isValidEmail, email, capturedEvents, key } = result;
          setEmail(email);
          setKey(key);
          const setting = getAlertSetting(capturedEvents);
          setAlertSetting(setting);
          setOriginSetting(setting);
          if (!isStringNotNullOrUndefined(email) || !isValidEmail) {
            backToRoot();
          }
        } else {
        }
      } catch (err) {
        backToRoot();
      }
    };

    if (!previewInfo.companyId || previewInfo.companyId <= 0) {
      return;
    }

    if (!isStringNotNullOrUndefined(param)) {
      backToRoot();
      return;
    }

    loadSetting();
  }, [previewInfo.companyId, param]);

  const backToRoot = () => {
    navigate(`/${previewInfo.friendlyAlias ?? alias}`);
  };
  useEffect(() => {
    const handleCloseTab = (ev: any) => {
      ev.preventDefault();
      if (hasChanges) {
        return (ev.returnValue = 'Are you sure you want to close?');
      }
    };

    window.addEventListener('beforeunload', handleCloseTab);

    return () => window.removeEventListener('beforeunload', handleCloseTab);
  }, [hasChanges]);

  return (
    <div className="flex flex-col gap-4 items-center w-full py-12">
      <h4 className="text-xl">{translate('CommonResource.SetupWeeklyEmailAlertsChangesRelatedEquipments')}</h4>
      <EditAlerts setting={alertSetting} titleAlign="center" handleToggle={handleToggle} />
      <PrimaryButton text={translate('CommonResource.Save')} onClick={handleSaveData} disabled={!hasChanges} />
    </div>
  );
};

export default Unsubscribe;
