import { ApiConstants } from '../constants/api.constants';
import { DocumentTypes } from '../enums/document-type.enum';
import { DocumentDto, GetDocumentDto } from '../models/document.model';
import { DocumentTypeDto } from '../models/document/documentType.model';
import { GroupPagination } from '../models/group-pagination.model';
import { Pagination } from '../models/pagination.model';
import { getLanguageId } from '../utils/localStorage.utils';
import { getAsync } from './base.service';

const getListDocument = (payload: GetDocumentDto): Promise<Pagination<DocumentDto>> => {
  return getAsync(ApiConstants.document.getDocuments(), payload);
};

const getDocumentTypesWithFilters = (
  defaultExcludes: number[] = [DocumentTypes.ProjectPageNumber2, DocumentTypes.ServiceReport],
  isLoading = true
): Promise<DocumentTypeDto[]> => {
  const excludeTypesParam = defaultExcludes.join(',');
  const languageId = getLanguageId();
  return getAsync(
    `${ApiConstants.document.getDocumentTypesWithFilters()}?excludeTypes=${excludeTypesParam}&languageId=${languageId}`,
    {},
    isLoading
  );
};

const getListDocumentGroup = (payload: any): Promise<GroupPagination<DocumentDto>> => {
  return getAsync(ApiConstants.document.getGroupDocuments(), payload);
};

export const documentService = {
  getListDocument,
  getDocumentTypesWithFilters,
  getListDocumentGroup,
};
