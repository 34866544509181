/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  CheckboxVisibility,
  ColumnActionsMode,
  DetailsRow,
  IColumn,
  IContextualMenuItem,
  IDetailsRowStyles,
  Selection,
  SelectionMode,
} from '@fluentui/react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toUpperFirstLetter } from '../../../common/helper/string-helper';
import { useRememberColumnSizing } from '../../../common/hooks/useRememberColumnSizing';
import { useSelectionToViewDetailPanel } from '../../../common/hooks/useSelectionToViewDetailPanel ';
import { onRenderCell } from '../../../common/hooks/useTable';
import { useTracking } from '../../../common/hooks/useTracking';
import { IOnixTableImperative, IOnixTableRequest } from '../../../common/ui/OnixTable/IOnixTable';
import { OnixTable } from '../../../common/ui/OnixTable/OnixTable';
import { EquipmentConstantsKey } from '../../../constants/equipment.constant';
import { Modules } from '../../../constants/modules.constants';
import { TableColumnConstant } from '../../../constants/table-column.constant';
import { TrackingEvent } from '../../../constants/tracking-event.constant';
import { EquipmentActiveStatuses } from '../../../enums/equipment-active-status.enum';
import { EquipmentColumns, EquipmentDisplayFieldKeyCaptions } from '../../../enums/equipment-columns.enum';
import CommonHelper from '../../../helpers/common-helper';
import { EquipmentListItem } from '../../../models/equipment.model';
import { SortedColumn } from '../../../models/sorted-column.model';
import { showCommonDialog } from '../../../services/dialog.service';
import { equipmentService } from '../../../services/equipment.service';
import { equipmentFilterActions } from '../../../states/equipment/equipmentFilterSlice';
import { equipmentActions, equipmentSelector } from '../../../states/equipment/equipmentSlice';
import { appDispatch } from '../../../states/store';
import { getLanguageId } from '../../../utils/localStorage.utils';
import { EquipmentTableColumns } from '../Columns/EquipmentColumns';

const pageSize = 50;

const ColumnWidth = TableColumnConstant.StandardColumnDimension;

export type EquipmentTableImperative = {
  clearSelectedEquipments: () => void;
};

type Props = {
  equipmentDetailPanelRef?: React.RefObject<any>;
};

const EquipmentTableContent = forwardRef((props: Props, ref?: React.Ref<EquipmentTableImperative>) => {
  const [translate] = useTranslation();
  const predicateToGetKey = (equipment: EquipmentListItem) => equipment.equipmentId;
  const [selection] = useState(
    new Selection({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => {
        const equipments = selection.getSelection() as EquipmentListItem[];
        appDispatch(equipmentActions.updateSectedEquipments(equipments));
      },
      getKey: (item: any) => predicateToGetKey(item),
    })
  );

  useImperativeHandle(
    ref,
    () =>
      ({
        clearSelectedEquipments() {
          selection.setAllSelected(false);
        },
      } as EquipmentTableImperative),
    []
  );

  const {
    searchId,
    refinedBy,
    dataFetch: data,
    selectedEquipments,
    selectedEquipmentId,
    isSelectingSingleLineOnly,
  } = useSelector(equipmentSelector);

  const tableRef = useRef<IOnixTableImperative | null>(null);

  const { trackEvent } = useTracking({ module: Modules.Equipment });

  const requestRef = useRef<IOnixTableRequest>();

  useEffect(() => {
    return () => {
      appDispatch(equipmentActions.resetData());
      appDispatch(equipmentFilterActions.resetData());
    };
  }, []);

  const createPayload = (request: IOnixTableRequest, additionalParams: Record<string, any> = {}) => {
    return {
      refinedBy: refinedBy,
      languageId: getLanguageId(),
      columnName: toUpperFirstLetter(request.sortedColumn.columnName),
      isDescending: request.sortedColumn.isDescending,
      ...additionalParams,
    };
  };

  const getEquipments = async (request: IOnixTableRequest) => {
    const payload = createPayload(request, {
      pageSize: pageSize,
      pageNumber: request.pageNumber,
    });

    const equipmentPagination = await equipmentService.getEquipments(payload);
    if (equipmentPagination.pageNumber === 0) {
      appDispatch(equipmentActions.setDataFetch(equipmentPagination.items));
      appDispatch(equipmentActions.setTotalItems(equipmentPagination.totalItems));
    }

    // set current filter made by Onix Table
    appDispatch(equipmentActions.setFilterDefault(request));

    if (
      requestRef.current &&
      (request.sortedColumn?.columnName !== requestRef.current.sortedColumn?.columnName ||
        request.sortedColumn?.isDescending !== requestRef.current.sortedColumn?.isDescending)
    ) {
      trackEventSortBy(request.sortedColumn);
    }

    return equipmentPagination;
  };

  const getGroupEquipments = async (request: IOnixTableRequest) => {
    const payload = createPayload(request, {
      groupPageNumber: request.groupPageNumber,
      groupColumnName: toUpperFirstLetter(request.groupColumnName),
      isFetchInsideGroup: request.isFetchInsideGroup,
      groupValue: request.groupValue,
      loadedRowNumber: request.loadedRowNumber,
    });

    const result = await equipmentService.getGroupEquipments(payload);
    if (request.pageNumber === 0) {
      appDispatch(equipmentActions.setTotalItems(result.totalItems));
    }

    // Log event.
    if (requestRef.current && request.groupColumnName !== requestRef.current.groupColumnName) {
      trackEventGroupBy(request.groupColumnName);
    }

    return result;
  };

  const onGetEquipments = async (request: IOnixTableRequest) => {
    try {
      if (!searchId) {
        return;
      }
      var result = request.groupColumnName ? await getGroupEquipments(request) : await getEquipments(request);
      requestRef.current = request;
      return result;
    } catch (error) {
      const message = CommonHelper.getErrorMessage(error);
      if (message) {
        showCommonDialog(translate('CaptionResource.CAPTION_ERROR'), translate(message));
      }
    }
  };

  const dependencyArrayToGetItems = useMemo(() => {
    return [searchId];
  }, [searchId]);

  const columns = useMemo(() => {
    const createIconColumn = (
      key: string,
      propertyKey: string,
      name: string,
      onRender: (item?: any) => JSX.Element | undefined = onRenderCell,
      onRenderHeader?: () => JSX.Element | null
    ): IColumn => {
      return {
        key,
        name,
        isIconOnly: true,
        fieldName: propertyKey,
        minWidth: 20,
        maxWidth: 40,
        isResizable: false,
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onRender: (item) => onRender(item),
        onRenderHeader,
      };
    };

    const createColumn = (
      key: string,
      propertyKey: string,
      name: string,
      maxWidth: number = ColumnWidth,
      onRender: (item?: any, column?: IColumn) => JSX.Element | null = onRenderCell
    ): IColumn => {
      return {
        key,
        name,
        fieldName: propertyKey,
        minWidth: 40,
        maxWidth: maxWidth,
        isResizable: true,
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onRender: (item, _, column) => onRender(item, column),
      };
    };

    const columns: IColumn[] = [];

    EquipmentTableColumns.map((column) => {
      const fieldKeyCaption = EquipmentDisplayFieldKeyCaptions[column.id];
      if (column.isIconOnly) {
        columns.push(
          createIconColumn(
            EquipmentColumns[column.id],
            column.propertyKey,
            translate(fieldKeyCaption),
            column.onRender,
            column.onRenderHeader as any
          )
        );
      } else {
        columns.push(
          createColumn(
            EquipmentColumns[column.id],
            column.propertyKey,
            translate(fieldKeyCaption),
            column.defaultWidth ?? undefined,
            column.onRender as any
          )
        );
      }
    });

    return columns;
  }, [translate]);

  const { detailListRef, onColumnResized } = useRememberColumnSizing(columns, EquipmentConstantsKey.EquipmentTableColumnSize());

  const onRenderRow = useCallback((rowProps?: any): JSX.Element | null => {
    const rowStyles: Partial<IDetailsRowStyles> = {};
    const rowItem = rowProps?.item as EquipmentListItem;
    if (rowItem && rowItem.activeStatus !== EquipmentActiveStatuses.Active) {
      rowStyles.cell = { opacity: '0.5 !important' };
    }
    return <DetailsRow {...rowProps} styles={rowStyles} onDoubleClick={() => handleRowClick(rowProps?.item)} />;
  }, []);

  const handleRowClick = (item: any) => {
    appDispatch(equipmentActions.setShowHidePanel(true));
    appDispatch(equipmentActions.setSelectedEquipmentId(item.equipmentId));
  };

  const renderEmpty = useCallback(
    () => (
      <div
        className="text-sm text-center"
        dangerouslySetInnerHTML={{
          __html:
            searchId && data !== undefined ? translate('CommonResource.EmptyMessageHits') : translate('CommonResource.ToLocateEquipment'),
        }}
      ></div>
    ),
    [data, searchId, translate]
  );

  const trackEventSortBy = (sortedColumn: SortedColumn) => {
    trackEvent(TrackingEvent.Equipment.EquipmentListSort, {
      sortField: sortedColumn.columnName,
      sortByDesc: sortedColumn.isDescending,
    });
  };

  const trackEventGroupBy = (field: string | undefined) => {
    trackEvent(TrackingEvent.Equipment.EquipmentListGroup, {
      groupField: field,
    });
  };

  const groupColumnKeys = useMemo(() => {
    return [
      EquipmentColumns[EquipmentColumns.ControlCategory],
      EquipmentColumns[EquipmentColumns.ProductNo],
      EquipmentColumns[EquipmentColumns.Type],
      EquipmentColumns[EquipmentColumns.Model],
      EquipmentColumns[EquipmentColumns.Location],
    ];
  }, []);

  const showEquipmentDetail = () => {
    appDispatch(equipmentActions.setShowHidePanel(true));
  };

  const hideEquipmentDetail = () => {
    appDispatch(equipmentActions.setShowHidePanel(false));
  };

  useSelectionToViewDetailPanel({
    selectedItems: selectedEquipments,
    itemDetailPanelRef: props.equipmentDetailPanelRef,
    onOpenDetailPanel: showEquipmentDetail,
    updateSelectedItemToDetailPanel: () => {
      //dispatch(JobActions.updateSelectedJobId({ jobId: selectedJobs[0].jobId }));
    },
    selection: selection,
    onDismissDetailPanel: hideEquipmentDetail,
  });

  const resetToDefaultMenuItem = useMemo(() => {
    const items: IContextualMenuItem[] = [
      {
        key: 'resetToDefault',
        canCheck: false,
        checked: false,
        text: translate('CommonResource.lblResetToDefault'),
        onClick: () => {
          tableRef.current?.setSort(undefined);
          tableRef.current?.setGroup(undefined);
        },
      },
    ];
    return items;
  }, [translate]);

  // clear others selected items when clicking on a link button
  // so that only one item is selected, and show the detail panel here
  useEffect(() => {
    if (isSelectingSingleLineOnly === true) {
      selection.setAllSelected(false);
      selection.setKeySelected(selectedEquipmentId.toString(), true, false);
      appDispatch(equipmentActions.setSelectingSingleLineOnly(false));
    }
  }, [isSelectingSingleLineOnly]);

  return (
    <OnixTable
      compact
      checkboxVisibility={CheckboxVisibility.always}
      ref={tableRef}
      selection={selection}
      columns={columns}
      dependencyArrayToGetItems={dependencyArrayToGetItems}
      predicateToGetKey={(equipment: any) => equipment.equipmentId}
      onGetItems={onGetEquipments}
      onRenderRow={onRenderRow}
      onRenderEmpty={renderEmpty}
      onColumnResize={onColumnResized}
      componentRef={detailListRef}
      groupColumnKeys={groupColumnKeys}
      isGroupedListV2={true}
      additionalContextualMenuItems={resetToDefaultMenuItem}
    />
  );
});

export default EquipmentTableContent;
