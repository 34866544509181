import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { CompanyExpressPreviewContext } from '../../context/CompanyExpressPreviewContext';
import { hasToken } from '../../services/authentication.service';
import { getAuthenticatedProfile, getLoggedAccountId, getLoggedCompanyId } from '../../utils/localStorage.utils';

interface IUseTracking {
  module: string;
}

export const useTracking = (props: IUseTracking) => {
  const appInsights = useAppInsightsContext();
  const authenProfile = getAuthenticatedProfile();
  const location = useLocation();
  const IsloggedIn = hasToken();
  const previewInfo = useContext(CompanyExpressPreviewContext);
  const defaultProperties = useMemo(() => {
    return {
      module: props?.module || location.pathname, // exception for case missing module, it will replace by location.pathname
      companyId: IsloggedIn ? getLoggedCompanyId() : previewInfo.companyId,
      companyName: IsloggedIn ? authenProfile.companyExpressSetup.companyName : previewInfo.companyName,
      name: IsloggedIn ? `${authenProfile.contact.firstName} ${authenProfile.contact.lastName}` : '',
      userAccountId: IsloggedIn ? getLoggedAccountId() : null,
      contactId: IsloggedIn ? getLoggedAccountId() : null,
      expressCompanyName: IsloggedIn ? authenProfile.expressName : '',
      userEmail: IsloggedIn ? authenProfile.contact.email : '',
    };
  }, [props?.module, IsloggedIn]);

  const trackPageView = useCallback(
    (pageName: string, customProperties?: any) => {
      const properties = customProperties ? Object.assign(customProperties, defaultProperties) : defaultProperties;
      appInsights.trackPageView({ name: pageName, properties });
    },
    [appInsights, defaultProperties]
  );

  const trackEvent = useCallback(
    (name: string, customProperties?: any, parentPageName?: string) => {
      const properties = customProperties ? Object.assign(customProperties, defaultProperties) : defaultProperties;
      if (parentPageName) {
        properties.parentPage = parentPageName;
      }
      appInsights.trackEvent({ name, properties });
    },
    [appInsights, defaultProperties]
  );

  return { trackPageView, trackEvent };
};
