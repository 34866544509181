import { useTranslation } from 'react-i18next';
import { setPageTitle } from '../../utils/browser.utils';

export interface IProps {
  supplierName?: string;
}

const NotAvailable = (props: IProps) => {
  const [translate] = useTranslation();
  setPageTitle(props.supplierName || translate('CommonResource.NotAvailable'));
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center gap-1">
      <h1 className="text-[80px] mb-5 Segoe UI font-semibold">{translate('CommonResource.NotAvailable')}</h1>
      <p className="text-xl font-normal">{translate('CommonResource.OnixExpressSupHasStoppedUsingThisDocument')}</p>
      <p className="text-xl font-normal">{translate('CommonResource.OnixExpressPlzContactYourSup')}</p>
    </div>
  );
};

export default NotAvailable;
