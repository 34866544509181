import { EquipmentHelper } from '../../helpers/equipment-helper';

export interface EquipmentImage {
  imageContentId: string;
}

export interface UploadEquipmentModel {
  equipmentId: number;
  description: string;
  uploadControl: FormData;
}

export interface IPicture {
  key: string;
  imageInfoId: number;
  imageContentId: string;
  description: string;
  createdDate: any;
  isDefaultImage: boolean;
  equipmentId: number;
  equipmentIdentifier: string;
  imageEquipmentId: string;
}

export class Picture implements IPicture {
  key: string;
  imageInfoId: number;
  imageContentId: string;
  description: string;
  createdDate: any;
  isDefaultImage: boolean;
  equipmentId: number;
  equipmentIdentifier: string;
  imageEquipmentId: string;

  constructor(item: IPicture, equipmentIdentifier?: string) {
    this.imageInfoId = item.imageInfoId;
    this.imageContentId = item.imageContentId;
    this.description = item.description;
    this.createdDate = item.createdDate;
    this.isDefaultImage = item.isDefaultImage;
    this.equipmentId = item.equipmentId;
    this.equipmentIdentifier = equipmentIdentifier || '';
    this.imageEquipmentId = item.imageEquipmentId;
    this.key = this.generateKey();
  }

  private generateKey(): string {
    return EquipmentHelper.generatePictureKey(this.equipmentId, this.imageContentId);
  }
}
