import { PanelType, Separator } from '@fluentui/react';
import { PanelWithScrollableBody } from '@onix/common';
import { IOnixTableRequest } from '@onix/common/src/components/OnixTable/IOnixTable';
import { SortedColumn } from '@onix/common/src/models/sorted-column.model';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShowUpgradeOWMessage } from '../../../common/hooks/useShowUpgradeOWMessage';
import { useTracking } from '../../../common/hooks/useTracking';
import { Modules } from '../../../constants/modules.constants';
import { TrackingEvent } from '../../../constants/tracking-event.constant';
import { CaptionForm } from '../../../enums/language.enum';
import CommonHelper from '../../../helpers/common-helper';
import { IPicture, Picture } from '../../../models/equipmentDetail/equipment-image.model';
import { showCommonDialog } from '../../../services/dialog.service';
import { equipmentService } from '../../../services/equipment.service';
import { equipmentActions } from '../../../states/equipment/equipmentSlice';
import { appDispatch } from '../../../states/store';
import { IPictureTilesViewImperative, PictureTilesView } from './PictureTilesView/PictureTilesView';
import { PictureToolBar } from './PictureToolBar/PictureToolBar';
import './index.scss';

const PAGE_SIZE = 50;

export enum ViewType {
  PictureTilesView,
  PictureSplitView,
}

export interface IPictureManagementProps {
  equipmentId: number;
  selectedEquipments?: any[];
}

export const PictureManagement = (props: IPictureManagementProps) => {
  const [translate] = useTranslation();
  const { equipmentId, selectedEquipments } = props;
  const { trackEvent } = useTracking({ module: Modules.Equipment });

  // equipment info
  const [type, setType] = useState<string>();
  const [typeInfo, setTypeInfo] = useState<string>();
  const [serialNumber, setSerialNumber] = useState<string>();
  const [selectedItems, setSelectedItems] = useState<IPicture[]>([]);
  const pictureTilesViewRef = useRef<IPictureTilesViewImperative | null>(null);

  const { showUpgradeOWMessage } = useShowUpgradeOWMessage();

  useEffect(() => {
    const fetchEquipmentItem = async () => {
      const equipmentItem = await equipmentService.getEquipmentInfoById(equipmentId);
      setSerialNumber(equipmentItem?.equipmentIdentifier ?? '');
      setType(equipmentItem?.type ?? '');
      setTypeInfo(equipmentItem?.typeInfo ?? '');
    };

    fetchEquipmentItem();
  }, [equipmentId]);

  const getPagedEquipmentImages = async (request: IOnixTableRequest, sortedColumn: SortedColumn) => {
    if (request) {
      try {
        const payload = {
          columnName: sortedColumn.columnName,
          isDescending: sortedColumn.isDescending,
          pageNumber: request.pageNumber,
          pageSize: PAGE_SIZE,
          equipmentIds: [equipmentId],
        };

        const pagedEquipmentPicture = await equipmentService.getPagedEquipmentPictures(payload);
        if (pagedEquipmentPicture) {
          const equipmentPictures = pagedEquipmentPicture.items as IPicture[];
          const result = equipmentPictures.map((item: IPicture) => {
            const foundItem = selectedEquipments?.find((x) => x.EquipmentId === item.equipmentId);
            return new Picture(item, foundItem ? foundItem.EquipmentIdentifier : undefined);
          });

          return { ...pagedEquipmentPicture, items: result };
        }
      } catch (err) {
        const message = CommonHelper.getErrorMessage(err);
        if (message) {
          showCommonDialog(translate('CaptionResource.CAPTION_ERROR'), translate(message));
        }
      }
    }
  };

  const onAddPicture = () => {
    showUpgradeOWMessage();
    trackEvent(TrackingEvent.Equipment.EquipmentDetail.Pictures.AddPicture);
  };

  const onDeletePicture = () => {
    showUpgradeOWMessage();
    trackEvent(TrackingEvent.Equipment.EquipmentDetail.Pictures.DeletePicture);
  };

  const onEditPicture = () => {
    showUpgradeOWMessage();
    trackEvent(TrackingEvent.Equipment.EquipmentDetail.Pictures.EditPicture);
  };

  return (
    <>
      <PanelWithScrollableBody
        className="picture-management-panel"
        isOpen={true}
        onDismiss={() => {
          appDispatch(equipmentActions.setShowPictureManagement({ isOpen: false }));
        }}
        hideSeparator={true}
        panelProps={{
          type: PanelType.extraLarge,
          isBlocking: true,
          isLightDismiss: true,
        }}
        renderHeader={() => (
          <>
            <span className="title">{translate(`${CaptionForm.CaptionResource}.Pictures`)}</span>
            <div className="description">
              <span>
                {serialNumber}
                {type && type.trim() !== '' && <> &#x2022; {type}</>}
                {typeInfo && typeInfo.trim() !== '' && <> &#x2022; {typeInfo}</>}
              </span>
            </div>
          </>
        )}
        renderBody={() => (
          <div className="picture-management-container">
            <PictureToolBar
              pictures={selectedItems}
              onDeletePicture={onDeletePicture}
              onAddPicture={onAddPicture}
              onEditPicture={onEditPicture}
              onClearAllSelected={() => {
                setSelectedItems([]);
                pictureTilesViewRef.current?.clearAll();
              }}
            />
            <Separator />
            <PictureTilesView
              ref={pictureTilesViewRef}
              onSelectionChanged={setSelectedItems}
              getItems={getPagedEquipmentImages}
              equipmentId={equipmentId}
            />
          </div>
        )}
      />
    </>
  );
};
