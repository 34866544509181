import { useEffect, useMemo, useRef } from 'react';

import ViewerJS from 'viewerjs';
import RViewerJS from './RCViewerjs';
interface IImageData {
  src: string;
  description: string;
}
interface IImageViewer {
  images: IImageData[];
}
export const ImageViewer = (props: IImageViewer) => {
  const viewerRef = useRef(null);
  const containerRef = useRef(null); // Reference to the .abc container
  const previousWidth = useRef(null); // Store the previous width
  const resizingFlag = useRef(false); // Add a flag to track resizing

  const imageOption: ViewerJS.Options = useMemo(() => {
    return {
      inline: true,
      navbar: false,
      toolbar: {
        zoomIn: { size: 'large' },
        zoomOut: { size: 'large' },
        oneToOne: { size: 'large' },
        reset: { size: 'large' },
        rotateLeft: { size: 'large' },
        rotateRight: { size: 'large' },
        download: {
          size: 'large',
          click: function (ev: any) {
            const viewer = viewerRef.current as any;
            const image = viewer.images[viewer.index] as HTMLImageElement;
            fetch(image.src)
              .then((response) => response.blob())
              .then((blob) => {
                // Create an Object URL for the Blob
                const blobURL = URL.createObjectURL(blob);

                // Create a download link
                const downloadLink = document.createElement('a');
                downloadLink.href = blobURL;
                downloadLink.download = image.alt; // Set the desired file name

                // Add the link to the DOM
                document.body.appendChild(downloadLink);

                // Trigger the download when the link is clicked
                downloadLink.click();

                // Clean up: Revoke the Object URL when it's no longer needed to release resources
                URL.revokeObjectURL(blobURL);
                document.body.removeChild(downloadLink);
              });
          },
        },
      },
    };
  }, []);

  useEffect(() => {
    // Detect window resizing events
    window.addEventListener('resize', handleResizing);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResizing);
    };
  }, []);

  useEffect(() => {
    // Initialize the ResizeObserver
    const resizeObserver = new ResizeObserver(resizeCallback);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      // Cleanup the observer when the component unmounts
      resizeObserver.disconnect();
    };
  }, []);

  const resizeCallback = (entries: any) => {
    for (const entry of entries) {
      // Check if the width of the container has changed
      if (!resizingFlag.current && entry.contentBoxSize[0].inlineSize !== previousWidth.current) {
        // Trigger any action you want here
        setTimeout(() => {
          (viewerRef.current as any)?.resize();
        }, 0);
        // Update the previous width
        previousWidth.current = entry.contentBoxSize[0].inlineSize;
      }
      resizingFlag.current = false; // Reset the resizing flag
    }
  };

  const handleResizing = () => {
    resizingFlag.current = true; // Set the resizing flag when you know resizing is happening
  };

  return (
    <div className="image-viewer-container h-full" ref={containerRef}>
      <RViewerJS ref={viewerRef} options={imageOption}>
        {props.images.map((img, i) => (
          <img key={i} src={img.src} alt={img.description} style={{ display: 'none' }} />
        ))}
      </RViewerJS>
    </div>
  );
};
