import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonIcon from '../../common/ui/Button/ButtonIcon';
import Loading from '../../common/ui/Loading/Loading';
import Title from '../../common/ui/Title/Title';
import { DashboardHelper } from '../../helpers/dashboard-helper';
import { DashboardData } from '../../models/dashboard/dashboard.model';
import { WidgetData } from '../../models/dashboard/widget.model';
import { DashboardService } from '../../services/dashboard.service';
import { showMessageBox } from '../../services/modal.service';
import { MessageBoxProps } from '../../types/modal.type';
import { formatDateTime } from '../../utils/dateTime';
import { DashboardGridContainer } from './DashboardGridContainer';

export type DashboardProps = {};
export type DashboardRef = {};

export const Dashboard = forwardRef<DashboardRef, DashboardProps>((props, ref) => {
  const { t } = useTranslation();

  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [dashboard, setDashboard] = useState<DashboardData>();
  const [lastUpdated, setLastUpdated] = useState<string>();

  const loadDashboardData = async () => {
    setPageLoading(true);
    try {
      const dashboardData = await DashboardService.getExpressStandardDashboard();

      setDashboard(dashboardData);
    } catch (err) {
      console.error(err);
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    loadDashboardData();
  }, []);

  const layout = useMemo(() => DashboardHelper.parseLayout(dashboard?.layout), [dashboard?.layout]);

  const refreshDashboard = async () => {
    if (pageLoading) {
      return;
    }

    const canRefresh = await DashboardService.refresh();

    if (!canRefresh) {
      const upgradeBox = {
        header: <Title text={t('CaptionResource.CAPTION_WARNING')} />,
        children: <div className="w-auto">{t('Home.wrnCannotRefreshDashboard')}</div>,
      } as MessageBoxProps;

      showMessageBox(upgradeBox);
      return;
    }

    setDashboard(undefined);
    setLastUpdated(undefined);

    await loadDashboardData();
  };

  const setLastUpdatedValue = (widgetData: WidgetData) => {
    if (lastUpdated) {
      return;
    }

    const date = new Date(widgetData.lastPopulatedDate);

    const formattedDate = formatDateTime(date);

    setLastUpdated(formattedDate);
  };

  return (
    <div className="size-full flex flex-col border border-t-0">
      {pageLoading && <Loading />}
      <div className="w-full bg-white h-16 flex justify-between items-center p-4 shadow-sm z-[1]">
        <div className="flex flex-col justify-between">
          <div className="">
            {lastUpdated ? (
              <>
                {t('Home.Updated')} {lastUpdated}
              </>
            ) : (
              <>&nbsp;</>
            )}
          </div>
          <div className="text-grey130">{t('Home.RefreshDashboardText')}</div>
        </div>

        <div className="">
          <ButtonIcon
            text={t('Home.Refresh')}
            icon="Refresh"
            onClick={refreshDashboard}
            className="bg-white text-black border border-grey110"
          />
        </div>
      </div>
      {!pageLoading && layout.length ? <DashboardGridContainer layout={layout} onLoadedWidget={setLastUpdatedValue} /> : undefined}
    </div>
  );
});
