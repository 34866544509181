import { IButtonProps, IButtonStyles, IconButton } from '@fluentui/react';
import { CSSProperties, ReactElement, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import './index.scss';

export interface CollapseRef {
  isOpen: boolean;
}
interface ICollapseStyles {
  header?: CSSProperties;
  iconStyles?: IButtonStyles;
  root?: CSSProperties;
  body?: CSSProperties;
}
export interface ICollapse {
  title?: string | ReactElement;
  isOpen?: boolean;
  children: string | ReactElement;
  className?: string;
  onlyRenderOnOpen?: boolean;
  setOpen?: (value: boolean) => void;
  iconPosition?: IconPositionEnum;
  styles?: ICollapseStyles;
  openIconName?: string;
  closeIconName?: string;
  iconButtonProps?: IButtonProps;
}

export enum IconPositionEnum {
  Left = 1,
  Right = 2,
}
const DefaultHeight = 50;

export const Collapse = (props: ICollapse) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const { title, children, isOpen: isOpenProps, className, onlyRenderOnOpen, setOpen: setOpenProps, styles, iconButtonProps } = props;
  const [isOpen, setOpen] = useState(isOpenProps ?? false);
  const [height, setHeight] = useState<number>(DefaultHeight);
  const iconPosition = props.iconPosition ?? IconPositionEnum.Right;
  const openIconName = props.openIconName ?? 'ChevronUp';
  const CloseIconName = props.closeIconName ?? 'ChevronDown';
  // Use useCallback to get default height because useMemo calculates before DOM renders
  const getDefaultHeight = useCallback(() => {
    let height = DefaultHeight;
    if (headerRef?.current?.clientHeight) {
      height = headerRef.current.offsetHeight;
    }
    return height;
  }, [headerRef]);

  useEffect(() => {
    if (isOpenProps !== undefined) {
      setOpen(isOpenProps);
    }
  }, [isOpenProps, setOpen]);

  useLayoutEffect(() => {
    let height = getDefaultHeight();
    if (isOpen) {
      height = height + (bodyRef?.current?.clientHeight ?? 0);
    }
    setHeight(height);
  }, [bodyRef, isOpen, getDefaultHeight]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (isOpen) {
        const height = (bodyRef?.current?.clientHeight ?? 0) + getDefaultHeight();
        setHeight(height);
      }
    });
    const bodyElement = bodyRef?.current;
    if (bodyElement) {
      resizeObserver.observe(bodyElement);
    }
    return () => {
      if (bodyElement) {
        resizeObserver.unobserve(bodyElement);
      }
    };
  }, [bodyRef, isOpen, getDefaultHeight]);

  const collapse = useCallback(() => {
    const value = !isOpen;
    setOpen(value);
    if (setOpenProps) {
      setOpenProps(value);
    }
  }, [isOpen, setOpenProps]);

  return (
    <div className={`collapse ${className ?? ''}`} style={{ height: `${height}px`, ...styles?.root }}>
      <div
        ref={headerRef}
        className={'collapse-header ' + (iconPosition == IconPositionEnum.Left ? 'flex-row-reverse' : '')}
        style={styles?.header}
        onClick={collapse}
      >
        {title}
        <IconButton {...iconButtonProps} iconProps={{ iconName: isOpen ? openIconName : CloseIconName }} styles={styles?.iconStyles} />
      </div>
      <div className="collapse-body" style={{ display: isOpen ? undefined : 'none', ...styles?.body }} ref={bodyRef}>
        {(isOpen || !onlyRenderOnOpen) && children}
      </div>
    </div>
  );
};

Collapse.displayName = 'Collapse';
