import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileConstants } from '../../../constants/file.constants';
import { ContentType } from '../../../enums/content-type.enum';
import { DocumentHelper } from '../../../helpers/document.helper';
import { appActions } from '../../../states/app/appSlice';
import { appDispatch } from '../../../states/store';
import { ImageViewer } from '../ImageViewer/ImageViewer';

interface IPreviewDocument {
  onLoadIFrame?: () => void;
  description: string;
  documentId: number;
  contentType: string;
  fileSize: number;
}

export const PreviewDocument = (props: IPreviewDocument) => {
  const [src, setSrc] = useState<string>('');
  const [type, setType] = useState<ContentType>(ContentType.Pdf);
  const [translate] = useTranslation();

  useEffect(() => {
    const type = DocumentHelper.getDocumentContentType(props.contentType);
    if (type === ContentType.Image || props.fileSize > size10MB) {
      appDispatch(appActions.hideLoading());
    } else {
      appDispatch(appActions.showLoading());
    }
    setType(type);
  }, [props.contentType, props.fileSize, props.documentId]);

  useEffect(() => {
    const type = DocumentHelper.getDocumentContentType(props.contentType);

    switch (type) {
      case ContentType.Pdf:
      case ContentType.Image:
        setSrc(DocumentHelper.getUrlPdfDocument({ documentId: props.documentId, description: props.description }));
        break;
      case ContentType.Excel:
      case ContentType.Word:
        if (props.fileSize > size10MB) {
          setSrc(DocumentHelper.getUrlPdfDocument({ documentId: props.documentId, description: props.description }));
        } else {
          setSrc(DocumentHelper.getUrlMicrosoftDocument({ documentId: props.documentId, description: props.description }));
        }
        break;
      default:
        setSrc('');
        break;
    }
  }, [props.contentType, props.documentId]);

  const onLoadedFile = useCallback(() => {
    appDispatch(appActions.hideLoading());
    if (props.onLoadIFrame) {
      props.onLoadIFrame();
    }
  }, [props]);

  const size10MB = 10 * FileConstants.Size.MB.size;
  const sizeHtml = useMemo(() => {
    return translate('CaptionResource.HeavyDocumentFileMessage').replace('{link}', src).replace('_blank', '');
  }, [src, translate]);
  const images = [{ src: src, description: props.description }];

  return (
    <>
      {(() => {
        switch (type) {
          case ContentType.Image:
            return <ImageViewer images={images} />;
          case ContentType.Excel:
          case ContentType.Word:
            return props.fileSize > size10MB ? (
              <div className="h-full flex items-center align-middle text-center">
                <div className="w-full link-download" style={{ fontSize: 14 }} dangerouslySetInnerHTML={{ __html: sizeHtml }}></div>
              </div>
            ) : (
              <>
                <div className="h-full w-full p-1 overflow-hidden">
                  <iframe
                    id="pdfViewer"
                    src={src}
                    title={props.description}
                    style={{ width: '100%', height: '100%' }}
                    onLoad={onLoadedFile}
                  ></iframe>
                </div>
              </>
            );
          default:
            return (
              <div className="h-full w-full p-1 overflow-hidden">
                <iframe
                  id="pdfViewer"
                  src={src}
                  title={props.description}
                  style={{ width: '100%', height: '100%' }}
                  onLoad={onLoadedFile}
                ></iframe>
              </div>
            );
        }
      })()}
    </>
  );
};
