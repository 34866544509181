const RoutingConstant = {
  HomeRoot: '',
  Home: 'home',
  Dashboard: 'dashboard',
  Documents: 'documents',
  Equipment: 'equipment',
  AuthCallback: 'auth-callback',
  Logout: 'logout',
  NotFound: 'not-found',
  Signout: 'sign-out',
  FriendlyAlias: '/:companyName',
  Preview: '/:id/preview',
  Unsubscribe: '/:id/unsubscribe',
  Others: '*',
};

export { RoutingConstant };
