export enum TaskQueueStatusValues {
    None = 0,
    New = 1,
    ReadyForProcess = 2,
    Processing = 3,
    Finished = 4,
    Error = 5,
    Cleaned = 6,
    Cancellation = 7,
    ReadyForFiling = 8,
    Filing = 9,
  }