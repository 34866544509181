import { DefaultButton } from '@fluentui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hideModal } from '../../../services/modal.service';
import { DiscardModalProps } from '../../../types/modal.type';
import { cn } from '../../../utils/css.utils';
import PrimaryButton from '../Button/PrimaryButton';
import Title from '../Title/Title';
import CommonModal, { CommonModalProps } from './CommonModal';

type Props = CommonModalProps & DiscardModalProps;

const DiscardChangesModal = ({ id, isOpen, onDiscard, styles: stylesProp }: Props) => {
  const [translate] = useTranslation();
  const [open] = useState(isOpen);

  const closeModal = () => {
    hideModal(id || '');
  };

  return (
    <CommonModal
      isOpen={open}
      header={<Title text={translate('CaptionResource.DiscardChanges')} />}
      children={<div className={cn('py-4 max-w-[90%] w-[330px]')}>{translate('CaptionResource.DiscardChangesDescription')}</div>}
      footer={
        <>
          <DefaultButton
            text={translate('CaptionResource.Discard')}
            onClick={() => {
              closeModal();
              onDiscard && onDiscard();
            }}
          />
          <PrimaryButton text={translate('CaptionResource.ContinueEditing')} onClick={closeModal} />
        </>
      }
      footerGreyOut={false}
      onDismiss={closeModal}
      onEscape={closeModal}
      styles={stylesProp}
    />
  );
};

export default DiscardChangesModal;
