import { FC } from 'react';
import { Widget } from '../../components/Widget/Widget';
import { DashboardLayoutItem } from '../../models/dashboard/dashboard.model';
import { WidgetData } from '../../models/dashboard/widget.model';

export interface DashboardGridContainerProps {
  layout: DashboardLayoutItem[];
  onLoadedWidget?: (widget: WidgetData) => void;
}

export const DashboardGridContainer: FC<DashboardGridContainerProps> = (props) => {
  const { layout, onLoadedWidget } = props;

  return (
    <div className="size-full flex flex-wrap justify-between p-3 gap-3 overflow-auto content-start">
      {layout.map((item, index) => (
        <Widget key={index} layout={layout} layoutItem={item} onLoadedWidget={onLoadedWidget} />
      ))}
    </div>
  );
};
