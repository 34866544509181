import { CapturedEventItem, EventAlertInterval } from '../enums/captured-event-alert.enum';
import { AlertSetting } from '../models/alert.model';
import { CapturedEventAlert } from '../models/captured-event-alert.model';
import { isNotNullOrUndefined } from '../utils/common.utils';

const getAlertSetting = (events: CapturedEventAlert[]) => {
  if (!isNotNullOrUndefined(events)) return { isInspection: false, isOrderRecevied: false } as AlertSetting;

  return {
    isInspection: events?.some(
      (x) => x.capturedEventItemId === CapturedEventItem.CompletedInspection && x.recurrenceInterval === EventAlertInterval.Weekly
    ),
    isOrderRecevied: events?.some(
      (x) => x.capturedEventItemId === CapturedEventItem.ReceivedOrderFromSupplier && x.recurrenceInterval === EventAlertInterval.Weekly
    ),
  } as AlertSetting;
};

export { getAlertSetting };
