import { FocusZone, IPanelProps, PanelType } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { globalAppVariables } from '../../../../constants/global-variables.constants';
import { FieldFilter, FilterControlRef, ISearchFieldProps } from '../../../../models/filter-controls/search-field.model';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import { usePrevious } from '../../../hooks/usePrevious';
import { IMultipleSelectionFieldOption } from '../../MultipleSelectionField/IMultipleSelectionField';
import MultipleSelectionField from '../../MultipleSelectionField/MultipleSelectionField';
import './index.scss';

class OwnerFieldItem {
  key: number = globalAppVariables.IGNORE_VALUE_INT;
  text: string = '';

  public reset() {
    this.key = globalAppVariables.IGNORE_VALUE_INT;
    this.text = '';
  }
}

export class OwnerFilterData {
  public static readonly OwnerShipProperty = 'OwnerShip';
  public static readonly OwnersProperty = 'Owners';
  public static readonly LocationProperty = 'Locations';
  public static readonly AreaProperty = 'Areas';
  public static readonly ContactProperty = 'Contacts';
  public static readonly ProjectProperty = 'Projects';

  currentField: string;
  company: OwnerFieldItem;

  companyKeys: number[];
  companyItems: any[];

  locationKeys: number[];
  locationItems: any[];

  areaKeys: number[];
  areaItems: any[];

  contactKeys: number[];
  contactItems: any[];

  projectKeys: number[];
  projectItems: any[];

  constructor(filters: FieldFilter[]) {
    this.currentField = '';
    this.company = new OwnerFieldItem();
    this.companyKeys = [];
    this.companyItems = [];
    this.locationKeys = [];
    this.locationItems = [];
    this.areaKeys = [];
    this.areaItems = [];
    this.contactKeys = [];
    this.contactItems = [];
    this.projectKeys = [];
    this.projectItems = [];

    if (filters && filters.length > 0) {
      const companyFilter = filters.find((x: any) => x.fieldName === OwnerFilterData.OwnersProperty);
      if (companyFilter !== undefined && companyFilter != null) {
        this.companyKeys = companyFilter.fieldValue?.parseToArrayOfNumbers(companyFilter.fieldValue).map((x) => Number(x));
        this.companyItems = companyFilter.options ?? [];
      }

      const locationFilter = filters.find((x: any) => x.fieldName === OwnerFilterData.LocationProperty);
      if (locationFilter !== undefined && locationFilter != null) {
        this.locationKeys = locationFilter.fieldValue?.parseToArrayOfNumbers(locationFilter.fieldValue).map((x) => Number(x));
        this.locationItems = locationFilter.options ?? [];
      }

      const areatFilter = filters.find((x: any) => x.fieldName === OwnerFilterData.AreaProperty);
      if (areatFilter !== undefined && areatFilter != null) {
        this.areaKeys = areatFilter.fieldValue?.parseToArrayOfNumbers(areatFilter.fieldValue).map((x) => Number(x));
        this.areaItems = areatFilter.options ?? [];
      }

      const contactFilter = filters.find((x: any) => x.fieldName === OwnerFilterData.ContactProperty);
      if (contactFilter !== undefined && contactFilter != null) {
        this.contactKeys = contactFilter.fieldValue?.parseToArrayOfNumbers(contactFilter.fieldValue).map((x) => Number(x));
        this.contactItems = contactFilter.options ?? [];
      }

      const projectFilter = filters.find((x: any) => x.fieldName === OwnerFilterData.ProjectProperty);
      if (projectFilter !== undefined && projectFilter != null) {
        this.projectKeys = projectFilter.fieldValue?.parseToArrayOfNumbers(projectFilter.fieldValue).map((x) => Number(x));
        this.projectItems = projectFilter.options ?? [];
      }
    }
  }
}

export const NewOwnership = (props: ISearchFieldProps) => {
  const [translate] = useTranslation();

  const [companySearchText, setCompanySearchText] = useState<string | undefined>(undefined);
  const [selectedCompanyOptions, setSelectedCompanyOptions] = useState<IMultipleSelectionFieldOption[]>([]);
  const [companyOptions, setCompanyOptions] = useState<IMultipleSelectionFieldOption[]>([]);

  const [locationSearchText, setLocationSearchText] = useState<string | undefined>(undefined);
  const [selectedLocationOptions, setSelectedLocationOptions] = useState<IMultipleSelectionFieldOption[]>([]);
  const [locationOptions, setLocationOptions] = useState<IMultipleSelectionFieldOption[]>([]);

  const [areaSearchText, setAreaSearchText] = useState<string | undefined>(undefined);
  const [selectedAreaOptions, setSelectedAreaOptions] = useState<IMultipleSelectionFieldOption[]>([]);
  const [areaOptions, setAreaOptions] = useState<IMultipleSelectionFieldOption[]>([]);

  const [contactSearchText, setContactSearchText] = useState<string | undefined>(undefined);
  const [selectedContactOptions, setSelectedContactOptions] = useState<IMultipleSelectionFieldOption[]>([]);
  const [contactOptions, setContactOptions] = useState<IMultipleSelectionFieldOption[]>([]);

  const [projectSearchText, setProjectSearchText] = useState<string | undefined>(undefined);
  const [selectedProjectOptions, setSelectedProjectOptions] = useState<IMultipleSelectionFieldOption[]>([]);
  const [projectptions, setProjectOptions] = useState<IMultipleSelectionFieldOption[]>([]);

  const previousSelectedCompanyOptions = usePrevious<IMultipleSelectionFieldOption[]>(selectedCompanyOptions);

  useEffect(() => {
    if (
      previousSelectedCompanyOptions !== undefined &&
      selectedCompanyOptions !== null &&
      previousSelectedCompanyOptions.length === 1 &&
      selectedCompanyOptions.length === 1
    ) {
      const previousId = previousSelectedCompanyOptions[0].id;
      const id = selectedCompanyOptions[0].id;
      if (previousId !== id) {
        setSelectedLocationOptions([]);
        setSelectedAreaOptions([]);
        setSelectedContactOptions([]);
        setSelectedProjectOptions([]);
      }
    }
  }, [selectedCompanyOptions]);

  useEffect(() => {
    const controlRef = new FilterControlRef();
    controlRef.onClearData = onClearAll;
    controlRef.getFilters = getFilters;
    props.field.controlRef = controlRef;
    // const data: OwnerFilterData = props.field.value;
    // setDataFilterSelected(data);
  }, []);

  useEffect(() => {
    const data: OwnerFilterData = props.field.value;
    setDataFilterSelected(data);
  }, [props.field.value]);

  const setDataFilterSelected = (data: OwnerFilterData) => {
    if (data?.companyKeys && data?.companyKeys.length > 0) {
      let dataList = data.companyItems.map((data) => {
        return {
          id: data.key ?? data.id,
          description: data.text ?? data.description,
        };
      });
      setSelectedCompanyOptions(dataList);
    }

    if (data?.locationKeys && data?.locationKeys.length > 0) {
      let dataList = data.locationItems.map((data) => {
        return {
          id: data.key ?? data.id,
          description: data.text ?? data.description,
        };
      });
      setSelectedLocationOptions(dataList);
    }

    if (data?.areaKeys && data?.areaKeys.length > 0) {
      let dataList = data.areaItems.map((data) => {
        return {
          id: data.key ?? data.id,
          description: data.text ?? data.description,
        };
      });
      setSelectedAreaOptions(dataList);
    }

    if (data?.contactKeys && data?.contactKeys.length > 0) {
      let dataList = data.contactItems.map((data) => {
        return {
          id: data.key ?? data.id,
          description: data.text ?? data.description,
        };
      });
      setSelectedContactOptions(dataList);
    }

    if (data?.projectKeys && data?.projectKeys.length > 0) {
      let dataList = data.projectItems.map((data) => {
        return {
          id: data.key ?? data.id,
          description: data.text ?? data.description,
        };
      });
      setSelectedProjectOptions(dataList);
    }
  };

  useEffect(() => {
    filFilterData();
  }, [selectedCompanyOptions, selectedLocationOptions, selectedAreaOptions, selectedContactOptions, selectedProjectOptions]);

  useDebouncedEffect(
    () => {
      if (companySearchText !== undefined) {
        onSearchCompany(companySearchText);
      }
    },
    [companySearchText],
    500
  );
  useDebouncedEffect(
    () => {
      if (locationSearchText !== undefined) {
        onSearchLocation(locationSearchText);
      }
    },
    [locationSearchText],
    500
  );
  useDebouncedEffect(
    () => {
      if (areaSearchText !== undefined) {
        onSearchArea(areaSearchText);
      }
    },
    [areaSearchText],
    500
  );
  useDebouncedEffect(
    () => {
      if (contactSearchText !== undefined) {
        onSearchContact(contactSearchText);
      }
    },
    [contactSearchText],
    500
  );
  useDebouncedEffect(
    () => {
      if (projectSearchText !== undefined) {
        onSearchProject(projectSearchText);
      }
    },
    [projectSearchText],
    500
  );

  const onSearchCompany = async (text: string = '') => {
    tryToCloseDetailPanel();
    let items: IMultipleSelectionFieldOption[] = [
      {
        id: globalAppVariables.LOGGED_IN_COMPANY_OPTION,
        description: translate('Equipment.LoggedInCompany'),
        data: {
          description: translate('Equipment.LoggedInCompany'),
        },
      },
    ];
    if (typeof props.onSearch === 'function') {
      const result = (await props.onSearch({ field: OwnerFilterData.OwnersProperty, text: text })) as any[];

      if (result && result.length > 0) {
        let dataList = result.map((data) => {
          return {
            id: data.CompanyId,
            description: data.Description,
            data: data,
          };
        });
        items = items.concat(dataList);
      }
    }
    setCompanyOptions(items);
  };

  const onSearchLocation = async (text: string = '') => {
    tryToCloseDetailPanel();
    let items: IMultipleSelectionFieldOption[] = [
      {
        id: globalAppVariables.NONE_EXISTING_VALUE_INT,
        description: translate('Menu.EmptyLocation'),
        data: {
          description: translate('Menu.EmptyLocation'),
        },
      },
      // {
      //   id: globalAppVariables.FAVORITE_LOCATIONS,
      //   description: '- ' + translate('Menu.FavoriteLocations') + ' -',
      //   data: {
      //     description: '- ' + translate('Menu.FavoriteLocations') + ' -',
      //   },
      // },
    ];
    if (typeof props.onSearch === 'function') {
      if (selectedCompanyOptions.length > 0) {
        const company = selectedCompanyOptions[0];
        const result = (await props.onSearch({
          field: OwnerFilterData.LocationProperty,
          companyId: company.id,
          text: text,
        })) as any[];
        if (result && result.length > 0) {
          let dataList = result.map((data) => {
            return {
              id: data.locationId,
              description: data.description,
              data: data,
            };
          });
          items = items.concat(dataList);
        }
      }
    }
    setLocationOptions(items);
  };

  const onSearchArea = async (text: string = '') => {
    let items: IMultipleSelectionFieldOption[] = [
      {
        id: globalAppVariables.NONE_EXISTING_VALUE_INT,
        description: translate('Menu.EmptyArea'),
        data: {
          description: translate('Menu.EmptyArea'),
        },
      },
      {
        id: globalAppVariables.FAVORITE_AREAS,
        description: '- ' + translate('Menu.FavoriteAreas') + ' -',
        data: {
          description: '- ' + translate('Menu.FavoriteAreas') + ' -',
        },
      },
    ];
    if (typeof props.onSearch === 'function') {
      if (selectedCompanyOptions.length > 0) {
        const company = selectedCompanyOptions[0];
        let locationKeys: any;
        if (selectedLocationOptions.length > 0) {
          locationKeys = selectedLocationOptions.map((item) => {
            return item.id;
          });
        }
        const result = (await props.onSearch({
          field: OwnerFilterData.AreaProperty,
          companyId: company.id,
          locationIds: locationKeys,
          text: text,
        })) as any[];

        if (result && result.length > 0) {
          let dataList = result.map((data) => {
            return {
              id: data.AreaId,
              description: data.Description,
              data: data,
            };
          });
          items = items.concat(dataList);
        }
      }
    }
    setAreaOptions(items);
  };

  const onSearchContact = async (text: string = '') => {
    let items: IMultipleSelectionFieldOption[] = [
      {
        id: globalAppVariables.NONE_EXISTING_VALUE_INT,
        description: translate('Menu.EmptyContact'),
        data: {
          description: translate('Menu.EmptyContact'),
        },
      },
      {
        id: globalAppVariables.LOGGED_IN_USER_OPTION,
        description: translate('Settings.Option.LoggedInUser'),
        data: {
          description: translate('Settings.Option.LoggedInUser'),
        },
      },
    ];
    if (typeof props.onSearch === 'function') {
      if (selectedCompanyOptions.length > 0) {
        const company = selectedCompanyOptions[0];
        const result = (await props.onSearch({
          field: OwnerFilterData.ContactProperty,
          companyId: company.id,
          text: text,
        })) as any[];
        if (result && result.length > 0) {
          let dataList = result.map((data) => {
            return {
              id: data.ContactId,
              description: data.Name,
              data: data,
            };
          });
          items = items.concat(dataList);
        }
      }
    }
    setContactOptions(items);
  };

  const onSearchProject = async (text: string = '') => {
    let items: IMultipleSelectionFieldOption[] = [
      {
        id: globalAppVariables.NONE_EXISTING_VALUE_INT,
        description: translate('Menu.EmptyProject'),
        data: {
          description: translate('Menu.EmptyProject'),
        },
      },
    ];
    if (typeof props.onSearch === 'function') {
      if (selectedCompanyOptions.length > 0) {
        const company = selectedCompanyOptions[0];
        const result = (await props.onSearch({
          field: OwnerFilterData.ProjectProperty,
          companyId: company.id,
          text: text,
        })) as any[];
        if (result && result.length > 0) {
          let dataList = result.map((data) => {
            return {
              id: data.ProjectId,
              description: data.Description,
              data: data,
            };
          });
          items = items.concat(dataList);
        }
      }
    }
    setProjectOptions(items);
  };

  const onClearData = (type: string) => {
    switch (type) {
      case OwnerFilterData.OwnersProperty:
        setSelectedCompanyOptions([]);
        setSelectedLocationOptions([]);
        setSelectedAreaOptions([]);
        setSelectedContactOptions([]);
        setSelectedProjectOptions([]);
        break;
      case OwnerFilterData.LocationProperty:
        setSelectedLocationOptions([]);
        setSelectedAreaOptions([]);
        break;
      case OwnerFilterData.AreaProperty:
        setSelectedAreaOptions([]);
        break;
      case OwnerFilterData.ContactProperty:
        setSelectedContactOptions([]);
        break;
      case OwnerFilterData.ProjectProperty:
        setSelectedProjectOptions([]);
        break;
    }
  };

  const onClearAll = (clearCompany = false) => {
    props.field.value = new OwnerFilterData([]);
    if (clearCompany === true) {
      setSelectedCompanyOptions([]);
    }
    setSelectedLocationOptions([]);
    setSelectedAreaOptions([]);
    setSelectedContactOptions([]);
    setSelectedProjectOptions([]);
  };

  const filFilterData = () => {
    const data: OwnerFilterData = props.field.value;
    data.companyKeys = [];
    data.companyItems = [];
    data.company = new OwnerFieldItem();

    data.locationItems = [];
    data.locationKeys = [];

    data.areaItems = [];
    data.areaKeys = [];

    data.contactItems = [];
    data.contactKeys = [];

    data.projectItems = [];
    data.projectKeys = [];

    data.companyItems = selectedCompanyOptions;
    data.locationItems = selectedLocationOptions;
    data.areaItems = selectedAreaOptions;
    data.contactItems = selectedContactOptions;
    data.projectItems = selectedProjectOptions;

    data.companyItems && (data.companyKeys = data.companyItems.map((item) => Number(item.id)));
    if (data.companyItems.length == 1) {
      data.company.key = Number(data.companyItems[0].id);
      data.company.text = data.companyItems[0].description;
    }
    data.locationItems && (data.locationKeys = data.locationItems.map((item) => Number(item.id)));
    data.areaItems && (data.areaKeys = data.areaItems.map((item) => Number(item.id)));
    data.contactItems && (data.contactKeys = data.contactItems.map((item) => Number(item.id)));
    data.projectItems && (data.projectKeys = data.projectItems.map((item) => Number(item.id)));
  };
  const getFilters = (): FieldFilter[] | undefined => {
    const filters: FieldFilter[] = [];
    const data: OwnerFilterData = props.field.value;
    // if (data.company.key !== globalAppVariables.IGNORE_VALUE_INT) {
    //   filters.push({ fieldName: OwnerFilterData.OwnerProperty, fieldValue: data.company.key.toString(), type: props.field.type, options: [data.company] });
    // }

    if (data.companyKeys && data.companyKeys.length > 0) {
      filters.push({
        fieldName: OwnerFilterData.OwnersProperty,
        fieldValue: data.companyKeys.join(','),
        type: props.field.type,
        options: data.companyItems,
      });
    }

    if (data.locationKeys && data.locationKeys.length > 0) {
      filters.push({
        fieldName: OwnerFilterData.LocationProperty,
        fieldValue: data.locationKeys.join(','),
        type: props.field.type,
        options: data.locationItems,
      });
    }

    if (data.areaKeys && data.areaKeys.length > 0) {
      filters.push({
        fieldName: OwnerFilterData.AreaProperty,
        fieldValue: data.areaKeys.join(','),
        type: props.field.type,
        options: data.areaItems,
      });
    }

    if (data.contactKeys && data.contactKeys.length > 0) {
      filters.push({
        fieldName: OwnerFilterData.ContactProperty,
        fieldValue: data.contactKeys.join(','),
        type: props.field.type,
        options: data.contactItems,
      });
    }

    if (data.projectKeys && data.projectKeys.length > 0) {
      filters.push({
        fieldName: OwnerFilterData.ProjectProperty,
        fieldValue: data.projectKeys.join(','),
        type: props.field.type,
        options: data.projectItems,
      });
    }

    if (filters.length > 0) {
      filters.unshift({ fieldName: OwnerFilterData.OwnerShipProperty, type: props.field.type, fieldValue: '' });
    }
    return filters;
  };

  const onCompanyRenderHeader = (panelProps: IPanelProps | undefined) => {
    return (
      <>
        <span className="title">{panelProps?.title}</span>
        <div className="company-instruction">
          <FocusZone disabled>
            <span dangerouslySetInnerHTML={{ __html: translate('Company.lblCompanyConnectionAlready') }}></span>
          </FocusZone>
          <span>{translate('AppInspect.RequireTextSearch') + '. ' + translate('CommonResource.lblInfoLabel')}</span>
        </div>
      </>
    );
  };

  const onCompanyOptionChanges = (options: IMultipleSelectionFieldOption[]) => {
    setSelectedCompanyOptions(options);
    if (options.length !== 1) {
      onClearData(OwnerFilterData.LocationProperty);
      onClearData(OwnerFilterData.AreaProperty);
      onClearData(OwnerFilterData.ContactProperty);
      onClearData(OwnerFilterData.ProjectProperty);
    }
  };

  const onLocationOptionChanges = (options: IMultipleSelectionFieldOption[]) => {
    setSelectedLocationOptions(options);
  };

  const onAreaOptionChanges = (options: IMultipleSelectionFieldOption[]) => {
    setSelectedAreaOptions(options);
  };

  const onContactOptionChanges = (options: IMultipleSelectionFieldOption[]) => {
    setSelectedContactOptions(options);
  };

  const onProjectOptionChanges = (options: IMultipleSelectionFieldOption[]) => {
    setSelectedProjectOptions(options);
  };

  const tryToCloseDetailPanel = () => {
    if (props.detailPanelRef && props.detailPanelRef?.current && props.detailPanelRef?.current?.onDismissPanel) {
      props.detailPanelRef?.current?.onDismissPanel();
    }
  };

  return (
    <div className="new-ownership-field">
      <MultipleSelectionField
        selectedOptions={selectedCompanyOptions}
        options={companyOptions}
        isDisplayClearButton={false}
        isAutoFocusSearchBox
        panelProps={{
          type: PanelType.custom,
          customWidth: '400px',
          className: 'main-ownership-field-panel',
          title: translate('Equipment.EquipmentOwner'),
          onRenderHeader: onCompanyRenderHeader,
        }}
        textFieldProps={{
          placeholder: translate('Equipment.PleaseSelectCompany'),
          label: translate(props.field.label),
          disabled: true,
        }}
        onRenderLabelCheckbox={(item) => {
          const iconClass = 'relation-icon relation-' + item?.data?.AccessType;
          return (
            <div className="row-company">
              <div className="col-icon">
                <div className={iconClass}></div>
              </div>
              <div className="col-name">{item?.description}</div>
            </div>
          );
        }}
        onAfterClickTextField={onSearchCompany}
        onAfterClearIconClick={() => onClearData(OwnerFilterData.OwnersProperty)}
        onSearchTextChanges={setCompanySearchText}
        onOptionChanges={onCompanyOptionChanges}
        selectAllPermission={false}
      />

      <div className="sub-multiple-selection">
        <MultipleSelectionField
          selectedOptions={selectedLocationOptions}
          isDisplayClearButton={!props.field.disabled}
          options={locationOptions}
          panelProps={{ title: translate('Equipment.Location'), className: 'sub-ownership-field-panel' }}
          textFieldProps={{
            placeholder: translate('Equipment.PleaseSelectLocation'),
            disabled: props.field.disabled || !(selectedCompanyOptions.length == 1),
          }}
          onAfterClearIconClick={() => onClearData(OwnerFilterData.LocationProperty)}
          onAfterClickTextField={onSearchLocation}
          onSearchTextChanges={setLocationSearchText}
          onOptionChanges={onLocationOptionChanges}
        />
        {/* <MultipleSelectionField
          selectedOptions={selectedAreaOptions}
          isDisplayClearButton={!props.field.disabled}
          options={areaOptions}
          panelProps={{ title: translate('Equipment.Area'), className: 'sub-ownership-field-panel' }}
          textFieldProps={{
            placeholder: translate('Menu.SelectArea'),
            disabled: props.field.disabled || !(selectedCompanyOptions.length == 1),
          }}
          onRenderLabelCheckbox={(item) => {
            return (
              <div className="content">
                <div className="line">{item?.description}</div>
                <div className="sub">{item?.data.LocationDescription}</div>
              </div>
            );
          }}
          onAfterClearIconClick={() => onClearData(OwnerFilterData.AreaProperty)}
          onAfterClickTextField={onSearchArea}
          onSearchTextChanges={setAreaSearchText}
          onOptionChanges={onAreaOptionChanges}
        />

        <MultipleSelectionField
          selectedOptions={selectedContactOptions}
          isDisplayClearButton={!props.field.disabled}
          options={contactOptions}
          panelProps={{ title: translate('Equipment.Contact'), className: 'sub-ownership-field-panel' }}
          textFieldProps={{
            placeholder: translate('Menu.SelectContact'),
            disabled: props.field.disabled || !(selectedCompanyOptions.length == 1),
          }}
          onRenderLabelCheckbox={(item) => {
            return (
              <div className="content">
                <div className="line">{item?.description}</div>
                <div className="sub">{item?.data.Email}</div>
              </div>
            );
          }}
          onAfterClearIconClick={() => onClearData(OwnerFilterData.ContactProperty)}
          onAfterClickTextField={onSearchContact}
          onSearchTextChanges={setContactSearchText}
          onOptionChanges={onContactOptionChanges}
        />

        <MultipleSelectionField
          selectedOptions={selectedProjectOptions}
          isDisplayClearButton={!props.field.disabled}
          options={projectptions}
          panelProps={{ title: translate('Equipment.Project'), className: 'sub-ownership-field-panel' }}
          textFieldProps={{
            placeholder: translate('Menu.SelectProject'),
            disabled: props.field.disabled || !(selectedCompanyOptions.length == 1),
          }}
          onAfterClearIconClick={() => onClearData(OwnerFilterData.ProjectProperty)}
          onAfterClickTextField={onSearchProject}
          onSearchTextChanges={setProjectSearchText}
          onOptionChanges={onProjectOptionChanges}
        /> */}
      </div>
    </div>
  );
};
