import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DialogTypeEnum } from '../../enums/dialog-type.enum';
import { RootState } from '../store';

type State = {
  isLoading: boolean;
  loggedIn: boolean;
  commonDialog: CommonDialogState;
  errorDialog: ErrorDialogState;
};
export interface CommonDialogState {
  isHidden: boolean;
  title: string | undefined | null;
  message: string;
}

export interface ErrorDialogState {
  isHidden: boolean;
  type: DialogTypeEnum;
  message: string;
  messageCaption: string;
  title: string;
}

const INTIAL_STATE: State = {
  isLoading: false,
  loggedIn: false,
  commonDialog: {
    isHidden: true,
    title: '',
    message: '',
  },
  errorDialog: {
    isHidden: true,
    type: DialogTypeEnum.Error,
    message: '',
    messageCaption: '',
    title: '',
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState: INTIAL_STATE,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    showCommonDialog: (state, action: PayloadAction<{ title: string; message: string }>) => {
      state.commonDialog.isHidden = false;
      state.commonDialog.message = action.payload.message;
      state.commonDialog.title = action.payload?.title ?? null;
    },
    showErrorDialog: (state, action: PayloadAction<{ type: DialogTypeEnum; message: string; messageCaption: string; title: string }>) => {
      state.errorDialog.isHidden = false;
      state.errorDialog.type = action.payload.type;
      state.errorDialog.message = action.payload.message;
      state.errorDialog.messageCaption = action.payload.messageCaption;
      state.errorDialog.title = action.payload.title;
    },
    hiddenErrorDialog: (state) => {
      state.errorDialog.isHidden = true;
    },
    hiddenCommonDialog: (state) => {
      state.commonDialog.isHidden = true;
    },
  },
});

export const appActions = appSlice.actions;
export const appReducer = appSlice.reducer;

const selectSelf = (state: RootState) => state.app;

export const appSelector = createSelector(selectSelf, (state) => state);
export const appIsLoadingSelector = createSelector(selectSelf, (state) => state.isLoading);
export const commonSelector = createSelector(selectSelf, (state) => state.commonDialog);
export const errorSelector = createSelector(selectSelf, (state) => state.errorDialog);