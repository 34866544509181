import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { CommonService } from '../services/common.service';
import { getLoggedAccountId, getLoggedContactId } from '../utils/localStorage.utils';
import ApplicationSetting from './application.constants';
import { ClientApplication } from './client-application.enum';

const reactPlugin = new ReactPlugin();
const ApplicationInsightBufferKey = CommonService.generateLocalKey(ApplicationSetting.applicationInsightBufferKey);

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: ApplicationSetting.applicationInsightKey,
    extensions: [reactPlugin as any],
    extensionConfig: {},
    enableAutoRouteTracking: false,
    disableAjaxTracking: true,
    autoTrackPageVisitTime: false,
    bufferOverride: {
      getItem(_, ___) {
        return localStorage.getItem(ApplicationInsightBufferKey) || '';
      },
      setItem(_, __, data) {
        localStorage.setItem(ApplicationInsightBufferKey, data);
        return true;
      },
    },
  },
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  //https://github.com/Microsoft/ApplicationInsights-dotnet/blob/39a5ef23d834777eefdd72149de705a016eb06b0/Schema/PublicSchema/ContextTagKeys.bond#L93
  env.tags = env.tags || [];
  env.tags['ai.operation.id'] = ClientApplication.OnixExpress;
  env.tags['ai.operation.name'] = 'OnixExpress';
  env.tags['ai.user.id'] = getLoggedContactId();

  env.tags['ai.user.authUserId'] = getLoggedAccountId();
  env.tags['ai.user.accountId'] = getLoggedAccountId();
});

export { appInsights, reactPlugin };

