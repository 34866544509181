import { createContext, useEffect, useState } from 'react';
import Loading from '../common/ui/Loading/Loading';
import { CompanyExpressSetup } from '../models/company-express-setup.model';
import { HomeViewInfo } from '../models/home-view.model';
import { CompanyService } from '../services/company.service';
import { getFirstAlias } from '../utils/browser.utils';
import { setupAppColor } from '../utils/css.utils';
import { getLanguageId } from '../utils/localStorage.utils';

export type CompanyExpressPreviewContextType = CompanyExpressSetup & HomeViewInfo;

export const CompanyExpressPreviewContext = createContext<CompanyExpressPreviewContextType>({} as CompanyExpressPreviewContextType);

type Props = {
  children: React.ReactNode;
};

export const CompanyExpressPreviewContextProvider = ({ children }: Props) => {
  const [previewInfo, setPreviewInfo] = useState({} as CompanyExpressPreviewContextType);
  const alias = getFirstAlias();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadPreviewInfo = async () => {
      setLoading(true);
      try {
        const result = await CompanyService.getCompanyExpressPreviewInfo(alias, getLanguageId());
        if (result) {
          setPreviewInfo(result);
          setupAppColor({
            primary: result.primaryColor,
            primaryText: result.primaryTextColor,
          });
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    loadPreviewInfo();
  }, [alias]);

  if (loading) return <Loading />;

  return <CompanyExpressPreviewContext.Provider value={previewInfo}>{children}</CompanyExpressPreviewContext.Provider>;
};
