const getDistinctValuesByKey = (array: any, key: string) => {
  if (array === null || array === undefined || array.length === 0) return;

  return array.map((item: any) => item[key])
    .filter((value: any, index: any, self: any) => self.indexOf(value) === index);
}

const insertItemsIntoArray = <T>(arr: T[], items: T[], index: number): any => {
  if (arr && items && items.length > 0) {
    const leftArr = arr.slice(0, index);
    const rightArr = index >= arr.length ? [] : [...arr.slice(index, arr.length)];
    const newArr = [...leftArr, ...items, ...rightArr];
    return newArr;
  }
  return null;
}

const sortAZFn = <T>(selector: (item: T) => any = (item => item)) => (a: T, b: T) => selector(a) > selector(b) ? 1 : -1

const swap = <T>(arr: T[], idx: number, idx2: number): T[] => {
  // Destructuring swap is nice but it's super noisy, so no.
  const tmpEl = arr[idx]

  arr[idx] = arr[idx2]
  arr[idx2] = tmpEl

  return arr
}

type SearchArraySelectorFn<T, U = string | number | boolean> = (item: T) => U | null | undefined

/**
 * Search array with keyword. This is search-contains.
 * If you want to case-insensitive, convert the keyword and all those selectors to upper or lowercase.
 * 
 * NOTE: be careful when passing selectors returning boolean.
 * 
 * @param arr the array to be searched against.
 * @param keyword the keyword for searching
 * @param selectors list of selectors, if the value returned from the selector is boolean true, it will ignore keyword.
 * @returns new array that matches keyword with selectors.
 */
const searchArray = <T, U extends string | number | boolean>(arr: T[], keyword: U, ...selectors: SearchArraySelectorFn<T, U>[]): T[] => {
  if (!selectors?.length) {
    return arr
  }

  return arr.filter(item => selectors.some(selector => {
    const selectorItemValue = selector(item)

    if (selectorItemValue === null || selectorItemValue === undefined) {
      return false
    }

    if (typeof selectorItemValue === "boolean") {
      return selectorItemValue
    }

    if (
      typeof selectorItemValue === "string"
      && typeof keyword === "string"
    ) {
      return selectorItemValue.includes(keyword)
    }

    return selectorItemValue === keyword
  }))
}

const distinctArray = <T, K extends keyof T>(arr: T[], key: K): T[] => {
  const seen = new Set<T[K]>();
  return arr.filter((item) => {
    const keyValue = item[key];
    if (!seen.has(keyValue)) {
      seen.add(keyValue);
      return true;
    }
    return false;
  });
}

export {
  distinctArray, getDistinctValuesByKey,
  insertItemsIntoArray,
  searchArray,
  sortAZFn,
  swap,
  type SearchArraySelectorFn
};

