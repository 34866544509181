import { IColumn, IDetailsList } from '@fluentui/react';
import { useCallback, useEffect, useRef } from 'react';
import { CommonService } from '../../services/common.service';
/**
 * Open a default dialog.
 *
 * @columns : the column list of DetailList
 * @localStorageKey : the key to save to and get from  localstorage, if null then not remember
 * @callbackPreventRemember :  detect when you want to prevent remember, if this method return to true then prevent
 * defaul prevent: (column?.calculatedWidth ?? column?.maxWidth) == newWidth
 */
export const useRememberColumnSizing = (
  columns: IColumn[],
  localStorageKey?: string,
  callbackPreventRemember?: (column?: IColumn, newWidth?: number, columnIndex?: number) => boolean
) => {
  const detailListRef = useRef<IDetailsList>(null);

  useEffect(() => {
    if (!localStorageKey) {
      return;
    }

    if (detailListRef.current && columns.length) {
      const rememberColumnSizes = JSON.parse(CommonService.getLocalStorageByKey(localStorageKey) ?? '{}');

      columns.forEach((column) => {
        if (rememberColumnSizes[column.key]) {
          column.calculatedWidth = rememberColumnSizes[column.key];
        }
        detailListRef.current?.updateColumn(column, { width: column.calculatedWidth ?? column.maxWidth });
      });
    }
  }, [columns]);

  const onColumnResized = useCallback(
    (column?: IColumn, newWidth?: number, columnIndex?: number) => {
      if (!localStorageKey) {
        return;
      }

      if (callbackPreventRemember && callbackPreventRemember(column)) {
        return;
      } else if ((column?.calculatedWidth ?? column?.maxWidth) == newWidth) {
        return;
      }
      const columnSizes = CommonService.getLocalStorageByKey(localStorageKey);
      const currentColumnSizes = columnSizes ? JSON.parse(columnSizes) : {};
      currentColumnSizes[(column as IColumn).key] = newWidth;
      CommonService.setLocalStorage(localStorageKey, JSON.stringify(currentColumnSizes));
    },
    [callbackPreventRemember, localStorageKey]
  );

  return { detailListRef, onColumnResized };
};
