declare global {
  interface String {
    parseToArrayOfNumbers(str: string): number[];
  }
}

String.prototype.parseToArrayOfNumbers = function (this: string): number[] {
  if(this === undefined || this === null || this === '') return [];
  
  return this.split(',').map(Number);
};

export { };

