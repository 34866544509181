/* eslint-disable @typescript-eslint/naming-convention */
export enum DocumentTypes {
  Certificate = 1,
  JobReport = 2,
  Intructions = 3,
  JobReportDevationOnly = 4,
  Others = 5,
  ControlDocumentation = 6,
  Picture = 7,
  ProjectPageNumber2 = 8,
  DeclarationOfConfirmity = 9,
  Service = 10,
  OriginalCertificate = 11,
  ControlDocumentation4Year = 12,
  OriginalIntructions = 13,
  OriginalDeclarationOfConfirmity = 14,
  ServiceReport = 15,
  SummaryJobReport = 16
}
