import {
  CommandBarButton,
  ContextualMenuItemType,
  IButtonProps,
  IContextualMenuItem,
  IContextualMenuProps,
  IIconProps,
} from '@fluentui/react';
import { TFunction } from 'i18next';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useTracking } from '../../../common/hooks/useTracking';
import { TrackingEvent } from '../../../constants/tracking-event.constant';
import { allEquipmentsSelector } from '../../../states/equipment/equipmentSlice';

interface IDownloadMenuProps {
  onDownloadCallback?: (equipmentIds: number[]) => void;
  canExportEquipment?: (numberOfEquipment: number | undefined) => boolean;
  isDisableExportMenuItem?: boolean;
}
const iconDownloadProps: IIconProps = {
  iconName: 'ReportDocument',
};

const MenuStyles: IContextualMenuProps['styles'] = {
  header: {
    fontSize: 14,
  },
};

const ButtonStyles: IButtonProps['styles'] = {
  root: {
    height: '100%',
  },
  rootHovered: {
    backgroundColor: '#f3f2f1',
    color: '#201f1e',
  },
};

const getStaticSectionProps = (
  t: TFunction<'translation', undefined>,
  sectionSelectedItems: IContextualMenuItem[],
  sectionStandalone: IContextualMenuItem[]
): IContextualMenuProps['items'] => [
  {
    key: 'section_selectedItems',
    itemType: ContextualMenuItemType.Section,
    sectionProps: {
      title: t('Equipment.ReportBasedOnSelectedItems'),
      items: sectionSelectedItems,
      bottomDivider: true,
    },
    className: 'section-header',
  },
  {
    key: 'section_standalone',
    itemType: ContextualMenuItemType.Section,
    sectionProps: {
      title: t('Equipment.ReportStandalone'),
      items: sectionStandalone,
    },
    className: 'section-header',
  },
];

const DownloadMenu = (props: IDownloadMenuProps) => {
  const [t] = useTranslation();
  const { trackEvent } = useTracking({ module: 'Equipment' });
  const equipments = useSelector(allEquipmentsSelector);
  const selectedEquipmentRef = useRef(equipments);
  const clickExportCSVRef = useRef(() => {});
  // Refresh everytime this component rendered.
  selectedEquipmentRef.current = equipments;

  clickExportCSVRef.current = () => {
    trackEvent(TrackingEvent.Equipment.ReportActions.ExportToExcel);

    const equipmentIds = equipments.map((x) => x.equipmentId);
    props.onDownloadCallback!(equipmentIds);
  };

  const reportMenuItems: IContextualMenuProps['items'] = useMemo(
    () =>
      getStaticSectionProps(
        t,
        [
          {
            key: 'ReportEqListCSV',
            text: t('Equipment.ReportEqListCSV'),
            iconProps: { iconName: 'ExcelDocument' },
            onClick: () => {
              clickExportCSVRef.current();
              trackEvent(TrackingEvent.Equipment.Toolbar.Reports.ExportToExcel);
            },
          },
        ],
        []
      ),
    []
  );

  const reportMenu: IContextualMenuProps = {
    items: reportMenuItems,
    directionalHintFixed: true,
    styles: MenuStyles,
    className: 'eq2-download-menu',
  };

  return (
    <>
      <CommandBarButton
        iconProps={iconDownloadProps}
        menuProps={reportMenu}
        disabled={props.isDisableExportMenuItem}
        checked={false}
        text={t('RibbonMenu.Report')}
        styles={ButtonStyles}
      />
    </>
  );
};

export default DownloadMenu;
