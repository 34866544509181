import { NumberEquipmentSizeEnum, OrganizationSizeEnum } from '../enums/company.enum';

export const OrganizationSizeConstants = {
  [OrganizationSizeEnum.SIZE_0_1]: '0-1',
  [OrganizationSizeEnum.SIZE_2_10]: '2-10',
  [OrganizationSizeEnum.SIZE_11_50]: '11-50',
  [OrganizationSizeEnum.SIZE_51_200]: '51-200',
  [OrganizationSizeEnum.SIZE_201_500]: '201-500',
  [OrganizationSizeEnum.SIZE_501_1000]: '501-1000',
  [OrganizationSizeEnum.SIZE_OVER_1000]: '1000+',
};

export const NumberEquipmentSizeConstants = {
  [NumberEquipmentSizeEnum.SIZE_0_200]: '0-200',
  [NumberEquipmentSizeEnum.SIZE_200_500]: '200-500',
  [NumberEquipmentSizeEnum.SIZE_500_1000]: '500-1000',
  [NumberEquipmentSizeEnum.SIZE_1000_5000]: '1000-5000',
  [NumberEquipmentSizeEnum.SIZE_OVER_5000]: '5000+',
};
