import {
  ContextualMenuItemType,
  DefaultButton,
  IButtonProps,
  IContextualMenuItem,
  IContextualMenuProps,
  mergeStyleSets,
} from '@fluentui/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedEffect } from '../../../../../common/hooks/useDebouncedEffect';
import { DocumentTypeDto } from '../../../../../models/document/documentType.model';
import { documentService } from '../../../../../services/document.service';

const FilterGroupKey = {
  DocumentTypeGroup: 'DocumentTypeGroup',
};

export interface IDocumentFilterButton {
  /**
   * Init value when component did mount
   */
  value?: IDocumentFilterButtonValue;
  /**
   * Filter values change function
   */
  onValueChanges?: (value: IDocumentFilterButtonValue) => void;
}

const classNames = mergeStyleSets({
  menuSection: {
    '.ms-ContextualMenu-header': {
      // color: color.$primaryColor,

      '.ms-ContextualMenu-linkContent': {
        '.ms-ContextualMenu-itemText': {
          fontSize: 14,
          marginLeft: -9,
        },
      },
    },
  },
});

export interface IDocumentFilterButtonValue {
  activeStatuses: boolean[];
  documentTypeIds: number[];
}

export const DocumentFilterButton = (props: IDocumentFilterButton) => {
  const [translate] = useTranslation();

  const [documentTypes, setDocumentTypes] = useState<DocumentTypeDto[]>([]);

  const [contextualMenuProps, setContextualMenuProps] = useState<IContextualMenuProps>();

  const [value, setValue] = useState<IDocumentFilterButtonValue | undefined>(undefined);

  useDebouncedEffect(
    () => {
      if (props.onValueChanges && value) {
        props.onValueChanges(value);
      }
    },
    [value],
    700
  );

  useEffect(() => {
    const configContextualMenuProps = async () => {
      const clearButton = {
        key: 'clear',
        itemType: ContextualMenuItemType.Section,
        sectionProps: {
          items: [{ key: 'clearButton', text: translate('CaptionResource.Clearallfilters'), iconProps: { iconName: 'ClearFilter' } }],
        },
      } as IContextualMenuItem;

      const documentTypeGroup: IContextualMenuItem = {
        key: FilterGroupKey.DocumentTypeGroup,
        itemType: ContextualMenuItemType.Section,
        sectionProps: {
          topDivider: false,
          bottomDivider: true,
          title: translate('Document.Type'),
          items: documentTypes.map((item) => {
            const valueParsed = Number.parseInt(item.documentTypeId.toString());
            return {
              key: item.documentTypeId.toString(),
              text: item.description,
              canCheck: true,
              checked: value?.documentTypeIds?.includes(valueParsed),
              data: {
                group: FilterGroupKey.DocumentTypeGroup,
                value: valueParsed,
              },
            };
          }),
        },
        className: classNames.menuSection,
      };

      setContextualMenuProps({
        items: documentTypes.length
          ? [
              clearButton,
              {
                key: 'divider1',
                itemType: ContextualMenuItemType.Divider,
              },
              documentTypeGroup,
            ]
          : [],
        onItemClick: (ev, item) => {
          if (ev?.preventDefault) {
            ev.preventDefault();
            ev.stopPropagation();
          }
          const newValue = { ...(value ?? { activeStatuses: [], documentTypeIds: [] }) };

          if (!item?.data) {
            newValue.activeStatuses = [];
            newValue.documentTypeIds = [];
          } else {
            if (item?.data.group === FilterGroupKey.DocumentTypeGroup) {
              const keyNumber = Number.parseInt(item.key);
              newValue.documentTypeIds = [...newValue.documentTypeIds];
              if (item.checked) {
                newValue.documentTypeIds = newValue.documentTypeIds.filter((m) => m !== keyNumber);
              } else {
                newValue.documentTypeIds.push(keyNumber);
              }
            }
          }
          setValue(newValue);
        },
      });
    };
    configContextualMenuProps();
  }, [documentTypes, translate, value]);

  const defaultButtonProps = useMemo((): IButtonProps => {
    const countFilters = value ? value.activeStatuses.length + value.documentTypeIds.length : 0;
    let iconName = 'List';
    let text = translate('Company.Filters');
    if (countFilters > 0) {
      iconName = 'FilterSolid';
      text = `${text} (${countFilters})`;
    }
    return {
      text,
      iconProps: { iconName },
      style: { border: 'none' },
    };
  }, [translate, value]);

  const getDocumentTypes = () => {
    if (!documentTypes.length) {
      documentService.getDocumentTypesWithFilters().then((item) => {
        setDocumentTypes(item);
      });
    }
  };

  return <DefaultButton {...defaultButtonProps} onMenuClick={getDocumentTypes} allowDisabledFocus menuProps={contextualMenuProps} />;
};
