import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useTracking } from '../../common/hooks/useTracking';
import SideBar from '../../components/SideBar/SideBar';
import { Modules } from '../../constants/modules.constants';
import { TrackingPage } from '../../constants/tracking-page.constant';

const ContentLayout = () => {
  const { trackPageView } = useTracking({ module: Modules.CoreInsights });

  useEffect(() => {
    trackPageView(TrackingPage.HomePage);
  }, []);

  return (
    <>
      <SideBar />
      <div className="w-full bg-grey10 min-h-full overflow-auto">
        <Outlet />
      </div>
    </>
  );
};

export default ContentLayout;
