import { CommonGlobal } from './CommonGlobal';
import ModalContainer from './common/ui/Modal/ModalContainer';
import Routers from './routers/Routers';

function App() {
  return (
    <>
      <CommonGlobal></CommonGlobal>
      <Routers />
      <ModalContainer />
    </>
  );
}

export default App;
