import { DialogTypeEnum } from "../enums/dialog-type.enum";
import { appActions } from "../states/app/appSlice";
import { appDispatch } from "../states/store";

export const showCommonDialog = (title: string, message: string = '') => {
  const payload = {
    title: title,
    message: message
  };
  appDispatch(appActions.showCommonDialog(payload))
}

export const showErrorDialogWithResponse = (err: any, type: DialogTypeEnum = DialogTypeEnum.Error) => {
  const payload = {
    type: type,
    message: err?.message ?? '',
    messageCaption: err?.messageCaption ?? '',
    title: '',
  };
  appDispatch(appActions.showErrorDialog(payload));
};

export const hiddenErrorDialog = () => {
  appDispatch(appActions.hiddenErrorDialog());
};

export const hiddenCommonDialog = () => {
  appDispatch(appActions.hiddenCommonDialog());
}