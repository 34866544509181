import { UnitOfMeasure } from "../enums/unit-of-measure.enum";

export const UnitOfMeasureConstants = {
  Captions: {
    [UnitOfMeasure.Day]: "CommonResource.lblDays",
    [UnitOfMeasure.Week]: "CommonResource.lblWeeks",
    [UnitOfMeasure.Month]: "CommonResource.lblMonths",
    [UnitOfMeasure.Year]: "CommonResource.lblYears",
  }
}
