import {
  ActionButton,
  Checkbox,
  DefaultButton,
  FocusZone,
  FocusZoneDirection,
  IButtonStyles,
  ISearchBox,
  ITextField,
  Icon,
  IconButton,
  Label,
  List,
  Panel,
  SearchBox,
  Separator,
} from '@fluentui/react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyDownCode } from '../../../constants/key-down-code.constant';
import { isListEqualSimple } from '../../../utils/common.utils';
// import { useFocusPanel } from '../../common/hooks/useFocusPanel';
import { colors } from '../../../constants/common.constants';
import { distinctArray } from '../../../utils/array';
import { buttonCloseClassName, buttonSaveClassName, onActionKeyDown } from '../../../utils/event';
import PrimaryButton from '../Button/PrimaryButton';
import { NoData } from '../NoData/NoData';
import TextField from '../TextField/TextField';
import { IMultipleSelectionField, IMultipleSelectionFieldImperative, IMultipleSelectionFieldOption } from './IMultipleSelectionField';
import './index.scss';
// import { useDiscardPrompt } from '../../common/hooks/useDiscardPrompt';

const MultipleSelectionInputClassName = 'multiple-selection-input';

export const OtherOptionWithInputKey = 'other-with-input';

const MultipleSelectionField = forwardRef(
  <T extends IMultipleSelectionFieldOption>(props: IMultipleSelectionField<T>, ref: React.Ref<IMultipleSelectionFieldImperative>) => {
    // Props
    const {
      selectedOptions,
      isDisplayClearButton,
      textFieldProps,
      panelProps,
      isAutoFocusSearchBox,
      onOptionChanges,
      onSearchTextChanges,
      onAfterClickTextField,
      selectAllPermission,
      renderNoData,
      renderSubBody,
      subText,
      isLightDismiss,
      maxNumberSelection,
      otherOptionWithInput: otherOptionWithInputProps,
    } = props;

    //States
    const [isOpenPanel, setIsOpenPanel] = useState(props.isOpenPanel ?? false);
    const [currentValue, setCurrentValue] = useState<string | undefined>('');
    const [currentSelectedOptions, setCurrentSelectedOptions] = useState<T[]>([]);
    const [isFocused, setFocused] = useState<boolean>(false);
    const [isChanged, setChanged] = useState(false);
    // Hooks
    const [translate] = useTranslation();
    const listRef = useRef<any>(null);
    // const { openDiscardPrompt } = useDiscardPrompt();

    const textFieldComponentRef = useRef<ITextField>(null);
    const searchBoxComponentRef = useRef<ISearchBox | null>(null);
    const showSelectAll = selectAllPermission ?? true;
    const originalValue = selectedOptions ?? [];

    const [otherInput, setOtherInput] = useState(otherOptionWithInputProps?.inputText);
    const otherInputRef = useRef<ITextField>(null);

    useImperativeHandle(ref, () => {
      return {
        resetValue: () => {
          setCurrentValue('');
        },
      } as IMultipleSelectionFieldImperative;
    });

    useEffect(() => {
      if (isAutoFocusSearchBox && searchBoxComponentRef.current && !isFocused) {
        searchBoxComponentRef.current.focus();
        setFocused(true);
      }
    }, [isFocused, isAutoFocusSearchBox, searchBoxComponentRef.current]);

    useEffect(() => {
      setCurrentSelectedOptions(selectedOptions ?? []);
      let value = '';
      if (selectedOptions && selectedOptions?.length > 0) {
        value = selectedOptions
          .filter((x) => (x.id as any) !== OtherOptionWithInputKey)
          .map((item) => item.description)
          .join(', ');
      }
      if (otherOptionWithInputProps && otherInput) {
        value += `${value !== '' ? ',' : ''} ${otherInput}`;
      }
      setCurrentValue(value + `${subText ?? ''}`);
    }, [selectedOptions]);

    const textFieldClick = useCallback(() => {
      setIsOpenPanel(true);
      if (onAfterClickTextField) {
        onAfterClickTextField();
      }
    }, [onAfterClickTextField]);

    const handleDismiss = () => {
      setIsOpenPanel(false);
      setCurrentSelectedOptions(selectedOptions ?? []);
      setFocused(false);

      if (
        selectedOptions === null ||
        selectedOptions === undefined ||
        !selectedOptions?.some((x) => (x.id as any) === OtherOptionWithInputKey)
      ) {
        setOtherInput('');
      }

      if (isAutoFocusSearchBox && textFieldComponentRef.current) {
        textFieldComponentRef.current.focus();
      }

      if (panelProps?.onDismiss) {
        panelProps?.onDismiss();
      }
    };
    const onDismiss = useCallback(() => {
      closePanel();
    }, [selectedOptions, isAutoFocusSearchBox, textFieldComponentRef.current, isChanged]);

    const closePanel = () => {
      handleDismiss();
    };

    useEffect(() => {
      if (listRef.current) {
        listRef.current.forceUpdate();
      }
      const isChanged = !isListEqualSimple(originalValue, currentSelectedOptions ?? []);
      setChanged(isChanged);
    }, [currentSelectedOptions]);

    const searchingTextChange = (value?: string) => {
      if (!isOpenPanel) {
        return;
      }
      if (onSearchTextChanges) {
        onSearchTextChanges(value || '');
      }
    };

    const actionButtonStyle = {
      textContainer: {
        fontSize: '15px',
        fontWeight: '500',
        textAlign: 'left',
        color: colors.grey190,
      },
      labelHovered: {
        color: colors.primarypurple,
      },
    } as IButtonStyles;

    const onRenderHeader = () => {
      return (
        <div className="multiple-selection-header-panel">
          {panelProps?.onRenderHeader ? panelProps.onRenderHeader(panelProps) : <span className="title">{panelProps?.title}</span>}

          <SearchBox
            componentRef={searchBoxComponentRef}
            placeholder={props?.searchPlaceholder ?? translate('CaptionResource.Caption38')}
            onChange={(_, newValue) => searchingTextChange(newValue)}
          />

          <IconButton
            className={`close-button ${buttonCloseClassName}`}
            iconProps={{ iconName: 'Cancel' }}
            onClick={onDismiss}
            tabIndex={-1}
          />
          {showSelectAll && (
            <div className="top-buttons-select-clear-all" style={{ height: '20px' }}>
              <ActionButton allowDisabledFocus onClick={onSelectAll} styles={actionButtonStyle} text={translate('AppInspect.SelectAll')} />
              <ActionButton
                allowDisabledFocus
                onClick={onClearAll}
                styles={actionButtonStyle}
                text={translate('CommonResource.ClearAll')}
              />
            </div>
          )}

          <Separator />
        </div>
      );
    };
    const onSelectAll = () => {
      setCurrentSelectedOptions(currentSelectedOptions.concat(props.options ?? []));
    };

    const onClearAll = () => {
      setCurrentSelectedOptions([]);
    };

    const checkboxOnChange = useCallback(
      (item: T, isChecked?: boolean) => {
        const newValue = !isChecked;
        if (!item) {
          return;
        }

        if (maxNumberSelection && maxNumberSelection === currentSelectedOptions.length && newValue) {
          return;
        }

        let newCurrentSelectedKeys = currentSelectedOptions ? [...currentSelectedOptions] : [];

        if (newValue) {
          //add the selected option
          if (item.isGroup) {
            newCurrentSelectedKeys = [
              ...newCurrentSelectedKeys,
              ...(props.options?.filter((x) => x.id !== item.id && x.groupKey === item.id) ?? []),
            ];
          } else {
            newCurrentSelectedKeys.push(item);
          }
        } else {
          //remove selected option
          const removeIds = [
            ...[item.id],
            ...(item.isGroup ? newCurrentSelectedKeys.filter((x) => x.groupKey === item.id).map((x) => x.id) : []),
          ];
          newCurrentSelectedKeys = newCurrentSelectedKeys.filter((m) => !removeIds.includes(m.id));
        }
        setCurrentSelectedOptions(newCurrentSelectedKeys);
      },
      [currentSelectedOptions, props.options]
    );

    const onRenderCell = (item: T, index?: number) => {
      let isChecked = false;

      if (currentSelectedOptions && currentSelectedOptions.length > 0 && item.id) {
        if (item.isGroup) {
          //case item is group
          const groupOptionKeys = props.options?.filter((x) => x.id != item.id && x.groupKey === item.id).map((x) => x.id) ?? [];
          isChecked = groupOptionKeys.every((key) => currentSelectedOptions.some((x) => x.id == key));
        } else {
          isChecked = currentSelectedOptions.some((m) => m.id === item.id);
        }
      }

      const checkboxLabel =
        item?.data !== undefined && item?.data?.title !== undefined && item?.data?.title !== '' ? item.data.title : item.description;

      return (
        <>
          <Checkbox
            className={`multiple-selection-checkbox ${item.isHiddenCheckbox ? 'hidden-checkbox' : ''}`}
            key={index}
            data-is-focusable={true}
            label={checkboxLabel}
            onRenderLabel={(checkboxProps, defaultRenderer) => {
              if (props.onRenderLabelCheckbox) {
                return props.onRenderLabelCheckbox(item, checkboxProps, defaultRenderer);
              }
              if (defaultRenderer) {
                return defaultRenderer?.(checkboxProps);
              }
              return <></>;
            }}
            checked={isChecked}
            onChange={() => checkboxOnChange(item, isChecked)}
            styles={item.isGroup ? { text: { fontWeight: 600, color: 'red' } } : {}}
            disabled={props.disabledCheckbox}
          />
        </>
      );
    };

    const isSelectedOtherWithInput = () => currentSelectedOptions?.some((x) => (x?.id as any) === OtherOptionWithInputKey);

    const onRenderBody = () => {
      const enterHandler = (ev?: any) => {
        if (ev?.code === KeyDownCode.Enter || ev?.code === KeyDownCode.NumpadEnter) {
          applyClick();
        }
      };

      return (
        <div className="multiple-selection-body-panel" data-is-scrollable="true">
          <FocusZone style={{ height: '100%' }} direction={FocusZoneDirection.vertical} onKeyDown={enterHandler}>
            {props.options?.length ? (
              <>
                <List ref={listRef} onRenderCell={onRenderCell as any} items={props?.options ?? []}></List>
                {otherOptionWithInputProps && isSelectedOtherWithInput() && (
                  <TextField
                    maxLength={otherOptionWithInputProps?.maxLength}
                    required={otherOptionWithInputProps?.required}
                    value={otherInput}
                    errorMessage={otherOptionWithInputProps?.required && !otherInput ? translate('CaptionResource.Required') : ''}
                    onChange={(_, newValue) => {
                      setOtherInput(newValue || '');
                    }}
                    autoFocus={true}
                    componentRef={otherInputRef}
                  />
                )}

                {renderSubBody && renderSubBody()}
              </>
            ) : (
              (renderNoData && renderNoData()) || (
                <div className="no-data-wrapper">
                  <NoData text={translate('CommonResource.EmptyMessageHits')} />
                </div>
              )
            )}
          </FocusZone>
        </div>
      );
    };

    const applyClick = () => {
      if (props.checkChanged && !isChanged) {
        onDismiss();
        return;
      }

      if (otherOptionWithInputProps && otherOptionWithInputProps.required && isSelectedOtherWithInput() && !otherInput) {
        otherInputRef.current?.focus();
        return;
      }

      const values = distinctArray(
        currentSelectedOptions.filter((x) => !x.isGroup),
        'id'
      );

      let otherInputTxt = otherInput;
      if (!values.some((x) => (x.id as any) === OtherOptionWithInputKey)) {
        setOtherInput('');
        otherInputTxt = '';
      }

      const newTextValue = values.map((m: any) => m.description).join(', ');
      setCurrentValue(newTextValue + `${subText ?? ''}`);
      setIsOpenPanel(false);
      setFocused(false);
      if (onOptionChanges) {
        onOptionChanges(values, otherInputTxt);
      }
    };

    const onRenderFooter = () => {
      return (
        <div className="multiple-selection-footer-panel">
          <PrimaryButton text={props.applyText ?? translate('CommonResource.Apply')} className={buttonSaveClassName} onClick={applyClick} />
          <DefaultButton text={props.closeText ?? translate('CommonResource.cmdClose')} onClick={onDismiss} />
        </div>
      );
    };

    const textFieldKeyDownCapture = useCallback(
      (ev: any) => {
        if (ev.code === KeyDownCode.Space && ev.target?.click && !isOpenPanel) {
          ev.target.click();
        }
      },
      [isOpenPanel]
    );

    const clearDataIconClick = useCallback(() => {
      if (textFieldProps?.disabled === true) return;

      setCurrentValue('');
      setOtherInput('');
      setCurrentSelectedOptions([]);

      if (props?.onAfterClearIconClick) {
        props.onAfterClearIconClick();
      }
    }, [props?.onAfterClearIconClick]);

    const openPanelIconClick = useCallback(() => {
      if (!textFieldProps?.disabled) {
        textFieldClick();
      }
    }, [textFieldProps?.disabled, textFieldClick]);

    // useFocusPanel(isOpenPanel);

    return (
      <>
        {props.renderTextField ? (
          props.renderTextField(props)
        ) : (
          <>
            <Label required={textFieldProps?.required}>{textFieldProps?.label}</Label>
            <TextField
              {...textFieldProps}
              required={false}
              readOnly
              value={currentValue}
              className={`${textFieldProps?.className || ''} multiple-selection-text-field`}
              inputClassName={`${MultipleSelectionInputClassName} ${textFieldProps?.inputClassName ?? ''}`}
              componentRef={textFieldComponentRef}
              label={undefined}
              onClick={textFieldClick}
              onKeyDown={textFieldKeyDownCapture}
              onRenderInput={(props, defaultRenderer) => (
                <>
                  {defaultRenderer && defaultRenderer(props)}
                  <div className={`ms-Dropdown-caretDownWrapper icon-wrapper ${props?.disabled ? 'icon-wrapper-disabled' : ''}`}>
                    {(!isDisplayClearButton || (isDisplayClearButton && !currentValue)) && (
                      <Icon iconName="ChevronDown" onClick={openPanelIconClick} />
                    )}
                    {isDisplayClearButton && currentValue && <Icon iconName="Cancel" onClick={clearDataIconClick} />}
                  </div>
                </>
              )}
            />
          </>
        )}

        {isOpenPanel && (
          <Panel
            {...panelProps}
            className={`multiple-selection-panel ${panelProps?.className || ''}`}
            isOpen={isOpenPanel}
            isLightDismiss={isLightDismiss ?? true}
            onKeyDown={(e) => {
              onActionKeyDown(e);
            }}
            tabIndex={0}
            onDismiss={onDismiss}
            onRenderHeader={onRenderHeader}
            onRenderBody={onRenderBody}
            onRenderFooter={panelProps?.onRenderFooter ?? onRenderFooter}
            hasCloseButton={false}
            layerProps={{ eventBubblingEnabled: false }}
          />
        )}
      </>
    );
  }
);

export default MultipleSelectionField;
