import { IconButton } from '@fluentui/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTracking } from '../../../common/hooks/useTracking';
import PrimaryButton from '../../../common/ui/Button/PrimaryButton';
import { renderControlFunctions } from '../../../common/ui/FilterControls/FilterControls';
import { OwnerFilterData } from '../../../common/ui/FilterControls/NewOwnership/NewOwnership';
import { Icon } from '../../../common/ui/Icon/Icon';
import { OnixTooltip } from '../../../common/ui/OnixTooltip/OnixTooltip';
import { Modules } from '../../../constants/modules.constants';
import { TrackingEvent } from '../../../constants/tracking-event.constant';
import { EquipmentActiveStatuses } from '../../../enums/equipment-active-status.enum';
import { FilterControl } from '../../../enums/filter-control.enum';
import { FieldFilter, ISearchField } from '../../../models/filter-controls/search-field.model';
import { equipmentFilterActions, equipmentFilterSelector } from '../../../states/equipment/equipmentFilterSlice';
import { equipmentActions, equipmentSelector } from '../../../states/equipment/equipmentSlice';
import { appDispatch } from '../../../states/store';
import { getAuthenticatedProfile } from '../../../utils/localStorage.utils';
import { EquipmentFilterFields, getEquipmentFilterFields, renderTitleCustomFunctions, searchFunctions } from './FilterFields';

type Props = {
  equipmentDetailPanelRef?: React.RefObject<any>;
};

const EquipmentFilters = ({ equipmentDetailPanelRef }: Props) => {
  const [translate] = useTranslation();
  const { totalItems, totalFilters } = useSelector(equipmentSelector);
  const [searchFields, setSearchFields] = useState<ISearchField[]>([]);
  const { filtersList } = useSelector(equipmentFilterSelector);

  const { trackEvent } = useTracking({ module: Modules.Equipment });

  const onRenderFilterControl = (field?: ISearchField) => {
    if (field !== undefined) {
      const EquipmentFilterControl = renderControlFunctions[field.control];
      if (EquipmentFilterControl !== undefined) {
        const keys = getEquipmentFilterFields(translate);
        let labelAdditionalInfo = null;
        if (keys) {
          if (field.key === EquipmentFilterFields[EquipmentFilterFields.EquipmentIdentifier]) {
            const content = (
              <>
                <h5 style={{ fontWeight: 500 }}>{translate('CaptionResource.Caption56')}</h5>
                <div dangerouslySetInnerHTML={{ __html: translate('CaptionResource.SearchDocumentById') }}></div>
              </>
            );

            labelAdditionalInfo = (
              <>
                {' '}
                <OnixTooltip content={content}>
                  <Icon style={{ marginLeft: 4 }} iconName="Info" />
                </OnixTooltip>
              </>
            );
          }
          if (field.key === EquipmentFilterFields[EquipmentFilterFields.SecondaryQRCode]) {
            const content = (
              <>
                <h5 style={{ fontWeight: 500 }}>{translate('CaptionResource.Caption56')}</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: translate('CaptionResource.SearchDocumentByOnixID'),
                  }}
                ></div>
              </>
            );

            labelAdditionalInfo = (
              <>
                {' '}
                <OnixTooltip content={content} closeDelay={500}>
                  <Icon style={{ marginLeft: 4 }} iconName="Info" />
                </OnixTooltip>
              </>
            );
          }
        }

        const onSearchFunc = searchFunctions[field.key];
        const onRenderTitleFunc = renderTitleCustomFunctions[field.key];

        return (
          <>
            <EquipmentFilterControl
              key={field.key}
              field={field}
              label={field.label}
              value={field.value}
              labelAdditionalInfo={labelAdditionalInfo}
              onSearch={onSearchFunc}
              onRenderTitleCustom={onRenderTitleFunc}
              //onDataChanged={onDataChange}
              onInvokeApplyFilters={onApplyFilters}
              detailPanelRef={equipmentDetailPanelRef}
            />
          </>
        );
      }
    }
    return undefined;
  };

  const ownershipFields = [OwnerFilterData.OwnersProperty, OwnerFilterData.LocationProperty] as string[];

  const setFilters = (fields: ISearchField[]) => {
    const filtersData = filtersList;

    // By default, set Active status filter
    const activeFieldIdx = fields?.findIndex((x) => x.key === EquipmentFilterFields[EquipmentFilterFields.ActiveStatus]);
    if (activeFieldIdx > -1) {
      fields[activeFieldIdx].value = EquipmentActiveStatuses.Active.toString();
    }

    fields.forEach((field) => {
      if (field.control !== FilterControl.OWNER_SHIP) {
        const fieldData = filtersData.find((x) => x.fieldName === field.key);
        if (fieldData) {
          field.value = fieldData.fieldValue;
          if (fieldData.options && fieldData.options.length > 0) {
            field.options = fieldData.options;
          }
        }
      }
      if (field.control === FilterControl.OWNER_SHIP) {
        const authenProfile = getAuthenticatedProfile();
        const ownerFilters = filtersData.filter((x) => ownershipFields.includes(x.fieldName));
        if (ownerFilters.length === 0) {
          ownerFilters.push({
            fieldName: OwnerFilterData.OwnersProperty,
            fieldValue: authenProfile.expressId?.toString(),
            options: [{ key: authenProfile.expressId, text: authenProfile.expressName }] as any[],
          });
        }
        field.value = new OwnerFilterData(ownerFilters);
      }
    });

    return fields;
  };

  const getFilters = useCallback(() => {
    let result: FieldFilter[] = [];
    searchFields.forEach((item) => {
      if (item.control === FilterControl.OWNER_SHIP) {
        if (item.controlRef && typeof item.controlRef.getFilters === 'function') {
          const filters = item.controlRef.getFilters();
          if (filters) {
            result = result.concat(filters);
          }
        }
      } else {
        if (item.controlRef && typeof item.controlRef.getFilter === 'function') {
          const filter = item.controlRef.getFilter();
          if (filter) {
            result.push(filter);
          }
        }
      }
    });

    return result;
  }, [searchFields]);

  const onApplyFilters = () => {
    const filters = getFilters();
    appDispatch(equipmentActions.setRefinedBy(filters));
    appDispatch(equipmentActions.updateSearchId());
    appDispatch(equipmentFilterActions.setHasFilters(true));
    appDispatch(equipmentFilterActions.setFiltersList(filters));

    trackEvent(TrackingEvent.Equipment.SearchAction, { filters: filters });
  };

  const onInitSearchFields = () => {
    let fields = setFilters(getEquipmentFilterFields(translate));
    setSearchFields(fields);
  };

  const closePanel = () => {
    appDispatch(equipmentFilterActions.hidePanel());
  };

  const filterTitle = `${translate('CommonResource.Filter')} ${totalFilters > 0 ? `(${totalFilters})` : ''}`;

  const onClearFilters = useCallback(() => {
    searchFields.forEach((item) => {
      if (item.controlRef && typeof item.controlRef.onClearData === 'function') {
        item.controlRef.onClearData();
      }
    });
  }, [searchFields]);

  const onClearFilter = () => {
    appDispatch(equipmentFilterActions.setFiltersList([]));
    onClearFilters();
    onApplyFilters();
  };

  useEffect(() => {
    onInitSearchFields();
  }, []);

  return (
    <div className="px-5">
      <div className="header border-b pb-3 sticky top-0 z-[1] bg-white overflow-hidden py-[20px]">
        <div className="flex items-center justify-between">
          <span className="text-[20px] font-semibold text-grey160">{filterTitle}</span>
          <div>
            <OnixTooltip content={translate('CommonResource.ClearAll')}>
              <IconButton iconProps={{ iconName: 'ClearFilter' }} onClick={onClearFilter} />
            </OnixTooltip>
            <OnixTooltip content={translate('CaptionResource.Caption53')}>
              <IconButton iconProps={{ iconName: 'Cancel' }} onClick={closePanel} />
            </OnixTooltip>
          </div>
        </div>
        <div className="text-[12px] mt-1">
          {totalItems} {translate('CommonResource.lblItem')}
        </div>
        <PrimaryButton className="w-[78px] mt-3 ml-[1px]" text={translate('CommonResource.Apply')} onClick={onApplyFilters} />
      </div>
      <div className="content py-3">
        <div className="flex flex-col">
          {searchFields.map((item) => (
            <div key={item.key}>{onRenderFilterControl(item)}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EquipmentFilters;
