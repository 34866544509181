import { DefaultButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { hideModal } from '../../../services/modal.service';
import { ConfirmModalProps } from '../../../types/modal.type';
import { cn } from '../../../utils/css.utils';
import PrimaryButton from '../Button/PrimaryButton';
import Title from '../Title/Title';
import CommonModal, { CommonModalProps } from './CommonModal';

type Props = CommonModalProps & ConfirmModalProps;

const ConfirmModal = ({
  id,
  isOpen,
  header,
  children,
  acceptButton: acceptButtonProp,
  cancelButton: cancelButtonProp,
  onAccept: onAcceptConfirm,
  styles: stylesProp,
}: Props) => {
  const [translate] = useTranslation();

  const acceptButton = acceptButtonProp ?? translate('CaptionResource.Yes');
  const cancelButton = cancelButtonProp ?? translate('CaptionResource.No');

  const closeModal = () => {
    hideModal(id || '');
  };

  return (
    <CommonModal
      isOpen={isOpen}
      header={header || <Title text={translate('CaptionResource.CAPTION_CONFIRM')} />}
      children={<div className={cn('pr-4 py-4')}>{children || 'Are you sure perform the action?'}</div>}
      footer={
        <>
          <PrimaryButton
            text={acceptButton}
            onClick={() => {
              closeModal();
              onAcceptConfirm && onAcceptConfirm();
            }}
          />
          <DefaultButton text={cancelButton} onClick={closeModal} />
        </>
      }
      footerGreyOut={false}
      onDismiss={closeModal}
      styles={stylesProp}
    />
  );
};

export default ConfirmModal;
