import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export type UseElementResizeWatcherProps = {
  element: HTMLElement | null;
  onResize?: (size: ResizeObserverSize) => void;
};

export type UseElementResizeWatcherReturn = {
  size: ResizeObserverSize;
};

export const useElementResizeWatcher = ({ element, onResize }: UseElementResizeWatcherProps): UseElementResizeWatcherReturn => {
  const [elementSize, setElementSize] = useState<ResizeObserverSize>({
    blockSize: 0,
    inlineSize: 0,
  });

  const increaseResizeCount = debounce<ResizeObserverCallback>((ev) => {
    const entry = ev[0];

    if (!entry) {
      return;
    }

    const contentSize = entry.contentBoxSize[0];
    const sizeChanged = contentSize?.blockSize !== elementSize.blockSize || contentSize?.inlineSize !== elementSize.inlineSize;

    if (sizeChanged) {
      setElementSize({
        blockSize: contentSize?.blockSize || 0,
        inlineSize: contentSize?.inlineSize || 0,
      });
    }
  }, 500);

  const resizeObserver = useMemo<ResizeObserver>(() => new ResizeObserver(increaseResizeCount), []);

  useEffect(() => {
    if (!element) {
      return;
    }

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
    };
  }, [element]);

  useEffect(() => {
    onResize?.(elementSize);
  }, [elementSize]);

  return {
    size: elementSize,
  };
};
